import { Switch, Route } from "react-router-dom"
import { CustomReportsView } from "../views"
import { TopBar } from "../components"

function CustomRouter() {
    const PROFILE_OPTIONS = []
    const NAVBAR_OPTIONS = []

    return (
        <div className="flex flex-col h-full">
            <TopBar profileOptions={PROFILE_OPTIONS} navOptions={NAVBAR_OPTIONS} />
            <div className="grow overflow-auto">
                <Switch>
                    <Route path="/custom">
                        <CustomReportsView />
                    </Route>
                </Switch>
            </div>
        </div>
    )
}

export default CustomRouter
