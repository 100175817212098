import React from "react"

function LowWindRiskIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M13.5 16c0 1.65-1.35 3-3 3s-3-1.35-3-3h2c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1H2v-2h8.5c1.65 0 3 1.35 3 3ZM19 5.5C19 3.57 17.43 2 15.5 2S12 3.57 12 5.5h2c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5S16.33 7 15.5 7H2v2h13.5C17.43 9 19 7.43 19 5.5ZM22 12h-6v2h6v-2Z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default LowWindRiskIcon
