import React, { Children } from "react"
import ArrowRight from "../Icons/ArrowRight"

import "./MenuItem.css"

function MenuItem(props) {
    const { label, icon: IconComponent, onClick = () => null, children } = props
    return (
        <>
            <div className="menu-item" onClick={onClick}>
                {IconComponent && (
                    <div className="select__icon">
                        <IconComponent fill="#666d74" />
                    </div>
                )}
                <div className="select__value menu-item__value">
                    {label}
                    {children && (
                        <div className="menu-item__value__arrow">
                            <ArrowRight fill="#666D74" />
                        </div>
                    )}
                </div>
                {children && <div className="menu__children menu-item__children left">{children}</div>}
            </div>
        </>
    )
}

export default MenuItem
