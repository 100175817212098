import React, { useCallback, useState } from "react"

// Modules
import { withRouter } from "react-router"
import { Link as RouterLink } from "react-router-dom"

// Components
import Copyright from "../ui/Copyright/Copyright"
// Views
// Context providers / Utils
import networking from "../Util/Networking"

// Hooks
// Material-UI *

// Styles
import "./SignUp.css"

const Sign = function SignUp({ history }) {
    const [state, setState] = useState({
        name: "",
        email: "",
        company: "",
    })

    const [submitEnabled, setSubmitEnabled] = useState("")
    const [messageTitle, setMessageTitle] = useState("Contact ClimateAI's Sales Team by filling the below form.")

    const handleSignUp = useCallback(
        async (event) => {
            event.preventDefault()
            const { email, name, company } = event.target.elements
            resetForm()
            try {
                // Call to backend API
                let payload = {
                    email: email.value,
                    name: name.value,
                    company: company.value,
                }
                networking
                    .post("/api/v1/contact/contact_form", payload)
                    .then((res) => res.data)
                    .then(() => {
                        history.push("/signup/confirmation")
                    })
                    .catch((error) => {
                        console.log("There was a problem with consulting endpoint")
                        console.log(error)
                    })
            } catch (error) {
                alert(error)
            }
        },
        [history]
    )

    function areFieldsFilled(state) {
        let status = "enabled"
        Object.values(state).forEach((prop) => {
            if (prop === "") {
                status = ""
            }
        })
        return status
    }

    function onChangeState(propName, value) {
        let newState = { ...state, [propName]: value }
        setState(newState)
        setSubmitEnabled(areFieldsFilled(newState))
    }

    function resetForm() {
        setMessageTitle("We are sending your information!")
        setSubmitEnabled("")
        setState({
            name: "",
            email: "",
            company: "",
        })
    }

    return (
        <div className="sign-up">
            <div className="sign-up__container">
                <img alt="Climate AI logo" className="confirmation-screens__logo" src="/Climate-AI-Color-Logo.png" />

                <div className="sign-up__content">
                    <div className="sign-up__title">{messageTitle}</div>

                    {/* <div className="sign-up__checkbox-container">
                        <span className={checkmark}></span>
                        <input
                            type="checkbox"
                            className="sign-up__checkbox"
                            value="remember"
                            onClick={changeCheckmark}
                        />

                        <div className="sign-up__checkbox-text">I want to receive my account information</div>
                    </div> */}

                    <form onSubmit={handleSignUp} className="sign-up__form">
                        <input
                            className="sign-up__text-input"
                            placeholder="First and last name"
                            type="text"
                            id="name"
                            name="name"
                            required
                            value={state.name}
                            onChange={(e) => {
                                onChangeState("name", e.target.value)
                            }}
                        ></input>
                        <input
                            className="sign-up__text-input"
                            placeholder="Email address"
                            type="email"
                            id="email"
                            name="email"
                            required
                            value={state.email}
                            onChange={(e) => {
                                onChangeState("email", e.target.value)
                            }}
                        ></input>
                        <input
                            className="sign-up__text-input"
                            placeholder="Company"
                            type="text"
                            id="company"
                            name="company"
                            required
                            value={state.company}
                            onChange={(e) => {
                                onChangeState("company", e.target.value)
                            }}
                        ></input>

                        <div className="sign-up__button-container">
                            <RouterLink to="/">
                                <button className="sign-up__cancel-button">Cancel</button>
                            </RouterLink>

                            <button
                                type="submit"
                                className={"sign-up__confirm-button " + submitEnabled}
                                disabled={submitEnabled !== "enabled"}
                            >
                                Continue
                            </button>
                        </div>
                    </form>
                </div>

                <div className="sign-up__copyright">
                    <Copyright />
                </div>
            </div>
        </div>
    )
}

export default withRouter(Sign)
