import React from "react"

function HumidityIcon(props) {
    const {} = props
    return (
        <>
            <svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.5 13.8c0-3.32 2.67-7.25 8-11.8 5.33 4.55 8 8.48 8 11.8 0 4.98-3.8 8.2-8 8.2s-8-3.22-8-8.2Zm13.997.2H6.503a7.371 7.371 0 0 1-.003-.2c0-2.34 1.95-5.44 6-9.14 4.05 3.7 6 6.79 6 9.14 0 .067 0 .134-.003.2Z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default HumidityIcon
