import React from "react"

function InsufficientSolarRadiationIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 23h2v-4h-2v4Zm0-6.1a5.002 5.002 0 0 0 6-4.9 5.002 5.002 0 0 0-6-4.9v9.8ZM11 5h2V1h-2v4Zm8 6h4v2h-4v-2Zm-2.75-4.66 2.47-2.47 1.41 1.41-2.47 2.47-1.41-1.41ZM23 17v6h-6l2.293-2.293-3.05-3.05 1.414-1.414 3.05 3.05L23 17Z"
                    fill="#666D74"
                />
                <path fill="#666D74" d="M5 6.774h2v6H5zM5 14.774h2v2H5z" />
            </svg>
        </>
    )
}

export default InsufficientSolarRadiationIcon
