import React from "react"
// Modules
import { useContext, useEffect, useState } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { ToastContainer, toast } from "react-toastify"

// Components
import CropTab from "./components/CropTab"
import CropAlert from "./components/CropAlert"

// Views

// Context providers / Utils
import { AuthContext } from "../../../Auth/Auth"
import { UIAlertContext } from "../../../ui/UIAlert/UIAlert"
import { getUserAlerts, getCropVarietyConfigs, updateCropVarietyConfigs } from "../../../Util/AlertsMiddleware"
import { parseCSVFile, toCSVFile } from "../../../Util/CSVParser"
// Hooks

// Material-UI *

// Styles
import "react-toastify/dist/ReactToastify.css"
import "./AlertSettings.css"
import "./components/Alert.css"

const AlertSettings = (props) => {
    const { stacked = false } = props

    const [crops, setCrops] = useState({
        crops: {
            name: "Loading",
        },
    })
    const [loading, setLoading] = useState(false)

    const { currentUser, featurePermissions } = useContext(AuthContext)
    const { confirmationModal } = useContext(UIAlertContext)

    async function handleVarietyFileDownload(crop, variety) {
        setLoading(true)
        const userToken = await currentUser.getIdToken()
        const { data } = await getCropVarietyConfigs(userToken, crop, variety)
        setLoading(false)
        // download json, directly from stack overflow lol

        let result = ""
        try {
            result = toCSVFile(crop, variety, data)
        } catch (err) {
            toast.error("Error: There was an unexpected error while downloading the alerts -> " + err)
            return
        }

        const dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(result)
        const downloadAnchorNode = document.createElement("a")
        downloadAnchorNode.setAttribute("href", dataStr)
        downloadAnchorNode.setAttribute("download", crop + "-" + variety + ".csv")
        document.body.appendChild(downloadAnchorNode) // required for firefox
        downloadAnchorNode.click()
        downloadAnchorNode.remove()
    }

    async function handleVarietyFileUpload(crop, variety, file) {
        // let uploadedFile = e.target.files[0]

        const success = await confirmationModal("Are you sure?", "This action will replace all your alerts.", [])

        if (!success) return

        setLoading(true)
        let result = null

        try {
            result = await parseCSVFile(5, file)
        } catch (err) {
            setLoading(false)
            toast.error(err)
            return
        }

        const userToken = await currentUser.getIdToken()
        await updateCropVarietyConfigs(userToken, crop, variety, result)

        setTimeout(async () => {
            await fetchAlerts()
            setLoading(false)
            toast.success("Alert configurations successfully updated!")
        }, 1000)
    }

    async function fetchAlerts() {
        try {
            let userToken = await currentUser.getIdToken()
            getUserAlerts(userToken).then((res) => {
                let { notify_via_table, notify_via_email, ...crops } = res.data
                // setNotifyVia({
                //     table: notify_via_table || false,
                //     email: notify_via_email || false,
                // })
                setCrops(crops)
            })
        } catch (err) {
            console.log("Error getting user alerts ", err)
        }
    }

    useEffect(() => {
        fetchAlerts()
    }, [])

    function handleAlertStatusChange(status, mPath) {
        setCrops({
            ...crops,
            [mPath.crop]: {
                ...crops[mPath.crop],
                [mPath.variety]: {
                    ...crops[mPath.crop][mPath.variety],
                    [mPath.risk]: {
                        ...crops[mPath.crop][mPath.variety][mPath.risk],
                        [mPath.alert_id]: {
                            ...crops[mPath.crop][mPath.variety][mPath.risk][mPath.alert_id],
                            active: status,
                        },
                    },
                },
            },
        })
    }

    function handleNewAlert() {
        fetchAlerts()
    }

    return (
        <>
            <div className="alert-settings">
                <div className="alert-settings__container">
                    <div className="alert-settings__menu">
                        <div className="alert-settings__alerts-menu-container">
                            <Tabs>
                                <TabList className="alert-settings__alerts__crop-menu-container">
                                    {Object.keys(crops).map((crop) => {
                                        let name = crops[crop].name || crop
                                        return (
                                            <Tab key={"tab-" + crop}>
                                                <CropTab name={name} />
                                            </Tab>
                                        )
                                    })}
                                </TabList>
                                <div className="alert-settings__alerts-container">
                                    {Object.keys(crops).map((crop) => {
                                        let cropObj = crops[crop]
                                        return (
                                            <TabPanel key={"tabpanel-" + crop}>
                                                <CropAlert
                                                    cropObj={{ ...cropObj }}
                                                    path={{ crop: crop }}
                                                    onVarietyFileDownload={handleVarietyFileDownload}
                                                    onVarietyFileUpload={handleVarietyFileUpload}
                                                    isDownloadEnabled={featurePermissions?.alerts?.view}
                                                    isUploadEnabled={featurePermissions?.alerts?.add}
                                                    stackedAlertsView={stacked}
                                                    onAlertStatusChange={handleAlertStatusChange}
                                                    reloadAlertSettings={handleNewAlert}
                                                />
                                            </TabPanel>
                                        )
                                    })}
                                </div>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default AlertSettings
