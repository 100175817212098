export function capitalizeFirstCharacter(input_string) {
    return input_string[0].toUpperCase() + input_string.substring(1);
}

export function capitalizeFirstCharacterOfEachWord(sentence) {
    const words = sentence.split(" ");

    return words.map((word) => { 
        return word[0].toUpperCase() + word.substring(1); 
    }).join(" ");
}