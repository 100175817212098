// import ColdIcon from "../ui/Icons/AlertSettings/ColdIcon"
// import ColdSoilIcon from "../ui/Icons/AlertSettings/ColdSoilIcon"
// import ComulativeDroughtIcon from "../ui/Icons/AlertSettings/ComulativeDroughtIcon"
// import ComulativePrecipitationIcon from "../ui/Icons/AlertSettings/ComulativePrecipitationIcon"
// import DroughtIcon from "../ui/Icons/AlertSettings/DroughtIcon"
// import DrySoilIcon from "../ui/Icons/AlertSettings/DrySoilIcon"
// import ExcessiveSolarRadIcon from "../ui/Icons/AlertSettings/ExcessiveSolarRadIcon"
// import GrowingDegreeDaysIcon from "../ui/Icons/AlertSettings/GrowingDegreeDaysIcon"
// import HeatSoilIcon from "../ui/Icons/AlertSettings/HeatSoilIcon"
// import HighHumidityIcon from "../ui/Icons/AlertSettings/HighHumidityIcon"
// import HighWindDayIcon from "../ui/Icons/AlertSettings/HighWindDayIcon"
// import InsufficientChillTemperatureIcon from "../ui/Icons/AlertSettings/InsufficientChillTemperatureIcon"
// import InsufficientHighTemperatureIcon from "../ui/Icons/AlertSettings/InsufficientHighTemperatureIcon"
// import InsufficientSolarRadIcon from "../ui/Icons/AlertSettings/InsufficientSolarRadIcon"
// import LowHumidityIcon from "../ui/Icons/AlertSettings/LowHumidityIcon"
// import LowWindDayIcon from "../ui/Icons/AlertSettings/LowWindDayIcon"
// import PestDegreeIcon from "../ui/Icons/AlertSettings/PestDegreeDays"
// import PrecipitationIcon from "../ui/Icons/AlertSettings/PrecipitationIcon"
// import SunIcon from "../ui/Icons/AlertSettings/SunIcon"
import InsufficientHighTemperatureIcon from "../ui/Icons/AlertSettings/InsufficientHighTemperatureIcon"
import ChillHoursStressIcon from "../ui/Icons/RisksIcons/ChillHoursStressIcon"
import ColdRiskIcon from "../ui/Icons/RisksIcons/ColdRiskIcon"
import ComulativeDroughtRiskIcon from "../ui/Icons/RisksIcons/ComulativeDroughtRiskIcon"
import ComulativePrecipitationRiskIcon from "../ui/Icons/RisksIcons/ComulativePrecipitationRiskIcon"
import DroughtRiskIcon from "../ui/Icons/RisksIcons/DroughtRiskIcon"
import DrySoilRiskIcon from "../ui/Icons/RisksIcons/DrySoilRiskIcon"
import ExcessiveSolarRadiationIcon from "../ui/Icons/RisksIcons/ExcessiveSolarRadiationIcon"
import HeatStressRiskIcon from "../ui/Icons/RisksIcons/HeatStressRiskIcon"
import HighChillHoursIcon from "../ui/Icons/RisksIcons/HighChillHoursIcon"
import HighHumidityRiskIcon from "../ui/Icons/RisksIcons/HighHumidityRiskIcon"
import HighSoilTempRiskIcon from "../ui/Icons/RisksIcons/HighSoilTempRiskIcon"
import HighWindDayRiskIcon from "../ui/Icons/RisksIcons/HighWindDayRiskIcon"
import InsufficientChillTempIcon from "../ui/Icons/RisksIcons/InsufficientChillTempIcon"
import InsufficientSolarRadiationIcon from "../ui/Icons/RisksIcons/InsufficientSolarRadiationIcon"
import LowChillHoursIcon from "../ui/Icons/RisksIcons/LowChillHoursIcon"
import LowHumidityRiskIcon from "../ui/Icons/RisksIcons/LowHumidityRiskIcon"
import LowSoilTempRiskIcon from "../ui/Icons/RisksIcons/LowSoilTempRiskIcon"
import LowWindRiskIcon from "../ui/Icons/RisksIcons/LowWindDayRiskIcon"
import PestDegreeDaysIcon from "../ui/Icons/RisksIcons/PestDegreeDaysIcon"
import GrowingDegreeDaysIcon from "../ui/Icons/VariablesIcons/GrowingDegreeDaysIcon"
import PrecipitationIcon from "../ui/Icons/VariablesIcons/PrecipitationIcon"

export default function getRiskIcon(riskName) {
    switch (riskName) {
        case "risk_chill_hours_stress":
            return <ChillHoursStressIcon />
        case "risk_low_chill_hours":
            return <LowChillHoursIcon />
        case "risk_high_chill_hours":
            return <HighChillHoursIcon />
        case "risk_heat_stress":
            return <HeatStressRiskIcon />
        case "risk_cold":
            return <ColdRiskIcon />
        case "risk_insufficient_high_temp":
            return <InsufficientHighTemperatureIcon />
        case "risk_insufficient_low_temp":
            return <InsufficientChillTempIcon />
        case "risk_flood":
            return <PrecipitationIcon />
        case "risk_cumulative_flood":
            return <ComulativePrecipitationRiskIcon />
        case "risk_drought":
            return <DroughtRiskIcon />
        case "risk_cumulative_drought":
            return <ComulativeDroughtRiskIcon />
        case "risk_dry_soil":
            return <DrySoilRiskIcon />
        case "risk_wet_soil":
            return <ExcessiveSolarRadiationIcon />
        case "risk_low_soil_temperature":
            return <LowSoilTempRiskIcon />
        case "risk_high_soil_temperature":
            return <HighSoilTempRiskIcon />
        case "risk_low_humidity":
            return <LowHumidityRiskIcon />
        case "risk_high_humidity":
            return <HighHumidityRiskIcon />
        case "risk_growing_degree_days":
            return <GrowingDegreeDaysIcon />
        case "risk_pest_degree_days":
            return <PestDegreeDaysIcon />
        case "risk_low_wind_speed":
            return <LowWindRiskIcon />
        case "risk_high_wind_speed":
            return <HighWindDayRiskIcon />
        case "risk_insufficient_solar_radiation":
            return <InsufficientSolarRadiationIcon />
        case "risk_excessive_solar_radiation":
            return <ExcessiveSolarRadiationIcon />
        default:
            return null
    }
}
