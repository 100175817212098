import React from "react"

export default function DoubleCandleStick(optionsAvailable = {}) {
    const getOptionBoxes = () => {
        return (
            <g transform="translate(6,12) ">
                {Object.keys(optionsAvailable).map((opt, i) => {
                    let obj = optionsAvailable[opt]
                    return (
                        <>
                            <rect
                                key={"legend-opt" + i}
                                x="10"
                                width="15"
                                height="15"
                                strokeWidth="2"
                                stroke={obj.color}
                                y={10 + i * 30}
                                fill={obj.fill}
                            ></rect>

                            <text y={20 + i * 30} x="40" fill="#696969">
                                {obj.title}
                            </text>
                        </>
                    )
                })}

                <rect
                    x="10"
                    width="15"
                    height="15"
                    strokeWidth="2"
                    stroke="#878787"
                    y={10 + Object.keys(optionsAvailable).length * 30}
                    fillOpacity="0.1"
                    fill="#878787"
                ></rect>

                <text y={20 + Object.keys(optionsAvailable).length * 30} x="40" fill="#696969">
                    Historical
                </text>
            </g>
        )
    }

    return (
        <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
                <div>
                    <svg width="115" height="100" style={{ fontSize: 12 }}>
                        {getOptionBoxes()}
                    </svg>
                </div>
            </div>
            <svg width="115" height="110" style={{ fontSize: 12 }}>
                <g transform="translate(0,18) ">
                    <g transform="translate(5,0) ">
                        <rect width="2" x="9" height="20" fill="#878787"></rect>
                        <rect width="2" x="9" y="60" height="20" fill="#878787"></rect>
                        <rect width="20" x="0" y="39" height="2" fill="#878787"></rect>
                        <rect
                            rx="3"
                            width="20"
                            strokeWidth="2"
                            stroke="#878787"
                            height="40"
                            y="20"
                            fillOpacity="0.1"
                            fill="#878787"
                        ></rect>
                    </g>
                    <line x1="20" x2="50" y1="0" y2="0" stroke="#878787" strokeDasharray="1 3"></line>
                    <line x1="30" x2="50" y1="20" y2="20" stroke="#878787" strokeDasharray="1 3"></line>
                    <line x1="30" x2="50" y1="40" y2="40" stroke="#878787" strokeDasharray="1 3"></line>
                    <line x1="30" x2="50" y1="60" y2="60" stroke="#878787" strokeDasharray="1 3"></line>
                    <line x1="20" x2="50" y1="80" y2="80" stroke="#878787" strokeDasharray="1 3"></line>

                    <text y="5" x="50" fill="#696969">
                        95th
                    </text>
                    <text y="25" x="50" fill="#696969">
                        75th
                    </text>
                    <text y="45" x="50" fill="#696969">
                        50th
                    </text>
                    <text y="65" x="50" fill="#696969">
                        25th
                    </text>
                    <text y="83" x="50" fill="#696969">
                        5th
                    </text>
                </g>
            </svg>
        </div>
    )
}
