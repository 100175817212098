import React, { useState, useEffect } from "react"

const ActionBlock = (props) => {
    const { chartIcon = { active: null, default: null }, currentTab, title, current, select, hasAlert, id } = props

    const [isCurrent, setCurrent] = useState(false)

    function handleSelect() {
        select(currentTab)
    }

    useEffect(() => {
        if (current === currentTab) {
            setCurrent(true)
        } else {
            setCurrent(false)
        }
    }, [current, currentTab])

    return (
        <div
            id={`${id}-tab__weather-model`}
            className="action-block"
            style={{
                boxShadow: isCurrent ? "none" : "",
                background: isCurrent ? "#1C9690" : "none",
            }}
            onClick={handleSelect}
        >
            {hasAlert && <div className="weather-tabs__alert-dot"></div>}

            {/* TODO: are 'alt's useful in this context ? */}
            {/* TODO: icons seem to take more to load ?? Type Coercion?? */}
            {isCurrent ? (
                <img className="chart-action__icon" src={chartIcon.active} alt={`${title} selected icon`} />
            ) : (
                <img className="chart-action__icon" src={chartIcon.default} alt={`${title} default icon`} />
            )}

            <div
                style={{
                    color: isCurrent ? "#fff" : "",
                }}
                className="action-block__title"
            >
                {title}
            </div>
        </div>
    )
}

export default ActionBlock
