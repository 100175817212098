import React from "react"

import { Popover } from "../../../ui/Popover"
import "../../../ui/Icons/Styles/Icons.css"
function CropTableAlert(props) {
    const { value = {} } = props
    let count = value.count
    if (count > 0) {
        return (
            <Popover text={count + " alerts triggered"}>
                {count && <label className="alert-icon__indicator">{count}</label>}
            </Popover>
        )
    } else {
        return <div></div>
    }
}

export default CropTableAlert
