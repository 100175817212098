export function getYLabel(cas, fun, cons, va, un) {
    switch (cas) {
        case 1: {
            if (fun === "average") return `Average ${va} ${un}`
            else return `Total ${va} (${un})`
        }
        case 2: {
            if (cons) return `Number of events`
            else return `Number of days`
        }
        case 3: {
            return `Number of days`
        }
    }
}

export function getYFormat(cas, fun, cons, va, un) {
    switch (cas) {
        case 1: {
            return un
        }
        case 2:
        case 3: {
            return ""
        }
    }
}
