import React, { useRef } from "react"

import LabelItem from "./LabelItem"
import "./LabelSelect.css"

function LabelSelect(props) {
    const {
        labels,
        onSelectLabel = () => null,
        onDeleteLabel = () => null,
        actions: { close = () => null },
        hideDeleteButton = false,
        onAddLabelFilter = () => null,
        onRemoveLabelFilter = () => null,
        selectedLabels = [],
    } = props
    const labelSelectRef = useRef(null)

    function handleSelectLabel(...args) {
        onSelectLabel(...args)
        !hideDeleteButton && close()
    }

    function handleLabelCheckChange(e, label) {
        if (e.target.checked) {
            onAddLabelFilter(label)
        } else {
            onRemoveLabelFilter(label)
        }
    }

    const hashLabels = {}
    for (let _label of selectedLabels) hashLabels[_label.name] = true

    return (
        <>
            <div className="label-select" ref={labelSelectRef}>
                <div className="label-select__text">Select label</div>
                {labels && labels.length > 0 && (
                    <div className="label-select__list">
                        {labels.map((label) => (
                            <LabelItem
                                name={label.name}
                                color={label.color}
                                onDeleteLabel={onDeleteLabel}
                                onSelectLabel={handleSelectLabel}
                                hideDeleteButton={hideDeleteButton}
                                checked={hashLabels[label.name] || false}
                                onCheckChange={(e) => handleLabelCheckChange(e, label)}
                            />
                        ))}
                    </div>
                )}
            </div>
        </>
    )
}

export default LabelSelect
