import React, { useEffect, useRef, useState } from "react"
import useOutsideComponentClickHandler from "../../hooks/ClickOutsideHook"
import UploadIcon from "../Icons/UploadIcon"
import CheckIcon from "../Icons/CheckIcon"
import { toast } from "react-toastify"
import { getAllCrops } from "../../services/cropVariety.service"

const EMPTY_FIELD = {
    name: "",
    crop: "",
    variety: "",
    cropId: -1,
    varietyId: -1,
    plantingDateP: "",
    region: "",
    shapefile: "",
    uuid: "",
    wheather_variables: {},
    datasources: {},
    labels: [],
    triggered_alerts: {},
}
const REQUIRED_KEYS = [
    "name",
    "cropId",
    "varietyId",
    "region",
    // "shapefile",
    // "datasources",
]

const AddEditField = (props) => {
    const { doShow, field, onCancel = () => {}, onUpdate = () => {}, onSave = () => {}, canEditDatasource } = props

    const [oldNewField, setOldNewField] = useState(EMPTY_FIELD)
    const [showErrors, setShowErrors] = useState(false)
    const [error, setError] = useState("")

    const [crops, setCrops] = useState({})

    let isEditing = field && field.uuid ? true : false

    useEffect(() => {
        if (!doShow) {
            setOldNewField({ ...EMPTY_FIELD })
            setShowErrors(false)
        }
    }, [doShow])

    useEffect(() => {
        async function fetchData() {
            await getAllCrops().then(async (response) => {
                setCrops(response)
            })
        }
        fetchData()
    }, [])

    const isValidId = (id) => {
        return id !== "" && id !== -1 && id !== "-1" && id !== null && id !== undefined
    }

    const getCropVarietyInfo = (field) => {
        return {
            crop: isValidId(field.crop_id) ? crops[+field.crop_id].name : "",
            variety: isValidId(field.variety_id) ? crops[+field.crop_id].varieties[+field.variety_id].name : "",
            cropId: isValidId(field.crop_id) ? +field.crop_id : -1,
            varietyId: isValidId(field.variety_id) ? +field.variety_id : -1,
        }
    }

    useEffect(() => {
        if (isEditing) {
            setOldNewField({
                name: field.name,
                ...getCropVarietyInfo(field),
                plantingDateP: field.plantingDateP,
                region: field.region,
                shapefile: field.shapefile,
                uuid: field.uuid,
                wheather_variables: { ...field.wheather_variables },
                datasources: { ...field.datasources },
                labels: [...field.labels],
                triggered_alerts: { ...field.triggered_alerts },
            })
        } else {
            setOldNewField({ ...EMPTY_FIELD })
        }
    }, [field])

    const addEditFieldComponentRef = useOutsideComponentClickHandler(() => {
        setShowErrors(false)
        onCancel()
    })

    const handleFileDrop = (event) => {
        setOldNewField({ ...oldNewField, shapefile: event.dataTransfer.files[0] })
        event.preventDefault()
    }

    const handleFileChange = (event) => {
        setOldNewField({ ...oldNewField, shapefile: event.target.files[0] })
        event.preventDefault()
    }

    const handleDragOver = (event) => {
        event.preventDefault()
    }

    const handlePropInputChange = (value, prop) => {
        if (prop === "cropId") {
            setOldNewField({
                ...oldNewField,
                [prop]: value,
                crop: crops[value].name,
                variety: "",
                varietyId: -1,
            })
        } else if (prop === "varietyId") {
            setOldNewField({
                ...oldNewField,
                [prop]: value,
                variety: crops[oldNewField.cropId].varieties[value].name,
            })
        } else {
            setOldNewField({
                ...oldNewField,
                [prop]: value,
            })
        }
    }

    const handleDatasourceChange = (event) => {
        setOldNewField({
            ...oldNewField,
            datasources: {
                ...oldNewField.datasources,
                selected: event.target.value,
            },
        })
    }

    const isReqPropValid = (prop) => {
        if (prop === "cropId" || prop === "varietyId") {
            if (oldNewField[prop] === -1) {
                setError(prop)
                return false
            }
        } else if (!oldNewField[prop]) {
            setError(prop)
            return false
        }
        return true
    }

    const isFormValid = () => {
        if (isEditing && !oldNewField.datasources.selected) {
            setError("datasource")
            return false
        }
        if (!isEditing && !oldNewField.shapefile) {
            setError("shapefile")
            return false
        }
        for (const reqKey of REQUIRED_KEYS) {
            if (!isReqPropValid(reqKey)) return false
        }
        return true
    }

    const handleSubmit = () => {
        if (!isFormValid()) {
            toast.error(`There is an error with the "${error}"`)
            setShowErrors(true)
            return
        }
        if (isEditing) {
            onUpdate({ ...oldNewField }, field)
        } else {
            onSave({ ...oldNewField })
        }
        setShowErrors(false)
        onCancel()
    }

    const showErrorForProp = (prop) => {
        if (prop === "datasources") return showErrors && !oldNewField.datasources.selected
        if (prop === "cropId" || prop === "varietyId") return showErrors && oldNewField[prop] === -1
        return showErrors && !oldNewField[prop]
    }

    const formatDistance = (distance) => {
        if (!distance) return "NA"
        return distance.toFixed().toString() + "km"
    }

    const shapefileInputRef = useRef(null)

    return (
        <div
            ref={addEditFieldComponentRef}
            className={
                "fixed inset-y-0 right-0 w-0 bg-white z-full shadow-md transition-all duration-150 ease-out overflow-hidden overflow-y-auto" +
                (doShow ? " w-[300px]" : "")
            }
        >
            <div className="w-[252px] m-[24px] mb-[38px]">
                <h3 className="pt-[30px] text-[16px] montserrat text-gray-90 font-semibold m-0">
                    {isEditing ? "Edit" : "Add New"} Field
                </h3>

                <div className="pt-[42px]">
                    <div>
                        <label className="text-[14px] text-gray-60 roboto">Enter field name</label>
                        <input
                            onChange={(e) => handlePropInputChange(e.target.value, "name")}
                            value={oldNewField.name}
                            type="text"
                            placeholder="Write the name"
                            className={
                                "w-[240px] h-[36px] mt-[8px] border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-60 placeholder:text-gray-30" +
                                (showErrorForProp("name") ? " border-red-500" : "")
                            }
                        ></input>
                        {showErrorForProp("name") && (
                            <span className="block fixed text-red-500 text-[12px] pt-[1px]">Name is required</span>
                        )}
                    </div>

                    <div className="pt-[26px]">
                        <label className="text-[14px] text-gray-60 roboto">Select crop</label>
                        <select
                            onChange={(e) => handlePropInputChange(e.target.value, "cropId")}
                            value={oldNewField.cropId}
                            className={
                                "w-[240px] mt-[8px] h-[36px] climateai-select" +
                                (showErrorForProp("cropId") ? " border-red-500" : "")
                            }
                            disabled={Object.keys(crops).length === 0}
                        >
                            {Object.keys(crops).length > 0 ? (
                                <>
                                    <option disabled value={-1}>
                                        Select a crop from your crop list
                                    </option>
                                    {Object.keys(crops).map((id) => (
                                        <option value={id} key={id}>
                                            {crops[id].name}
                                        </option>
                                    ))}
                                </>
                            ) : (
                                <option disabled value={-1}>
                                    Add a crop in Crop Settings
                                </option>
                            )}
                        </select>
                        {showErrorForProp("cropId") && (
                            <span className="block fixed text-red-500 text-[12px] pt-[1px]">Crop is required</span>
                        )}
                    </div>

                    <div className="pt-[26px]">
                        <label className="text-[14px] text-gray-60 roboto">Select variety</label>
                        <select
                            onChange={(e) => handlePropInputChange(e.target.value, "varietyId")}
                            value={oldNewField.varietyId}
                            className={
                                "w-[240px] mt-[8px] h-[36px] climateai-select" +
                                (showErrorForProp("varietyId") ? " border-red-500" : "")
                            }
                            disabled={
                                oldNewField.cropId === -1 ||
                                Object.keys(crops[oldNewField.cropId].varieties).length === 0
                            }
                        >
                            {oldNewField.cropId !== -1 ? (
                                Object.keys(crops[oldNewField.cropId].varieties).length > 0 ? (
                                    <>
                                        <option disabled value={-1}>
                                            Select a variety from your variety list
                                        </option>
                                        {Object.keys(crops[oldNewField.cropId].varieties).map((id) => (
                                            <option value={id} key={id}>
                                                {crops[oldNewField.cropId].varieties[id].name}
                                            </option>
                                        ))}
                                    </>
                                ) : (
                                    <option disabled value={-1}>
                                        Add a variety in Crop Settings
                                    </option>
                                )
                            ) : (
                                <option disabled value={-1}>
                                    Select a crop
                                </option>
                            )}
                        </select>
                        {showErrorForProp("varietyId") && (
                            <span className="block fixed text-red-500 text-[12px] pt-[1px]">Variety is required</span>
                        )}
                    </div>

                    <div className="pt-[26px]">
                        <label className="text-[14px] text-gray-60 roboto">Enter region</label>
                        <input
                            onChange={(e) => handlePropInputChange(e.target.value, "region")}
                            value={oldNewField.region}
                            type="text"
                            placeholder="Write the region name"
                            className={
                                "w-[240px] h-[36px] mt-[8px] border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-60 placeholder:text-gray-30 bg-white disabled:bg-gray-3 disabled:cursor-not-allowed cursor-pointer" +
                                (showErrorForProp("region") ? " border-red-500" : "")
                            }
                        ></input>
                        {showErrorForProp("region") && (
                            <span className="block fixed text-red-500 text-[12px] pt-[1px]">Region is required</span>
                        )}
                    </div>

                    <div className="pt-[26px]">
                        <label className="text-[14px] text-gray-60 roboto">Enter planting date</label>
                        <input
                            onChange={(e) => handlePropInputChange(e.target.value, "plantingDateP")}
                            value={oldNewField.plantingDateP}
                            type="date"
                            className={
                                "w-[240px] h-[36px] mt-[8px] climateai-date-input" +
                                (showErrorForProp("plantingDateP") ? " border-red-500" : "")
                            }
                        ></input>
                    </div>

                    {!isEditing && (
                        <div className="pt-[26px]">
                            {oldNewField.shapefile && (
                                <div
                                    className="w-[240px] border border-gray-10 rounded-sm p-[8px] text-center text-gray-60 text-[16px] cursor-pointer bg-green-100"
                                    onClick={() => setOldNewField({ ...oldNewField, shapefile: null })}
                                >
                                    <span className="h-[40px] opacity-60 flex flex-row justify-center">
                                        <CheckIcon size={34} />
                                    </span>
                                    <div style={{ marginTop: 16 }}>Change Shape File</div>
                                </div>
                            )}
                            {!oldNewField.shapefile && (
                                <div
                                    onDrop={handleFileDrop}
                                    onDragOver={handleDragOver}
                                    className={
                                        "w-[240px] border border-gray-10 rounded-sm p-[8px] text-center text-gray-60 text-[16px] cursor-pointer bg-gray-50 hover:bg-gray-5" +
                                        (showErrorForProp("shapefile") ? " border-red-500" : "")
                                    }
                                    onClick={() => shapefileInputRef.current.click()}
                                >
                                    <span className="h-[40px] opacity-60 flex flex-row justify-center">
                                        <UploadIcon width={40} height={40} />
                                    </span>
                                    <div style={{ marginTop: 10 }}>Upload Shape File</div>
                                </div>
                            )}
                            <input
                                onClick={() => {
                                    shapefileInputRef.current.value = null
                                }}
                                onChange={handleFileChange}
                                ref={shapefileInputRef}
                                type="file"
                                className="hidden"
                            ></input>
                            {showErrorForProp("shapefile") && (
                                <span className="block fixed text-red-500 text-[12px] pt-[1px] w-[240px] text-center">
                                    A shape file is required
                                </span>
                            )}
                        </div>
                    )}

                    {isEditing && canEditDatasource && (
                        <div className="pt-[26px]">
                            <label className="text-[14px] text-gray-60 roboto">Data Source</label>
                            <select
                                className={
                                    "w-[240px] h-[36px] mt-[8px] border border border-gray-10 rounded-lg px-2 roboto text-[16px] text-gray-60 placeholder:text-gray-30 bg-white disabled:bg-gray-3 disabled:cursor-not-allowed cursor-pointer" +
                                    (showErrorForProp("datasources") ? " border-red-500" : "")
                                }
                                value={oldNewField.datasources.selected}
                                onChange={handleDatasourceChange}
                            >
                                <option disabled>Select an option</option>
                                {Object.keys(oldNewField.datasources)
                                    .filter((key) => key !== "suggested" && key !== "selected")
                                    .map((key) => {
                                        if (
                                            oldNewField.datasources[key] &&
                                            Object.keys(oldNewField.datasources[key]).length === 0 &&
                                            oldNewField.datasources[key].constructor === Object
                                        )
                                            return null

                                        return (
                                            <option key={key} value={key}>
                                                {key.toUpperCase()} -{" "}
                                                {formatDistance(oldNewField.datasources[key]?.distance)}
                                            </option>
                                        )
                                    })}
                            </select>
                            {showErrorForProp("datasources") && (
                                <span className="block fixed text-red-500 text-[12px] pt-[1px]">
                                    Datasource is required
                                </span>
                            )}
                        </div>
                    )}
                </div>

                <div className="w-[240px] pt-[46px] flex flex-row items-center self-stretch space-x-3">
                    <button
                        className="flex-1 border-[1px] border-accent text-accent p-1 rounded-lg font-semibold hover:text-accent-dark hover:border-accent-dark"
                        onClick={() => {
                            setShowErrors(false)
                            onCancel()
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="flex-1 border-[1px] border-accent bg-accent text-white p-1 rounded-lg font-semibold hover:bg-accent-dark hover:border-accent-dark"
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AddEditField
