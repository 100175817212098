import React, { useRef } from "react"
import ReportListItem from "./ReportListItem"

import "./ReportList.css"

const items = new Array(20).fill(null).map((_, index) => ({ title: index.toString(), image: null, id: index }))

function ReportList(props) {
    const {
        reports = items,
        selected,
        onSelectItem = () => null,
        onEditTitle = () => null,
        onReorder = () => null,
    } = props
    const listRef = useRef(null)

    return (
        <>
            <div className={"report-list" + (selected ? " collapsed" : "")} ref={listRef}>
                {reports &&
                    reports.length > 0 &&
                    reports.map((report, index) => {
                        return (
                            <ReportListItem
                                key={report.id}
                                index={index}
                                report={report}
                                active={selected && report.id === selected.id}
                                onReorder={onReorder}
                                onClick={onSelectItem}
                                onEditTitle={onEditTitle}
                            />
                        )
                    })}
            </div>
        </>
    )
}

export default ReportList
