import React from "react"

interface TableColumnProps {
    // main props (required)
    propName: string
    displayName: string

    // visual props
    toggler: boolean
    draggable: boolean
    sortable: boolean
    sticky: boolean

    // new/edit/delete/form
    form: boolean
    type: string
    required: boolean
    editField: boolean
    deleteField: boolean

    // styling properties
    style: React.CSSProperties
    headerStyle: React.CSSProperties
    className: string
    headerClassName: string

    // two ways to declare group columns
    // TODO change it to groupColumns: Array<TableColumnProps>
    groupFields: Array<TableColumnProps> // I'd go with this one, is more redable
    children: React.ReactNode

    // modifiers
    transform: Function
    render: Function
    headerRender: Function
    groupMethod: Function
    component: React.FC
    headerComponent: React.FC
}

function TableColumn(props: TableColumnProps): React.FC<TableColumnProps> | null {
    console.log(props)

    // TODO add draggable header here @Alberto

    return null
}

export default TableColumn
