import React, { createContext, useState, useRef } from "react"

import EventEmitter from "../../Util/EventEmiter"

import UIModal from "./UIModal"
import UINotification from "./UINotification"
import { customDescription, companyLogos } from "./AboutData"

import "./AboutModal.css"

export const UIAlertContext = createContext({})

function UIAlertProvider(props) {
    const { children } = props

    // Modal handlers
    const [activeModal, toggleModal] = useState(false)
    const [modalInfo, setModalInfo] = useState({})

    // Notification handlers
    const [activeNotification, toggleNotification] = useState(false)
    const [notificationText, setNotificationText] = useState("")

    const eventEmitter = useRef(new EventEmitter())

    function confirmationModal(text, description, buttons) {
        toggleModal(true)
        setModalInfo({ text, description, buttons })

        return new Promise((resolve, _) => {
            function handleModalStateChanged(value) {
                toggleModal(false)
                // result
                resolve(value)
                eventEmitter.current.removeListener("modal-state-changed", handleModalStateChanged)
            }

            eventEmitter.current.addListener("modal-state-changed", handleModalStateChanged)
        })
    }

    function aboutModal() {
        toggleModal(true)
        setModalInfo({ customDescription, companyLogos })

        return new Promise((resolve, _) => {
            function handleModalStateChanged(value) {
                toggleModal(false)
                // result
                resolve(value)
                eventEmitter.current.removeListener("modal-state-changed", handleModalStateChanged)
            }

            eventEmitter.current.addListener("modal-state-changed", handleModalStateChanged)
        })
    }

    function simpleNotification(text) {
        toggleNotification(true)
        setNotificationText(text)

        return new Promise((resolve, _) => {
            function handleNotificationClose() {
                toggleNotification(false)
                resolve()
                eventEmitter.current.removeListener("notification-close", handleNotificationClose)
            }

            eventEmitter.current.addListener("notification-close", handleNotificationClose)
        })
    }

    // Modal
    function handleConfirmationModalContinue() {
        eventEmitter.current.emit("modal-state-changed", true) // modal continue
    }
    function handleConfirmationModalCancel() {
        eventEmitter.current.emit("modal-state-changed", false) // modal closes
    }

    // Notification
    function handleNotificationClose() {
        eventEmitter.current.emit("notification-close")
    }

    return (
        <>
            <UIAlertContext.Provider value={{ confirmationModal, simpleNotification, aboutModal }}>
                {children}
            </UIAlertContext.Provider>

            <>
                <UINotification
                    visible={activeNotification}
                    text={notificationText}
                    onClose={handleNotificationClose}
                />
                <UIModal
                    // image={modalInfo.image}
                    lowerImage={modalInfo.companyLogos}
                    visible={activeModal}
                    text={modalInfo.text}
                    description={modalInfo.description}
                    customDescription={modalInfo.customDescription}
                    buttons={modalInfo.buttons}
                    onContinue={handleConfirmationModalContinue}
                    onClose={handleConfirmationModalCancel}
                />
            </>
        </>
    )
}

export default React.memo(UIAlertProvider)
