import React from "react"
import InfoIcon from "../Icons/InfoIcon"

import { Popover } from "../Popover"

import "./Section.css"

function Section(props) {
    const { title, description, header = () => null, children } = props
    return (
        <>
            <div className="section">
                <div className="section__header">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="section__header__info-icon">
                            <Popover text={description}>
                                <div style={{ width: 20, height: 20 }}>
                                    <InfoIcon fill="#B3B6BA" />
                                </div>
                            </Popover>
                        </div>
                        <div className="section__header__container">
                            <div className="section__header__title">{title}</div>
                        </div>
                    </div>
                    <div className="section__header__controls" style={{ width: 300 }}>
                        {header()}
                    </div>
                </div>
                <div className="section__content">{children}</div>
            </div>
        </>
    )
}

export default Section
