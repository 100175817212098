import * as React from "react"

function InsufficientChillTemperatureIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M23.53 23.928l-.414-7.799c-.03-.799-.81-1.306-1.401-.934l-1.813 1.214-3.336-6.1c-.168-.308-.446-.567-.752-.667l-6.19-2.011-3.528-6.416C5.722.53 4.992.293 4.426.672c-.567.38-.716 1.205-.341 1.89L7.87 9.484c.169.309.446.568.752.667l6.213 2.02 3.055 5.585-1.814 1.214c-.566.38-.522 1.38.098 1.807l6.052 4.024c.626.4 1.334-.074 1.303-.873z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default InsufficientChillTemperatureIcon
