import * as React from "react"

function LabelIcon(props) {
    return (
        <svg
            style={{ width: "100%", height: "100%" }}
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M13.86.826A1.87 1.87 0 0012.316 0L1.895.01C.853.01 0 .885 0 1.966v9.832c0 1.082.853 1.957 1.895 1.957l10.42.01a1.87 1.87 0 001.545-.826L18 6.882 13.86.826z"
                fill="#505050"
            />
        </svg>
    )
}

export default LabelIcon
