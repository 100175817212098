import * as React from "react"

function WetSoilIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#prefix__clip0)">
                <path
                    d="M24.205 16.19h-2.031c-.471 0-.853-.416-.853-.927 0-.512.382-.927.853-.927h2.559c.323 0 .618.199.762.513l3.412 7.41c.284.616-.129 1.34-.763 1.34H.85c-.634 0-1.046-.724-.763-1.34L3.5 14.85a.849.849 0 01.763-.513H6.82c.47 0 .853.415.853.927 0 .511-.382.926-.853.926H4.789L2.23 21.747h24.534l-2.559-5.558z"
                    fill={props.fill || "#fff"}
                />
            </g>
            <path
                d="M15 13.333a4 4 0 01-4-4c0-2.666 4-7.166 4-7.166s4 4.5 4 7.166a4 4 0 01-4 4z"
                fill={props.fill || "#fff"}
            />
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill={props.fill || "#fff"} transform="translate(0 6)" d="M0 0h29v22H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default WetSoilIcon
