import React, { useEffect, useContext } from "react"
import "./Sidebar.css"

import SidebarItem from "./SidebarItem"
import SidebarOptions from "./SidebarOptions"
import DashboardIcon from "../Icons/Navbar/DashboardIcon"
import FieldsIcon from "../Icons/Navbar/FieldsIcon"
import ToolsIcon from "../Icons/Sidebar/ToolsIcon"
import SettingsIcon from "../Icons/Sidebar/SettingsIcon"

import { getPolygonData, getTableData } from "../../services/table.service"

import { AuthContext } from "../../Auth/Auth"
import { DataContext } from "../../Util/Data"
import HomeIcon from "../Icons/Navbar/HomeIcon"

import { Link, useParams, useLocation, useHistory } from "react-router-dom"
import SideBarMobileToggler from "../../components/TopBar/SideBarMobileToggler"
import ChevronIcon from "../Icons/newIcons/ChevronArrow"
import ClimateAnalogsIcon from "../Icons/Navbar/ClimateAnalogsIcon"
import PortfolioAnalysisIcon from "../Icons/Navbar/PortfolioAnalysisIcon"
import HistoricalValidationIcon from "../Icons/Navbar/HistoricalValidationIcon"
import SlidesIcon from "../Icons/SlidesIcon"
// import ClimateRiskIcon from "../Icons/Navbar/ClimateRiskIcon"
import MapWorldIcon from "../Icons/Sidebar/MapWorldIcon"
import ClimateRiskIcon from "../Icons/Sidebar/ClimateRiskIcon"
import ClimateTrendsIcon from "../Icons/Sidebar/ClimateTrendsIcon"
import AnalogsIcon from "../Icons/Sidebar/AnalogsIcon"
import PortfolioIcon from "../Icons/Sidebar/PortfolioIcon"
import HistoricalIcon from "../Icons/Sidebar/HistoricalIcon"
import ReportBuilderIcon from "../Icons/Sidebar/ReportBuilderIcon"
import OptionsIcon from "../Icons/Sidebar/OptionsIcon"
import WeatherIcon from "../Icons/Sidebar/WeatherIcon"
import { SidebarFields } from "."
import AboutIcon from "../Icons/Navbar/AboutIcon"
import { UIAlertContext } from "../UIAlert/UIAlert"

function Sidebar(props) {
    const { settings, navItem, children, climate, weather } = props

    const openFlag = JSON.parse(sessionStorage.getItem("@sidebar/open"))
    const [open, _toggle] = React.useState((openFlag === null && true) || openFlag)
    const { id: fieldId, reportId } = useParams()

    function toggle(value) {
        _toggle(value)
        sessionStorage.setItem("@sidebar/open", JSON.stringify(value))
    }

    const { pathname } = useLocation()
    const history = useHistory()

    const { aboutModal } = useContext(UIAlertContext)
    const { permissions } = useContext(AuthContext)

    const {
        state: { fields },
        dispatch,
    } = useContext(DataContext)

    useEffect(() => {
        if (!fields || fields.length === 0) {
            getTableData()
                .then((fields) => {
                    // save fields to upper state
                    dispatch({ type: "GET_FIELDS", payload: { fields } })
                    // uid array
                    const fieldPolygonsToRetrieve = fields.map((field) => field.uuid)
                    // get polygon data from all fields
                    return getPolygonData(fieldPolygonsToRetrieve)
                })
                .then((polygons) => {
                    // save polygons to upper state
                    dispatch({ type: "GET_POLYGONS", payload: { polygons } })
                })
                .catch(console.log)
        }
    }, [])

    useEffect(() => {
        // force resize
        setTimeout(() => {
            let _event = new CustomEvent("force-resize", { bubbles: true })
            window.dispatchEvent(_event)
        }, 200)
    }, [open])

    // get active tab
    let separator = "/"
    if (settings) separator += (weather && "weather") || (climate && "climate")
    else if (!settings && climate) separator += "climate/" + reportId
    else if (!settings && weather) separator += "weather/" + fieldId
    let view = pathname.split(separator)[1]
    view = (weather && !view && "/weather") || view

    const SETTINGS_NAV_ITEM = {
        name: "Settings",
        icon: SettingsIcon,
        children: [
            {
                view: "/alerts-settings-individual",
                to: "/weather/alerts-settings-individual",
                name: "Individual Alerts",
            },
            {
                view: "/crop-settings",
                to: "/weather/crop-settings",
                name: "Crop & Variety Settings",
            },
            {
                view: "/table-settings",
                to: "/weather/table-settings",
                name: "Table Settings",
            },
        ],
        settings: true,
    }

    const WEATHER_NAV = [
        {
            view: "/overview",
            to: "/weather",
            name: "Overview",
            permission: "general",
            icon: MapWorldIcon,
        },
        {
            name: "My Fields",
            icon: OptionsIcon,
            children: () => {
                return (
                    <SidebarFields
                        linkComponent={Link}
                        currentRegion="Cavendish"
                        view={view}
                        items={fields.map((field) => ({
                            ...field,
                            to: `/weather/${field.uuid}${(view === "/weather" && "/") || view}`,
                        }))}
                    />
                )
            },
        },
        {
            view: "/weather",
            to: `/weather/${fieldId}`,
            name: "Weather Model",
            permission: "weather",
            icon: WeatherIcon,
        },
        {
            name: "Optimization Tools",
            icon: ToolsIcon,
            children: [
                {
                    view: "/planting_tool",
                    to: `/weather/${fieldId}/planting_tool`,
                    name: "Planting Tool",
                    permission: "planting_tool",
                },
                {
                    view: "/harvesting_tool",
                    to: `/weather/${fieldId}/harvesting_tool`,
                    name: "Harvesting Tool",
                    permission: "harvesting_tool",
                },
                {
                    view: "/spraying_tool",
                    to: `/weather/${fieldId}/spraying_tool`,
                    name: "Spraying Tool",
                    permission: "spraying_tool",
                },
                {
                    view: "/nitrogen_application_tool",
                    to: `/weather/${fieldId}/nitrogen_application_tool`,
                    name: "Nitrogen Application Tool",
                    permission: "weather/nitrogen_application_tool",
                },
            ],
        },
        SETTINGS_NAV_ITEM,
    ]

    const CLIMATE_NAV = [
        {
            view: "/overview",
            to: (settings && `/climate/reports`) || `/climate/${reportId}/overview`,
            name: (settings && "Home") || "Overview",
            icon: MapWorldIcon,
        },
        {
            name: "Climate Risk",
            children: [
                { view: "/decadal", to: `/climate/${reportId}/decadal`, name: "Decadal Risk" },
                { view: "/monthly", to: `/climate/${reportId}/monthly`, name: "Seasonal Risk" },
            ],
            icon: ClimateRiskIcon,
        },
        {
            name: "Climate Trends",
            children: [
                { view: "/decadal-trend", name: "Decadal Trend", disabled: true },
                { view: "/seasonal-trend", name: "Seasonal Trend", disabled: true },
                { view: "/departure", to: `/climate/${reportId}/departure`, name: "Climate Tipping Point" },
            ],
            icon: ClimateTrendsIcon,
        },
        {
            view: "/analogs",
            to: `/climate/${reportId}/analogs`,
            name: "Climate Analogs",
            icon: AnalogsIcon,
            // disabled: true,
        },
        {
            view: "/portfolio",
            to: `/climate/${reportId}/portfolio`,
            name: "Portfolio Risk Analysis",
            icon: PortfolioIcon,
            // disabled: true,
        },
        {
            view: "/historical",
            to: `/climate/${reportId}/historical`,
            name: "Historical Validation",
            icon: HistoricalIcon,
        },
        // {
        //     view: "/builder",
        //     to: `/climate/${reportId}/builder`,
        //     name: "Report Builder",
        //     icon: ReportBuilderIcon,
        // },
    ]

    const OPTIONS = (climate && CLIMATE_NAV) || (weather && WEATHER_NAV)

    return (
        <>
            <div
                className={
                    "absolute inset-y-0 left-0 z-full md:z-50 md:relative shrink-0 grow-0 bg-white elevation-1 border-r border-gray-10 transition-all group" +
                    (open ? " w-[240px]" : " w-[0px] md:w-[68px]")
                }
            >
                {/* SIDENAV MOBILE BUTTON */}
                <SideBarMobileToggler isOpen={open} toggleFunction={() => toggle(!open)} />

                {/* SIDENAV TOGGLE BUTTON */}
                <div className="relative overflow-visible w-full">
                    <div
                        className={"md:block absolute -right-3 top-[24px]" + (open ? " block" : " hidden")}
                        onClick={() => toggle(!open)}
                    >
                        <div
                            className={
                                "cursor-pointer rounded-full bg-white elevation-1 h-[24px] w-[24px] fill-gray-60 border border-gray-10 " +
                                " flex flex-col items-center justify-center transition-all duration-75" +
                                " hover:bg-accent hover:fill-white hover:border-accent"
                            }
                            onClick={() => toggle(!open)}
                        >
                            <div className={"transform" + (open ? " rotate-90" : " -rotate-90")}>
                                <ChevronIcon />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"overflow-hidden overflow-y-auto h-full w-full pt-[12px] flex flex-col grow"}>
                    <>
                        {OPTIONS.filter((i) => (settings ? i.settings || i.view === "/overview" : true)).map((item) => {
                            return (
                                <SidebarItem
                                    sidebarOpen={open}
                                    active={
                                        (item.view && item.view === view) ||
                                        (item.children &&
                                            Array.isArray(item.children) &&
                                            item.children.find((c) => c.view === view))
                                    }
                                    icon={item.icon}
                                    name={item.name}
                                    onClick={(goToFirst) => {
                                        if (!open) toggle(true)

                                        if (item.to) history.push(item.to)
                                        else if (goToFirst && item.children && Array.isArray(item.children))
                                            history.push(item.children[0].to)
                                    }}
                                    // onClick={(item.to && (() => history.push(item.to))) || undefined}
                                >
                                    {(item.children && Array.isArray(item.children) && (
                                        <SidebarOptions
                                            view={view}
                                            sidebarOpen={open}
                                            linkComponent={Link}
                                            items={
                                                (climate && item.children) ||
                                                (weather && item.settings && item.children) ||
                                                (weather &&
                                                    !item.settings &&
                                                    item.children.filter(
                                                        (i) => i.permission && permissions[i.permission]
                                                    ))
                                            }
                                        />
                                    )) ||
                                        (item.children && typeof item.children === "function" && item.children()) ||
                                        null}
                                </SidebarItem>
                            )
                        })}
                    </>
                    <div className="navbar__bottom">
                        <SidebarItem
                            sidebarOpen={open}
                            active={false}
                            icon={AboutIcon}
                            name="About"
                            onClick={() => aboutModal()}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar
