import React from "react"

export const companyLogosObject = [
    {
        ibm: {
            image: "/images/ibm_logo.png",
            url: "https://www.ibm.com",
        },
        meteoblue: {
            image: "/images/meteoblue_logo.png",
            url: "https://www.meteoblue.com",
        },
        openWeather: {
            image: "/images/open-weather_logo.png",
            url: "https://openweathermap.org/",
        },
        spire: {
            image: "/images/spire_logo.svg",
            url: "https://spire.com/",
        },
        clearAg: {
            image: "/images/clearag_logo.jpg",
            url: "https://www.dtn.com/agriculture/agribusiness/clearag/",
        },
        ecmwf: {
            image: "/images/ecmwf_logo.png",
            url: "https://www.ecmwf.int/",
        },
        noaa: {
            image: "/images/noaa_logo.jpg",
            url: "https://www.noaa.gov/",
        },
    },
]

export const companyLogos = (
    <div className="about-modal__company-logo-container">
        {companyLogosObject.map((item) => {
            const logoArray = []
            let addToClass = ""
            for (let [k, n] of Object.entries(item)) {
                if (k === "noaa") {
                    addToClass = "__noaa"
                } else if (k === "ecmwf") {
                    addToClass = "__ecmwf"
                } else if (k === "openWeather") {
                    addToClass = "__open-weather"
                } else {
                    addToClass = ""
                }
                logoArray.push(
                    <a
                        className={"about-modal__company-logo" + addToClass}
                        key={k}
                        href={n.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img className="about-modal__company-logo-image" src={n.image} alt={`${k} logo`} />
                    </a>
                )
            }

            return logoArray
        })}
    </div>
)

export const customDescription = (
    <div className="about-modal__text">
        <b>ClimateAi ingests all available forecasts </b> (public, private vendors, weather stations, and any other
        ground truth data) <b>to deliver the world's most accurate forecast.</b>
    </div>
)
