import React, { useState, useEffect } from 'react'
import { Popover } from '@material-ui/core'
import PositionHelper from './PositionHelper';
import "./Popover.css"

function MaterialPopover({text, position, distance, isVisible=true, children}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [positionState, setPosition] = useState([])

    const [anchorVertical, anchorHorizontal, transformVertical, transformHorizontal] = positionState

    useEffect(() => (
        setPosition(PositionHelper(position, distance))
    ), [position, distance])


    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <div
                className="material-popover-target"
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                
            >
                {children}
            </div>
            <Popover
                sx={{
                pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: anchorVertical,
                horizontal: anchorHorizontal,
                }}
                transformOrigin={{
                vertical: transformVertical,
                horizontal: transformHorizontal,
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div 
                    style={{display: isVisible ? "inherit" : "none"}}
                    className="table-item__popover-message">
                    {text}
                </div>
            </Popover>
        </>
    )
}

export default MaterialPopover
