import * as React from "react"

function ColdIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M10 22a.687.687 0 01-.688-.688v-1.777l-.888.89a.688.688 0 11-.972-.974l1.86-1.86v-5.4L4.638 14.89l-.683 2.544a.688.688 0 11-1.328-.358l.326-1.213-1.54.889a.688.688 0 01-.687-1.191l1.54-.888-1.216-.326a.688.688 0 01.358-1.329l2.54.681L8.626 11 3.95 8.3l-2.544.681a.688.688 0 01-.354-1.328l1.214-.326-1.54-.888a.688.688 0 11.687-1.19l1.54.888-.326-1.214a.688.688 0 111.329-.355l.68 2.542 4.676 2.7v-5.4l-1.86-1.86a.688.688 0 01.972-.974l.889.89V.686a.688.688 0 011.374 0v1.778l.89-.89a.688.688 0 01.972.974l-1.861 1.86v5.4l4.675-2.699.681-2.544a.688.688 0 111.329.358l-.325 1.213 1.54-.889a.687.687 0 01.688 1.191l-1.54.888 1.214.326a.688.688 0 01-.358 1.329l-2.54-.681L11.374 11l4.675 2.7 2.542-.681a.688.688 0 01.357 1.328l-1.215.326 1.54.888a.687.687 0 01-.687 1.19l-1.54-.888.324 1.215a.687.687 0 11-1.328.354l-.68-2.542-4.675-2.7v5.4l1.86 1.86a.688.688 0 11-.972.974l-.89-.89v1.779A.687.687 0 0110 22H10z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default ColdIcon
