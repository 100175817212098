// Modules
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"

// Context providers / Utils
import { SettingsProvider } from "./Util/SettingsContext"
import { AuthProvider } from "./Auth/Auth" // TODO move Auth to Util collection
import DataProvider from "./Util/Data"
import UIAlertProvider from "./ui/UIAlert"

// styles
import "./App.css"
import "./styles/chart.css"
import "react-toastify/dist/ReactToastify.css"

import SignIn from "./components/SignIn"
import SignUpConfirmation from "./components/SignUpConfirmation"
import RestorePassword from "./components/RestorePassword"
import PasswordCode from "./components/PasswordCode"
import CreatePassword from "./components/CreatePassword"
import PasswordConfirmation from "./components/PasswordConfirmation"
import SignUp from "./components/SignUp"
import WeatherRouter from "./routers/WeatherRouter"
import ClimateRouter from "./routers/ClimateRouter"
import CustomRouter from "./routers/CustomRouter"

function App() {
    return (
        <AuthProvider>
            <DataProvider>
                <SettingsProvider>
                    <UIAlertProvider>
                        <Router>
                            <Switch>
                                <Route exact path="/">
                                    <SignIn />
                                </Route>
                                <Route exact path="/signup">
                                    <SignUp />
                                </Route>
                                <Route exact path="/signup/confirmation">
                                    <SignUpConfirmation />
                                </Route>
                                <Route exact path="/restore-password">
                                    <RestorePassword />
                                </Route>
                                <Route exact path="/password-code">
                                    <PasswordCode />
                                </Route>
                                <Route exact path="/create-password">
                                    <CreatePassword />
                                </Route>
                                <Route exact path="/password-confirmation">
                                    <PasswordConfirmation />
                                </Route>
                                {/* COD */}
                                <Route path="/weather">
                                    <WeatherRouter />
                                </Route>
                                {/* CAD */}
                                <Route path="/climate">
                                    <ClimateRouter />
                                </Route>
                                {/* Custom */}
                                <Route path="/custom">
                                    <CustomRouter />
                                </Route>

                                <Redirect to="/" />
                            </Switch>
                        </Router>
                    </UIAlertProvider>
                </SettingsProvider>
            </DataProvider>
        </AuthProvider>
    )
}

export default App
