import React, { Children } from "react"
import ArrowBottom from "../Icons/newIcons/ArrowBottom"

import "./ControlItem.css"

function ControlItem(props) {
    const { children, title, defaultList, actions, options } = props

    let onlyChild
    if (!defaultList) onlyChild = Children.only(children)

    return (
        <>
            <div className="control-item">
                <div className="control-item__header">
                    <button
                        className="control-item__title"
                        onClick={() => !defaultList && !onlyChild && actions.goBack()}
                    >
                        {!defaultList && !onlyChild && (
                            <div className="h-5 mr-1 transform rotate-90">
                                <ArrowBottom />
                            </div>
                        )}
                        {title}
                    </button>
                </div>
                {onlyChild && (
                    <div className="control-item__content">{React.cloneElement(onlyChild, { actions, options })}</div>
                )}
                {defaultList && (
                    <div className="control-item__content">
                        <div className="control-item__content__default-options">
                            {options.map((option) => (
                                <button
                                    key={JSON.stringify(option)}
                                    className="control-item__content__default-options__option"
                                    onClick={() => actions.goTo(option.name)}
                                    disabled={option.disabled}
                                >
                                    {option.title}
                                </button>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ControlItem
