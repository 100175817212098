function ReportReducer(state, action) {
    const { type, payload } = action

    switch (type) {
        case "INPUT_SEARCH": {
            const { inputSearch } = payload
            console.info(inputSearch)
            return { ...state, inputSearch }
        }
        case "ADD_LABEL_SEARCH": {
            const { labelSearch } = payload
            console.info(labelSearch)
            return { ...state, labelSearch: [...state.labelSearch, labelSearch] }
        }
        case "DELETE_LABEL_SEARCH": {
            const { labelSearch } = payload
            console.info(labelSearch)
            return {
                ...state,
                labelSearch: state.labelSearch.filter((item) => item !== labelSearch),
            }
        }
        default: {
            return state
        }
    }
}

export default ReportReducer
