import React, { useCallback, useMemo, useRef, useState, useEffect, useContext, memo } from "react"
import { CircularProgress } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"
import { ToastContainer, toast } from "react-toastify"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import { SettingsContext } from "../../../../Util/SettingsContext"
import { interpolateRgbBasis, hcl } from "d3"
import { convertWaterLengthValueOnly } from "../../../../Util/UnitConversion"

import {
    getHistoricalTemp,
    getExtraHistoricalTemp,
    getForecastArr,
    getExtraForecastArr,
    getForecastTemp,
    getExtraForecastTemp,
    getClim,
    getExtraClim,
    trimmData,
} from "./helper"
import {
    addDays,
    processUnitSystem,
    getUnit,
    assembleCumulativeData,
    assembleAreaData,
    assembleLineData,
    mergeHistoricalAndForecastData,
    getForecastConfidenceData,
    addMonths,
    validateData,
    duplicateMonthlyHistoricalDataForFutureSevenMonths,
    convertToShadedRangesFormat,
    convertDataToBarStructure,
    convertToLineFromBar,
    convertToBarObj,
    convertAPIDataToSeasonalBarStructure,
    convertToDoubleCandlestick,
} from "../../../../helpers/chartHelpers"
import ChartSpecs from "../ChartSpecs"
import networking from "../../../../Util/Networking"
import { AuthContext } from "../../../../Auth/Auth"
import { isEmptyObject } from "../../../../Util/General"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useLastUpdated from "../../../../hooks/useLastUpdated"

const REMEMBERED_COEFFICIENT = "WaterBudgetCoefficient"

// Create custom theme for switch slider
const theme = createTheme({
    overrides: {
        MuiSwitch: {
            switchBase: {
                // Controls default (unchecked) color for the thumb
                color: "#ccc",
            },
            colorSecondary: {
                "&$checked": {
                    // Controls checked color for the thumb
                    color: "#fafafa",
                },
            },
            track: {
                // Controls default (unchecked) color for the track
                opacity: 0.2,
                backgroundColor: "gray",
                "$checked$checked + &": {
                    // Controls checked color for the track
                    opacity: 1,
                    backgroundColor: "#41A3A4",
                },
            },
        },
    },
})

const PrecipitationChart = ({ actionsState }) => {
    const chartRef = useRef(null)
    const { currentUser } = useContext(AuthContext)
    const { currentSettings } = useContext(SettingsContext)
    const { id } = useParams()
    const [fieldId, setFieldId] = useState()

    const [confidenceVisible, setConfidenceBarsVisibility] = useState(false)
    const [climatologyVisible, setClimatologyVisible] = useState(false)

    const [historicalPending, setHistoricalPending] = useState(false)

    // Media Queries for Tablet View
    const [tabletMedia, setTabletMedia] = useState(true)
    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)", { noSsr: true })
    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    // ******************************* BAR DATA PART ****************************
    // BarData - Forecast
    const [monthlyBarForecastData, setMonthlyBarForecastData] = useState({
        time: [],
    })

    // BarData - Climatology
    const [monthlyBarClimatologyData, setMonthlyBarClimatologyData] = useState({
        time: [],
    })

    // BarData - Forecast - Vigintiles
    const [monthlyBarForecastDataVigintiles, setMonthlyBarForecastDataVigintiles] = useState({ time: [] })

    // BarData - Climatology - Vigintiles
    const [monthlyBarClimatologyDataVigintiles, setMonthlyBarClimatologyDataVigintiles] = useState({ time: [] })

    // Bar Data - Insights
    const barDataInsights = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "tp_sum",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                property: "tp_sum",
            }),
        [monthlyBarForecastDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsData = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarClimatologyDataVigintiles,
                property: "tp_sum",
            }),
        [monthlyBarClimatologyDataVigintiles]
    )

    const [barType, setBarType] = useState("med")

    // ******************************* END OF BAR DATA PART ****************************

    // Prepare initial data
    const weatherVariable = "precipitation"

    const [data, setData] = useState({
        ds_hist: {
            time: [],
            tp_sum: [],
        },
        ds_fc: {
            time: [],
            tp_sum: [],
        },
        ds_clim: {
            time: [],
            tp_sum: [],
        },
        pending: true,
    })

    const [cumulative, setCumulative] = useState(false)
    const [cumulativeData, setCumulativeData] = useState(false)

    // Prepare initial data
    const [monthlyData, setMonthlyData] = useState({
        ds_hist: {
            time: [],
            tp_sum: [],
        },
        ds_fc: {
            time: [],
            tp_sum: [],
        },
        ds_clim: {
            time: [],
            tp_sum: [],
        },
        pending: true,
    })

    const initialHourlyData = {
        ds_hist: {
            time: [],
            t2m: [],
        },
        ds_fc: {
            time: [],
            t2m: [],
        },
    }

    // Alerts Data
    const [alertsData, setAlertsData] = useState({
        tp_sum: {},
    })

    const [hourlyData, setHourlyData] = useState(initialHourlyData)

    const [evaporationData, setEvaporationData] = useState({
        ds_hist: {
            time: [],
            e_sum: [],
        },
        ds_fc: {
            time: [],
            e_sum: [],
        },
        ds_clim: {
            time: [],
            e_sum: [],
        },
        pending: true,
        coefficient:
            localStorage.getItem(REMEMBERED_COEFFICIENT) !== null &&
            localStorage.getItem(REMEMBERED_COEFFICIENT) !== undefined &&
            localStorage.getItem(REMEMBERED_COEFFICIENT) !== ""
                ? localStorage.getItem(REMEMBERED_COEFFICIENT)
                : 0.3,
        error: false,
    })

    // Load data
    useEffect(() => {
        if (fieldId !== id || (fieldId === id && climatologyVisible && data.ds_clim.time.length === 0)) {
            if (!actionsState.extraPrecipitationChart) {
                setData((prevData) => ({
                    ...prevData,
                    pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
                }))

                let localData = data

                let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
                if (climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
                if (!climatologyVisible) {
                    if (fieldId !== id) {
                        //  localData.pending = true
                    }
                    localData = Object.assign({}, localData, {
                        ds_clim: {
                            time: [],
                            tp_sum: [],
                        },
                    })
                    setData(localData)
                }

                setEvaporationData({
                    ds_hist: {
                        time: [],
                        e_sum: [],
                    },
                    ds_fc: {
                        time: [],
                        e_sum: [],
                    },
                    ds_clim: {
                        time: [],
                        e_sum: [],
                    },
                    pending: true,
                    coefficient: 0.3,
                    error: false,
                })
                currentUser.getIdToken().then((userToken) => {
                    let localData = data

                    if (climatologyVisible) {
                        setHistoricalPending(true)
                    }
                    // let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
                    // if (climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
                    let datasets = "history%2Cforecast%2Cclimatology"
                    if (!climatologyVisible) {
                        localData = Object.assign({}, localData, {
                            ds_clim: {
                                time: [],
                                tp_sum: [],
                            },
                        })
                        setData(localData)
                    }

                    networking
                        .get(`/api/v1/weather/${weatherVariable}/daily/${id}?datasets=${datasets}`, {
                            extraHeaders: { "User-Token": userToken },
                        })
                        .then((res) => {
                            if (datasets.indexOf("climatology") !== -1) {
                                setHistoricalPending(false)
                            }
                            setData({
                                ...Object.assign({}, localData, res.data),
                                pending: false,
                            })
                        })
                        .catch(() => {
                            setData((prevData) => ({
                                ...prevData,
                                pending: false,
                            }))
                            toast.error("Error occurred with server. Please, try later.")
                        })

                    // Break network call if data already loaded
                    if (fieldId === id && monthlyData.ds_fc.time.length) {
                        return
                    }

                    networking
                        .get(
                            `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                            {
                                extraHeaders: { "User-Token": userToken },
                            }
                        )
                        .then((res) => {
                            setMonthlyBarForecastData(res.data.ds_fc)
                            setMonthlyBarClimatologyData(res.data.ds_clim)
                        })
                        .catch(() => {
                            toast.warn("Could not load monthly terciles forecast data.")
                        })

                    networking
                        .get(
                            `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                            {
                                extraHeaders: { "User-Token": userToken },
                            }
                        )
                        .then((res) => {
                            setMonthlyBarForecastDataVigintiles(res.data.ds_fc)
                            setMonthlyBarClimatologyDataVigintiles(res.data.ds_clim)
                        })
                        .catch(() => {
                            toast.warn("Could not load monthly vigintiles forecast data.")
                        })

                    // networking
                    //     .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                    //         extraHeaders: { "User-Token": userToken },
                    //     })
                    //     .then((res) => {
                    //         setHourlyData({
                    //             ...res.data,
                    //             pending: false,
                    //         })
                    //     })
                    //     .catch(() => {
                    //         setHourlyData((prevData) => ({
                    //             ...prevData,
                    //             pending: false,
                    //         }))
                    //         toast.warn("Could not load hourly data.")
                    //     })

                    networking
                        .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
                            extraHeaders: { "User-Token": userToken },
                        })
                        .then((res) => {
                            if (isEmptyObject(res.data)) {
                                toast.success(`There are no alerts for ${weatherVariable.replace("_", " ")}`)
                            }
                            setAlertsData(res.data)
                        })
                        .catch(() => {
                            //             toast.warn(
                            //                 `Alerts not displayed on dashboard due to internet
                            // connectivity issues. All other functions working.`
                            //             )
                        })
                })
            } else {
                setEvaporationData((prevData) => ({
                    ...prevData,
                    pending: true,
                }))
                setData((prevData) => ({
                    ...prevData,
                    pending: true,
                }))

                currentUser
                    .getIdToken()
                    .then(async (userToken) => {
                        await networking
                            .get(`/api/v1/weather/${weatherVariable}/daily/${id}`, {
                                extraHeaders: { "User-Token": userToken },
                            })
                            .then((res) => {
                                setData({
                                    ...res.data,
                                    pending: true,
                                })
                            })
                            .catch(() => {
                                setData((prevData) => ({
                                    ...prevData,
                                    pending: false,
                                }))
                                toast.error("Error occurred with server. Please, try later.")
                            })
                        return userToken
                    })
                    .then(async (userToken) => {
                        await networking
                            .get(`/api/v1/weather/evaporation/daily/${id}`, {
                                extraHeaders: { "User-Token": userToken },
                            })
                            .then((res) => {
                                setEvaporationData((prevState) => ({
                                    ...prevState,
                                    ...res.data,
                                    pending: false,
                                }))
                                setData((prevData) => ({
                                    ...prevData,
                                    pending: false,
                                }))
                            })
                            .catch(() => {
                                setEvaporationData((prevData) => ({
                                    ...prevData,
                                    pending: false,
                                }))
                                setData((prevData) => ({
                                    ...prevData,
                                    pending: false,
                                }))
                                toast.error("Error occurred with server. Please, try later.")
                            })
                    })
            }
            setFieldId(id)
        }
    }, [actionsState.selectedGranularity, actionsState.extraPrecipitationChart, id, currentUser, climatologyVisible])

    const lastUpdated = useLastUpdated(data, monthlyData)

    // Prepare historical and forecast data
    const historicalTemp = useMemo(() => getHistoricalTemp(data["ds_hist"]), [data])
    const forecastArr = useMemo(() => getForecastArr(data["ds_fc"]), [data])
    const forecastTemp = useMemo(
        () => getForecastTemp(data["ds_fc"], historicalTemp[historicalTemp.length - 1], forecastArr),
        [data, forecastArr, historicalTemp]
    )
    const { climLighten, climDarken } = useMemo(() => getClim(data["ds_clim"]), [data])

    // Prepare extra historical and forecast data
    const extraHistoricalTemp = useMemo(() => {
        try {
            return getExtraHistoricalTemp(historicalTemp, evaporationData["ds_hist"], evaporationData.coefficient)
        } catch (e) {
            actionsState.extraPrecipitationChart && toast.error("Problem ocurred processsing information")
            return []
        }
    }, [evaporationData, historicalTemp, actionsState.extraPrecipitationChart])
    const extraForecastArr = useMemo(() => {
        try {
            return getExtraForecastArr(evaporationData["ds_fc"])
        } catch (e) {
            return []
        }
    }, [evaporationData])
    const extraForecastTemp = useMemo(() => {
        try {
            return getExtraForecastTemp(
                forecastTemp,
                evaporationData["ds_fc"],
                extraForecastArr,
                extraHistoricalTemp[extraHistoricalTemp.length - 1],
                evaporationData.coefficient
            )
        } catch (e) {
            return []
        }
    }, [evaporationData, extraForecastArr, forecastTemp, extraHistoricalTemp])

    const forecastConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalTemp[historicalTemp.length - 1],
            data["ds_fc"]["tp_sum"],
            "0.75",
            historicalTemp
        )
    }, [data, historicalTemp])
    const forecastConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalTemp[historicalTemp.length - 1],
            data["ds_fc"]["tp_sum"],
            "0.95",
            historicalTemp
        )
    }, [data, historicalTemp])

    const extraForecastConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            evaporationData["ds_fc"],
            extraHistoricalTemp[extraHistoricalTemp.length - 1],
            evaporationData["ds_fc"]["e_sum"],
            "0.75",
            false
        )
    }, [evaporationData, extraHistoricalTemp])
    const extraForecastConfidence95 = useMemo(() => {
        const extraEvapResult = getForecastConfidenceData(
            evaporationData["ds_fc"],
            extraHistoricalTemp[extraHistoricalTemp.length - 1],
            evaporationData["ds_fc"]["e_sum"],
            "0.95",
            false
        )
        return extraEvapResult
    }, [evaporationData, extraHistoricalTemp])

    const { extraClimLighten, extraClimDarken } = useMemo(() => {
        try {
            return getExtraClim({ climLighten, climDarken }, evaporationData["ds_clim"], evaporationData.coefficient)
        } catch (e) {
            return { extraClimLighten: [], extraClimDarken: [] }
        }
    }, [evaporationData, climLighten, climDarken])

    // Look at the changes for historical and forecast data and display warning messages if invalid
    useMemo(() => {
        if (actionsState.extraPrecipitationChart) {
            validateData({
                diffToAlert: 15,
                historic: extraClimLighten,
                forecast: extraForecastTemp,
                accessorKey: "y",
                message: "Forecast Anomaly Detected",
            })
        } else {
            validateData({
                diffToAlert: 15,
                historic: climLighten,
                forecast: forecastTemp,
                accessorKey: "y",
                message: "Forecast Anomaly Detected",
            })
        }
    }, [extraForecastTemp, extraClimLighten, forecastTemp, climLighten, actionsState.extraPrecipitationChart])

    // Prepare CSV Data
    const histCsvData = data["ds_hist"].time.map((item, index) => {
        return [data["ds_hist"]["tp_sum"][index]]
    })
    const forcCsvData = data["ds_fc"].time.map((item, index) => {
        return [forecastArr[index]]
    })
    const climArr = [].concat.apply([], Object.values(data["ds_clim"]["tp_sum"]))
    const climCsvData = data["ds_clim"].time.map((item, index) => {
        return [item, climArr[index]]
    })

    // Combine CSV data, which will be used for export
    const combinedCsvData = (clim, forecast, historical) => {
        const csvArr = []
        let j = 0
        for (let i = 0; i < clim.length; i++) {
            if (historical[i]) {
                csvArr.push([...clim[i], [""], ...historical[i]])
            } else if (clim[i] && forecast[j]) {
                csvArr.push([...clim[i], ...forecast[j], [""]])
                j += 1
            } else if (clim[i]) {
                csvArr.push([...clim[i], [""]])
            }
        }
        return csvArr
    }

    // Change slider handler
    const handleChangeSlider = useCallback((e, value) => {
        setEvaporationData((prevState) => ({
            ...prevState,
            coefficient: value,
        }))
        localStorage.setItem(REMEMBERED_COEFFICIENT, value)
    }, [])

    const dailyChartData = assembleLineData({
        isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
        historical: historicalTemp,
        isCumulative: cumulative,
        cumulativeType: "sum_per_month",
        forecast: forecastTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.tp_sum["0.5"][i],
            }
        }),
    })

    const monthlyChartData = convertToLineFromBar({
        obj: forecastQuantilesBarData[0.5],
        props: {
            color: "#1D74B3",
            barType: barType,
            visible: true,
            unit: currentSettings.units,
            unitType: "precip",
            getArrow: (d) => {
                if (!barDataInsights)
                    return {
                        color: "",
                        symbol: `
        <style>
        .point-loader {
          border: 3px solid #f3f3f3;
          border-radius: 50%;
          border-top: 3px solid #3498db;
          width: 10px;
          height: 10px;
          -webkit-animation: spin 2s linear infinite; /* Safari */
          animation: spin 2s linear infinite;
        }
        
        /* Safari */
        @-webkit-keyframes spin {
          0% { -webkit-transform: rotate(0deg); }
          100% { -webkit-transform: rotate(360deg); }
        }
        
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        </style>
        
        <div class="point-loader"></div>
        `,
                    }
                const month = d.key
                    .toLocaleString("default", {
                        month: "short",
                        year: "numeric",
                    })
                    .split("")
                    .join("")
                const normalMax = barDataInsights.normalMax[month]
                const normalMin = barDataInsights.normalMin[month]
                const normal = barDataInsights.normal[month]
                const maxValue = Math.max(...[normalMax, normalMin, normal])
                if (maxValue == normalMin) {
                    return {
                        symbol: "↓",
                        color: "#805A03",
                    }
                }
                if (maxValue == normalMax) {
                    return {
                        symbol: "↑",
                        color: "#163D67",
                    }
                }
                if (maxValue == normal) {
                    return {
                        symbol: "=",
                        color: "gray",
                    }
                }
            },
        },
    })

    const csvDailyData = dailyChartData.map((d, i) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })],
        [d.y],
    ])

    const csvMonthlyData = monthlyChartData.points.map((d, i) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", year: "numeric" })],
        [d.y],
    ])

    return (
        <>
            <div className="weather-chart">
                <div className="weather-chart__chart-container">
                    <div
                        style={{ display: data.pending ? "flex" : "none" }}
                        className="weather-chart__preload-container"
                    >
                        <CircularProgress />
                    </div>
                    <LineAreaChartComponent
                        // Pass height externally
                        svgHeight={650}
                        // Title text
                        title={
                            actionsState.extraPrecipitationChart
                                ? "Water Budget"
                                : cumulative && ["monthly"].includes(actionsState.selectedGranularity)
                                ? "Cumulative Precipitation"
                                : "Precipitation"
                        }
                        // Set title hover text
                        titleHover={
                            !actionsState.extraPrecipitationChart
                                ? {
                                      daily: `This graph shows the daily observed and forecasted ${weatherVariable} accumulation which is the volume of water accumulated over an area within a day.`,
                                      monthly: `This graph shows the daily observed and forecasted ${weatherVariable} accumulation which is the volume of water accumulated over an area within a month.`,
                                      hourly: `This graph shows the daily observed and forecasted ${weatherVariable} accumulation which is the volume of water accumulated over an area within a hour.`,
                                  }[actionsState.selectedGranularity]
                                : "This graph shows the daily observed and forecasted amount of water stored in your area. It takes into account surface and subsurface water bodies and water stored in the atmosphere."
                        }
                        // Y label text
                        labelY={
                            actionsState.extraPrecipitationChart
                                ? `Water Budget [${getUnit({ system: currentSettings.units }).precipUnit}]`
                                : `Precipitation [${getUnit({ system: currentSettings.units }).precipUnit}]`
                        }
                        // Add top margin
                        marginTop={tabletMedia ? 65 : 75}
                        // Convert received data to shaded ranges format
                        shadedRanges={
                            cumulative ? [] : convertToShadedRangesFormat(alertsData, Object.keys(alertsData))
                        }
                        // Pass unique resize event key
                        resizeEventListenerId={`${weatherVariable}-chart`}
                        // Pass y bottom offset proportion values
                        yBottomOffset={
                            actionsState.extraPrecipitationChart ||
                            (cumulative && ["monthly"].includes(actionsState.selectedGranularity))
                                ? 0.3
                                : 0.1
                        }
                        // Pass y top offset proportion values
                        yTopOffset={cumulative && ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.3}
                        // Pass whether chart should be zero basis or not (default it is)
                        zeroBasis={actionsState.extraPrecipitationChart ? false : true}
                        centerTicks={
                            {
                                daily: false,
                                monthly: !actionsState.extraPrecipitationChar ? true : false,
                                hourly: false,
                            }[actionsState.selectedGranularity]
                        }
                        // Provide custom date max axis extent for monthly view charts
                        xDateMax={
                            {
                                daily: tabletMedia ? addDays(new Date(), 14) : null,
                                monthly: !actionsState.extraPrecipitationChart
                                    ? addMonths(
                                          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                                          monthlyBarForecastDataVigintiles.time.length
                                      )
                                    : null,
                                hourly: null,
                            }[actionsState.selectedGranularity]
                        }
                        // Provide custom date min axis extent for monthly view charts
                        xDateMin={
                            {
                                daily: tabletMedia ? addDays(new Date(), -2) : null,
                                monthly: !actionsState.extraPrecipitationChart
                                    ? addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), 28), -1)
                                    : null,
                                hourly: null,
                            }[actionsState.selectedGranularity]
                        }
                        // How x ticks will be formatted in chart
                        xTickFormat={
                            {
                                daily: (d, i, arr) => {
                                    if (i < arr.length - 1 && i !== 0) return d.getDate()
                                    if (i === 0)
                                        return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                    return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                                },
                                monthly: (d, i, arr) => {
                                    // Remove last, overflowing tick item
                                    if (i === arr.length - 1) return ""
                                    return d.toLocaleString(undefined, { month: "short" })
                                },
                                hourly: (d, i, arr) => {
                                    if (i < arr.length - 1 && i !== 0) return d.getHours() + "h"
                                    if (i === 0)
                                        return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                                    return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                },
                            }[actionsState.selectedGranularity]
                        }
                        // Give chart tips count tip
                        xTicksCount={
                            {
                                daily: tabletMedia ? 14 : 30,
                                monthly: 5,
                                hourly: 16,
                            }[actionsState.selectedGranularity]
                        }
                        // Hide chart if data is pending
                        hide={data.pending}
                        // Tooltip content on line points mouse over
                        tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                            let prec = 1000
                            let hour = undefined
                            let day = "numeric"
                            let month = "short"
                            let h = ""
                            if (actionsState.selectedGranularity === "hourly") {
                                prec = 1000
                                hour = "numeric"
                                day = undefined
                                month = undefined
                                h = "h"
                            }
                            if (barType == "candl") {
                                day = undefined
                            }

                            return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                 <tr><td style="font-weight:bold;font-size:20px" rowspan="${
                     values.length
                 }"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:40px;line-height:1.1">${
                                cumulative && ["monthly"].includes(actionsState.selectedGranularity)
                                    ? key.toLocaleString(undefined, {
                                          month: "short",
                                      })
                                    : key.toLocaleString(undefined, { day, month, hour })
                            }${h}</div></td> 
                     <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                         points[0].dashed ? "dashed" : "solid"
                     } ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${Math.round(values[0] * prec) / prec} ${
                                getUnit({ system: currentSettings.units }).precipUnit
                            }</td>
                 </tr>
                 ${values
                     .filter((d, i) => i > 0)
                     .map((value, i) => {
                         return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                             points[i + 1].dashed ? "dashed" : "solid"
                         } ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${
                             Math.round(value * prec) / prec
                         } ${getUnit({ system: currentSettings.units }).precipUnit}</td></tr>`
                     })
                     .join("")}
             </table>`
                        }}
                        shapeTip={
                            ["monthly"].includes(actionsState.selectedGranularity)
                                ? (EVENT, boundObj, state, point) => {
                                      let resultObj = point ?? boundObj
                                      let month = resultObj.key
                                          .toLocaleString("default", { month: "short", year: "numeric" })
                                          .split("")
                                          .join("")
                                      const prec = 100

                                      const warmer = Math.round(1000 * barDataInsights.normalMax[month]) / 1000
                                      let clim67 = Math.round(1000 * barDataInsights.clim67[month]) / 1000
                                      const normal = Math.round(1000 * barDataInsights.normal[month]) / 1000
                                      let clim33 = Math.round(1000 * barDataInsights.clim33[month]) / 1000
                                      const colder = Math.round(1000 * barDataInsights.normalMin[month]) / 1000
                                      let unit = "mm"
                                      if (currentSettings.units === "imperial") {
                                          clim33 = +convertWaterLengthValueOnly("metric", "imperial", clim33)
                                          clim67 = +convertWaterLengthValueOnly("metric", "imperial", clim67)
                                          unit = "in"
                                      }

                                      return `<div style="max-width:250px;">There is a ${warmer}% chance that precipitation will be higher than usual (above ${clim67} ). </br>  </br> There is a ${normal}% chance that precipitation will be within the normal range (${clim33} to ${clim67}). </br>  </br>There is a ${colder}% chance that precipitation will be lower than normal (below ${clim33}).</div>`
                                  }
                                : null
                        }
                        // Chart data content
                        data={
                            !actionsState.extraPrecipitationChart
                                ? [
                                      //======================= PLOT MEDIAN AS LINE ===================
                                      !cumulative && ["monthly"].includes(actionsState.selectedGranularity)
                                          ? barType != "med"
                                              ? null
                                              : monthlyChartData
                                          : null,

                                      //=================== DOUBLE  CANDLESTICK VERSION  =========================

                                      !cumulative &&
                                      ["monthly"].includes(actionsState.selectedGranularity) &&
                                      barType == "candl"
                                          ? convertToLineFromBar({
                                                obj: forecastQuantilesBarData[0.5],
                                                props: {
                                                    color: "#1D74B3",
                                                    barType: barType,
                                                    visible: false,
                                                    unit: currentSettings.units,
                                                    unitType: "precip",
                                                    date: 15,
                                                },
                                            })
                                          : null,

                                      !cumulative &&
                                      ["monthly"].includes(actionsState.selectedGranularity) &&
                                      barType == "candl"
                                          ? convertToDoubleCandlestick({
                                                obj: [
                                                    {
                                                        values: [
                                                            forecastQuantilesBarData,
                                                            climatologyQuantilesBarsData,
                                                        ],
                                                    },
                                                ],
                                                unit: currentSettings.units,
                                                unitType: "precip",
                                                colors: ["#1D74B3", "#EB6425"],
                                            })
                                          : null,

                                      // ===================== End Of Horizontal and vertical bars versions  ====================

                                      (["monthly"].includes(actionsState.selectedGranularity) && cumulative) ||
                                      (["daily"].includes(actionsState.selectedGranularity) && climatologyVisible)
                                          ? {
                                                type: "area",
                                                points: (!["monthly"].includes(actionsState.selectedGranularity)
                                                    ? trimmData(climLighten)
                                                    : cumulative
                                                    ? []
                                                    : duplicateMonthlyHistoricalDataForFutureSevenMonths(climLighten)
                                                ).map((d) =>
                                                    processUnitSystem(d, {
                                                        system: currentSettings.units,
                                                        type: "precip",
                                                    })
                                                ),
                                                color: "#C0E1EB",
                                                opacity: 0.6,
                                            }
                                          : null,
                                      (["monthly"].includes(actionsState.selectedGranularity) && cumulative) ||
                                      (["daily"].includes(actionsState.selectedGranularity) && climatologyVisible)
                                          ? {
                                                type: "area",
                                                points: (!["monthly"].includes(actionsState.selectedGranularity)
                                                    ? trimmData(climDarken)
                                                    : cumulative
                                                    ? []
                                                    : duplicateMonthlyHistoricalDataForFutureSevenMonths(climDarken)
                                                ).map((d) =>
                                                    processUnitSystem(d, {
                                                        system: currentSettings.units,
                                                        type: "precip",
                                                    })
                                                ),
                                                color: "#A0CBE0",
                                                opacity: 0.6,
                                            }
                                          : null,
                                      // Confidence Bands

                                      (["monthly"].includes(actionsState.selectedGranularity) && cumulative) ||
                                      (["daily"].includes(actionsState.selectedGranularity) && confidenceVisible)
                                          ? {
                                                type: "area",
                                                points: (cumulative &&
                                                ["monthly"].includes(actionsState.selectedGranularity)
                                                    ? []
                                                    : // assembleCumulativeData({
                                                      //   forecastData: cumulativeData.ds_fc,
                                                      //   confidenceLevel: 0.05,
                                                      //   variable: 'tp_sum'
                                                      // })
                                                      assembleAreaData({
                                                          areaData: forecastConfidence95,
                                                          isMonthly: ["monthly"].includes(
                                                              actionsState.selectedGranularity
                                                          ),
                                                          isCumulative: cumulative,
                                                          cumulativeType: "sum_per_month",
                                                          seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                                              return {
                                                                  x: +new Date(d),
                                                                  key: +new Date(d),
                                                                  y1: monthlyData.ds_fc.tp_sum["0.95"][i],
                                                                  y0: monthlyData.ds_fc.tp_sum["0.05"][i],
                                                                  max: monthlyData.ds_fc.tp_sum["0.95"][i],
                                                                  min: monthlyData.ds_fc.tp_sum["0.05"][i],
                                                              }
                                                          }),
                                                      })
                                                ).map((d) =>
                                                    processUnitSystem(d, {
                                                        system: currentSettings.units,
                                                        type: "precip",
                                                    })
                                                ),
                                                color: "#237CB5",
                                                opacity: 0.4,
                                            }
                                          : null,
                                      (["monthly"].includes(actionsState.selectedGranularity) && cumulative) ||
                                      (["daily"].includes(actionsState.selectedGranularity) && confidenceVisible)
                                          ? {
                                                type: "area",
                                                points: (cumulative &&
                                                ["monthly"].includes(actionsState.selectedGranularity)
                                                    ? assembleCumulativeData({
                                                          forecastData: cumulativeData.ds_fc,
                                                          confidenceLevel: 0.25,
                                                          variable: "tp_sum",
                                                      })
                                                    : assembleAreaData({
                                                          areaData: forecastConfidence75,
                                                          isMonthly: ["monthly"].includes(
                                                              actionsState.selectedGranularity
                                                          ),
                                                          isCumulative: cumulative,
                                                          cumulativeType: "sum_per_month",
                                                          seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                                              return {
                                                                  x: +new Date(d),
                                                                  key: +new Date(d),
                                                                  y1: monthlyData.ds_fc.tp_sum["0.75"][i],
                                                                  y0: monthlyData.ds_fc.tp_sum["0.25"][i],
                                                                  max: monthlyData.ds_fc.tp_sum["0.75"][i],
                                                                  min: monthlyData.ds_fc.tp_sum["0.25"][i],
                                                              }
                                                          }),
                                                      })
                                                ).map((d) =>
                                                    processUnitSystem(d, {
                                                        system: currentSettings.units,
                                                        type: "precip",
                                                    })
                                                ),
                                                color: "#237CB5",
                                                opacity: 0.4,
                                            }
                                          : null,
                                      (["monthly"].includes(actionsState.selectedGranularity) && cumulative) ||
                                      ["daily"].includes(actionsState.selectedGranularity)
                                          ? {
                                                type: "line",
                                                id: "line-mid",
                                                alwaysOnTop: true,
                                                points: (cumulative &&
                                                ["monthly"].includes(actionsState.selectedGranularity)
                                                    ? assembleCumulativeData({
                                                          forecastData: cumulativeData.ds_fc,
                                                          confidenceLevel: 0.5,
                                                          climCumulativeData: assembleLineData({
                                                              isMonthly: ["monthly"].includes(
                                                                  actionsState.selectedGranularity
                                                              ),
                                                              historical: historicalTemp,
                                                              isCumulative: cumulative,
                                                              cumulativeType: "sum_per_month",
                                                              forecast: forecastTemp,
                                                              seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                                                  return {
                                                                      x: new Date(d),
                                                                      y: monthlyData.ds_fc.tp_sum["0.5"][i],
                                                                  }
                                                              }),
                                                          }),
                                                          variable: "tp_sum",
                                                      })
                                                    : assembleLineData({
                                                          isMonthly: ["monthly"].includes(
                                                              actionsState.selectedGranularity
                                                          ),
                                                          historical: historicalTemp,
                                                          isCumulative: cumulative,
                                                          cumulativeType: "sum_per_month",
                                                          forecast: forecastTemp,
                                                          seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                                              return {
                                                                  x: new Date(d),
                                                                  y: monthlyData.ds_fc.tp_sum["0.5"][i],
                                                              }
                                                          }),
                                                      })
                                                ).map((d) =>
                                                    processUnitSystem(d, {
                                                        system: currentSettings.units,
                                                        type: "precip",
                                                    })
                                                ),
                                                color: "#237CB5",
                                                "stroke-width": 2,
                                            }
                                          : null,

                                      ["hourly"].includes(actionsState.selectedGranularity)
                                          ? {
                                                type: "line",
                                                id: "line-mid",
                                                alwaysOnTop: true,
                                                points: assembleLineData({
                                                    selectedGranularity: actionsState.selectedGranularity,
                                                    hourlyData: hourlyData,
                                                    propVar: "tp",
                                                }).map((d) =>
                                                    processUnitSystem(d, {
                                                        system: currentSettings.units,
                                                        type: "precip",
                                                    })
                                                ),
                                                color: "#237CB5",
                                                "stroke-width": 2,
                                            }
                                          : null,
                                  ]
                                : [
                                      climatologyVisible
                                          ? {
                                                type: "area",
                                                points: trimmData(
                                                    duplicateMonthlyHistoricalDataForFutureSevenMonths(extraClimLighten)
                                                ),
                                                color: "#C0E1EB",
                                                opacity: 0.6,
                                            }
                                          : null,
                                      climatologyVisible
                                          ? {
                                                type: "area",
                                                points: trimmData(
                                                    duplicateMonthlyHistoricalDataForFutureSevenMonths(extraClimDarken)
                                                ),
                                                color: "#A0CBE0",
                                                opacity: 0.6,
                                            }
                                          : null,
                                      // Confidence Bands
                                      climatologyVisible
                                          ? {
                                                type: "area",
                                                points: trimmData(extraForecastConfidence95),
                                                color: "#237CB5",
                                                opacity: 0.4,
                                            }
                                          : null,
                                      climatologyVisible
                                          ? {
                                                type: "area",
                                                points: trimmData(extraForecastConfidence75),
                                                color: "#237CB5",
                                                opacity: 0.4,
                                            }
                                          : null,

                                      {
                                          type: "line",
                                          id: "line-mid",
                                          alwaysOnTop: true,
                                          points: trimmData(
                                              extraHistoricalTemp.concat(
                                                  extraForecastTemp.map((d) => Object.assign(d, { dashed: true }))
                                              )
                                          ),
                                          color: "#237CB5",
                                          "stroke-width": 2,
                                      },
                                  ]
                        }
                    ></LineAreaChartComponent>
                </div>
                <div className="weather-chart__specs-container">
                    <ChartSpecs
                        sliderDefaultValue={evaporationData.coefficient}
                        barType={barType}
                        type={actionsState.extraPrecipitationChart ? "water-budget" : weatherVariable}
                        historicalPending={historicalPending}
                        chartRef={chartRef}
                        cumulative={["monthly"].includes(actionsState.selectedGranularity) && cumulative}
                        climatologyVisible={climatologyVisible}
                        confidenceVisible={confidenceVisible}
                        selectedGranularity={actionsState.selectedGranularity}
                        lastUpdated={lastUpdated}
                        handleBarTypeChange={(candlChecked) => {
                            if (candlChecked) setBarType("candl")
                            else setBarType("med")
                        }}
                        handleAreasVisibilityChange={({ conf, clim }) => {
                            setConfidenceBarsVisibility(conf)
                            setClimatologyVisible(clim)
                        }}
                        colsArr={["Date", "Value (mm)"]}
                        data={{
                            // csv: combinedCsvData(climCsvData, forcCsvData, histCsvData),
                            csv: ["daily"].includes(actionsState.selectedGranularity) ? csvDailyData : csvMonthlyData,
                            // hourlyCsv: !actionsState.extraPrecipitationChart
                            //     ? mergeHistoricalAndForecastData({
                            //           forecast: hourlyData.ds_fc,
                            //           historical: hourlyData.ds_hist,
                            //           prop: "tp",
                            //       })
                            //     : mergeHistoricalAndForecastData({
                            //           forecast: hourlyData.ds_fc,
                            //           historical: hourlyData.ds_hist,
                            //           prop: "e",
                            //       }),
                        }}
                        // onHourlyCsvDataTrigger={() => {
                        //     if (actionsState.extraPrecipitationChart) {
                        //         return new Promise((resolve, reject) => {
                        //             if (hourlyData.ds_fc.time.length > 0) {
                        //                 setTimeout((d) => {
                        //                     const mergedData = mergeHistoricalAndForecastData({
                        //                         forecast: hourlyData.ds_fc,
                        //                         historical: hourlyData.ds_hist,
                        //                         prop: "tp",
                        //                     })
                        //                     resolve(mergedData)
                        //                 }, 1000)
                        //             } else {
                        //                 currentUser.getIdToken().then((userToken) => {
                        //                     networking
                        //                         .get(`/api/v1/weather/evaporation/hourly/${id}`, {
                        //                             extraHeaders: { "User-Token": userToken },
                        //                         })
                        //                         .then((res) => {
                        //                             setHourlyData({
                        //                                 ...res.data,
                        //                             })
                        //                             resolve(res.data)
                        //                         })
                        //                         .catch(() => {
                        //                             reject()
                        //                         })
                        //                 })
                        //             }
                        //         })
                        //     } else {
                        //         return new Promise((resolve, reject) => {
                        //             currentUser.getIdToken().then((userToken) => {
                        //                 if (hourlyData.ds_fc.time.length > 0) {
                        //                     setTimeout((d) => {
                        //                         const mergedData = mergeHistoricalAndForecastData({
                        //                             forecast: hourlyData.ds_fc,
                        //                             historical: hourlyData.ds_hist,
                        //                             prop: "tp",
                        //                         })
                        //                         resolve(mergedData)
                        //                     }, 1000)
                        //                 } else {
                        //                     networking
                        //                         .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                        //                             extraHeaders: { "User-Token": userToken },
                        //                         })
                        //                         .then((res) => {
                        //                             setHourlyData({
                        //                                 ...res.data,
                        //                             })
                        //                             resolve(res.data)
                        //                         })
                        //                         .catch(() => {
                        //                             reject()
                        //                         })
                        //                 }
                        //             })
                        //         })
                        //     }
                        // }}
                        actionsState={actionsState}
                        onSliderChange={handleChangeSlider}
                        disabled={!evaporationData.pending && !extraHistoricalTemp.length}
                    />
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default memo(PrecipitationChart)
