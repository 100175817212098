import React, { useContext } from "react"

import { ArrowUpwardSharp, ArrowDownwardSharp } from "@material-ui/icons"

import "./TableHeader.css"
import TableContext from "./TableContext"

function TableHeaders(props) {
    const {
        propName,
        active = false,
        sortFunctionName = "",
        displayName,
        style,
        className,
        onClick = () => null,
        onDragEnd = () => null,
        sortable = false,
        draggable = false,
        sticky,
        // paddingLeft,
        headerRender = () => null,
        headerComponent: HeaderComponent,
    } = props

    const { checked, handleCheckAllRows, checkedStatus } = useContext(TableContext)

    function handleHeaderDragStart(e) {
        // pass dragged header data through the event
        e.dataTransfer.setData("draggable-header-prop-name", propName)
        e.dataTransfer.setData("draggable-header-display-name", displayName)
    }

    return (
        <>
            <th
                className={`table-header ${active ? "active" : ""} ${className || ""} ${(sticky && "stick") || ""}`}
                style={{ ...style, cursor: draggable ? "grab" : "initial" }}
                draggable={draggable}
                onClick={() => sortable && onClick(propName)}
                onDragStart={handleHeaderDragStart}
                onDragEnd={onDragEnd}
            >
                {(headerRender && headerRender(displayName, { checked, handleCheckAllRows, checkedStatus })) ||
                    (HeaderComponent && (
                        <HeaderComponent
                            displayName={displayName}
                            headerOptions={{ checked, handleCheckAllRows, checkedStatus }}
                        />
                    )) ||
                    displayName}
                {sortable && (
                    <div className="table-header__icon">
                        {sortFunctionName === "ASC" ? (
                            <ArrowUpwardSharp fontSize="inherit" />
                        ) : sortFunctionName === "DES" ? (
                            <ArrowDownwardSharp fontSize="inherit" />
                        ) : (
                            <ArrowUpwardSharp fontSize="inherit" /> // Default
                        )}
                    </div>
                )}
            </th>
        </>
    )
}

export default TableHeaders
