import React, { useState, useEffect, useContext } from "react"

import HistoricalChart from "./components/HistoricalChart"
import TableColumn from "../../ui/Table/TableColumn"
import SimpleTable from "../../ui/Table/SimpleTable"
import ReportDownloadButton from "../LongTerm/components/ReportDownloadButton"
import useHistoricalData from "../../hooks/useHistoricalData"

import { useParams } from "react-router-dom"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import ChartLabels from "../../components/LongTerm/ChartLabels/ChartLabels"
import ChartLabelItem from "../../components/LongTerm/ChartLabels/ChartLabelItem"
import Checkbox from "../../ui/Checkbox/Checkbox"
import { LineIcon } from "../Departure/components/DepartureChart"

function Historical(props) {
    const { reportId } = useParams()
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const {
        ready,
        locations,
        scenarios,
        variables,
        selectedLocation,
        selectedScenario,
        selectedVariable,
        setSelectedLocation,
        setSelectedScenario,
        setSelectedVariable,
    } = useAdaptationValues()

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?scenario=${selectedScenario}&location=${selectedLocation}&var=${selectedVariable}`

        const request = http.get(`/api/v1/adapt/${reportId}/validation${query}`, { timeout: 180000 })
        request
            .then((response) => {
                setData(response.data)
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedLocation, selectedVariable, selectedScenario])

    const { chartData, tableData } = useHistoricalData(data)

    const selectedVariableObj = variables.find(({ value }) => value === selectedVariable)
    const selectedScenarioObj = scenarios.find((s) => s.value === selectedScenario)

    return (
        <>
            <ReportDownloadButton
                text={`Historical Validation / ${selectedLocation} / ${selectedVariableObj.label} / ${selectedScenarioObj.label}`}
            />
            <div className="overview historical">
                <div className="overview__main">
                    <HistoricalChart
                        loading={loading}
                        data={chartData}
                        locations={locations}
                        scenarios={scenarios}
                        variables={variables}
                        selectedLocation={selectedLocation}
                        selectedScenario={selectedScenario}
                        selectedVariable={selectedVariable}
                        setSelectedLocation={setSelectedLocation}
                        setSelectedScenario={setSelectedScenario}
                        setSelectedVariable={setSelectedVariable}
                    >
                        <SimpleTable data={tableData}>
                            <TableColumn
                                propName="desc"
                                displayName="Data Summary"
                                style={{ flex: 1, maxWidth: "initial" }}
                            />
                            <TableColumn
                                propName="public"
                                displayName="Public Data Providers"
                                style={{ flex: 2 }}
                                render={(v) => v && v.toFixed(2)}
                            />
                            <TableColumn
                                propName="climateai"
                                displayName="ClimateAi"
                                style={{ flex: 2 }}
                                render={(v) => v && v.toFixed(2)}
                            />
                            <TableColumn
                                propName="improvement"
                                displayName="Improvement (%)"
                                style={{ flex: 2 }}
                                render={(v) => v + "%"}
                            />
                        </SimpleTable>
                        <ChartLabels>
                            <ChartLabelItem renderIcon={() => <Checkbox color={"#1C9690"} status={"empty"} checked />}>
                                ClimateAi
                            </ChartLabelItem>
                            <ChartLabelItem renderIcon={() => <Checkbox color={"#EE6900"} status={"empty"} checked />}>
                                Public Data Providers
                            </ChartLabelItem>
                            <ChartLabelItem renderIcon={() => <LineIcon fill="#9F24AA" />}>Observation</ChartLabelItem>
                        </ChartLabels>

                        {!tableData || (tableData.length === 0 && <div style={{ height: 170 }} />)}
                    </HistoricalChart>
                </div>
            </div>
        </>
    )
}

export default Historical
