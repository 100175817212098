import React, { useState } from "react"
import MaterialPopover from "../Popover/MaterialPopover"

function SidebarItem(props) {
    const { icon: IconComponent, name, active, children, grow = false, onClick = () => null, sidebarOpen } = props

    const [open, toggle] = useState(true)

    return (
        <>
            {/* <MaterialPopover position="right" text={name} isVisible={open ? false : true}> */}
            <div
                className={
                    "flex flex-row items-center h-[48px] pr-[14px] w-full cursor-pointer min-h-[48px]" +
                    " hover:bg-gray-5" +
                    (active ? " text-accent font-bold fill-accent" : " fill-gray-60")
                }
                onClick={() => {
                    if (sidebarOpen) (children && toggle(!open)) || onClick(false)
                    else {
                        // force open
                        toggle(true)
                        onClick(!active)
                    }
                }}
                // onClick={() => (children && toggle(!open)) || onClick()}
            >
                <div
                    className={
                        "grow-0 shrink-0 transition-all duration-200 ease-in-out pr-[12px] pl-[20px]" +
                        (sidebarOpen ? "" : " md:px-[22px]")
                    }
                >
                    <span className="block w-[24px] h-[24px]">
                        {IconComponent && (
                            <IconComponent
                                fill={sidebarOpen ? " text-accent font-bold fill-accent" : " fill-gray-60"}
                            />
                        )}
                    </span>
                </div>

                <div className="grow shrink-0 flex flex-row items-center h-full border-y border-transparent border-b-gray-10">
                    <h2 className="text-[16px] leading-none -mb-[1px]">{name}</h2>
                </div>
            </div>
            {/* </MaterialPopover> */}
            {sidebarOpen && open && children}
        </>
    )
}

export default SidebarItem
