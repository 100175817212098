import * as React from "react"

function FieldsIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.125 13.375h-2.5A.625.625 0 000 14v2.5a.625.625 0 00.625.625h2.5a.625.625 0 00.625-.625V14a.625.625 0 00-.625-.625zm0-12.5h-2.5A.625.625 0 000 1.5V4a.625.625 0 00.625.625h2.5A.625.625 0 003.75 4V1.5a.625.625 0 00-.625-.625zm0 6.25h-2.5A.625.625 0 000 7.75v2.5a.625.625 0 00.625.625h2.5a.625.625 0 00.625-.625v-2.5a.625.625 0 00-.625-.625zM19.375 14h-12.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h12.5a.624.624 0 00.625-.625v-1.25a.624.624 0 00-.625-.625zm0-12.5h-12.5a.625.625 0 00-.625.625v1.25A.625.625 0 006.875 4h12.5A.625.625 0 0020 3.375v-1.25a.625.625 0 00-.625-.625zm0 6.25h-12.5a.625.625 0 00-.625.625v1.25a.625.625 0 00.625.625h12.5A.624.624 0 0020 9.625v-1.25a.625.625 0 00-.625-.625z"
                fill={props.fill}
            />
        </svg>
    )
}

export default FieldsIcon
