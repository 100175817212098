import React from "react"

// Components
import MaterialPopover from "../../../ui/Popover/MaterialPopover"

// Utils
import { convertWaterLengthValueOnly } from "../../../Util/UnitConversion"

function CropTableTomorrowPrecipt(props) {
    const { rowData, units } = props

    let data = rowData?.weather_variables?.tp?.data?.tp_sum
    return (
    <>
        <MaterialPopover text="Tomorrow's Precipitation" position="bottom" distance="medium">
             <div className="weather_variables__temp_container">
                {convertWaterLengthValueOnly("metric", units, data)}
            </div>
        </MaterialPopover>
    </>
    )
}

export default CropTableTomorrowPrecipt
