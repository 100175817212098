// sort array ascending
const validNumber = (arr) => arr.filter((d) => typeof d === "number")
const asc = (arr) => arr.sort((a, b) => a - b)
const sum = (arr) => arr.reduce((a, b) => a + b, 0)
const mean = (arr) => sum(arr) / arr.length

function quantile(arr, q) {
    const pos = (arr.length - 1) * q
    const base = Math.floor(pos)
    const rest = pos - base
    if (arr[base + 1] !== undefined) return arr[base] + rest * (arr[base + 1] - arr[base])
    else return arr[base]
}

export function getQuartiles(data) {
    const arr = asc(validNumber(data))
    return {
        min: arr[0],
        q1: quantile(arr, 0.25),
        q2: quantile(arr, 0.5),
        q3: quantile(arr, 0.75),
        max: arr[arr.length - 1],
    }
}
