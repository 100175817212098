import React from "react"
import { withRouter } from "react-router"
import { Link as RouterLink } from "react-router-dom"
import Copyright from "../ui/Copyright/Copyright"
import "./CreatePassword.css"

const PasswordCode = () => {
    return (
        <div className="create-password">
            <div className="create-password__container">
                <img alt="climate ai color logo" className=".confirmation-screens__logo " src="/Climate-AI-Color-Logo.png" />
                <div className="create-password__content">
                    <div className="create-password__title">Create New Password</div>
                    <div className="create-password__text">We will request this password every time you log in.</div>
                    <div className="create-password__form">
                        <div className="create-password__form-text">New Password</div>
                        <input className="create-password__text-input" type="text" id="code" name="code"></input>
                        <div className="create-password__form-text-2">Confirm New Password</div>
                        <input className="create-password__text-input" type="text" id="code" name="code"></input>
                        <div className="create-password__button-container">
                            <RouterLink to="/">
                                <button className="create-password__cancel-button">Cancel</button>
                            </RouterLink>
                            <RouterLink to="/password-confirmation">
                                <button className="create-password__confirm-button">Confirm</button>
                            </RouterLink>
                        </div>
                    </div>
                </div>
                <div className="create-password__copyright">
                    <Copyright />
                </div>
            </div>
        </div>
    )
}

export default withRouter(PasswordCode)
