import React, { useRef, useState } from "react"

// Components
import ColorPicker from "./ColorPicker"
import TimesIcon from "../Icons/TimesIcon"

//
import "./AddLabelForm.css"

function AddLabelForm(props) {
    const {
        onSelectLabel = () => null,
        actions: { close = () => null },
    } = props

    const [value, setValue] = useState("")
    const [color, setColor] = useState(null)

    const inputRef = useRef(null)

    function handleInputChange(e) {
        setValue(e.target.value)
    }

    function handleColorSelect(_color) {
        setColor(_color)
    }

    function handleSaveButtonPress() {
        onSelectLabel({ name: value, color })
        close()
    }

    return (
        <>
            <div className="label-form">
                <div className="label-form__text">Type a name:</div>
                <div className="label-form__input-container">
                    <input
                        id="new-label-name__table"
                        type="text"
                        placeholder="Label name"
                        className="label-form__input-container__input"
                        value={value}
                        onChange={handleInputChange}
                        ref={inputRef}
                        autoFocus
                    />
                    <button
                        id="label-color-selector__table"
                        className="label-form__input-container__button"
                        onClick={() => setValue("")}
                    >
                        <TimesIcon />
                    </button>
                </div>
                <div className="label-form__text">Choose a color:</div>
                <div className="label-form__color-picker">
                    <ColorPicker value={color} onColorSelect={handleColorSelect} />
                </div>
                <div style={{ textAlign: "right" }}>
                    <button className="label-form__save" onClick={handleSaveButtonPress} disabled={!value || !color}>
                        Save
                    </button>
                </div>
            </div>
        </>
    )
}

export default AddLabelForm
