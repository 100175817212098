import * as React from "react"

function LowHumidityIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18.004 25c-1.875 0-3-.8-3-2.8 0-2.8 3-5.2 3-5.2s3 2.4 3 5.2c0 2-1.125 2.8-3 2.8zm6-13a3.969 3.969 0 00-1.334.245 4.977 4.977 0 00-9.332 0 3.992 3.992 0 00-3.334 7.22h3.614a11.735 11.735 0 013.137-4.027 2 2 0 012.5 0 11.733 11.733 0 013.137 4.027h3.612a4 4 0 00-2-7.465z"
                fill={props.fill || "#fff"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5.375a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25z"
                fill={props.fill || "#fff"}
            />
            <path d="M2 4a.5.5 0 000 1V4zm5 1a.5.5 0 000-1v1zM2 5h5V4H2v1z" fill={props.fill || "#fff"} />
        </svg>
    )
}

export default LowHumidityIcon
