import React from "react"
// Modules
// Components
// Views
// Context providers / Utils

// Hooks
// Material-UI *
// Styles
import "./ToggleSwitch.css"

const ToggleSwitch = (props) => {
    const { isOn = false, isAllowed, onChange = () => null, onText = "", offText = "" } = props

    return (
        <div className="toggle-container">
            <div className="toggle-label">{isOn ? onText : offText}</div>
            <label className={`toggle-switch ${isAllowed ? "" : "disabled"}`}>
                <input
                    type="checkbox"
                    checked={isOn}
                    onChange={() => {
                        if (isAllowed) onChange(!isOn)
                    }}
                />
                <span className="toggle-round"></span>
            </label>
        </div>
    )
}

export default ToggleSwitch
