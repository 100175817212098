import React, { useRef, useState } from "react"

import AltArrowLeft from "../../../ui/Icons/newIcons/AltArrowLeft"
import CheckAltIcon from "../../../ui/Icons/CheckAltIcon"
import PlusIconAlt from "../../../ui/Icons/PlusIconAlt"
import TrashIcon from "../../../ui/Icons/TrashIcon"
import ScenarioCard from "./ScenarioCard"
import ScenarioForm from "./ScenarioForm"
import ScenarioItem from "./ScenarioItem"

import { v4 as uuidv4 } from "uuid"

import "./Scenarios.css"
import { capitalizeFirstLetter } from "../../../helpers/plantingTool"

const now = new Date()
now.setHours(0)
now.setMinutes(0)
now.setSeconds(0)
now.setMilliseconds(0)

const formInitialState = {
    name: "",
    plantingDate: now,
    length: 14,
    checked: true,
}

function Scenarios(props) {
    const {
        mainScenario,
        scenarios = {},
        onNewScenario = () => null,
        onEditScenario = () => null,
        loading,
        onDeleteScenario = () => null,
        onCheckStatusChange = () => null,
        now,
        tab = "",
    } = props

    const [currentlyEditing, setCurrentlyEditing] = useState(null)
    const [advancedSettings, toggleAdvancedSettings] = useState(false)
    const [errors, setErrors] = useState({})

    const updatedPaths = useRef({})
    let editingFlag = useRef(false)

    function validateScenario() {
        const _errors = { ...errors }

        if (currentlyEditing && !currentlyEditing.name) {
            _errors.name = "You need to provide a name for your scenario."
        } else {
            delete _errors.name
        }

        // check errors inside alerts
        currentlyEditing &&
            Object.keys(currentlyEditing.chart_data).forEach((riskId) => {
                currentlyEditing.chart_data[riskId] &&
                    Object.keys(currentlyEditing.chart_data[riskId]).forEach((alertId) => {
                        const alert = currentlyEditing.chart_data[riskId][alertId]
                        if (alert && Object.keys(alert).length > 0 && alert.alert_data) {
                            // check threshold
                            const {
                                alert_data: { threshold },
                                lag,
                                weight,
                            } = alert

                            // check weight
                            if (typeof weight !== "number" || Number.isNaN(lag) || lag === "NaN") {
                                _errors["lag-" + riskId + alertId] = "Please enter a value"
                            } else {
                                delete _errors["lag-" + riskId + alertId]
                            }

                            // check lag
                            if (typeof lag !== "number" || Number.isNaN(weight) || weight === "NaN") {
                                _errors["weight-" + riskId + alertId] = "Please enter a value"
                            } else {
                                delete _errors["weight-" + riskId + alertId]
                            }

                            // check threshold
                            if (typeof threshold !== "number" || Number.isNaN(threshold) || threshold === "NaN") {
                                _errors["threshold-" + riskId + alertId] = "Please enter a value"
                            } else {
                                delete _errors["threshold-" + riskId + alertId]
                            }
                        }
                    })
            })

        return _errors
    }

    function selectNewScenario() {
        const uid = uuidv4()
        setCurrentlyEditing({
            ...mainScenario,
            ...formInitialState,
            uid,
        })
    }

    function selectExistingScenario(uid) {
        if (!scenarios[uid]) {
            console.warn("Could not find the scenario")
            return
        }

        editingFlag.current = true
        setCurrentlyEditing(scenarios[uid])
    }

    function handleScenarioEdit(path = "", value) {
        if (errors && Object.keys(errors)) {
            setErrors({})
        }

        updatedPaths.current[path] = value

        const keys = path.split("/")
        const body = { ...currentlyEditing }
        let head = body

        while (keys.length > 0) {
            const currentKey = keys[0]
            if (keys.length === 1) {
                head[currentKey] = value
            } else {
                head[currentKey] = {
                    ...head[currentKey],
                }

                head = head[currentKey]
            }
            keys.shift()
        }

        setCurrentlyEditing(body)
    }

    return (
        <>
            <div className="scenarios">
                {/* <div className="scenario-card"> */}
                {/* <ScenarioCard
                    render={() => {
                        return "Settings"
                    }}
                >
                    
                </ScenarioCard> */}
                <ScenarioCard
                    render={() => {
                        return (
                            <>
                                {!currentlyEditing && (
                                    <>
                                        {capitalizeFirstLetter(tab.split("_")[0])} scenarios
                                        {!loading && (
                                            <button
                                                className="scenarios-actions__button right"
                                                onClick={() => {
                                                    selectNewScenario()
                                                }}
                                            >
                                                <PlusIconAlt fill="#666D74" />
                                            </button>
                                        )}
                                    </>
                                )}
                                {currentlyEditing && (
                                    <>
                                        <button
                                            className="scenarios-actions__button left"
                                            onClick={() => {
                                                editingFlag.current = false
                                                updatedPaths.current = {}
                                                setCurrentlyEditing(null)
                                                setErrors({})
                                            }}
                                        >
                                            <AltArrowLeft fill="#666D74" />
                                        </button>
                                        {currentlyEditing.uid !== "current" && (
                                            <button
                                                className="scenarios-actions__button right"
                                                style={{ marginRight: 10 }}
                                                onClick={() => {
                                                    editingFlag.current = false
                                                    updatedPaths.current = {}
                                                    setCurrentlyEditing(null)
                                                    setErrors({})
                                                    onDeleteScenario(currentlyEditing.uid)
                                                }}
                                            >
                                                <TrashIcon fill="#666D74" size="100%" />
                                            </button>
                                        )}
                                        <button
                                            className="scenarios-actions__button"
                                            onClick={() => {
                                                const _errors = validateScenario()

                                                if (_errors && Object.keys(_errors).length > 0) {
                                                    setErrors(_errors)
                                                } else {
                                                    if (editingFlag.current) {
                                                        onEditScenario(currentlyEditing.uid, currentlyEditing, {
                                                            ...updatedPaths.current,
                                                        })
                                                    } else {
                                                        onNewScenario(currentlyEditing.uid, currentlyEditing, {
                                                            ...updatedPaths.current,
                                                        })
                                                    }

                                                    editingFlag.current = false
                                                    updatedPaths.current = {}
                                                    setCurrentlyEditing(null)
                                                    setErrors({})
                                                }
                                            }}
                                        >
                                            <CheckAltIcon fill="#666D74" />
                                        </button>
                                    </>
                                )}
                            </>
                        )
                    }}
                >
                    {loading && <div className="table__loader" />}
                    {!currentlyEditing &&
                        Object.keys(scenarios).map((key) => {
                            const scenario = scenarios[key]

                            return (
                                <ScenarioItem
                                    key={key}
                                    scenario={scenario}
                                    onEdit={() => selectExistingScenario(scenario.uid)}
                                    onCheckStatusChange={onCheckStatusChange}
                                />
                            )
                        })}

                    {currentlyEditing && (
                        <ScenarioForm
                            scenario={currentlyEditing}
                            editScenario={handleScenarioEdit}
                            errors={errors}
                            minDate={now}
                            advancedSettings={advancedSettings}
                            toggleAdvancedSettings={toggleAdvancedSettings}
                            tab={tab}
                        />
                    )}
                    {/* {currentlyEditing && (
                        <div className="scenarios__advance-settings-separator">
                            <CardItem
                                icon={
                                    <div style={{ width: 20, height: 20 }}>
                                        <Checkbox
                                            checked={advancedSettings}
                                            status={(advancedSettings && "full") || "empty"}
                                            onChange={() => toggleAdvancedSettings(!advancedSettings)}
                                        />
                                    </div>
                                }
                                render={() => {
                                    return "See advanced settings"
                                }}
                            />
                        </div>
                    )} */}
                </ScenarioCard>
                {/* </div> */}
            </div>
        </>
    )
}

export default Scenarios
