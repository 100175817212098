import * as React from "react"

function HeatSoilIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#prefix__clip0)">
                <path
                    d="M24.205 16.19h-2.031c-.471 0-.853-.416-.853-.927 0-.512.382-.927.853-.927h2.559c.323 0 .618.199.762.513l3.412 7.41c.284.616-.129 1.34-.763 1.34H.85c-.634 0-1.046-.724-.763-1.34L3.5 14.85a.849.849 0 01.763-.513H6.82c.47 0 .853.415.853.927 0 .511-.382.926-.853.926H4.789L2.23 21.747h24.534l-2.559-5.558z"
                    fill={props.fill || "#fff"}
                />
            </g>
            <path d="M15 4.083a2.917 2.917 0 100 5.834 2.917 2.917 0 000-5.834z" fill={props.fill || "#fff"} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 .583a.583.583 0 01.583.584v.583a.583.583 0 01-1.166 0v-.583A.583.583 0 0115 .583zM9.92 1.921a.583.583 0 01.826 0l.875.875a.584.584 0 01-.825.825l-.875-.875a.583.583 0 010-.825zm10.159 0a.583.583 0 010 .825l-.875.875a.584.584 0 11-.825-.825l.875-.875a.583.583 0 01.825 0zM8.583 7a.583.583 0 01.584-.583h.583a.583.583 0 010 1.166h-.583A.583.583 0 018.583 7zm11.084 0a.583.583 0 01.583-.583h.583a.583.583 0 010 1.166h-.583A.583.583 0 0119.667 7zm-8.046 3.38a.584.584 0 010 .824l-.875.875a.583.583 0 01-.825-.825l.875-.875a.583.583 0 01.825 0zm6.758 0a.583.583 0 01.825 0l.875.874a.583.583 0 01-.825.825l-.875-.875a.583.583 0 010-.825zM15 11.666a.583.583 0 01.584.583v.583a.583.583 0 11-1.166 0v-.583a.583.583 0 01.583-.583z"
                fill={props.fill || "#fff"}
            />
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill={props.fill || "#fff"} transform="translate(0 6)" d="M0 0h29v22H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default HeatSoilIcon
