import { useState, useEffect, useMemo } from "react"

import "./Overview.css"
import "./components/OverviewRisks.css"
import OverviewMap from "./components/OverviewMap"
import TableColumn from "../../ui/Table/TableColumn"
import SimpleTable from "../../ui/Table/SimpleTable"
// import http from "../../Util/http"
import useOverviewData from "../../hooks/useOverviewData"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import { useParams } from "react-router-dom"
import { Popover } from "../../ui/Popover"
import getRiskIcon from "../../Util/getRiskIcon"
import ReportDownloadButton from "../LongTerm/components/ReportDownloadButton"
import { capitalizeFirstCharacter } from "../../helpers/wordHelper"

function Overview(props) {
    const { reportId } = useParams()

    const [mapLoading, setMapLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const {
        ready,
        locations,
        crops,
        selectedCrop,
        setSelectedCrop,
        decades,
        selectedDecade,
        setSelectedDecade,
        scenarios,
        selectedScenario,
        setSelectedScenario,
        setSelectedLocation,
        risks,
    } = useAdaptationValues()

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        const request = http.get(
            `/api/v1/adapt/${reportId}/overview?scenario=${selectedScenario}&crop=${selectedCrop}&decade=${selectedDecade}`,
            { timeout: 180000 }
        )
        request
            .then((response) => {
                setData(response.data)
                console.log(response.data)
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedCrop, selectedDecade, selectedScenario])

    useEffect(() => {
        // This waits out the navbar to be closed
        setTimeout(() => {
            setMapLoading(false)
        }, 300)
    }, [])

    // Transform Info
    const { mapData, tableData } = useOverviewData(data, locations)

    // create risks array (iteration per location)
    const tableColumns = useMemo(() => {
        const cols = []
        risks.forEach((risk) => {
            console.log(risk)
            const icon = getRiskIcon(risk.icon)
            cols.push({
                propName: `risk_${risk.id}`,
                displayName: (
                    <>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {icon && (
                                <Popover text={risk.stage || null}>
                                    <div style={{ width: 20, height: 20, marginRight: 10 }}>{icon}</div>
                                </Popover>
                            )}
                            <div>
                                {`${risk.title}`}
                                {(risk.subtitle || risk.stage) && (
                                    <>
                                        <br />
                                        {`(${(risk.subtitle && risk.subtitle + ", ") || ""}${
                                            (risk.stage && risk.stage) || ""
                                        })`}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                ),
            })
        })
        return cols
    }, [risks])

    const selectedDecadeObj = decades.find((d) => d.value === selectedDecade)
    const selectedScenarioObj = scenarios.find((s) => s.value === selectedScenario)

    console.log(tableData)

    return (
        <>
            <ReportDownloadButton
                text={`Overview / ${selectedCrop && capitalizeFirstCharacter(selectedCrop)} / ${
                    selectedDecadeObj.label
                } / ${selectedScenarioObj.label}`}
            />
            <div className="overview">
                <div className="overview__main">
                    <OverviewMap
                        pathname={`/${reportId}/decadal`}
                        showMap={!mapLoading}
                        loading={loading}
                        data={mapData}
                        tableData={tableData}
                        crops={crops}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        decades={decades}
                        selectedDecade={selectedDecade}
                        setSelectedDecade={setSelectedDecade}
                        scenarios={scenarios}
                        selectedScenario={selectedScenario}
                        setSelectedScenario={setSelectedScenario}
                        setSelectedLocation={setSelectedLocation}
                    >
                        {/* <div className="overview__table"> */}
                        <SimpleTable data={tableData}>
                            <TableColumn
                                propName="location"
                                displayName="Location"
                                style={{ minWidth: 150, backgroundColor: "#fff", flex: 1 }}
                                headerStyle={{ minWidth: 150, flex: 1 }}
                                sticky
                            />
                            <TableColumn
                                propName="yield"
                                displayName="Yield Impact"
                                style={{ minWidth: 150, backgroundColor: "#fff", flex: 1 }}
                                headerStyle={{ minWidth: 150, flex: 1 }}
                                render={(v) => v + "%"}
                                sortable
                            />
                            {tableColumns &&
                                tableColumns.map((col) => {
                                    return (
                                        <TableColumn
                                            key={`overview_tablecol_${col.propName}`}
                                            propName={col.propName}
                                            displayName={col.displayName}
                                            style={{ minWidth: 200, backgroundColor: "#fff", flex: 1 }}
                                            headerStyle={{ minWidth: 200, flex: 1 }}
                                            render={(v) => typeof v === "number" && v.toFixed(2) + "%"}
                                            sortable
                                        />
                                    )
                                })}
                        </SimpleTable>
                        {/* </div> */}
                    </OverviewMap>
                </div>
                {/* <div className="overview__aside">
                    {risks && Object.keys(risks).length > 0 && <OverviewRisks loading={loading} risks={risks} />}
                </div> */}
            </div>
        </>
    )
}

export default Overview
