import React from "react"

import Checkbox from "../Checkbox/Checkbox"
import TrashIcon from "../Icons/TrashIcon"

import "./LabelItem.css"

// TODO
// import { BlockPicker } from "react-color"

function LabelItem(props) {
    const {
        name,
        color,
        // onColorChange = () => null,
        onDeleteLabel = () => null,
        onSelectLabel = () => null,
        hideDeleteButton = false,
        onCheckChange = () => null,
        checked = false,
    } = props
    return (
        <>
            <div id={`${name}__saved-label__table`} key={`label-item__${name}`} className="label-item">
                {/* <BlockPicker /> */}
                {hideDeleteButton && <Checkbox checked={checked} status={checked && "full"} onChange={onCheckChange} />}
                <button
                    id="saved-label-name-button__table"
                    className="label-item__label"
                    style={{ backgroundColor: color, marginLeft: hideDeleteButton && 5 }}
                    onClick={() => onSelectLabel({ name, color })}
                >
                    <div className="label-item__label__name">{name}</div>
                </button>
                {!hideDeleteButton && (
                    <button
                        id="saved-label-delete-button__table"
                        className="label-item__delete"
                        onClick={() => onDeleteLabel({ name, color })}
                    >
                        <TrashIcon className="" fill="505050" />
                    </button>
                )}
            </div>
        </>
    )
}

export default LabelItem
