import React, { useState, useEffect, useContext } from "react"
import getElementImage from "../../../Util/getElementImage"

import { v4 as uuid } from "uuid"
import TogglerButton from "../../../ui/Button/TogglerButton"
import Dropdown from "../../../ui/Controls/Dropdown"
import DropdownItem from "../../../ui/Controls/DropdownItem"
import DownloadIcon from "../../../ui/Icons/DownloadIcon"
import { AuthContext } from "../../../Auth/Auth"
import { DataContext } from "../../../Util/Data"

import { useLocation } from "react-router-dom"
import { demo_user } from "../../../Util/config"

import { BounceLoader } from "react-spinners"
import { ToastContainer, toast } from "react-toastify"

import app from "../../../Util/Fire"
import http from "../../../Util/http"

function download(href, name) {
    var link = document.createElement("a")
    link.download = name
    link.style.opacity = "0"
    link.href = href
    link.click()
    link.remove()
}

function uploadToFirebase(ref, child, base64) {
    return new Promise((resolve, reject) => {
        const storageRef = app.storage().ref(ref)
        let task = storageRef.child(child).putString(base64, "data_url", { contentType: "image/png" })

        task.on(
            "state_changed", // or 'state_changed'
            () => null,
            (err) => reject(new Error("Could not upload this image: " + err)),
            async function () {
                // Upload completed successfully, now we can get the download URL
                let downloadURL = await storageRef.child(child).getDownloadURL()
                resolve(downloadURL)
            }
        )
    })
}

function textToFileName(text = "") {
    // remove slashes
    let _text = text.replaceAll("/ ", "")
    // remove hyphen
    _text = _text.replaceAll("- ", "")
    // remove spaces
    _text = _text.replaceAll(" ", "_")
    // lowercase
    _text = _text.toLowerCase()

    return _text
}

function ReportDownloadButton(props) {
    const { children, tool_name, header, navItem, text = "report" } = props

    const {
        currentUser: { email },
    } = useContext(AuthContext)

    const { pathname } = useLocation()
    let currentRoute = pathname.split("/")[2]

    const [loading, setLoading] = useState(false)

    async function handleSaveReport(id = "builder", type = "SVG", selector = ".svg-chart-container") {
        setLoading(true)

        try {
            const { data } = await getElementImage({ id, title: text, type, selector })
            switch (id) {
                case "image": {
                    download(data, `${textToFileName(text)}.png`)
                    toast.success("Image saved successfully")
                    break
                }
                case "builder": {
                    const imgId = uuid()
                    const downloadURL = await uploadToFirebase("AdaptationReports", imgId, data)
                    // send to API
                    await http.post("/api/v1/adapt/report_builder", {
                        title: text,
                        img: downloadURL,
                        notes: "",
                    })

                    toast.success("Report saved successfully")
                    setLoading(false)
                }
            }
        } catch (e) {
            console.log(e)
            toast.warn("An error occurred, try later")
        }

        setLoading(false)
    }

    // const type = ["overview", "analogs"].includes(currentRoute) ? "ELEMENT" : "SVG"
    // const selector = type === "ELEMENT" ? ".mapContainer" : ".svg-chart-container"
    const type = "ELEMENT"
    const selector = ".section__content"

    return (
        <>
            <div className={"report__download-button" + (email === demo_user ? " demo" : "")}>
                {(!loading && (
                    <TogglerButton icon={DownloadIcon}>
                        <Dropdown className="left">
                            {/* <DropdownItem
                                onClick={() => handleSaveReport("builder", type, selector)}
                                title="Add to Report Builder"
                            /> */}
                            <DropdownItem
                                onClick={() => handleSaveReport("image", type, selector)}
                                title="Save as Image"
                            />
                        </Dropdown>
                    </TogglerButton>
                )) || <BounceLoader size={32} color="#f27930" />}
            </div>
            {/* <ToastContainer /> */}
        </>
    )
}

export default ReportDownloadButton
