import * as React from "react"

function SunIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M11 6a5 5 0 100 10 5 5 0 000-10z" fill={props.fill || "#fff"} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 0a1 1 0 011 1v1a1 1 0 01-2 0V1a1 1 0 011-1zM2.293 2.293a1 1 0 011.414 0l1.5 1.5a1 1 0 01-1.414 1.414l-1.5-1.5a1 1 0 010-1.414zm17.414 0a1 1 0 010 1.414l-1.5 1.5a1 1 0 11-1.414-1.414l1.5-1.5a1 1 0 011.414 0zM0 11a1 1 0 011-1h1a1 1 0 010 2H1a1 1 0 01-1-1zm19 0a1 1 0 011-1h1a1 1 0 010 2h-1a1 1 0 01-1-1zM5.207 16.793a1 1 0 010 1.414l-1.5 1.5a1 1 0 01-1.414-1.414l1.5-1.5a1 1 0 011.414 0zm11.586 0a1 1 0 011.414 0l1.5 1.5a1 1 0 01-1.414 1.414l-1.5-1.5a1 1 0 010-1.414zM11 19a1 1 0 011 1v1a1 1 0 01-2 0v-1a1 1 0 011-1z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default SunIcon
