import React from "react"

import "./ChartLabels.css"

function ChartLabels(props) {
    const { children } = props

    return (
        <>
            <div className="chart-labels">{children}</div>
        </>
    )
}

export default ChartLabels
