import React, { useState, useEffect, useContext } from "react"
// Modules
import { useParams } from "react-router-dom"

// Components
import ActionBlock from "./components/ActionBlock"
import { FieldProperties } from "../../../../components"
import DailyMonthlyOptions from "./components/DailyMonthlyOptions"
import Dropdown from "../../../../ui/Controls/Dropdown"
import DropdownItem from "../../../../ui/Controls/DropdownItem"

// Views
// Icons - The Active versions are actually used on the unselected Tabs
import sunIconActive from "../../../../assets/Chart/sun-active.svg"
import sunIcon from "../../../../assets/Chart/sun.svg"
import CloudIconActive from "../../../../assets/Chart/cloud-active.svg"
import CloudIcon from "../../../../assets/Chart/cloud.svg"
import TrendingUp from "../../../../assets/Chart/trending-up.svg"
import TrendingUpActive from "../../../../assets/Chart/trending-up-active.svg"
import WaterBudgetIconActive from "../../../../assets/Chart/water-budget-active.svg"
import WaterBudgetIcon from "../../../../assets/Chart/water-budget.svg"
import HumidityIcon from "../../../../assets/Chart/humidity.svg"
import HumidityIconActive from "../../../../assets/Chart/humidity-active.svg"
import EvapotranspirationIcon from "../../../../assets/Chart/evapotranspiration.svg"
import EvapotranspirationIconActive from "../../../../assets/Chart/evapotranspiration-active.svg"
import WindSpeedIcon from "../../../../assets/Chart/wind_speed.svg"
import WindSpeedIconActive from "../../../../assets/Chart/wind_speed-active.svg"
import SoilMoistureIcon from "../../../../assets/Chart/soil_moisture.svg"
import SoilMoistureIconActive from "../../../../assets/Chart/soil_moisture-active.svg"
import SoilTemperatureIcon from "../../../../assets/Chart/soil_temperature.svg"
import SoilTemperatureIconActive from "../../../../assets/Chart/soil_temperature-active.svg"
import SolarRadiationIcon from "../../../../assets/Chart/solar_radiation.svg"
import SolarRadiationIconActive from "../../../../assets/Chart/solar_radiation-active.svg"
import DotIcon from "../../../../assets/Chart/dots.svg"
import DotIconWhite from "../../../../assets/Chart/dots-white.svg"

// Context providers / Utils
import { DataContext } from "../../../../Util/Data"
import useOutsideComponentClickHandler from "../../../../hooks/ClickOutsideHook"
// Hooks

// Material-UI *
import useMediaQuery from "@material-ui/core/useMediaQuery"

const REMEMBERED_WEATHER_VARIABLE_KEY = "WeatherModelSelectedVariable"
const REMEMBERED_VARIABLE_PERIOD = "WeatherModelIsMonthly"

const WeatherVariableTabs = (props) => {
    const { onStateChange, allowedWeatherVariables, selectedTab = "" } = props
    const [fieldData, setFieldData] = useState({
        triggered_alerts: {
            count: 2,
            variables: [],
        },
    })
    let { id } = useParams()
    const {
        state: { fields },
    } = useContext(DataContext)
    const [shownVariables, setShownVariables] = useState([])
    const [dropdownVariables, setDropdownVariables] = useState([])
    const [selectedVariable, setSelectedVariable] = useState("daily")
    const [selectedGranularity, setSelectedGranularity] = useState("")
    const [amountOfShownVariables, setAmountOfShownVariables] = useState(4)
    const [monthlySelected, setMonthlySelected] = useState(false)
    const [granularityAlerts, setGranularityAlerts] = useState({ daily: false, monthly: false })
    const [open, toggle] = useState(false)
    // TODO: Better naming media
    const tvMedia = useMediaQuery("(min-width: 3000px)", { noSsr: true })
    const xlMedia = useMediaQuery("(min-width: 2000px)", { noSsr: true })
    const largeMedia = useMediaQuery("(min-width: 1250px)", { noSsr: true })
    const mediumMedia = useMediaQuery("(min-width: 1000px)", { noSsr: true })
    const smallMedia = useMediaQuery("(max-width: 550px)", { noSsr: true })

    useEffect(() => {
        if (tvMedia) {
            setAmountOfShownVariables(6)
        } else if (xlMedia) {
            setAmountOfShownVariables(5)
        } else if (mediumMedia) {
            setAmountOfShownVariables(4)
        } else if (smallMedia) {
            setAmountOfShownVariables(2)
        } else {
            setAmountOfShownVariables(3)
        }
    }, [largeMedia, mediumMedia, tvMedia, xlMedia, smallMedia])

    useEffect(() => {
        fillAllowedVariablesState()
    }, [amountOfShownVariables])

    function fillAllowedVariablesState() {
        const vars = Object.keys(allowedWeatherVariables)
        let variablesToShow = []
        let dropDownVariablesToShow = []
        let valueTab = (selectedTab === "temp" && "temperature_t2m") || selectedTab || ""
        const selectedVar = valueTab || localStorage.getItem(REMEMBERED_WEATHER_VARIABLE_KEY) || "temperature_t2m"
        for (var i = 0; i < vars.length; i++) {
            let key = vars[i]
            if (allowedWeatherVariables[key]) {
                if (variablesToShow.length < amountOfShownVariables) {
                    variablesToShow.push(key)
                } else {
                    if (selectedVar === key) {
                        const varToDropDown = variablesToShow.pop()
                        variablesToShow.push(key)
                        dropDownVariablesToShow.push(varToDropDown)
                    } else {
                        dropDownVariablesToShow.push(key)
                    }
                }
            }
        }
        setShownVariables(variablesToShow)
        setDropdownVariables(dropDownVariablesToShow)
        onSelect(selectedVar || variablesToShow[0] || "")
    }

    const VARIABLES_META = {
        temperature_t2m: {
            hourly: false,
            daily: true,
            monthly: true,
            title: "Min & Max Temperature",
            icons: { active: sunIcon, default: sunIconActive },
            key: "temperature_t2m",
        },
        precipitation: {
            hourly: false,
            daily: true,
            monthly: true,
            title: "Precipitation",
            icons: { active: CloudIcon, default: CloudIconActive },
            key: "precipitation",
        },
        soil_temperature: {
            hourly: false,
            daily: true,
            monthly: true,
            title: "Soil Temperature",
            icons: { active: SoilTemperatureIcon, default: SoilTemperatureIconActive },
            key: "soil_temperature",
        },
        water_budget: {
            hourly: false,
            daily: true,
            monthly: false,
            title: "Water Budget",
            icons: { active: WaterBudgetIcon, default: WaterBudgetIconActive },
            key: "water_budget",
        },
        relative_humidity: {
            hourly: false,
            daily: true,
            monthly: true,
            title: "Relative Humidity",
            icons: { active: HumidityIcon, default: HumidityIconActive },
            key: "relative_humidity",
        },
        evapotranspiration: {
            hourly: false,
            daily: true,
            monthly: true,
            title: "Evapotranspiration",
            icons: { active: EvapotranspirationIcon, default: EvapotranspirationIconActive },
            key: "evapotranspiration",
        },
        solar_radiation: {
            hourly: false,
            daily: true,
            monthly: true,
            title: "Solar Radiation",
            icons: { active: SolarRadiationIcon, default: SolarRadiationIconActive },
            key: "solar_radiation",
        },
        gdd: {
            hourly: false,
            daily: false,
            monthly: true,
            title: "Growing Degree Days",
            icons: { active: TrendingUp, default: TrendingUpActive },
            key: "gdd",
        },
        soil_moisture: {
            hourly: false,
            daily: true,
            monthly: true,
            title: "Soil Moisture",
            icons: { active: SoilMoistureIconActive, default: SoilMoistureIcon },
            key: "soil_moisture",
        },
        wind_speed: {
            hourly: false,
            daily: true,
            monthly: true,
            title: "Wind Speed",
            icons: { active: WindSpeedIconActive, default: WindSpeedIcon },
            key: "wind_speed",
        },
    }

    function getSelectedOrAvailableGranularity(variable) {
        let granularity = "daily"
        if (localStorage.getItem(REMEMBERED_VARIABLE_PERIOD) === "monthly") {
            granularity = "monthly"
        }
        if (localStorage.getItem(REMEMBERED_VARIABLE_PERIOD) === "hourly") {
            granularity = "hourly"
        }
        if (granularity === "monthly" && !VARIABLES_META[variable].monthly) {
            granularity = "daily"
        }
        if (granularity === "hourly" && !VARIABLES_META[variable].hourly) {
            granularity = "daily"
        }
        return granularity
    }

    function onSelect(variable) {
        localStorage.setItem(REMEMBERED_WEATHER_VARIABLE_KEY, variable)
        setSelectedVariable(variable)
        var granularity = getSelectedOrAvailableGranularity(variable)
        if (!VARIABLES_META[variable].daily) {
            granularity = "monthly"
        }
        setSelectedGranularity(granularity)
        const isMonthly = granularity === "monthly"
        setMonthlySelected(isMonthly)
        onStateChange({
            currentTab: variable,
            isMonthly,
            selectedGranularity: granularity,
        })
    }

    function handleGranularityChange(granularityPeriod) {
        localStorage.setItem(REMEMBERED_VARIABLE_PERIOD, granularityPeriod)
        setSelectedGranularity(granularityPeriod)
        onStateChange({
            currentTab: selectedVariable,
            isMonthly: granularityPeriod === "monthly",
            selectedGranularity: granularityPeriod,
        })
    }

    function onSelectFromDropdown(variable) {
        // Copy arrays
        let shownVariablesCopy = shownVariables
        let dropdownVariablesCopy = dropdownVariables

        // Get the last shown variable to add it to the dropdown and remove it
        // from shown
        let lastShownVariable = shownVariablesCopy.pop()

        // Add last shown variable to dropdown
        dropdownVariablesCopy.push(lastShownVariable)

        // Remove the selected dropdown variable
        dropdownVariablesCopy = dropdownVariablesCopy.filter(function (value) {
            return value !== variable
        })

        // Add the selected dropdown to the shown variables
        shownVariablesCopy.push(variable)

        setShownVariables(shownVariablesCopy)
        setDropdownVariables(dropdownVariablesCopy)
        onSelect(variable)
        toggle(!open)
    }

    function variableHasTriggeredAlert(variable) {
        if (fieldData !== null && fieldData?.triggered_alerts?.variables) {
            return fieldData?.triggered_alerts?.variables.some(function (el) {
                return el.variable_name === variable
            })
        }
        return false
    }

    function setAlertsOnTabs() {
        setFieldData({ triggered_alerts: { count: 2, variables: [] } })

        let newFieldData = fields.filter((obj) => {
            return obj.uuid === id
        })

        if (newFieldData[0]) {
            setFieldData(newFieldData[0])
        }
    }

    function setAlertOnGranularities() {
        setGranularityAlerts({ daily: false, monthly: false })

        if (variableHasTriggeredAlert(selectedVariable)) {
            let variables = fieldData?.triggered_alerts?.variables?.filter((obj) => {
                return obj.variable_name === selectedVariable
            })

            let newGranularityAlerts = {}
            variables.forEach((variable) => {
                newGranularityAlerts[variable.granularity] = true
            })
            setGranularityAlerts(newGranularityAlerts)
        }
    }

    useEffect(() => {
        setAlertOnGranularities()
    }, [fieldData, selectedVariable])

    useEffect(() => {
        setAlertsOnTabs()
    }, [id, selectedVariable, fields])

    const wrapperRef = useOutsideComponentClickHandler(() => toggle(false))

    return (
        <>
            <div className="chart-actions">
                <div className="chart-actions__options-container">
                    {!smallMedia && (
                        <DailyMonthlyOptions
                            dailyAvailable={!smallMedia && VARIABLES_META[selectedVariable]?.daily}
                            hourlyAvailable={!smallMedia && VARIABLES_META[selectedVariable]?.hourly}
                            monthlyAvailable={!smallMedia && VARIABLES_META[selectedVariable]?.monthly}
                            monthlySelected={monthlySelected}
                            selectedGranularity={selectedGranularity}
                            onChange={handleGranularityChange}
                            granularityAlerts={granularityAlerts}
                        />
                    )}

                    <div className="chart-actions__actions-container">
                        {shownVariables.map((variable) => {
                            let hasAlert = variableHasTriggeredAlert(variable)
                            return (
                                <ActionBlock
                                    key={variable}
                                    select={onSelect}
                                    current={selectedVariable}
                                    title={VARIABLES_META[variable]?.title}
                                    currentTab={VARIABLES_META[variable]?.key}
                                    chartIcon={VARIABLES_META[variable]?.icons}
                                    id={variable}
                                    hasAlert={hasAlert}
                                />
                            )
                        })}
                    </div>

                    <div
                        className={"chart-actions__dropdown-button" + (open ? " open" : "")}
                        onClick={() => toggle(!open)}
                        ref={wrapperRef}
                    >
                        {!open ? <img alt="" src={DotIcon} /> : <img alt="" src={DotIconWhite} />}
                        {open && dropdownVariables && dropdownVariables.length && (
                            <Dropdown className="left">
                                {dropdownVariables.map((variable) => {
                                    return (
                                        <DropdownItem
                                            key={"dropdown-" + variable}
                                            onClick={() => onSelectFromDropdown(variable)}
                                            title={
                                                <>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        {VARIABLES_META[variable]?.title}
                                                        {variableHasTriggeredAlert(variable) && (
                                                            <div className="weather-tabs__alert-dot"></div>
                                                        )}
                                                    </div>
                                                </>
                                            }
                                        ></DropdownItem>
                                    )
                                })}
                            </Dropdown>
                        )}
                    </div>
                </div>

                {largeMedia && (
                    <div className="chart-actions__field-properties">
                        <FieldProperties />
                    </div>
                )}
            </div>
        </>
    )
}

export default WeatherVariableTabs
