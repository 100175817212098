import React, { useCallback, useContext, useState, useRef } from "react"
import app from "../Util/Fire"
import { AuthContext } from "../Auth/Auth"
import Locale from "../ui/Locale/Locale"
import { Link as RouterLink } from "react-router-dom"
import { Redirect, withRouter } from "react-router"
import { useTranslation } from "react-i18next"

import "./SignIn.css"
import { UIAlertContext } from "../ui/UIAlert/UIAlert"

import firebase from "firebase/compat/app"
import { capitalizeFirstCharacter, capitalizeFirstCharacterOfEachWord } from "../helpers/wordHelper"

import { demo_password, demo_user, payment_url } from "../Util/config"

const SignIn = ({ history }) => {
    const { t } = useTranslation()

    // TODO: Set default to user's check
    const [rememberUser, setRememberUser] = useState(!!localStorage.getItem("@flag/remember-me"))

    const { confirmationModal } = useContext(UIAlertContext)

    const { currentUser } = useContext(AuthContext)

    const loginClick = useRef(null)
    const emailInput = useRef(null)
    const passwordInput = useRef(null)

    const currentYear = new Date().getFullYear()

    const handleLogin = useCallback(
        async (event) => {
            event.preventDefault()
            const { email, password } = event.target.elements
            try {
                console.log(rememberUser)
                await app
                    .auth()
                    .setPersistence(
                        rememberUser ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION
                    )
                await app.auth().signInWithEmailAndPassword(email.value, password.value)
            } catch (error) {
                // alert(error)
                confirmationModal(null, error.message)
            }
        },
        [history, rememberUser]
    )

    if (currentUser) {
        return <Redirect to="/weather/dashboard" />
    }

    function handleRememberUser(e) {
        console.log(e.target.checked)
        if (e.target.checked) localStorage.setItem("@flag/remember-me", "true")
        else localStorage.removeItem("@flag/remember-me")

        setRememberUser(e.target.checked)
    }

    function demoLogin() {
        emailInput.current.value = demo_user
        passwordInput.current.value = demo_password
        loginClick.current.click()
    }

    return (
        <div className="sign-in__screen">
            <div className="sign-in">
                <div className="sign-in__card">
                    <img alt="ClimateAi logo" className="sign-in__logo" src="/Climate-AI-Color-Logo.png" />
                    <div className="sign-in__card-title">
                        {capitalizeFirstCharacterOfEachWord(t("operational_dashboard"))}
                    </div>
                    <div className="sign-in__demo-button" onClick={demoLogin}>
                        {capitalizeFirstCharacter(t("demo"))}
                    </div>
                    <div className="sign-in__hr"></div>
                    <form className="sign-in__form" noValidate onSubmit={handleLogin}>
                        <div className="sign-in__input-group">
                            <label>{capitalizeFirstCharacterOfEachWord(t("email_address"))}</label>
                            <input
                                placeholder={t("email_placeholder")}
                                type="email"
                                id="email"
                                name="email"
                                ref={emailInput}
                            />
                        </div>
                        <div className="sign-in__input-group">
                            <label>{capitalizeFirstCharacter(t("password"))}</label>
                            <input
                                name="password"
                                placeholder="********"
                                type="password"
                                id="password"
                                ref={passwordInput}
                            />
                        </div>
                        <RouterLink
                            id="restore-password-link__login"
                            className="sign-in__restore-password"
                            to="/restore-password"
                        >
                            {capitalizeFirstCharacter(t("forgot_password"))}
                        </RouterLink>

                        <div className="sign-in-side__checkbox-container">
                            <span className="sign-in-side__checkbox">
                                <input
                                    type="checkbox"
                                    checked={rememberUser}
                                    onChange={handleRememberUser}
                                    id="remember-me-checkbox__log-in"
                                    className="sign-in-side__checkbox-input"
                                />
                                <label className="sign-in-side__checkbox-label">
                                    {rememberUser && (
                                        <svg
                                            width="90%"
                                            height="90%"
                                            viewBox="0 0 8 6"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M2.74982 4.73743L1.01232 2.99993L0.420654 3.58743L2.74982 5.9166L7.74982 0.916601L7.16232 0.329102L2.74982 4.73743Z"
                                                fill="white"
                                            />
                                        </svg>
                                    )}
                                </label>
                            </span>
                            <div className="sign-in-side__checkbox-text">
                                {capitalizeFirstCharacter(t("remember_me"))}
                            </div>
                        </div>

                        <button
                            type="submit"
                            id="login-button__login"
                            className="sign-in__button submit"
                            ref={loginClick}
                        >
                            {capitalizeFirstCharacterOfEachWord(t("log_in"))}
                        </button>
                    </form>
                    <div className="sign-in__hr"></div>
                    <div className="sign-in__sign-up-text">{capitalizeFirstCharacter(t("no_account"))}</div>
                    <RouterLink id="sign-up-link__login" className="sign-in__sign-up-button" to="/signup">
                        {capitalizeFirstCharacterOfEachWord(t("sign_up"))}
                    </RouterLink>
                    <span className="sign-in__sign-up-rights">
                        © ClimateAi {currentYear}, {capitalizeFirstCharacterOfEachWord(t("copyright"))}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SignIn)
