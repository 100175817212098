import React from "react"
// Modules
import ReactDOM from "react-dom"

// Components

// Views
import App from "./App"

// Context providers / Utils
import * as serviceWorker from "./serviceWorker"

// Hooks

// Material-UI *
import "@material/react-checkbox/dist/checkbox.css"

// Styles
import "./index.css"
import "react-vis/dist/style.css"
import "rc-dropdown/assets/index.css"

// Import the Translations package
import "./i18n/config"

if (process.env.REACT_APP_STAGE === "production") {
    console.log = () => {}
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
