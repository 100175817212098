import { useMemo } from "react"

function getMapGeoJSON(locations) {
    if (!locations) return {}

    const result = {
        type: "FeatureCollection",
        features: [],
    }

    /**
     * {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [-77.032, 38.913],
        },
        properties: {
            title: "Mapbox",
            type: "pin",
            description: "Washington, D.C.",
        },
    },
    {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: [-122.414, 37.776],
        },
        properties: {
            title: "Mapbox",
            type: "pin",
            description: "San Francisco, California",
        },
    },
     */

    // Map locations to map pins
    result.features =
        locations &&
        locations.map((location) => {
            return {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [
                        location.coords.lon > 180 ? location.coords.lon - 360 : location.coords.lon,
                        location.coords.lat,
                    ],
                },
                properties: {
                    label: location.name,
                    type: "pin",
                    // popup: "Crop: Potato",
                },
            }
        })

    return result
}

function getTableData({ locations, risks_list }) {
    return (
        locations &&
        Object.keys(locations).map((key) => {
            const location = locations[key]

            const result = {
                location: key,
                yield: (location.probability * 100).toFixed(2),
            }

            for (let riskKey in risks_list) {
                const risk = location.risks[riskKey]
                result[`risk_${riskKey}`] = risk.probability * 100
            }

            console.log(result)
            return result
        })
    )
}

export default function useOverviewData(data, locations) {
    return useMemo(() => {
        let mapData = {}
        let tableData = {}

        // Map Data
        mapData = getMapGeoJSON(locations)

        // Table data
        tableData = getTableData(data)

        console.log(tableData)

        return {
            mapData,
            tableData,
        }
    }, [data])
}
