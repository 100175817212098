import React from "react"

// Modules
import { Link } from "react-router-dom"

// Components
import { Popover } from "../../../ui/Popover"

function CropTableName(props) {
    const { value, rowData = {}, permissions } = props
    return (
        <Popover text={"Go to " + rowData.name} className="center">
            <Link
                to={
                    "/" +
                    permissions["default"] +
                    "/" +
                    (rowData.__group ? rowData.data[0] && rowData.data[0].uuid : rowData.uuid)
                }
                className="underline"
            >
                {value}
            </Link>
        </Popover>
    )
}

export default CropTableName
