import * as React from "react"
import "mapbox-gl/dist/mapbox-gl.css"
import { token } from "../accessToken"
import "./mapbox.css"
import "mapbox-gl/dist/mapbox-gl.css"
/* eslint import/no-webpack-loader-syntax: off */
import mapboxgl from "!mapbox-gl"
import * as d3 from "d3"
import html2canvas from "html2canvas"

mapboxgl.accessToken = token

export class MapboxBasicLayersComponent extends React.Component {
    /**
     * @private
     * @hideconstructor
     */
    constructor(props) {
        super(props)

        // Define initial state
        this.state = {
            layer: props.satellite || false,
            markers: [],
            viewport: {
                longitude: 42,
                latitude: 42,
                zoom: 0,
            },
        }

        // Save mapbox instance reference
        this._map = React.createRef()
    }

    // Export map as image
    export() {
        const node = this.mapContainer
        var dpi = 200
        Object.defineProperty(window, "devicePixelRatio", {
            get: function () {
                return dpi / 96
            },
        })
        html2canvas(node).then((canvas) => {
            var link = document.createElement("a")
            link.download = "map.png"
            link.href = canvas.toDataURL()
            link.click()
        })
    }
    // Handle component mounting event
    componentDidMount() {
        const self = this

        // Define map instance
        const map = new mapboxgl.Map({
            preserveDrawingBuffer: true,
            container: this.mapContainer,
            //style: 'mapbox://styles/mapbox/dark-v10',   // Dark Style
            // style: "mapbox://styles/mapbox/outdoors-v11", // Satelite Style
            style: "mapbox://styles/mapbox/light-v10", // Light Style
            center: [this.state.viewport.longitude, this.state.viewport.latitude],
            zoom: this.state.viewport.zoom,
            renderWorldCopies: false,
        })

        if (this.props.zoomControls) {
            // new mapboxgl.NavigationControl()
            map.addControl(
                new mapboxgl.NavigationControl({
                    showCompass: false,
                    showZoom: true,
                })
            )
        }

        // Set centers
        map.on("move", () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2),
            })
        })

        this.map = map

        // Listen for map load and save it into state
        map.on("load", (d) => {
            this.mapLoaded = true
            this.updateMap({ layerChanged: this.state.layer !== false })
        })

        // Create a popup, but don't add it to the map yet.
        var popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false,
        })
    }

    updateMap({ layerChanged = false } = {}) {
        // If data is empty return
        if (this.props.geojson?.features.length === 0) {
            this.removeMarkers(this)
            // this.removeSourcesAndLayers(this)
            this.removePoints(this)
            return
        }

        // Add source of geojson layers
        const drawObjects = ({ map, props, state }) => {
            // Add marker labels
            this.removeMarkerLabels({ map })
            this.addMarkerLabels({ map, places: props.geojson, layer: state.layer })

            // Add markers
            this.removeMarkers({ state })
            this.addMarkers({ props, map })

            // Add points
            this.removePoints({ map })
            this.addPoints({ map, places: props.geojson })

            // Add Heatmap Layer
            this.removeHeatmapLayer({ map })
            this.addHeatmapLayer({ map, places: props.geojson })

            // Add Rectangular Heatmap
            this.removeRectangularHeatmapLayer({ map })
            this.addRectangularHeatmapLayer({ map, places: props.geojson })

            // Fit Bounds
            this.boundsFit = false
            this.fitBounds({ props, map })
        }

        // If layer was changed during redrawing phase
        if (layerChanged) {
            // Set corresponding light or satellite layer
            if (this.state.layer) {
                this.map.setStyle("mapbox://styles/mapbox/satellite-v9")
            } else {
                this.map.setStyle("mapbox://styles/mapbox/light-v10")
            }

            // After 1 second, add layers and sources again (because it was removed by styling changes)
            setTimeout(() => {
                drawObjects(this)
            }, 1000)
        }

        // If map was loaded
        if (this.mapLoaded) {
            // Add source
            drawObjects(this)
        } else {
            // If map is not yet loaded, wait for it and then add polygon source
            this.map.on("load", (d) => {
                this.mapLoaded = true
                drawObjects(this)
            })
        }
    }

    fitBounds = ({ props, map }) => {
        // If not already fit bounds, try to fit it again
        if (!this.boundsFit) {
            const allCoords = props.geojson?.features.map((d) => d.geometry.coordinates)
            // Fitting map to bounds
            map.fitBounds([this.maxRightTop(allCoords), this.minLeftBottom(allCoords)], { padding: 100, maxZoom: 4 })

            // this.setState({ boundsFit: true })
            this.boundsFit = true
        }
    }

    removeRectangularHeatmapLayer = ({ map }) => {
        // If map layer found, remove it
        if (map.getLayer("rectangular-heatmap-points-layer")) {
            map.removeLayer("rectangular-heatmap-points-layer")
        }

        // If map source found, remove it
        if (map.getSource("rectangular-heatmap-points-source")) {
            map.removeSource("rectangular-heatmap-points-source")
        }
    }

    addRectangularHeatmapLayer = ({ map, places }) => {
        const rectHeatmapFeatures = places.features.filter((d) => d.properties.type === "rectangularHeatmap")
        const featuresCopy = JSON.parse(JSON.stringify(rectHeatmapFeatures))
        const maxOpacity = Math.max(...featuresCopy.map((d) => d.properties.opacity))
        const minOpacity = Math.min(...featuresCopy.map((d) => d.properties.opacity))
        const scale = d3
            .scalePow()
            .exponent(places.exponent || 3)
            .domain([minOpacity, maxOpacity])
            .range([0, 1])
        const polygonFeatures = featuresCopy.map((fc) => {
            const lng = fc.geometry.coordinates[0]
            const lat = fc.geometry.coordinates[1]
            const longitudeWidth = fc.properties.longitudeWidth
            const latitudeHeight = fc.properties.latitudeHeight
            const leftTop = [lng - longitudeWidth / 2, lat + latitudeHeight / 2]
            const rightTop = [lng + longitudeWidth / 2, lat + latitudeHeight / 2]
            const rightBot = [lng + longitudeWidth / 2, lat - latitudeHeight / 2]
            const leftBottom = [lng - longitudeWidth / 2, lat - latitudeHeight / 2]
            const scaledOpacity = scale(fc.properties.opacity)
            let colors = Array.isArray(fc.properties.color) ? fc.properties.color : [fc.properties.color]
            if (this.state.layer) {
                colors = Array.isArray(fc.properties.satelliteColors)
                    ? fc.properties.satelliteColors
                    : [fc.properties.satelliteColors]
            }
            const colorScale = d3.interpolateRgbBasis(colors)
            const scaledColor = colorScale(scaledOpacity)
            return {
                type: "Feature",
                properties: Object.assign({}, fc.properties, {
                    opacity: scaledOpacity,
                    scaledColor: scaledColor,
                }),
                geometry: {
                    type: "Polygon",
                    coordinates: [[leftTop, rightTop, rightBot, leftBottom, leftTop]],
                },
            }
        })

        const data = {
            type: "FeatureCollection",
            features: polygonFeatures,
        }

        map.addSource("rectangular-heatmap-points-source", {
            type: "geojson",
            data: data,
        })

        // Add a new layer to visualize the polygon.
        map.addLayer({
            id: "rectangular-heatmap-points-layer",
            type: "fill",
            source: "rectangular-heatmap-points-source", // reference the data source
            layout: {},
            paint: {
                "fill-color": ["get", "scaledColor"], // blue color fill
                "fill-opacity": ["get", "opacity"],
                "fill-outline-color": "rgba(0,0,0,0)",
            },
        })

        console.log({ data })
    }

    removeHeatmapLayer = ({ map }) => {
        // If map layer found, remove it
        if (map.getLayer("heatmap-points-layer")) {
            map.removeLayer("heatmap-points-layer")
        }

        // If map layer found, remove it
        if (map.getLayer("heatmap-layer")) {
            map.removeLayer("heatmap-layer")
        }

        // If map source found, remove it
        if (map.getSource("heatmap-points-source")) {
            map.removeSource("heatmap-points-source")
        }
    }

    addHeatmapLayer = ({ map, places }) => {
        const maxRadius = Math.max(
            ...places.features.filter((d) => d.properties.type == "heatmap").map((d) => d.properties.radius)
        )
        const minRadius = Math.min(
            ...places.features.filter((d) => d.properties.type == "heatmap").map((d) => d.properties.radius)
        )

        map.addSource("heatmap-points-source", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: places.features.filter((d) => d.properties.type == "heatmap"),
            },
        })

        map.addLayer({
            id: "heatmap-points-layer",
            type: "circle",
            source: "heatmap-points-source",
            minzoom: 14,
            paint: {
                // increase the radius of the circle as the zoom level and radius value increases
                "circle-radius": {
                    property: "radius",
                    type: "exponential",
                    stops: [
                        [{ zoom: 15, value: 1 }, 5],
                        [{ zoom: 15, value: 62 }, 10],
                        [{ zoom: 22, value: 1 }, 20],
                        [{ zoom: 22, value: 62 }, 50],
                    ],
                },
                "circle-color": {
                    property: "radius",
                    type: "exponential",
                    stops: [
                        [minRadius, "rgba(1,108,89,0.0)"],
                        [minRadius + 0.1 * (maxRadius - minRadius), "rgba(1,108,89,0.1)"],
                        [minRadius + 0.2 * (maxRadius - minRadius), "#CFE3E0"],
                        [minRadius + 0.4 * (maxRadius - minRadius), "#C2E0C4"],
                        [minRadius + 0.6 * (maxRadius - minRadius), "#A3D1A7"],
                        [minRadius + 0.8 * (maxRadius - minRadius), "#85C28A"],
                        [maxRadius, "#66B26C"],
                    ],
                },
                "circle-blur": 0.5,
                "circle-opacity": {
                    stops: [
                        [10, 0],
                        [11, 1],
                    ],
                },
            },
        })

        map.addLayer({
            id: "heatmap-layer",
            type: "heatmap",
            source: "heatmap-points-source",
            maxzoom: 15,
            paint: {
                // increase weight as diameter breast height increases
                "heatmap-weight": {
                    property: "radius",
                    type: "exponential",
                    stops: [
                        [1, 0],
                        [62, 1],
                    ],
                },
                // increase intensity as zoom level increases
                "heatmap-intensity": {
                    stops: [
                        [11, 1],
                        [15, 3],
                    ],
                },
                // use sequential color palette to use exponentially as the weight increases
                "heatmap-color": [
                    "interpolate",
                    ["linear"],
                    ["heatmap-density"],
                    0,
                    "rgba(0,0,0,0)",
                    0.2,
                    "#CFE3E0",
                    0.4,
                    "#C2E0C4",
                    0.6,
                    "#A3D1A7",
                    0.8,
                    "#85C28A",
                    1,
                    "#66B26C",
                ],
                // increase radius as zoom increases
                "heatmap-radius": {
                    stops: [
                        [11, 35],
                        [15, 40],
                    ],
                },
                // decrease opacity to transition into the circle layer
                "heatmap-opacity": {
                    default: 1,
                    stops: [
                        [14, 1],
                        [15, 0],
                    ],
                },
            },
        })
    }

    removeMarkers = ({ state }) => {
        // Remove old markers
        state.markers.forEach((marker) => marker.remove())
    }

    addMarkers = ({ props, map }) => {
        // Add geojson markers
        const markers = (props.geojson?.features || [])
            .filter((d) => d.geometry.type == "Point")
            .filter((d) => d.properties.type == "pin")
            .map((d) => {
                const marker = new mapboxgl.Marker({
                    color: d.properties.color || "teal",
                })

                if (d.properties.popup) {
                    marker.setPopup(new mapboxgl.Popup().setHTML(`<div>${d.properties.popup}</div>`))
                }

                marker.setLngLat(d.geometry.coordinates).addTo(map)

                const markerDiv = marker.getElement()

                markerDiv.addEventListener("mouseenter", () => marker.togglePopup())
                markerDiv.addEventListener("mouseleave", () => marker.togglePopup())

                if (props.onMarkerClick && typeof props.onMarkerClick === "function")
                    markerDiv.addEventListener("click", (e) => props.onMarkerClick(d))
                return marker
            })

        // Save new markers into state
        this.setState({ markers: markers })
    }

    removePoints = ({ map }) => {
        // If map layer found, remove it
        if (map.getLayer("points-layer")) {
            map.removeLayer("points-layer")
        }

        // If map source found, remove it
        if (map.getSource("points-source")) {
            map.removeSource("points-source")
        }
    }

    addPoints = ({ places, map }) => {
        // Add the vector tileset as a source.
        map.addSource("points-source", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: places.features.filter((d) => d.properties.type == "point"),
            },
        })
        map.addLayer({
            id: "points-layer",
            type: "circle",
            source: "points-source",
            paint: {
                // Make circles larger as the user zooms from z12 to z22.
                "circle-radius": ["get", "radius"],
                // Color circles by ethnicity, using a `match` expression.
                "circle-color": ["get", "color"],
            },
        })
    }

    addMarkerLabels = ({ map, places, layer }) => {
        // Add a GeoJSON source containing place coordinates and information.
        map.addSource("marker-labels-source", {
            type: "geojson",
            data: {
                type: "FeatureCollection",
                features: places.features.filter((d) => ["pin", "point"].includes(d.properties.type)),
            },
        })

        map.addLayer({
            id: "marker-labels-layer",
            type: "symbol",
            source: "marker-labels-source",
            paint: {
                "text-color": layer ? "white" : "black",
                "text-halo-blur": 1,
                "text-halo-color": layer ? "black" : "white",
                "text-halo-width": 1,
            },
            layout: {
                "text-field": ["get", "label"],
                "text-variable-anchor": ["top"],
                "text-size": 18,
                "text-radial-offset": 0.8,
                "text-justify": "right",
            },
        })
    }

    removeMarkerLabels = ({ map }) => {
        // If map layer found, remove it
        if (map.getLayer("marker-labels-layer")) {
            map.removeLayer("marker-labels-layer")
        }

        // If map source found, remove it
        if (map.getSource("marker-labels-source")) {
            map.removeSource("marker-labels-source")
        }
    }

    // Viewport update func - Mostly needed for dragging functionality
    _updateViewport = (viewport) => {
        this.setState({ viewport })
    }

    // Calculate right top end coordinates (lat,lon) of passed polygon
    maxRightTop = (polygonCoords) => {
        const maxX = Math.max(...polygonCoords.map((d) => d[0]))
        const maxY = Math.max(...polygonCoords.map((d) => d[1]))
        return [maxX, maxY]
    }

    // Calculate left bottom end coordinates (lat,lon) of passed polygon
    minLeftBottom = (polygonCoords) => {
        const minX = Math.min(...polygonCoords.map((d) => d[0]))
        const minY = Math.min(...polygonCoords.map((d) => d[1]))
        return [minX, minY]
    }

    // Calculate right top end coordinates (lat,lon) of passed multi polygon figures
    maxRightTopMulti = (polygonCoords) => {
        const maxes = polygonCoords.map((d) => this.maxRightTop(d))
        const maxX = Math.max(...maxes.map((d) => d[0]))
        const maxY = Math.max(...maxes.map((d) => d[1]))
        return [maxX, maxY]
    }

    // Calculate left bottom end coordinates (lat,lon) of passed multi polygon figures
    minLeftBottomMulti = (polygonCoords) => {
        const mins = polygonCoords.map((d) => this.minLeftBottom(d))
        const minX = Math.min(...mins.map((d) => d[0]))
        const minY = Math.min(...mins.map((d) => d[1]))
        return [minX, minY]
    }

    // Handle component update event
    componentDidUpdate(prevProps, prevState) {
        // If passed polygon data is the same as in the previous case and
        // Passed variable is the same as in the previous state and
        // Passed layer is the same as in the previous state return
        if (
            this.props.geojson === prevProps.geojson &&
            this.props.width === prevProps.width &&
            this.props.height === prevProps.height &&
            this.state.layer === prevState.layer
        )
            return

        this.updateMap({
            layerChanged: this.state.layer !== prevState.layer || this.props.geojson !== prevProps.geojson,
        })
    }

    // Render chart
    render() {
        // Render map , variable and layer controls
        return (
            <div style={{ position: "relative" }}>
                <div
                    id="layer-switch__table-map"
                    className="layer-switch"
                    onClick={(d) => {
                        this.setState({ layer: !this.state.layer })
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z" />
                    </svg>
                </div>

                <div
                    style={{
                        width: this.props.width || "200px",
                        height: this.props.height || "200px",
                    }}
                    ref={(el) => {
                        this.mapContainer = el
                    }}
                    className="mapContainer"
                />
            </div>
        )
    }
}
