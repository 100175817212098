import * as React from "react"

function ArrowBottom(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10H7Z" fill={props.fill || "#4E4E4E"} />
        </svg>
    )
    // <svg width={5} height={9} viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    //     <path d="M5 0L0 4.5 5 9V0z" fill="#4E4E4E" />
    // </svg>
}

export default ArrowBottom
