import { useRef, useEffect } from "react"

/**
 * Hook that executes handler when you click outside of the passed ref
 */
function useOutsideComponentClickHandler(handler) {
    const ref = useRef()

    useEffect(() => {
        function handleClickOutside(event) {
            let toast_container = event.target.classList.contains('Toastify__toast-container')
            if (ref.current && !ref.current.contains(event.target) && !toast_container) {
                handler()
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [ref, handler])

    return ref
}

export default useOutsideComponentClickHandler
