import { useContext, useEffect, useState } from "react"
import SearchInput from "../../../ui/Input/SearchInput"
import { Link } from "react-router-dom"
import ReportItem from "./ReportItem"
import { AuthContext } from "../../../Auth/Auth"
import { demo_user, payment_url } from "../../../Util/config"

function LTNavbar({ reports = [], inputSearch = () => null, labelSearch = () => null }) {
    const [crops, setCrops] = useState([])
    const { currentUser } = useContext(AuthContext)

    const userID = currentUser.uid
    const userEmail = currentUser.email

    function handleInputSearch(e) {
        inputSearch(e)
    }

    useEffect(() => {
        function parseCrops() {
            const reportsCrops = []
            reports.map((report) => {
                return reportsCrops.push(report.crops)
            })
            const cropsArray = [...new Set(reportsCrops.flat())]
            setCrops(cropsArray)
        }

        parseCrops()
    }, [reports])

    const paymentUrl = `${payment_url}/input_field/${userID}?email=${userEmail}`

    return (
        <>
            <div className="long-term__navbar-container">
                <div className="p-6">
                    <div className="long-term__navbar-subtitle">My Reports</div>
                    <div className="long-term__navbar-search-input">
                        <SearchInput onChange={handleInputSearch} />
                    </div>
                </div>
                <div className="long-term__navbar-reports-container">
                    {reports.length === 0 && <div className="table__loader" />}
                    {reports !== [] ? (
                        reports.map((report, index) => {
                            return <ReportItem key={`ltnav_report_${index}`} linkComponent={Link} report={report} />
                        })
                    ) : (
                        <></>
                    )}
                    {/* {console.log(reports)} */}
                </div>
                {userEmail !== demo_user && (
                    <div className="long-term__navbar-button-container">
                        <a className="long-term__navbar-request-button" href={paymentUrl} target="_blank">
                            Request New Report
                        </a>
                    </div>
                )}
            </div>
        </>
    )
}

export default LTNavbar
