import { useMemo, useContext } from "react"
import { useParams } from "react-router-dom"

import { DataContext } from "../Util/Data"

export default function useFieldName() {
    let { id } = useParams()

    const {
        state: { fields },
    } = useContext(DataContext)

    return useMemo(() => {
        for (let i = 0; i < fields.length; i++) {
            if (fields[i].uuid === id) {
                return fields[i].name
            }
        }
    }, [fields, id])
}
