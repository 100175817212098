import React from "react"

function HighSoilTempRiskIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fill="#666D74"
                    d="M3 19h18v2H3zM21 16v-1.667h-3.273V16H21ZM16.009 16c.054-.27.082-.548.082-.833C16.09 12.867 14.258 11 12 11s-4.09 1.867-4.09 4.167c0 .285.027.564.081.833h8.018ZM6.273 16v-1.667H3V16h3.273ZM11.182 9.333V6h1.636v3.333h-1.636ZM18.652 9.575l-2.021 2.058-1.154-1.175L17.498 8.4l1.154 1.175ZM7.37 11.625 5.347 9.567l1.154-1.175 2.02 2.058-1.153 1.175Z"
                />
            </svg>
        </>
    )
}

export default HighSoilTempRiskIcon
