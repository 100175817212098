import React, { useState, useEffect, useContext } from "react"
import ReportDownloadButton from "../LongTerm/components/ReportDownloadButton"

import AnalogsChart from "./components/AnalogsChart"

import { useLocation, useParams } from "react-router-dom"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import useAnalogsData from "../../hooks/useAnalogsData"

function Analogs(props) {
    const { reportId } = useParams()

    const [mapLoading, setMapLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const {
        //
        ready,
        locations,
        decades,
        selectedLocation,
        selectedDecade,
        setSelectedLocation,
        setSelectedDecade,
    } = useAdaptationValues()

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?location=${selectedLocation}&decade=${selectedDecade}`

        const request = http.get(`/api/v1/adapt/${reportId}/analogs${query}`, { timeout: 180000 })
        request
            .then((response) => {
                setData(response?.data?.data)
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedLocation, selectedDecade])

    useEffect(() => {
        // This waits out the navbar to be closed
        setTimeout(() => {
            setMapLoading(false)
        }, 300)
    }, [])

    const currentLocation = locations.find((loc) => loc.name === selectedLocation)
    const { chartData } = useAnalogsData(data, currentLocation)

    const selectedDecadeObj = decades.find((d) => d.value === selectedDecade)

    return (
        <>
            <ReportDownloadButton text={`Climate Analogs / ${selectedLocation} / ${selectedDecadeObj.label}`} />
            <div className="overview">
                <div className="overview__main">
                    <AnalogsChart
                        loading={loading}
                        data={chartData}
                        mapLoading={mapLoading}
                        locations={locations}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        decades={decades}
                        selectedDecade={selectedDecade}
                        setSelectedDecade={setSelectedDecade}
                    />
                </div>
            </div>
        </>
    )
}

export default Analogs
