import * as React from "react"

function ColdSoinIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15 14a.438.438 0 01-.437-.438v-1.13l-.566.565a.437.437 0 11-.618-.62l1.184-1.183V7.758l-2.975 1.717-.434 1.62a.438.438 0 11-.846-.228l.208-.772-.98.565a.437.437 0 01-.438-.758l.98-.565-.773-.207a.438.438 0 01.227-.846l1.617.434L14.125 7 11.15 5.282l-1.619.433a.438.438 0 01-.225-.845l.772-.207-.98-.565a.438.438 0 11.438-.758l.98.565-.208-.772a.438.438 0 11.846-.226l.433 1.618 2.976 1.717V2.806l-1.184-1.184a.438.438 0 01.618-.62l.566.567V.438a.438.438 0 01.875 0v1.13l.566-.565a.438.438 0 01.618.62l-1.184 1.183v3.436l2.975-1.717.434-1.62a.438.438 0 01.845.228l-.207.772.98-.565a.438.438 0 01.438.758l-.98.565.773.207a.438.438 0 11-.228.845l-1.617-.433L15.875 7l2.975 1.718 1.618-.434a.437.437 0 11.227.846l-.773.207.98.565a.438.438 0 01-.438.758l-.98-.565.207.772a.438.438 0 11-.845.226l-.433-1.618-2.975-1.717v3.436l1.184 1.184a.438.438 0 01-.62.62l-.565-.567v1.132a.438.438 0 01-.438.437H15z"
                fill={props.fill || "#fff"}
            />
            <g clipPath="url(#prefix__clip0)">
                <path
                    d="M24.205 15.19h-2.031c-.471 0-.853-.415-.853-.927s.382-.927.853-.927h2.559c.323 0 .618.199.762.513l3.412 7.41c.284.616-.129 1.34-.763 1.34H.85c-.634 0-1.046-.724-.763-1.34L3.5 13.85a.849.849 0 01.763-.513H6.82c.47 0 .853.415.853.927 0 .511-.382.926-.853.926H4.789L2.23 20.747h24.534l-2.559-5.558z"
                    fill={props.fill || "#fff"}
                />
            </g>
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill={props.fill || "#fff"} transform="translate(0 5)" d="M0 0h29v22H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ColdSoinIcon
