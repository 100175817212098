// Modules
import PropTypes from "prop-types"

// Styles
import "../../styles/chart.css"
import "./ClimateLayout.css"

import ClimateWrapper from "./ClimateWrapper"
import ClimateProvider from "./ClimateProvider"
import { ToastContainer } from "react-toastify"
import { Sidebar } from "../../ui/Sidebar"
import GenericPageHeader from "../GenericPageHeader"

function ClimateLayout(props) {
    const { children, title, settings } = props

    return (
        <>
            <ClimateProvider>
                {/* VIEW CONTAINER  */}
                <div className="grow flex flex-row w-full h-full text-gray-90 roboto">
                    {/* SIDENAV  */}
                    <Sidebar settings={settings} climate />

                    {/* REPORT */}
                    <div className="bg-gray-5 grow px-[14px] overflow-x-hidden overflow-y-visible">
                        <ClimateWrapper>
                            {title && title != "" && <GenericPageHeader pageTitle={title} />}
                            {children}
                        </ClimateWrapper>
                    </div>
                </div>
            </ClimateProvider>
            <ToastContainer />
        </>
    )
}

ClimateLayout.propTypes = {
    children: PropTypes.node,
}

export default ClimateLayout
