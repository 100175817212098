import React, { useState, useCallback } from "react"

import { useLocation } from "react-router-dom"

import WeatherVariableTabs from "../WeatherVariableTabs"
import TempChart from "../TempChart"
import PrecipitationChart from "../PrecipitationChart"
import SoilTempChart from "../SoilTempChart"
import RelativeHumidityChart from "../RelativeHumidityChart"
import EvapotranspirationChart from "../EvapotranspirationChart"
import WindSpeedChart from "../WindSpeedChart"
import SoilMoistureChart from "../SoilMoistureChart"
import SolarRadiationChart from "../SolarRadiationChart"
import GrowingDegreeDaysChart from "../GrowingDegreeDaysChart"

const TabView = (props) => {
    const { search } = useLocation()
    const selectedTab = new URLSearchParams(search).get("selected")

    const { allowedWeatherVariables } = props

    const [actionsState, setActionsState] = useState({
        isMonthly: false,
        currentTab: "",
        selectedGranularity: "daily",
    })

    const handleChangeAction = useCallback((state) => {
        let result = state
        if (window.innerWidth < 550 && state.currentTab !== "gdd") {
            result = Object.assign(result, { isMonthly: false, selectedGranularity: "daily" })
        } else if (state.currentTab === "gdd") {
            result = Object.assign(result, { isMonthly: true, selectedGranularity: "monthly" })
        }
        setActionsState(state)
    }, [])

    const renderComponent = useCallback(() => {
        switch (actionsState.currentTab) {
            case "temperature_t2m":
                return <TempChart actionsState={actionsState} />
            case "precipitation":
                return <PrecipitationChart actionsState={actionsState} />
            case "soil_temperature":
                return <SoilTempChart actionsState={actionsState} />
            case "relative_humidity":
                return <RelativeHumidityChart actionsState={actionsState} />
            case "water_budget":
                return <PrecipitationChart actionsState={{ ...actionsState, extraPrecipitationChart: true }} />
            case "evapotranspiration":
                return <EvapotranspirationChart actionsState={actionsState} />
            case "solar_radiation":
                return <SolarRadiationChart actionsState={actionsState} />
            case "gdd":
                return <GrowingDegreeDaysChart actionsState={actionsState} />
            case "wind_speed":
                return <WindSpeedChart actionsState={actionsState} />
            case "soil_moisture": {
                return <SoilMoistureChart actionsState={actionsState} />
            }
            default:
        }
    }, [actionsState])

    return (
        <>
            <WeatherVariableTabs
                selectedTab={selectedTab}
                allowedWeatherVariables={allowedWeatherVariables}
                initialState={actionsState}
                onStateChange={handleChangeAction}
            />
            {renderComponent()}
        </>
    )
}

export default TabView
