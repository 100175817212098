import React, { useContext, useEffect } from "react"
import http from "../../Util/http"

import { useParams } from "react-router-dom"

import { AdaptationContext } from "./ClimateProvider"

function getRiskIconName(name) {
    switch (name) {
        case "Heat Stress":
            return "risk_heat_stress"
        case "Cold Stress":
            return "risk_cold"
        case "Low Humidity":
            return "risk_low_humidity"
        case "High Humidity":
            return "risk_high_humidity"
        default:
            return "risk"
    }
}

function ClimateWrapper(props) {
    const { children } = props
    const { dispatch } = useContext(AdaptationContext)

    // TODO get report ID HERE!
    const { reportId } = useParams()

    async function getReportValues(reportId = "report_demo") {
        // first get locations and crops
        const results = await Promise.allSettled([
            http.get(`/api/v1/adapt/${reportId}/locations`),
            http.get(`/api/v1/adapt/${reportId}/crops`),
            http.get(`/api/v1/adapt/${reportId}/risks`),
        ])

        // TODO handle error inside the view
        if (results[0].status !== "fulfilled" || results[1].status !== "fulfilled" || results[2].status !== "fulfilled")
            throw new Error("There was a problem fetching the report information. Try again later.")

        const locations = []
        const crops = []
        const risks = []

        Object.keys(results[0].value?.data).forEach((key) => locations.push(results[0].value?.data[key]))
        Object.keys(results[1].value?.data).forEach((key) => crops.push(results[1].value?.data[key]))
        Object.keys(results[2].value?.data).forEach((key) =>
            risks.push({
                ...results[2].value?.data[key],
                id: key,
                iconName: getRiskIconName(results[2].value?.data[key].title),
            })
        )

        dispatch({ type: "SET_INITIAL_STATE", payload: { locations, crops, risks } })
    }

    useEffect(() => {
        getReportValues(reportId).catch((err) => console.log(err))
    }, [reportId])

    return <>{children}</>
}

export default React.memo(ClimateWrapper)
