import React from "react"
import "./TableContent.css"
import TableHeader from "./TableHeader"
import TableItem from "./TableItem"
import { applyFieldTransformation } from "../../helpers/table"

function TableContent(props) {
    const {
        displayData = [],
        fields = [],
        metaFields = [],
        rows,
        currentPage,
        sortBy = [],
        groupBy = [],
        sortFunctionName,
        onHeaderClick,
        root,
        path = "",
        selected = [],
    } = props

    // item click functionallity
    // const [open, setOpen] = useState([])

    // console.log("selected from content", selected)

    let currentFields = [...fields]
    let nextFields = []

    if (groupBy && groupBy.length) {
        for (let field of fields) {
            // check if field is the selected one
            // check if current fields === 0 because you can only group data by one field at a time
            let currentlyGroupedBy = field.propName === groupBy[0].propName ? field : null

            if (currentlyGroupedBy && !currentlyGroupedBy.groupFields) {
                // if currently grouped by has special fields
                currentFields = [currentlyGroupedBy]
            } else if (currentlyGroupedBy && currentlyGroupedBy.groupFields) {
                // if currently grouped by doesn't have special filds
                currentFields = [...currentlyGroupedBy.groupFields]
            } else {
                // if it's any other field
                nextFields.push(field)
            }
        }
    }

    // to be used for nested children
    const HEADERS_CONTAINER = root
        ? ({ children }) => <thead>{children}</thead>
        : ({ children }) => <React.Fragment>{children}</React.Fragment>

    const DATA_CONTAINER = root
        ? ({ children }) => <tbody>{children}</tbody>
        : ({ children }) => <React.Fragment>{children}</React.Fragment>

    let transformedData = applyFieldTransformation(displayData, currentFields)
    if (root) transformedData = transformedData.slice(rows * currentPage - rows, rows * currentPage)

    //
    const _selected = [...selected]

    return (
        <>
            {(root || groupBy.length === 0) && (
                <HEADERS_CONTAINER>
                    <tr>
                        {currentFields &&
                            currentFields.map((field, i) => {
                                const {
                                    propName,
                                    displayName,
                                    headerClassName,
                                    headerStyle,
                                    style,
                                    sticky,
                                    sortable,
                                    draggable,
                                    headerRender,
                                    headerComponent,
                                } = field
                                return (
                                    <TableHeader
                                        key={`field_${propName}_${i}`}
                                        propName={propName}
                                        active={propName === sortBy}
                                        sortFunctionName={sortFunctionName}
                                        displayName={displayName}
                                        className={headerClassName}
                                        style={headerStyle || style}
                                        onClick={onHeaderClick}
                                        draggable={draggable}
                                        sticky={sticky}
                                        sortable={sortable}
                                        headerRender={headerRender}
                                        headerComponent={headerComponent}
                                    />
                                )
                            })}
                    </tr>
                </HEADERS_CONTAINER>
            )}
            <DATA_CONTAINER>
                {(transformedData &&
                    transformedData.length > 0 &&
                    transformedData.map((rowData, index) => {
                        // if tableitem has data, arrange it and render it

                        let checked = false
                        let checkedStatus = "empty"
                        let checkedData = []

                        for (let i = 0; i < _selected.length; i++) {
                            if (groupBy[0] && rowData.__group) {
                                const { propName } = groupBy[0]
                                if (_selected[i][propName] === rowData[propName]) {
                                    checked = true
                                    checkedStatus =
                                        _selected[i].__count === rowData.__count
                                            ? "full"
                                            : _selected[i].__count > 0
                                            ? "half"
                                            : "empty"
                                    checkedData = [..._selected[i].data]
                                    // remove from selected array
                                    _selected.splice(i, 1)
                                    break
                                }
                            } else if (_selected[i].uuid === rowData.uuid) {
                                checked = true
                                checkedStatus = "full"
                                // remove from selected array
                                _selected.splice(i, 1)
                                break
                            }
                        }

                        return (
                            <TableItem
                                key={`${rowData.uuid}_${index}`}
                                index={index}
                                rowData={rowData}
                                fields={currentFields}
                                metaFields={metaFields}
                                previousPath={path}
                                checked={checked}
                                checkedStatus={checkedStatus}
                                path={
                                    groupBy.length > 0
                                        ? path + "/" + rowData[groupBy[0].propName]
                                        : path + "/" + rowData.uuid
                                }
                            >
                                {groupBy.length > 0 ? (
                                    <TableContent
                                        displayData={rowData.data}
                                        fields={nextFields}
                                        metaFields={metaFields}
                                        groupBy={groupBy.slice(1, groupBy.length)} // next group
                                        selected={checkedData}
                                        path={path + "/" + rowData[groupBy[0].propName]}
                                        sortFunctionName={sortFunctionName}
                                        onHeaderClick={onHeaderClick}
                                    />
                                ) : null}
                            </TableItem>
                        )
                    })) || (
                    <tr style={{ paddingLeft: "20px" }}>
                        <td className="table__content__default-message" colSpan={currentFields.length}>
                            No records to display
                        </td>
                    </tr>
                )}
            </DATA_CONTAINER>
        </>
    )
}

export default React.memo(TableContent)
