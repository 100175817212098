import * as React from "react"

function GrowingDegreeDaysIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.261.677L.542 5.377a.801.801 0 00.26 1.556.79.79 0 00.26-.043L15.09 2.082c-.568-.24-.844-.826-.829-1.405z"
                fill={props.fill || "#fff"}
            />
            <path
                d="M15.925 1.363a.435.435 0 00-.217-.656L13.731.024a.436.436 0 00-.552.548l.8 2.42a.43.43 0 00.769.108l1.177-1.737zM0 9.239v5.694C0 15.523.476 16 1.062 16h1.062c.586 0 1.062-.478 1.062-1.067V8.171L.242 9.198a.931.931 0 01-.242.04zM4.248 14.933c0 .59.475 1.067 1.061 1.067h1.062c.586 0 1.062-.478 1.062-1.067V6.69L4.248 7.801v7.132zM8.495 14.933c0 .59.476 1.067 1.062 1.067h1.062c.586 0 1.062-.478 1.062-1.067V5.207L8.495 6.32v8.614zM12.743 4.836v10.097c0 .59.475 1.067 1.062 1.067h1.062c.586 0 1.061-.478 1.061-1.067V3.725l-3.185 1.111z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default GrowingDegreeDaysIcon
