import "react-datepicker/dist/react-datepicker.css"

import Select from "../../../ui/Select/Select"
import Section from "../../../ui/Section"
import { MapboxBasicLayersComponent } from "../../../components/Charts/MapboxMaps/MapboxBasicLayersComponent/MapboxBasicLayersComponent"
import useContainerSize from "../../../hooks/useContainerSize"
import MapPinIcon from "../../../ui/Icons/MapPinIcon"
import CalendarIcon from "../../../ui/Icons/CalendarIcon"
import ChartLabels from "../../../components/LongTerm/ChartLabels/ChartLabels"
import ChartLabelItem from "../../../components/LongTerm/ChartLabels/ChartLabelItem"
import LoadingBar from "../../../components/LoadingBar"

function AnalogsChart(props) {
    const {
        loading,
        mapLoading,
        data,
        locations,
        selectedLocation,
        setSelectedLocation,
        decades,
        selectedDecade,
        setSelectedDecade,
    } = props

    const [containerRef, { height }] = useContainerSize(0)

    return (
        <>
            <Section
                title="Climate Analogs"
                description="This page displays the regions that will have analogous climate conditions compared to the reference location during a target decade in the future (e.g. 2040-2050)."
                header={() => (
                    <>
                        <Select
                            icon={MapPinIcon}
                            value={selectedLocation || ""}
                            onChange={(e) => setSelectedLocation(e.target.value)}
                        >
                            {locations &&
                                locations.map((location) => (
                                    <option value={location.name} key={`analogs_location_${location.name}`}>
                                        {location.name}
                                    </option>
                                ))}
                        </Select>
                        <Select
                            icon={CalendarIcon}
                            value={selectedDecade || ""}
                            onChange={(e) => setSelectedDecade(e.target.value)}
                        >
                            {decades.map((decade) => (
                                <option value={decade.value} key={`analogs_decade_${decade.value}`}>
                                    {decade.label}
                                </option>
                            ))}
                        </Select>
                    </>
                )}
            >
                <ChartLabels>
                    <ChartLabelItem renderIcon={() => <MapPinIcon fillColor="#1C9690" />}>
                        <label>Top 3 Analogs</label>
                    </ChartLabelItem>
                    <ChartLabelItem renderIcon={() => <MapPinIcon fillColor="#F37920" />}>
                        <label>Reference Location (2020-2029)</label>
                    </ChartLabelItem>
                </ChartLabels>
                <div style={{ flex: 1, width: "100%", height: "100%" }} ref={containerRef}>
                    {loading && <LoadingBar loading />}
                    {!mapLoading && (
                        <MapboxBasicLayersComponent
                            zoomControls
                            geojson={
                                (data && data.features && data.features.length > 0 && data) || {
                                    type: "FeatureCollection",
                                    features: [],
                                }
                            }
                            width={"100%"}
                            height={height}
                        ></MapboxBasicLayersComponent>
                    )}
                </div>
            </Section>
        </>
    )
}

export default AnalogsChart
