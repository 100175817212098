/**
 * initial state
 *
 * groupBy: [],
 * sortBy: "",
 * asc: true,
 * inputValue: "",
 * rows: 10,
 * currentPage: 1,
 */

function TableReducer(state = {}, action) {
    //
    const { type, payload } = action
    //
    switch (type) {
        //
        case "INPUT_CHANGE": {
            const { inputValue } = payload
            return { ...state, inputValue, currentPage: 1 }
        }
        //
        case "ADD_GROUP_BY": {
            const { propName, displayName } = payload
            const _openPaths = { ...state.openPaths }
            const _groupBy = [...state.groupBy, { propName, displayName }]

            for (let path in _openPaths) {
                if (_openPaths[path] && typeof _openPaths[path] === "object") {
                    const splittedPath = path.split("/")
                    splittedPath.splice(splittedPath.length - 1, 0, _openPaths[path][propName])

                    const previousSplittedPath = [...splittedPath].splice(0, splittedPath.length - 1)

                    let newPath = splittedPath.join("/")
                    let prevPath = previousSplittedPath.join("/")

                    _openPaths[newPath] = { ..._openPaths[path] }
                    _openPaths[prevPath] = true

                    delete _openPaths[path]
                }
            }

            return { ...state, groupBy: _groupBy, openPaths: _openPaths, currentPage: 1 }
        }
        //
        case "REMOVE_GROUP_BY": {
            const { propName, displayName } = payload
            // const _openPaths = { ...state.openPaths }
            // get the removed group position
            // let groupRemovedIndex = -1
            const _groupBy = state.groupBy.filter(
                (_propData) => _propData.propName !== propName && _propData.displayName !== displayName
            )
            return { ...state, groupBy: _groupBy, openPaths: [] }
        }
        //
        case "SET_SORT_BY": {
            const { sortBy, sortFunctionName } = payload

            if (!sortFunctionName) {
                if (state.sortBy !== sortBy) {
                    return { ...state, sortBy: sortBy, sortFunctionName: "ASC" }
                } else {
                    if (state.sortFunctionName === "ASC") {
                        return { ...state, sortBy: sortBy, sortFunctionName: "DES" }
                    } else if (state.sortFunctionName === "DES") {
                        return { ...state, sortBy: "", sortFunctionName: "" }
                    }
                }
            }
            return { ...state, sortBy, sortFunctionName }
        }
        //
        case "ROW_NUMBER_CHANGE": {
            const { rows, total } = payload
            const newState = { ...state, rows, currentPage: 1 }
            if (rows === "All") newState.rows = total
            return newState
        }
        //
        case "CURRENT_PAGE_CHANGE": {
            const { page } = payload
            return { ...state, currentPage: page }
        }
        case "ADD_OPEN_PATH": {
            const { path, rowData } = payload
            return { ...state, openPaths: { ...state.openPaths, [path]: rowData || null } }
        }
        case "REMOVE_OPEN_PATH": {
            const { path } = payload
            const _openPaths = { ...state.openPaths }
            delete _openPaths[path]
            return { ...state, openPaths: _openPaths }
        }
        case "ADD_SELECTED": {
            const { items = [] } = payload
            const { selected } = state

            const _selected = [...selected].concat(items)

            return { ...state, selected: _selected }
        }
        case "REMOVE_SELECTED": {
            const { deselected } = payload
            const { selected } = state
            const _selected = selected.filter((item) => {
                return item.uuid !== deselected.uuid
            })

            return { ...state, selected: _selected }
        }
        case "REMOVE_SELECTED_BY_PROPNAME": {
            const { propName, value } = payload
            const { selected } = state

            const _selected = selected.filter((item) => {
                return item[propName] !== value
            })

            return { ...state, selected: _selected }
        }
        case "ADD_SELECTED_BY_ROWS": {
            const { items, data, groupBy } = payload
            const { selected } = state

            const propName = (groupBy[0] && groupBy[0].propName) || "uuid"

            const hash = {}
            for (let item of items) hash[item[propName]] = item

            const _data = data.filter((item) => hash[item[propName]])
            const _selected = selected.filter((item) => !hash[item[propName]]).concat(_data)

            return { ...state, selected: _selected }
        }
        case "REMOVE_SELECTED_BY_ROWS": {
            const { items, groupBy } = payload
            const { selected } = state

            const propName = (groupBy[0] && groupBy[0].propName) || "uuid"

            const hash = {}
            for (let item of items) hash[item[propName]] = true

            const _selected = selected.filter((item) => !hash[item[propName]])

            return { ...state, selected: _selected }
        }
        case "ADD_LABEL_FILTER": {
            const { label } = payload
            const { selectedLabels } = state

            return { ...state, selectedLabels: [...selectedLabels, label] }
        }
        case "REMOVE_LABEL_FILTER": {
            const { label } = payload
            const { selectedLabels } = state

            return { ...state, selectedLabels: selectedLabels.filter((_label) => _label.name !== label.name) }
        }
        default: {
            return state
        }
    }
}

export default TableReducer
