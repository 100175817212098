// Modules
import React, { useCallback } from "react"
import { withRouter } from "react-router"
import { Link as RouterLink } from "react-router-dom"

// Components
import Copyright from "../ui/Copyright/Copyright"

// Views

// Context providers / Utils
import app from "../Util/Fire"
import validateEmail from "../Util/Validate"

// Hooks

// Material-UI *

// Styles
import "./RestorePassword.css"

const RestorePassword = ({ history }) => {
    const handlePassword = useCallback(
        async (event) => {
            event.preventDefault()
            const { email } = event.target.elements
            try {
                /* TODO Add better styles to handle successful email sent and error handling, maybe a message before redirecting*/
                if (validateEmail(email.value)) {
                    await app.auth().sendPasswordResetEmail(email.value)
                    history.push("/")
                } else {
                    alert("Invalid email address provided")
                }
            } catch (error) {
                alert(error)
            }
        },
        [history]
    )

    return (
        <div className="restore-password">
            <div className="restore-password__container">
                <img alt="Climate AI logo" className="confirmation-screens__logo" src="/Climate-AI-Color-Logo.png" />
                <div className="restore-password__content">
                    <div className="restore-password__title">Password recovery</div>
                    <div className="restore-password__text">Enter the email address assocciated with your account.</div>
                    <form onSubmit={handlePassword} className="restore-password__form">
                        <input
                            className="restore-password__text-input"
                            placeholder="Email address"
                            //type="email"
                            id="email"
                            name="email"
                        ></input>
                        <div className="restore-password__button-container">
                            <RouterLink to="/">
                                <button className="restore-password__cancel-button">Cancel</button>
                            </RouterLink>
                            <button type={"submit"} className="restore-password__confirm-button">
                                Confirm
                            </button>
                        </div>
                    </form>
                </div>
                <div className="restore-password__copyright">
                    <Copyright />
                </div>
            </div>
        </div>
    )
}

export default withRouter(RestorePassword)
