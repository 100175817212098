import React, { useState, useEffect, useContext } from "react"

import MonthlyChart from "./components/MonthlyChart"

import ChartLabels from "../../components/LongTerm/ChartLabels/ChartLabels"
import ChartLabelItem from "../../components/LongTerm/ChartLabels/ChartLabelItem"
import Checkbox from "../../ui/Checkbox/Checkbox"
import useMonthlyData from "../../hooks/useMonthlyData"

import ReportDownloadButton from "../LongTerm/components/ReportDownloadButton"

import { useLocation, useParams } from "react-router-dom"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"

const chartColors = ["#F37920", "#1C9690", "#9F24AA", "#039BE5", "#F5B410", "#3949AB", "#3d3d3d", "#8E7CC3"]
const initialSelected = {
    2010: false,
    2020: false,
    2030: false,
    2040: false,
    2050: false,
    2060: false,
    2070: false,
    2080: false,
}

function Monthly(props) {
    const { reportId } = useParams()

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const [selected, setSelected] = useState(initialSelected)

    const {
        ready,
        locations,
        scenarios,
        risks,
        variables,
        selectedLocation,
        selectedScenario,
        selectedRisk,
        selectedVariable,
        setSelectedLocation,
        setSelectedScenario,
        setSelectedRisk,
        setSelectedVariable,
        variablesByDefault,
    } = useAdaptationValues()

    function handleCheckboxChange(key) {
        if (selected[key]) setSelected({ ...selected, [key]: false })
        else setSelected({ ...selected, [key]: true })
    }

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?scenario=${selectedScenario}&location=${selectedLocation}`

        if (variablesByDefault && selectedVariable) query = query + `&var=${selectedVariable}`
        else if (!variablesByDefault && selectedRisk) query = query + `&risk=${selectedRisk}`

        const request = http.get(`/api/v1/adapt/${reportId}/seasonal${query}`, {
            timeout: 180000,
        })
        request
            .then((response) => {
                setData(response.data)
                let newSelected = { ...initialSelected }
                Object.keys(response.data).forEach((key) => (newSelected[key] = selected[key]))
                setSelected(newSelected)
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedLocation, selectedScenario, selectedRisk, selectedVariable, variablesByDefault])

    const { chartData } = useMonthlyData(data, selected, chartColors, variablesByDefault)

    const selectedRiskObj = risks.find(({ id }) => id === selectedRisk.toString())
    const selectedVariableObj = variables.find(({ value }) => value === selectedVariable)
    const selectedScenarioObj = scenarios.find((s) => s.value === selectedScenario)

    return (
        <>
            <ReportDownloadButton
                text={`Seasonal Risk / ${selectedLocation} / ${
                    variablesByDefault
                        ? selectedVariableObj.label
                        : `${selectedRiskObj.title} (${selectedRiskObj.subtitle}, ${selectedRiskObj.stage})`
                } / ${selectedScenarioObj.label}`}
            />
            <div className="overview">
                <div className="overview__main">
                    <MonthlyChart
                        loading={loading}
                        data={chartData}
                        locations={locations}
                        scenarios={scenarios}
                        risks={risks}
                        variables={variables}
                        selectedLocation={selectedLocation}
                        selectedScenario={selectedScenario}
                        selectedRisk={selectedRisk}
                        selectedVariable={selectedVariable}
                        setSelectedLocation={setSelectedLocation}
                        setSelectedScenario={setSelectedScenario}
                        setSelectedRisk={setSelectedRisk}
                        setSelectedVariable={setSelectedVariable}
                        selectedRiskObj={selectedRiskObj}
                        selectedVariableObj={selectedVariableObj}
                        variablesByDefault={variablesByDefault}
                    >
                        <ChartLabels>
                            {new Array(6)
                                .fill(null)
                                .map((_, i) => (variablesByDefault ? 2010 : 2010) + i * 10)
                                .map((d, i) => {
                                    const disabled = !data[d]
                                    return (
                                        <ChartLabelItem
                                            key={`monthly_select_${d.toString()}`}
                                            disabled={disabled}
                                            renderIcon={() => (
                                                <Checkbox
                                                    color={chartColors[i]}
                                                    checked={selected[d.toString()]}
                                                    status={selected[d.toString()] ? "full" : "empty"}
                                                    onChange={() => !disabled && handleCheckboxChange(d.toString())}
                                                />
                                            )}
                                        >
                                            <button
                                                className="btn"
                                                style={{
                                                    opacity: selected[d.toString()] ? 1 : 0.4,
                                                    fontFamily: "Montserrat",
                                                }}
                                                onClick={() => !disabled && handleCheckboxChange(d.toString())}
                                            >
                                                {d} - {d + 9}
                                            </button>
                                        </ChartLabelItem>
                                    )
                                })}
                        </ChartLabels>
                    </MonthlyChart>
                </div>
            </div>
        </>
    )
}

export default Monthly
