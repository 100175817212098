import "react-datepicker/dist/react-datepicker.css"

import Select from "../../../ui/Select/Select"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"
import Section from "../../../ui/Section"
import useContainerSize from "../../../hooks/useContainerSize"
import LoadingBar from "../../../components/LoadingBar"

import MapPinIcon from "../../../ui/Icons/MapPinIcon"
import Menu from "../../../ui/Controls/Menu"
import MenuItem from "../../../ui/Controls/MenuItem"
import getVariableIcon from "../../../Util/getVariableIcon"
import getRiskIcon from "../../../Util/getRiskIcon"
import { capitalizeFirstCharacterOfEachWord } from "../../../helpers/wordHelper"
import { getYFormat, getYLabel } from "../../../Util/CADChartLabel"

const chartArgs = {
    labelY: "Days with Risk",
    xTicksCount: 12,
    yTicksCount: 5,
    xDateMin: new Date(2022, 0, 1),
    xDateMax: new Date(2023, 0, 1),
    centerTicks: true,
    candlePadding: 0,
    labelYOffsetX: -20,
    candleTopBottomLines: true,
    maxCandlestickWidth: 100,
    xLeftOffset: 0.2,
    xRightOffset: 0.2,
    marginLeft: 100,
}

function MonthlyChart(props) {
    const {
        loading,
        children,
        data,
        locations,
        scenarios,
        risks,
        variables,
        selectedLocation,
        selectedScenario,
        selectedRiskObj,
        selectedVariable,
        selectedVariableObj,
        setSelectedLocation,
        setSelectedScenario,
        setSelectedRisk,
        setSelectedVariable,
        variablesByDefault,
    } = props

    const [containerRef, { height }] = useContainerSize(1000)

    let labelY = !variablesByDefault
        ? capitalizeFirstCharacterOfEachWord(
              getYLabel(
                  selectedRiskObj.case,
                  selectedRiskObj.function,
                  selectedRiskObj.consecutive,
                  selectedRiskObj.variable,
                  selectedRiskObj.unit
              )
          )
        : selectedVariableObj.label + " " + ((selectedVariableObj.units && `(${selectedVariableObj.units})`) || "")
    // let yValueMax = !variablesByDefault || selectedVariable.value === "rh" ? 100 : undefined
    let tickFormat = !variablesByDefault
        ? getYFormat(
              selectedRiskObj.case,
              selectedRiskObj.function,
              selectedRiskObj.consecutive,
              selectedRiskObj.variable,
              selectedRiskObj.unit
          )
        : selectedVariableObj.units || ""

    const yTickFormat = (d) => {
        if (!variablesByDefault) {
            // show risks from 0 to 100
            return d < 0 ? "" : d
        } else {
            // return selectedVariableObj.units || ""
            return ["gdd", "rh", "ch"].includes(selectedVariable) && d < 0 ? "" : d
        }
    }

    return (
        <>
            <Section
                title="Monthly Climate Trend"
                description="This page displays the average monthly climate risk for your selected location."
                header={() => {
                    return (
                        <>
                            <Select
                                icon={MapPinIcon}
                                value={selectedLocation || ""}
                                onChange={(e) => setSelectedLocation(e.target.value)}
                            >
                                {locations &&
                                    locations.map((location) => (
                                        <option value={location.name} key={`monthly_location_${location.name}`}>
                                            {location.name}
                                        </option>
                                    ))}
                            </Select>
                            <Menu
                                className="left"
                                icon={
                                    variablesByDefault
                                        ? () => getVariableIcon(selectedVariable)
                                        : () => getRiskIcon(selectedRiskObj.icon)
                                }
                                text={
                                    (variablesByDefault && selectedVariableObj.label) ||
                                    (!variablesByDefault &&
                                        `${selectedRiskObj.title} (${
                                            (selectedRiskObj.subtitle && selectedRiskObj.subtitle + ", ") || ""
                                        }${(selectedRiskObj.stage && selectedRiskObj.stage) || ""})`)
                                }
                            >
                                {/* Risks */}
                                <MenuItem label="Risks">
                                    {risks &&
                                        risks.map((risk) => (
                                            <MenuItem
                                                key={`monthly_risk_${risk.id}`}
                                                label={`${risk.title} (${
                                                    (risk.subtitle && risk.subtitle + ", ") || ""
                                                }${(risk.stage && risk.stage) || ""})`}
                                                icon={() => getRiskIcon(risk.icon)}
                                                onClick={() => setSelectedRisk(risk.id, false)}
                                            />
                                        ))}
                                </MenuItem>
                                <MenuItem label="Variables">
                                    {variables &&
                                        variables.map((v) => (
                                            <MenuItem
                                                key={`monthly_var_${v.value}`}
                                                label={v.label}
                                                icon={() => getVariableIcon(v.value)}
                                                onClick={() => setSelectedVariable(v.value, true)}
                                            />
                                        ))}
                                </MenuItem>
                            </Menu>
                            <Select
                                value={selectedScenario || ""}
                                onChange={(e) => setSelectedScenario(e.target.value)}
                            >
                                {scenarios.map((scenario) => (
                                    <option value={scenario.value} key={`monthly_scenario_${scenario.value}`}>
                                        {scenario.label}
                                    </option>
                                ))}
                            </Select>
                        </>
                    )
                }}
            >
                {loading && <LoadingBar loading />}
                {children}
                <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
                    <LineAreaChartComponent
                        labelY={labelY}
                        xTicksCount={chartArgs.xTicksCount}
                        yTicksCount={chartArgs.yTicksCount}
                        yValueMax={chartArgs.yValueMax}
                        marginLeft={chartArgs.marginLeft}
                        xDateMax={chartArgs.xDateMax}
                        xDateMin={chartArgs.xDateMin}
                        xLeftOffset={chartArgs.xLeftOffset}
                        xRightOffset={chartArgs.xRightOffset}
                        centerTicks={chartArgs.centerTicks}
                        labelYOffsetX={chartArgs.labelYOffsetX}
                        candlePadding={chartArgs.candlePadding}
                        candleTopBottomLines={chartArgs.candleTopBottomLines}
                        yTopOffset={0.2}
                        svgHeight={(height && height - 10) || 0}
                        svgWidth="100%"
                        xTickFormat={(d) => d.toLocaleDateString(undefined, { month: "long" })}
                        yTickFormat={yTickFormat}
                        shapeTip={(e, d, arr) => {
                            return `
                            <table>
                            <tr><th style="text-align:right">95th</th><td>${
                                d.y1 && d.y1.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">75th</th><td>${
                                d.yMax && d.yMax.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">50th</th><td>${
                                d.yMid && d.yMid.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">25th</th><td>${
                                d.yMin && d.yMin.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">5th</th><td>${
                                d.y0 && d.y0.toFixed(2) + " " + tickFormat
                            }</td></tr>
                          </table>
                    `
                        }}
                        data={!loading && data}
                    ></LineAreaChartComponent>
                </div>
            </Section>
        </>
    )
}

export default MonthlyChart
