import * as React from "react"

function ComulativePrecipitationIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15 5c3.961 0 6.207 2.622 6.534 5.787h.1a4.61 4.61 0 014.616 4.605 4.61 4.61 0 01-4.616 4.604h-.728l-1.979 3.295a.938.938 0 01-1.68-.822l.057-.115 1.437-2.359h-2.67l-1.979 3.296a.938.938 0 01-1.68-.822l.057-.115 1.437-2.359h-2.671l-1.977 3.296a.938.938 0 01-1.68-.822l.056-.115 1.436-2.359h-.704a4.608 4.608 0 01-4.616-4.603 4.609 4.609 0 014.615-4.602h.1C8.795 7.6 11.04 5 15 5z"
                fill={props.fill || "#fff"}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 2.375a4.125 4.125 0 100 8.25 4.125 4.125 0 000-8.25zM4.875 8a.375.375 0 01-.75 0V6.875H3a.375.375 0 110-.75h1.125V5a.375.375 0 11.75 0v1.125H6a.375.375 0 010 .75H4.875V8z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default ComulativePrecipitationIcon
