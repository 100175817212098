import React, { useEffect, useState } from "react"

const StageTypeOption = ({
    isActive,
    isSelected,
    label,
    toggleOption,
    baseTempInputValue = null,
    baseTempInputOnChange = () => {},
}) => {
    return (
        <div
            className={
                "flex flex-col justify-center ml-1 mr-2" +
                (!isActive ? " opacity-40 cursor-not-allowed" : " cursor-pointer")
            }
        >
            <div
                className="h-10 self-stretch flex flex-row items-center space-x-4"
                onClick={() => {
                    if (isActive) {
                        toggleOption()
                    }
                }}
            >
                <span
                    className={
                        "h-3 w-3 bg-transparent rounded-full border border-accent flex flex-row items-center justify-center"
                    }
                >
                    {isSelected && <span className="h-2 w-2 bg-accent rounded-full block"></span>}
                </span>
                <div>{label}</div>
            </div>
            {isActive && baseTempInputValue != null ? (
                <div className="self-end flex flex-row items-center">
                    <span>Base temperature (°C)</span>
                    <input
                        type="number"
                        className="w-16 ml-3 px-2 py-1 border-2 rounded-md border-gray-300 text-gray-800 focus:outline-none focus:border-accent"
                        value={baseTempInputValue}
                        onChange={(event) => {
                            baseTempInputOnChange(event.target.value)
                            // setBaseTemp(event.target.value)
                        }}
                    ></input>
                </div>
            ) : null}
            <div className="ml-7 h-1 border-b-2 border-gray-100 mt-3 mb-2"></div>
        </div>
    )
}

const StageTypeSelect = ({
    actualVariety,
    possibleType,
    setPosibleType,
    setUpdateStageType,
    baseTempBase,
    hasSuggestions = false,
}) => {
    let auxBaseTem = 0
    if (baseTempBase === undefined) {
        auxBaseTem = baseTempBase
    }
    const [baseTemp, setBaseTemp] = useState(auxBaseTem)
    const [isSuggested, setIsSuggested] = useState(false)

    useEffect(() => {
        if (actualVariety.baseTemp) {
            setBaseTemp(actualVariety.baseTemp)
        }
        if (actualVariety.type.includes("suggested")) {
            setIsSuggested(true)
        }
    }, [actualVariety])

    useEffect(() => {
        if (possibleType === "days") {
            setBaseTemp(auxBaseTem)
        }
    }, [possibleType])

    return (
        <div className="flex flex-col justify-center items-stretch text-sm m-4">
            <StageTypeOption
                isActive={hasSuggestions}
                isSelected={
                    isSuggested &&
                    ((actualVariety.type === "suggested days" && possibleType === "") ||
                        possibleType === "suggested days")
                }
                label="Suggested Calendar Days"
                toggleOption={() => {
                    setPosibleType("suggested days")
                    setBaseTemp(null)
                    setIsSuggested(true)
                }}
            />

            <StageTypeOption
                isActive={true}
                isSelected={
                    !isSuggested && ((actualVariety.type === "days" && possibleType === "") || possibleType === "days")
                }
                label="Custom Calendar Days"
                toggleOption={() => {
                    setPosibleType("days")
                    setBaseTemp(null)
                    setIsSuggested(false)
                }}
            />

            <StageTypeOption
                isActive={false}
                isSelected={
                    (actualVariety.type === "custom gdds" && possibleType === "") || possibleType === "custom gdds"
                }
                label="Custom Growing Degree Days"
                toggleOption={() => {
                    setPosibleType("custom gdds")
                    setBaseTemp(baseTempBase !== undefined && baseTempBase !== "" ? baseTempBase : 0)
                    setIsSuggested(false)
                }}
                baseTempInputValue={baseTemp}
                baseTempInputOnChange={setBaseTemp}
            />

            <button
                className={
                    "mt-6 w-full p-2 text-white font-semibold rounded-lg" +
                    ((actualVariety.type === "" &&
                        (possibleType === "" || (possibleType.includes("gdds") && baseTemp === 0))) ||
                    (possibleType === "" && actualVariety.type.includes("gdds") && baseTemp === 0)
                        ? " bg-gray-300"
                        : " bg-accent")
                }
                onClick={() => {
                    if (
                        !(
                            (actualVariety.type === "" &&
                                (possibleType === "" || (possibleType.includes("gdds") && baseTemp === 0))) ||
                            (possibleType === "" && actualVariety.type.includes("gdds") && baseTemp === 0)
                        )
                    ) {
                        setUpdateStageType(baseTemp, isSuggested)
                    }
                }}
            >
                Save
            </button>
        </div>
    )
}

export default StageTypeSelect
