import React from "react"

function InsufficientChillTempIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.83 11H22v2h-4.17l3.24 3.24-1.41 1.42L15 13h-2v2l4.66 4.66-1.42 1.41L13 17.83V22h-2V2h2v4.17l3.24-3.24 1.42 1.41L13 9v2h2l4.66-4.66 1.41 1.42L17.83 11Z"
                    fill="#666D74"
                />
                <path fill="#666D74" d="M5 7h2v6H5zM5 15h2v2H5z" />
            </svg>
        </>
    )
}

export default InsufficientChillTempIcon
