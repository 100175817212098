import React from "react"

function LowSoilTempRiskIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={25} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M11.1 9.79V6h1.8v3.79l2.916-2.945 1.278 1.282-4.194 4.237v1.818h1.8l4.194-4.237 1.269 1.291-2.916 2.946H21V16H3v-1.818h3.753l-2.916-2.946 1.269-1.29L9.3 14.181h1.8v-1.818L6.906 8.127l1.278-1.282L11.1 9.791ZM3 19h18v2H3z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default LowSoilTempRiskIcon
