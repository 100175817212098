import React, { useState, useRef, useContext } from "react"
// import Dropdown from "rc-dropdown"
import downloadSVG from "export-svg-with-styles"
// import Checkbox from "@material-ui/core/Checkbox"
// import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
// import CheckBoxIcon from "@material-ui/icons/CheckBox"
import { CSVLink } from "react-csv"
import { useParams } from "react-router-dom"

import { monthNames } from "../TempChart/helper"
import { DataContext } from "../../../../Util/Data"
import useFieldName from "../../../../hooks/useFieldName"

import DownloadIcon from "../../../../assets/Chart/download.svg"
import ArrowDownBlue from "../../../../assets/Chart/arrow-down-blue.svg"
import DownloadIconWhite from "../../../../assets/Chart/download-white.svg"
import ArrowDownWhite from "../../../../assets/Chart/arrow-down-white.svg"
import { toast } from "react-toastify"
import Dropdown from "../../../../ui/Controls/Dropdown"
import Checkbox from "../../../../ui/Checkbox/Checkbox"
import useOutsideComponentClickHandler from "../../../../hooks/ClickOutsideHook"

// const CustomCheckbox = withStyles({
//     root: {
//         color: "#1A2935",
//         "&$checked": {
//             color: "#325588",
//         },
//     },
//     checked: {},
// })((props) => (
//     <Checkbox
//         color="default"
//         inputProps={{
//             id: props.label,
//         }}
//         style={{ width: 10, height: 10, padding: 0 }}
//         icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 10 }} />}
//         checkedIcon={<CheckBoxIcon style={{ fontSize: 10 }} />}
//         {...props}
//     />
// ))

const DropdownComponent = ({ cols, data, actionsState, disabled, onHourlyCsvDataTrigger }) => {
    const linkRef = useRef(null)
    const hourlyCsvLinkRef = useRef(null)
    const [state, setState] = useState({
        exportToPng: false,
        exportToCsv: false,
        exportAsHourlyCsv: false,
        exportAsHincastCsv: false,
        visible: false,
        loadingHourly: false,
    })

    let { id } = useParams()

    const {
        state: { fields },
    } = useContext(DataContext)

    const fieldName = useFieldName(fields, id)

    const chart = document.getElementsByClassName("svg-chart-container")[0]
    let width, height

    if (chart) {
        const rect = chart.getBoundingClientRect()
        width = rect.width
        height = rect.height
    }

    function formatFilename(fieldName, variableName) {
        // RegExp to remove consecutive hypens (-) and illegal filename characters
        let textRegex = new RegExp(/\B-+|\/|\*|#|%|&|{|}|\\|'|"|<|>|\?|\$|!|:|@|\||`|\+|\||=/g)
        let formatedFilename = `${String(fieldName).replaceAll(" ", "-").replaceAll(textRegex, "")}_${String(
            variableName
        )
            .toLowerCase()
            .replaceAll("_", "")
            .replace("t2m", "")}`
        return formatedFilename
    }

    const handleSubmitExport = () => {
        if (state.exportToPng) {
            downloadSVG({
                width: width * 3,
                height: height * 3,
                svg: chart,
                filename: formatFilename(fieldName, currentTab),
            })
        }

        if (state.exportToCsv) {
            linkRef.current.link.click()
        }

        // Prepare initial state
        let initialState = { visible: false }

        // If some boxes are checked, process state accordingly
        if (state.exportToCsv || state.exportToPng || state.exportAsHincastCsv || state.exportAsHourlyCsv) {
            initialState.exportToPng = false
            initialState.exportToCsv = false
            initialState.exportAsHincastCsv = false
            if (state.exportAsHourlyCsv) {
                initialState.visible = true
                initialState.loadingHourly = true
            }
        }

        // Save state
        setState({
            ...state,
            ...initialState,
        })

        // If export as hourly data was checked
        // if (state.exportAsHourlyCsv) {
        //     // Get hourly csv data promise
        //     onHourlyCsvDataTrigger()
        //         .then((data) => {
        //             if (data.length > 0) {
        //                 hourlyCsvLinkRef.current.link.click()
        //             }
        //             // If promise was successfully fulfilled, close dropdown and download data
        //             setState({
        //                 ...state,
        //                 visible: false,
        //                 loadingHourly: false,
        //                 exportAsHourlyCsv: false,
        //                 exportToPng: false,
        //                 exportToCsv: false,
        //                 exportAsHincastCsv: false,
        //             })
        //         })
        //         .catch((data) => {
        //             // If promise was rejected, display warning and close dropdown
        //             toast.warning("Hourly data load error occured")
        //             setState({
        //                 ...state,
        //                 visible: false,
        //                 loadingHourly: false,
        //                 exportAsHourlyCsv: false,
        //                 exportToPng: false,
        //                 exportToCsv: false,
        //                 exportAsHincastCsv: false,
        //             })
        //         })
        // }
    }

    let currentTab = actionsState.currentTab

    if (actionsState.extraPrecipitationChart) {
        currentTab = "Water Budget"
    } else if (actionsState.extraHumidityChart) {
        currentTab = "Relative Humidity"
    } else if (actionsState.solarRadiationChart) {
        currentTab = "Solar Radiation"
    } else if (actionsState.growingDegreeDaysChart) {
        currentTab = "GDD"
    } else if (actionsState.currentTab === "minmax") {
        currentTab = "Min & Max Temp"
    } else if (actionsState.currentTab === "precipitation") {
        currentTab = "Precipitation"
    } else if (actionsState.currentTab === "soil") {
        currentTab = "Soil Temperature"
    } else if (actionsState.extraEvapotranspirationChart) {
        currentTab = "Evapotranspiration"
    } else if (actionsState.currentTab === "wind_speed") {
        currentTab = "Wind Speed"
    } else if (actionsState.currentTab === "soil_moisture") {
        currentTab = "Soil Moisture"
    } else if (actionsState.humidityChart) {
        currentTab = "Humidity"
    }

    function onVisibleChange() {
        setState({
            ...state,
            visible: !state.visible,
        })
    }

    const wrapperRef = useOutsideComponentClickHandler(() => setState({ ...state, visible: false }))

    return (
        <>
            <div className="tool__export-dropdown" style={{ position: "relative" }} ref={wrapperRef}>
                <button
                    id="export-now__planting-tool"
                    className={"button-dropdown" + (state.visible ? " active" : "")}
                    onClick={onVisibleChange}
                    style={{
                        backgroundColor: state.visible ? "#1C9690" : "",
                        color: state.visible ? "#fff" : "#1C9690",
                    }}
                >
                    <div className="buttonDropdownLeftSide">
                        {!state.visible ? <img src={DownloadIcon} alt="" /> : <img src={DownloadIconWhite} alt="" />}
                        Export
                    </div>
                    {!state.visible ? <img src={ArrowDownBlue} alt="" /> : <img src={ArrowDownWhite} alt="" />}
                </button>
                {state.visible && (
                    <Dropdown title="Export as" className="down left">
                        {/* <div className="control-item__content__default-options__option">Export as</div> */}
                        <div className="control-item__content__default-options__option">
                            <Checkbox
                                id="exportAsPng"
                                checked={state.exportToPng}
                                onChange={(e) => setState({ ...state, exportToPng: e.target.checked })}
                                status={(state.exportToPng && "full") || "empty"}
                            />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }} htmlFor="exportAsPng">
                                Export as{"\u00a0"}
                                <span className="dropdown-item-text-bold">Png</span>
                            </label>
                        </div>
                        <div className="control-item__content__default-options__option">
                            <Checkbox
                                id="exportAsCsv"
                                checked={state.exportToCsv}
                                onChange={(e) => setState({ ...state, exportToCsv: e.target.checked })}
                                status={(state.exportToCsv && "full") || "empty"}
                            />
                            <label style={{ paddingLeft: 10, cursor: "pointer" }} htmlFor="exportAsCsv">
                                Export as{"\u00a0"}
                                <span className="dropdown-item-text-bold">CSV</span>
                            </label>
                        </div>
                        {/* TODO: Fix Export as Hourly CSV option*/}
                        {/* {data.hourlyCsv && (
                            <div className="control-item__content__default-options__option">
                                <Checkbox
                                    id="exportAsHourlyCsv"
                                    checked={state.exportAsHourlyCsv}
                                    onChange={(e) => setState({ ...state, exportAsHourlyCsv: e.target.checked })}
                                    status={(state.exportAsHourlyCsv && "full") || "empty"}
                                />
                                <label style={{ paddingLeft: 10, cursor: "pointer" }} htmlFor="exportAsHourlyCsv">
                                    Export as{"\u00a0"}
                                    <span className="dropdown-item-text-bold">
                                        Hourly CSV {state.loadingHourly ? "(Loading...)" : ""}
                                    </span>
                                </label>
                            </div>
                        )} */}
                        <CSVLink
                            ref={linkRef}
                            data={data.csv}
                            headers={cols.csv}
                            className="hidden-link"
                            filename={`${formatFilename(fieldName, currentTab)}.csv`}
                        />
                        {data.hourlyCsv && (
                            <CSVLink
                                ref={hourlyCsvLinkRef}
                                data={data.hourlyCsv}
                                headers={cols.hourly}
                                className="hidden-link"
                                filename={`hourly_${formatFilename(fieldName, currentTab)}.csv`}
                            />
                        )}

                        <button className="export-now" onClick={handleSubmitExport}>
                            Export now
                        </button>
                    </Dropdown>
                )}
            </div>
        </>
    )
}

export default DropdownComponent
