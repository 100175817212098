import React from "react"

import "./IconButton.css"

function IconButton(props) {
    const { children, icon: IconComponent = () => null, onClick, type, fileTypesAllowed = "" } = props
    return (
        <>
            <div className="icon-button">
                {(type && type === "file" && (
                    <input
                        type="file"
                        className="icon-button__file-input"
                        onChange={onClick}
                        accept={fileTypesAllowed}
                    />
                )) || <button className="icon-button__button" onClick={onClick}></button>}
                <div className="icon-button__content">
                    <IconComponent /> {children}
                </div>
            </div>
        </>
    )
}

export default IconButton
