import React from "react"
import "./Copyright.css"
import { useTranslation } from "react-i18next";
import { capitalizeFirstCharacter, capitalizeFirstCharacterOfEachWord } from "../../helpers/wordHelper"

const Copyright = () => {
    const { t } = useTranslation();

    return (
        <div className="copyright">
            <a className="copyright-text" href="https://www.climate.ai" target="_blank" rel="noopener noreferrer">
                &#169; Climate AI
            </a>{" "}
            {new Date().getFullYear()}
            {", " + capitalizeFirstCharacterOfEachWord(t('copyright'))}
        </div>
    )
}

export default Copyright
