import React from "react"
import EditIcon from "../../../../ui/Icons/EditIcon"
import Checkbox from "../../../../ui/Checkbox/Checkbox"
import { ChevronLeft } from "@material-ui/icons"

import AddEditItem from "./AddEditItem"
import ProtectorGlass from "./ProtectorGlass"

const ListItem = ({
    itemId,
    itemInfo,
    activeItem,
    activateItem,
    saveItemInfo,
    isAddingItem,
    setIsAddingItem = () => {},
    editingItem,
    setEditingItem,
    checkedItems,
    toggleCheckedItem,
    childrenPropName,
    defaultEmptyValues = { name: "" },
    validateFunction = () => false,
    index,
    type,
    suggestions = {},
}) => {
    return (
        <>
            {editingItem === itemId ? (
                <AddEditItem
                    index={index}
                    type={type}
                    setIsActive={(isActive) => {
                        setEditingItem("")
                        setIsAddingItem(isActive)
                    }}
                    itemInfo={itemInfo}
                    saveItemInfo={saveItemInfo}
                    defaultEmptyValues={defaultEmptyValues}
                    validateFunction={validateFunction}
                    suggestions={suggestions}
                />
            ) : (
                <div className="relative">
                    {(isAddingItem || editingItem !== "") && <ProtectorGlass />}
                    <div
                        className={
                            "group pl-5 pr-4 hover:bg-gray-100 flex flex-row items-center cursor-pointer" +
                            (activeItem === itemId ? " bg-gray-100 hover:bg-gray-100" : "")
                        }
                        onClick={activateItem}
                    >
                        <div
                            className={
                                "h-16 flex flex-row items-center border-b-2 border-transparent group-hover:visible" +
                                (Object.keys(checkedItems).length > 0 ? " visible" : " invisible")
                            }
                        >
                            <Checkbox
                                backgroundColor="#666D74"
                                checked={checkedItems.hasOwnProperty(itemId)}
                                status={checkedItems.hasOwnProperty(itemId) ? "full" : "empty"}
                                onChange={() => {
                                    toggleCheckedItem(itemId)
                                }}
                            />
                        </div>
                        <div className="h-16 ml-5 flex-grow flex flex-row items-center justify-between border-b-2 border-gray-100">
                            <div className="flex-grow">
                                <span>
                                    {index !== undefined ? `${index}. ` : ""}
                                    {itemInfo.name}
                                </span>
                                {index !== undefined && (
                                    <span className="block text-sm text-gray-400">
                                        {itemInfo.days} {type === "gdds" ? "GDDs" : "days"} from{" "}
                                        {index === 1 ? "planting day" : "past stage"}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-row items-center space-x-3">
                                {Object.keys(checkedItems).length === 0 && (
                                    <span
                                        className={
                                            "hidden cursor-pointer group-hover:block h-5 w-[20px]" +
                                            (index !== undefined ? " mr-6" : "")
                                        }
                                        onClick={() => {
                                            setEditingItem(itemId)
                                        }}
                                    >
                                        <EditIcon fill="#666D74" />
                                    </span>
                                )}
                                {index === undefined &&
                                    (activeItem === itemId ? (
                                        <span className="transform rotate-180 text-gray-400">
                                            <ChevronLeft />
                                        </span>
                                    ) : (
                                        <span className="text-gray-300 px-2">
                                            {Object.keys(itemInfo[childrenPropName]).length === 0 &&
                                            itemInfo.type === ""
                                                ? "-"
                                                : Object.keys(itemInfo[childrenPropName]).length}
                                        </span>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ListItem
