import { Switch, Redirect } from "react-router-dom"

// Views
import Dashboard from "../components/Dashboard"
import { Weather, PlantingToolV2, ProfileSettings, TableSettings } from "../views"

// Components
import { RouteWithLayout, TopBar } from "../components"

// Layouts
import { ConsolidatedLayout } from "../layouts"
import PrivateRoute from "../Util/PrivateRoute"
import { AlertSettings } from "../views/Settings"
import CropVarietySettings from "../views/Settings/CropVarietySettings/CropVarietySettings"
import { AuthContext } from "../Auth/Auth"
import { useContext } from "react"

function WeatherRouter(props) {
    const PROFILE_OPTIONS = [
        {
            id: "profile-settings__user-profile",
            label: "Profile settings",
            to: "/weather/profile-settings",
        },
        {
            id: "alert-settings__user-profile",
            label: "Alerts settings",
            to: "/weather/alerts-settings-individual",
        },
        {
            id: "table-settings__user-profile",
            label: "Table settings",
            to: "/weather/table-settings",
        },
        {
            id: "crop-settings__user-profile",
            label: "Crop & Variety settings",
            to: "/weather/crop-settings",
        },
    ]

    const { currentUser } = useContext(AuthContext)
    if (!currentUser) return <Redirect to="/" />

    return (
        <div className="flex flex-col h-full">
            <TopBar profileOptions={PROFILE_OPTIONS} />
            <div className="grow overflow-auto">
                <Switch>
                    <PrivateRoute exact path="/weather/dashboard" component={Dashboard} />
                    <RouteWithLayout
                        requiresPermission={false}
                        component={ProfileSettings}
                        exact
                        layout={ConsolidatedLayout}
                        path="/weather/profile-settings"
                        title="Profile Settings"
                        settings
                    />
                    <RouteWithLayout
                        requiresPermission={false}
                        component={TableSettings}
                        exact
                        layout={ConsolidatedLayout}
                        path="/weather/table-settings"
                        title="Table Settings"
                        settings
                    />
                    <RouteWithLayout
                        requiresPermission={false}
                        component={AlertSettings}
                        exact
                        layout={ConsolidatedLayout}
                        path="/weather/alerts-settings-individual"
                        title="Individual Alerts Settings"
                        settings
                    />
                    <RouteWithLayout
                        requiresPermission={false}
                        component={AlertSettings}
                        exact
                        layout={ConsolidatedLayout}
                        path="/weather/alerts-settings-stacked"
                        stacked={true}
                        title="Stacked Alerts Settings"
                        settings
                    />
                    <RouteWithLayout
                        requiresPermission={false}
                        component={CropVarietySettings}
                        exact
                        layout={ConsolidatedLayout}
                        path="/weather/crop-settings"
                        title="Crop & Variety Settings"
                        settings
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={Weather}
                        permission="weather"
                        exact
                        layout={ConsolidatedLayout}
                        title={"Weather Model"}
                        navItem="dashboards"
                        path="/weather/:id"
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={PlantingToolV2}
                        permission="planting_tool"
                        title="Planting Tool"
                        exact
                        layout={ConsolidatedLayout}
                        navItem="tools"
                        config={{ tabName: "planting_tool" }}
                        path="/weather/:id/planting_tool"
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={PlantingToolV2}
                        permission="harvesting_tool"
                        title="Harvesting Tool"
                        exact
                        layout={ConsolidatedLayout}
                        navItem="tools"
                        config={{ tabName: "harvesting_tool" }}
                        path="/weather/:id/harvesting_tool"
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={PlantingToolV2}
                        permission="spraying_tool"
                        title="Spraying Tool"
                        exact
                        layout={ConsolidatedLayout}
                        navItem="tools"
                        config={{ tabName: "spraying_tool" }}
                        path="/weather/:id/spraying_tool"
                    />
                    <RouteWithLayout
                        requiresPermission={true}
                        component={PlantingToolV2}
                        permission="nitrogen_application_tool"
                        title="Nitrogen Application Tool"
                        exact
                        layout={ConsolidatedLayout}
                        navItem="tools"
                        config={{ tabName: "nitrogen_application_tool" }}
                        path="/weather/:id/nitrogen_application_tool"
                    />
                    <Redirect to="/weather/dashboard" />
                </Switch>
            </div>
        </div>
    )
}

export default WeatherRouter
