import React, { useEffect, useMemo, useState } from "react"

import "react-datepicker/dist/react-datepicker.css"

import Select from "../../../ui/Select/Select"
import Section from "../../../ui/Section"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"
import useContainerSize from "../../../hooks/useContainerSize"
import MapPinIcon from "../../../ui/Icons/MapPinIcon"
import LoadingBar from "../../../components/LoadingBar"
import getVariableIcon from "../../../Util/getVariableIcon"

function HistoricalChart(props) {
    const {
        loading,
        data,
        children,
        locations,
        scenarios,
        variables,
        selectedLocation,
        selectedScenario,
        selectedVariable,
        setSelectedLocation,
        setSelectedScenario,
        setSelectedVariable,
    } = props

    const [containerRef, { height }] = useContainerSize(1000)

    const selectedVariableObj = variables.find(({ value }) => value === selectedVariable)

    let labelY =
        selectedVariableObj.label + " " + ((selectedVariableObj.units && `(${selectedVariableObj.units})`) || "")
    let tickFormat = selectedVariableObj.units || ""
    const yTickFormat = (d) => {
        // return selectedVariableObj.units || ""
        return ["gdd", "rh", "ch", "tp"].includes(selectedVariable) && d < 0 ? "" : d
    }

    return (
        <>
            <Section
                title="ClimateAi Forecast vs Public Data Providers"
                description="This page displays ClimateAi's forecast performance against the raw IPCC climate model output for a single location. Accuracy is measured using the Continuous Ranked Probability Score (CRPS) which can be interpreted as the average error of our forecasted probabilities. Uncertainty is measured using the standard deviation. In both cases lower values are better."
                header={() =>
                    data &&
                    data[0] &&
                    data[0].points &&
                    data[0].points.length && (
                        <>
                            <Select
                                icon={MapPinIcon}
                                value={selectedLocation || ""}
                                onChange={(e) => setSelectedLocation(e.target.value)}
                            >
                                {locations &&
                                    locations.map((location) => (
                                        <option value={location.name} key={`analogs_location_${location.name}`}>
                                            {location.name}
                                        </option>
                                    ))}
                            </Select>
                            <Select
                                value={selectedVariable || ""}
                                icon={() => getVariableIcon(selectedVariable)}
                                onChange={(e) => setSelectedVariable(e.target.value)}
                            >
                                {variables &&
                                    variables
                                        .filter((v) => !["gdd", "ch"].includes(v.value))
                                        .map((v) => (
                                            <option value={v.value} key={`portfolio_var_${v.value}`}>
                                                {v.label}
                                            </option>
                                        ))}
                            </Select>
                            <Select
                                value={selectedScenario || ""}
                                onChange={(e) => setSelectedScenario(e.target.value)}
                            >
                                {scenarios.map((scenario) => (
                                    <option value={scenario.value} key={`portfolio_scenario_${scenario.value}`}>
                                        {scenario.label}
                                    </option>
                                ))}
                            </Select>
                        </>
                    )
                }
            >
                {loading && <LoadingBar loading />}
                {children}
                <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
                    <LineAreaChartComponent
                        labelY={labelY}
                        xTicksCount={8}
                        yTicksCount={5}
                        xDateMin={new Date(2009, 0, 1)}
                        xDateMax={new Date(2020, 0, 1)}
                        yTickFormat={yTickFormat}
                        zeroBasis={false}
                        // centerTicks={true}
                        candlePadding={0}
                        labelYOffsetX={-10}
                        candlestickGroupMargin={30}
                        candleTopBottomLines={true}
                        maxCandlestickWidth={50}
                        yBottomOffset={0.2}
                        // yTopOffset={0.2}
                        marginLeft={100}
                        svgHeight={(height && height - 10) || 0}
                        tooltip={(EVENT, { key, values, colors }, state) => {
                            return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                    <tr><td style="font-weight:bold;font-size:20px" rowspan="${
                        values.length
                    }"><div style="text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(
                                undefined,
                                {
                                    year: "numeric",
                                }
                            )}</div></td> 
                        <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:3px;background-color:${
                            colors[0]
                        };margin-top:-10px;border-radius:5px;"></div>${
                                Math.round(values[0] * 1000) / 1000
                            }${tickFormat}</td>
                    </tr>
                    ${values
                        .filter((d, i) => i > 0)
                        .map((value, i) => {
                            return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:3px;background-color:${
                                colors[i + 1]
                            };margin-top:-10px;border-radius:5px;"></div>${Math.round(value * 1000) / 1000}%</td></tr>`
                        })
                        .join("")}
                   
                    </table>`
                        }}
                        shapeTip={(e, d, arr) => {
                            return `
                            <table>
                            <tr><th style="text-align:right">95th</th><td>${
                                d.y1 && d.y1.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">75th</th><td>${
                                d.yMax && d.yMax.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">50th</th><td>${
                                d.yMid && d.yMid.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">25th</th><td>${
                                d.yMin && d.yMin.toFixed(2) + " " + tickFormat
                            }</td></tr>
                            <tr><th style="text-align:right">5th</th><td>${
                                d.y0 && d.y0.toFixed(2) + " " + tickFormat
                            }</td></tr>
                          </table>
                    `
                        }}
                        data={data}
                    ></LineAreaChartComponent>
                </div>
            </Section>
        </>
    )
}

export default HistoricalChart
