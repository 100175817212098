import "react-datepicker/dist/react-datepicker.css"

import Select from "../../../ui/Select/Select"
import { MapboxBasicLayersComponent } from "../../../components/Charts/MapboxMaps/MapboxBasicLayersComponent/MapboxBasicLayersComponent"
import CalendarIcon from "../../../ui/Icons/CalendarIcon"
import Section from "../../../ui/Section"
import LoadingBar from "../../../components/LoadingBar"

import { useHistory } from "react-router-dom"
import useContainerSize from "../../../hooks/useContainerSize"

function OverviewMap(props) {
    const {
        pathname,
        showMap,
        loading,
        data,
        tableData,
        children,
        crops,
        selectedCrop,
        setSelectedCrop,
        decades,
        selectedDecade,
        setSelectedDecade,
        scenarios,
        selectedScenario,
        setSelectedScenario,
        setSelectedLocation,
    } = props

    const history = useHistory()

    /**
     * Heat Stress risk_heat_stress
     * Cold Stress risk_cold_stress
     * Low Humidity risk_low_humidity
     * High Humidity risk_high_humidity
     */

    const [containerRef, { height: containerHeight }] = useContainerSize(300, [])

    return (
        <Section
            title="Climate Impact per Location"
            description="This page contains a complete overview of your location's risks and yield impact for the decade that you have selected."
            header={
                () => (
                    // !loading && (
                    <>
                        {/* <Select
                            icon={CropIcon}
                            value={selectedCrop || ""}
                            onChange={(e) => setSelectedCrop(e.target.value)}
                        >
                            {crops.map((crop) => (
                                <option value={crop} key={`overview_crop_${crop}`}>
                                    {capitalizeFirstCharacter(crop)}
                                </option>
                            ))}
                        </Select> */}
                        <Select
                            icon={CalendarIcon}
                            value={selectedDecade || ""}
                            onChange={(e) => setSelectedDecade(e.target.value)}
                        >
                            {decades.map((decade) => (
                                <option value={decade.value} key={`overview_decade_${decade.value}`}>
                                    {decade.label}
                                </option>
                            ))}
                        </Select>
                        <Select value={selectedScenario || ""} onChange={(e) => setSelectedScenario(e.target.value)}>
                            {scenarios.map((scenario) => (
                                <option value={scenario.value} key={`overview_scenario_${scenario.value}`}>
                                    {scenario.label}
                                </option>
                            ))}
                        </Select>
                    </>
                )
                // )
            }
        >
            {loading && <LoadingBar loading />}
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ width: "100%", height: "60%" }} ref={containerRef}>
                    <div style={{ width: "100%", height: "100%", position: "absolute" }}>
                        {(showMap && (
                            <MapboxBasicLayersComponent
                                geojson={data || { type: "FeatureCollection", features: [] }}
                                satellite={false}
                                width="100%"
                                height={containerHeight}
                                zoomControls
                                onMarkerClick={(d) => {
                                    setSelectedLocation(d.properties.label)
                                    history.push(pathname)
                                }}
                            ></MapboxBasicLayersComponent>
                        )) ||
                            null}
                    </div>
                </div>
                <div style={{ width: "100%", height: "40%" }}>{children}</div>
            </div>
        </Section>
    )
}

export default OverviewMap
