import React, { Children, useState, useReducer, useRef, useEffect, useCallback, useMemo } from "react"

import "./Table.css"

import _ from "lodash"

import TableContent from "./TableContent"
//
import TableContext from "./TableContext"

// reducer
import TableReducer from "./TableReducer"

//
import { transformData, getRowsFromGroups, groupData } from "../../helpers/table"
import useTableShadow from "../../hooks/useTableShadow"

const REMEMBERED_GROUPING = "MainTableGrouping"

const getInitialGroupingState = () => {
    const rg = localStorage.getItem(REMEMBERED_GROUPING)
    return rg?.length > 1
        ? rg.split(",").map((gb) => {
              return {
                  propName: gb,
                  displayName: gb.charAt(0).toUpperCase() + gb.slice(1),
              }
          })
        : []
}
const initialState = {
    // groupBy: [{ propName: "region", displayName: "Region" }],
    groupBy: getInitialGroupingState(),
    sortBy: "",
    sortFunctionName: "",
    inputValue: "",
    rows: 10,
    currentPage: 1,
    openPaths: {},
    selected: [],
    selectedLabels: [],
    // uuids: [],
}

function Table(props) {
    //
    const {
        metaColumns = [],
        children,
        data = [],
        onSelectionChanged = () => null,
        onRowAdd = () => null,
        onRowUpdate = () => null,
        onRowDelete = () => null,
        onDataSourceChange = () => null,
        showDataSourceDropdown,
        regionalViewMapVisibleSetting,
        onRemoveLabelFromField,
        highlightByColumn,
        units,
    } = props

    // state
    const [state, dispatch] = useReducer(TableReducer, initialState)
    const { groupBy, sortBy, sortFunctionName, inputValue, rows, currentPage, openPaths, selected, selectedLabels } =
        state

    useMemo(() => {
        onSelectionChanged(state.selected)
    }, [state.selected])

    const childrenArray = Children.toArray(children)

    const columns = childrenArray.map((child) => {
        // TODO detect if child is a component from TableColumn

        if (child && child.props) {
            let _groupColumns = []
            if (child.props.groupColumns && typeof child.props.groupColumns === "function") {
                _groupColumns = child.props.groupColumns(child.props)
            }

            return { ...child.props, groupFields: _groupColumns }
        }
    })

    // Table shadow
    // const { shadow, horizontalScrollHandler, shadowWidth } = useTableShadow()

    const inputRef = useRef(null)

    function handleHeaderClick(propName) {
        dispatch({
            type: "SET_SORT_BY",
            payload: { sortBy: propName },
        })
    }

    function handleRowSelect(rowData) {
        dispatch({
            type: "ADD_SELECTED",
            payload: { items: rowData.__group ? getRowsFromGroups(rowData.data) : [rowData] },
        })
    }

    function handleRowDeselect(rowData) {
        if (rowData.__group) {
            dispatch({
                type: "REMOVE_SELECTED_BY_PROPNAME",
                payload: { propName: rowData.__propName, value: rowData[rowData.__propName] },
            })
        } else {
            dispatch({ type: "REMOVE_SELECTED", payload: { deselected: rowData } })
        }
    }

    function handleRowOpen(rowData, path) {
        // console.log("select")
        dispatch({ type: "ADD_OPEN_PATH", payload: { path, rowData } })
    }

    function handleRowClose(_, path) {
        // console.log("deselect")
        dispatch({ type: "REMOVE_OPEN_PATH", payload: { path } })
    }

    // input handling
    const debounceInputChange = _.debounce((e) => {
        dispatch({ type: "INPUT_CHANGE", payload: { inputValue: e.target.value } })
    }, 500)

    const handleSearchBarInputChange = useCallback(
        (e) => {
            e.persist()
            debounceInputChange(e)
        },
        [debounceInputChange]
    )

    function handleSearchBarButtonClick(e) {
        dispatch({ type: "INPUT_CHANGE", payload: { inputValue: "" } })
        inputRef.current.value = ""
    }

    let displayData = transformData(
        data,
        groupBy,
        sortBy,
        sortFunctionName,
        inputValue,
        rows,
        currentPage,
        columns,
        selectedLabels
    )

    // Define a state variable to check whether checkAll checkbox was checked once
    const [checkedOnce, setCheckedOnce] = useState(false)
    useEffect(() => {
        // Listen for data changes and if data found and checkbox is not checked at least once
        if (data.length > 0) {
            if (!checkedOnce) {
                // Set checked flag
                setCheckedOnce(true)

                // Get currently displayed data
                let currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)

                // Dispatch check_all event (which checks `check all` checkbox)
                dispatch({
                    type: "ADD_SELECTED_BY_ROWS",
                    payload: { items: currentDisplayedData, data, rows, currentPage, groupBy },
                })
            }
        }
    }, [data])

    function handleCheckAllRows(e) {
        let currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)

        if (e.target.checked) {
            dispatch({
                type: "ADD_SELECTED_BY_ROWS",
                payload: { items: currentDisplayedData, data, rows, currentPage, groupBy },
            })
        } else {
            dispatch({
                type: "REMOVE_SELECTED_BY_ROWS",
                payload: { items: currentDisplayedData, rows, currentPage, groupBy },
            })
        }
    }

    // checkbox logic
    let checked = false
    let checkedStatus = "empty"

    let _currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)
    const groupedSelected = groupData(selected, groupBy)

    const _propName = (groupBy[0] && groupBy[0].propName) || "uuid"
    const hashed = {}

    for (let _currentItem of _currentDisplayedData) hashed[_currentItem[_propName]] = false
    for (let _selectItem of groupedSelected)
        if (hashed[_selectItem[_propName]] === false) hashed[_selectItem[_propName]] = true

    let c = Object.values(hashed).reduce((prev, curr) => (curr ? prev + 1 : prev), 0)

    checked = c > 0 && c <= _currentDisplayedData.length
    checkedStatus = c === 0 ? "empty" : c > 0 && c < _currentDisplayedData.length ? "half" : "full"

    return (
        <>
            <TableContext.Provider
                value={{
                    // TableItem
                    onRowAdd,
                    onRowDelete,
                    onRowUpdate,
                    onDataSourceChange,
                    onRowOpen: handleRowOpen,
                    onRowClose: handleRowClose,
                    onRowSelect: handleRowSelect,
                    onRowDeselect: handleRowDeselect,
                    onRemoveLabelFromField,
                    openPaths,
                    units,
                    showDataSourceDropdown,
                    highlightByColumn,
                    // TableControls
                    selected,
                    checked,
                    handleCheckAllRows,
                    checkedStatus,
                    handleSearchBarButtonClick,
                    handleSearchBarInputChange,
                }}
            >
                <div className="table__scroll-container">
                    <div className="table simple-table">
                        <div className="table__container">
                            <div style={{ width: "100%", position: "relative" }}>
                                <table className="table__content root">
                                    <TableContent
                                        displayData={displayData}
                                        fields={columns}
                                        metaFields={metaColumns}
                                        rows={rows}
                                        currentPage={currentPage}
                                        sortBy={sortBy}
                                        groupBy={groupBy}
                                        sortFunctionName={sortFunctionName}
                                        onHeaderClick={handleHeaderClick}
                                        selected={groupedSelected}
                                        root
                                    />
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </TableContext.Provider>
        </>
    )
}

export default React.memo(Table)
