import { useState } from "react"
import useOutsideComponentClickHandler from "../../hooks/ClickOutsideHook"
import ArrowLeft from "../Icons/ArrowLeft"

import "../Select/Select.css"
import "./Menu.css"

function Menu(props) {
    const { children, icon: IconComponent, text, className } = props
    const [open, toggle] = useState(false)

    const ref = useOutsideComponentClickHandler(() => open && toggle(false))

    return (
        <>
            <div className={"menu" + ((className && " " + className) || "")} ref={ref}>
                <div className="select" style={{ width: "100%" }} onClick={() => toggle(!open)}>
                    {IconComponent && (
                        <div className="select__icon">
                            <IconComponent fill="#666d74" />
                        </div>
                    )}
                    <div className="select__value">{text}</div>
                    <div className="select__arrow">
                        <ArrowLeft />
                    </div>
                </div>
                {open && (
                    <div className="menu__children" onClick={() => toggle(false)}>
                        {children}
                    </div>
                )}
            </div>
        </>
    )
}

export default Menu
