// Planting Date
export const plantingDateField = {
    propName: "plantingDateP",
    displayName: "Planting Date",
    form: true,
    type: "date",
}

// Plant Stage
export const plantStageField = {
    propName: "plantStage",
    displayName: "Plant Stage",
    form: true,
    type: "string",
}

// Yield Forecast
export const yieldForecastField = {
    propName: "yieldForecast",
    displayName: "Yield Forecast",
    form: true,
    type: "string",
}

// Some notes lol "where is potato?"
export const notesField = {
    propName: "notes",
    displayName: "Notes",
    form: true,
    type: "string",
}

export const temperatureField = {
    propName: "temperature",
    displayName: "Tempera",
    form: true,
    type: "string",
}

export const temporaryYieldsForHazeraFields = {
    "Golan - North": {
        yield: "-",
        vsPlan: 0,
    },
    "Golan - South ": {
        yield: "482",
        vsPlan: -9,
    },
    "Harod Valley": {
        yield: "402",
        vsPlan: -30,
    },
    "Upper Galilee": {
        yield: "125",
        vsPlan: -58,
    },
    "Lower Galilee - North": {
        yield: "-",
        vsPlan: 0,
    },
    "Lower Galilee - South": {
        yield: "502",
        vsPlan: -3,
    },
}
