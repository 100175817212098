import React, { useState } from "react"

const CropConfirmationModal = ({ info, setConfirmationModal }) => {
    return (
        <div className="z-full fixed inset-0 flex flex-row justify-center items-center">
            <div className="z-40 opacity-20 bg-gray-90 absolute inset-0"></div>
            <div className="z-50 bg-white rounded-lg w-full max-w-sm min-h-64 p-6 flex flex-col items-stretch justify-between">
                <div>
                    <h6 className="text-2xl montserrat text-gray-800 font-semibold">{info.title}</h6>
                    <p className="roboto text-sm text-gray-400 mt-4">{info.implications}</p>
                </div>
                <div className="flex flex-row items-center self-stretch space-x-3 mt-12">
                    <button
                        className="flex-1 border-2 border-accent text-accent p-1 rounded-md font-semibold hover:text-accent-dark hover:border-accent-dark"
                        onClick={() => {
                            setConfirmationModal({ active: false })
                            if (info.hasOwnProperty("cancelFunction")) {
                                info.cancelFunction()
                            }
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="flex-1 border-2 border-accent bg-accent text-white p-1 rounded-md font-semibold hover:bg-accent-dark hover:border-accent-dark"
                        onClick={() => {
                            setConfirmationModal({ active: false })
                            if (info.hasOwnProperty("nextFunction")) {
                                info.nextFunction()
                            }
                        }}
                    >
                        {info.nextText}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CropConfirmationModal
