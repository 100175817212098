import * as React from "react"

const PortfolioIcon = (props) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 8a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8Zm-2 11V8H4v11h16Z"
            fill={props.fill}
        />
        <path
            d="M14 2a2 2 0 0 1 2 2v2h-2V4h-4v2H8V4a2 2 0 0 1 2-2h4ZM15 10h2v7h-2v-7ZM11 12h2v5h-2zM7 14h2v3H7z"
            fill={props.fill}
        />
    </svg>
)

export default PortfolioIcon
