import CropTable from "../CropTable"
import "./Dashboard.css"

export default function Dashboard() {
    return (
        <main className="dashboard">
            <div className="dashboard__table lg:pt-4 lg:px-4">
                <div className="dashboard__crop-table">
                    <CropTable />
                </div>
            </div>
        </main>
    )
}
