import { Sidebar } from "../../ui/Sidebar"
import GenericPageHeader from "../GenericPageHeader"

import useFieldName from "../../hooks/useFieldName"

function ConsolidatedLayout(props) {
    const { children, title = "", settings } = props
    const fieldName = useFieldName()

    return (
        <>
            {/* VIEW CONTAINER  */}
            <div className="grow flex flex-row w-full h-full text-gray-90 roboto">
                {/* SIDENAV  */}
                <Sidebar settings={settings} weather />

                {/* REPORT */}
                <div
                    className={
                        "bg-gray-5 grow px-[14px] overflow-x-hidden overflow-y-visible" + (title == "" ? " pt-6" : "")
                    }
                >
                    {title && title != "" && <GenericPageHeader pageTitle={title} pageSubtitle={fieldName} />}
                    {children}
                </div>
            </div>
        </>
    )
}

export default ConsolidatedLayout
