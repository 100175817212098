import * as React from "react"

function InsufficientHighTemperatureIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M23.53 1.594l-.414 7.799c-.03.799-.811 1.306-1.401.934L19.9 9.113l-3.335 6.1c-.169.308-.446.567-.752.666l-6.19 2.012-3.528 6.415c-.375.686-1.104.923-1.67.543-.568-.38-.716-1.204-.342-1.89l3.786-6.922c.168-.308.446-.567.752-.667l6.213-2.019 3.055-5.585-1.814-1.214c-.566-.38-.523-1.38.098-1.807L22.226.72c.625-.4 1.334.074 1.303.873z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default InsufficientHighTemperatureIcon
