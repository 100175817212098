import React from "react"
import PopoverTarget from "./PopoverTarget"
import "./Popover.css"

// NOTE: This is a variation of Popover.js, just to add different styling and make it more extendable without messing the styling of the already used one
// TODO: Remove this later, implement a fully extendable popover

function SimplePopover({ text, text2="", targetId, children, className="" }) {
    return (
        <>
            <div className="popover__container">
                <PopoverTarget targetId={targetId}>{children}</PopoverTarget>
                <div className={`simple-popover ${className}`}>
                    <div className="simple-popover__label">
                        <div className="simple-popover__tip"></div>
                        <div className="simple-popover__primary-text">{text}</div>
                        {text2 !== "" && (
                            <div className="simple-popover__secondary-text">{text2}</div>
                        )}    
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default SimplePopover
