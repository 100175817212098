import React from "react"

function ChillHoursIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.17 13H2v-2h4.17L2.93 7.76l1.41-1.42L9 11h2V9L6.34 4.34l1.42-1.41L11 6.17V2h2v20h-2v-4.17l-3.24 3.24-1.42-1.41L11 15v-2H9l-4.66 4.66-1.41-1.42L6.17 13Z"
                    fill="#666D74"
                />
                <path
                    d="M15 21.542c4.057-1.274 7-5.064 7-9.542s-2.943-8.268-7-9.542V4.58c.694.281 1.34.657 1.92 1.11L15.491 7.12c.546.39 1.023.87 1.41 1.418l1.43-1.43A7.96 7.96 0 0 1 19.938 11h-2.021a6.036 6.036 0 0 1 0 2h2.021a7.96 7.96 0 0 1-1.618 3.906l-1.43-1.43a6.028 6.028 0 0 1-1.414 1.415l1.43 1.429A7.99 7.99 0 0 1 15 19.419v2.123Z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default ChillHoursIcon
