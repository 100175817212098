import React, { useState, useEffect, useContext } from "react"
import ReportDownloadButton from "../LongTerm/components/ReportDownloadButton"
import DepartureAside from "./components/DepartureAside"

import DepartureChart from "./components/DepartureChart"

import { useLocation, useParams } from "react-router-dom"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import useDepartureData from "../../hooks/useDepartureData"

function Departure(props) {
    const { search } = useLocation()

    const { reportId } = useParams()

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState({})
    const [pastq, setPastq] = useState(0.1)
    const [futureq, setFutureq] = useState(0.5)

    const {
        ready,
        locations,
        crops,
        scenarios,
        variables,
        risks,
        selectedLocation,
        selectedCrop,
        selectedScenario,
        selectedVariable,
        selectedRisk,
        setSelectedLocation,
        setSelectedCrop,
        setSelectedScenario,
        setSelectedVariable,
        setSelectedRisk,
        variablesByDefault,
    } = useAdaptationValues()

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?scenario=${selectedScenario}&location=${selectedLocation}&var=${selectedVariable}`

        const request = http.get(`/api/v1/adapt/${reportId}/departure${query}`, { timeout: 180000 })
        request
            .then((response) => {
                // thresholds fix
                const result = {}
                for (let key in response.data.threshold) {
                    const value = response.data.threshold[key]
                    const [pq, fq] = JSON.parse(key)
                    result[JSON.stringify([Math.round(pq * 100) / 100, Math.round(fq * 100) / 100])] = value
                }

                setData({ x: response.data.x, y: response.data.y, threshold: result })
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedLocation, selectedCrop, selectedVariable, selectedScenario])

    const { chartData, threshold } = useDepartureData(data, pastq, futureq)

    const selectedVariableObj = variables.find(({ value }) => value === selectedVariable)
    const selectedScenarioObj = scenarios.find((s) => s.value === selectedScenario)

    return (
        <>
            <ReportDownloadButton
                text={`Climate Tipping Point / ${selectedLocation} / ${selectedVariableObj.label} / ${selectedScenarioObj.label}`}
            />
            <div className="overview">
                <div className="overview__main">
                    <DepartureChart
                        loading={loading}
                        data={chartData}
                        threshold={threshold}
                        loading={loading}
                        locations={locations}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        crops={crops}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        variables={variables}
                        selectedVariable={selectedVariable}
                        setSelectedVariable={setSelectedVariable}
                        risks={risks}
                        selectedRisk={selectedRisk}
                        setSelectedRisk={setSelectedRisk}
                        scenarios={scenarios}
                        selectedScenario={selectedScenario}
                        setSelectedScenario={setSelectedScenario}
                    />
                </div>
                <div className="overview__aside">
                    <DepartureAside
                        loading={loading}
                        threshold={threshold}
                        futureq={futureq}
                        pastq={pastq}
                        setFutureq={setFutureq}
                        setPastq={setPastq}
                    />
                </div>
            </div>
        </>
    )
}

export default Departure
