import { ArrowRight } from "@material-ui/icons"
import { useState, useEffect, useRef, useMemo } from "react"

const AddEditItem = ({
    index,
    itemInfo,
    setIsActive,
    saveItemInfo,
    defaultEmptyValues = { name: "", default_id: null },
    validateFunction = () => false,
    type = null,
    suggestions = {},
}) => {
    const [item, setItem] = useState(defaultEmptyValues)
    const [doShowSuggestions, setDoShowSuggestions] = useState(false)

    const inputRef = useRef(null)
    const saveButtonRef = useRef(null)
    useEffect(() => {
        const funct = (key) => {
            if (key.code === "Enter") {
                saveButtonRef.current.focus()
                saveButtonRef.current.click()
            } else if (key.code === "Escape") {
                setIsActive(false)
            }
        }
        window.addEventListener("keyup", funct)
        return () => {
            window.removeEventListener("keyup", funct)
        }
    }, [])

    useEffect(() => {
        if (itemInfo !== null) {
            setItem({ ...itemInfo })
        }
        inputRef.current.focus()
    }, [itemInfo])

    const actualSuggestions = useMemo(() => {
        let suggest = {}
        if (item.name !== "" && index === undefined) {
            let showSuggestions = false
            Object.keys(suggestions).forEach((key) => {
                if (suggestions[key].name.toLowerCase().includes(item.name.toLowerCase())) {
                    suggest[key] = suggestions[key]
                    showSuggestions = true
                }
            })
            if (showSuggestions) setDoShowSuggestions(showSuggestions)
        } else if (item.name === "") {
            suggest = { ...suggestions }
            // setDoShowSuggestions(false)
        }
        return suggest
    }, [item, suggestions])

    const isFormValid = (optionalItem) => {
        let data = item
        if (optionalItem !== undefined) data = optionalItem
        return validateFunction(data)
    }

    const save = (optionalItem) => {
        let data = item
        if (optionalItem !== undefined) data = optionalItem
        setDoShowSuggestions(false)
        if (!isFormValid(data)) {
            return
        }
        saveItemInfo(data)
        if (index !== undefined && (index === -1 || index === 0)) {
            setItem(defaultEmptyValues)
            setIsActive(true)
            inputRef.current.focus()
        } else {
            setIsActive(false)
        }
    }

    const saveSuggested = (key) => {
        save({ ...item, name: suggestions[key].name, default_id: key })
    }

    return (
        <div
            className={
                "ml-11 pl-2 pr-6 py-4 flex flex-col space-y-2 text-gray-400 border-b-2 border-gray-100" +
                (index !== undefined ? " text-xs items-end" : " text-sm items-stretch")
            }
        >
            {index !== undefined ? (
                <>
                    <div className="flex flex-row items-center">
                        <span>Stage Name</span>
                        <input
                            ref={inputRef}
                            className="w-40 ml-4 px-2 py-1 border-2 rounded-md border-gray-300 text-gray-800 focus:outline-none focus:border-accent"
                            value={item.name}
                            onChange={(event) => {
                                setItem({ ...item, name: event.target.value })
                            }}
                        ></input>
                    </div>
                    <div className="flex flex-row items-center">
                        <span>
                            {type === "gdds" ? "GDDs" : "Days"} from{" "}
                            {index === 1 || index === 0 ? "planting day" : "previous stage"}
                        </span>
                        <input
                            className="w-16 ml-3 px-2 py-1 border-2 rounded-md border-gray-300 text-gray-800 focus:outline-none focus:border-accent"
                            value={item.days}
                            type="number"
                            min={0}
                            onChange={(event) => {
                                setItem({ ...item, days: event.target.value })
                            }}
                        ></input>
                    </div>
                </>
            ) : (
                <div className="relative w-full">
                    <input
                        ref={inputRef}
                        className="px-2 py-1 w-full border-2 rounded-md border-gray-300 text-gray-800 focus:outline-none focus:border-accent"
                        value={item.name}
                        onChange={(event) => {
                            setItem({ ...item, name: event.target.value, default_id: null })
                        }}
                    ></input>
                    <span
                        className="absolute right-2 inset-y-1 flex flex-row justify-center items-center cursor-pointer w-3"
                        onClick={() => setDoShowSuggestions(!doShowSuggestions)}
                    >
                        <ArrowRight className="transform rotate-90" />
                    </span>
                    {doShowSuggestions && (
                        <ul className="absolute top-full left-6 bg-white z-50 rounded-md shadow-lg w-40 p-2 border border-gray-100">
                            {Object.keys(actualSuggestions).length > 0 ? (
                                <>
                                    {Object.keys(actualSuggestions).length === Object.keys(suggestions).length ? (
                                        <li className="px-2 py-1 rounded text-gray-400 text-sm font-semibold">
                                            All suggestions
                                        </li>
                                    ) : null}
                                    {Object.keys(actualSuggestions).map((key) => {
                                        return (
                                            <li
                                                className="px-2 py-1 rounded hover:bg-gray-200 text-gray-800"
                                                onClick={() => saveSuggested(key)}
                                            >
                                                {suggestions[key].name}
                                            </li>
                                        )
                                    })}
                                </>
                            ) : (
                                <li className="px-2 py-1 rounded cursor-not-allowed">No suggestions available</li>
                            )}
                        </ul>
                    )}
                </div>
            )}

            <div className="flex flex-row items-center self-stretch space-x-3">
                <button
                    className="flex-1 border-2 border-accent text-accent p-1 rounded-md font-semibold hover:text-accent-dark hover:border-accent-dark"
                    onClick={() => {
                        setIsActive(false)
                    }}
                >
                    Cancel
                </button>
                <button
                    ref={saveButtonRef}
                    className={
                        "flex-1 border-2 text-white p-1 rounded-md font-semibold" +
                        (isFormValid()
                            ? " border-accent bg-accent hover:bg-accent-dark hover:border-accent-dark"
                            : " border-gray-300 bg-gray-300 hover:bg-gray-200 hover:border-gray-200")
                    }
                    onClick={() => {
                        save()
                    }}
                >
                    Save
                </button>
            </div>
        </div>
    )
}

export default AddEditItem
