import React from "react"

function HighChillHoursIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.17 13H2v-2h4.17L2.93 7.76l1.41-1.42L9 11h2V9L6.34 4.34l1.42-1.41L11 6.17V2h2v20h-2v-4.17l-3.24 3.24-1.42-1.41L11 15v-2H9l-4.66 4.66-1.41-1.42L6.17 13ZM15 21.542c4.057-1.274 7-5.064 7-9.542s-2.943-8.268-7-9.542V4.58a8.003 8.003 0 0 1 0 14.838v2.123Z"
                    fill="#666D74"
                />
                <path d="M15 8V2h6l-6 6Z" fill="#666D74" />
                <path d="M15 8V2h6l-6 6Z" fill="#666D74" />
            </svg>
        </>
    )
}

export default HighChillHoursIcon
