import React, { useRef, useState, useEffect } from "react"
import { useLayout } from "../../../hooks/useLayout"

import { PROPERTY_TITLES_EQUIVALENCE } from "../../../Util/CSVParser"
import { getIcon } from "../../Settings/AlertSettings/components/VarietyAlert"

import "./RiskTable.css"

const SCENARIO_COLUMN_WIDTH = 150
const CLIMATE_RISK_COLUMN_WIDTH = 150
const RISK_COLUMN_WIDTH = 240

function RiskTable({ title = "Climate Risk per Scenario", columns = [], data = [], loading }) {
    const [adjustSize, setAdjustSize] = useState(false)
    const adjustSizeRef = useRef(false)

    columns
        .filter((col) => Object.keys(data).find((key) => data[key][col.propName]))
        .forEach((col) => (col.available = true))

    columns.sort(({ available: a }, { available: b }) => (a && !b ? -1 : !a && b ? 1 : 0))

    const {
        ref: contentRef,
        dimensions: { width },
    } = useLayout()

    // TODO: this logic could use a specific hook
    useEffect(() => {
        const condition =
            SCENARIO_COLUMN_WIDTH + CLIMATE_RISK_COLUMN_WIDTH + (columns.length - 2) * RISK_COLUMN_WIDTH > width

        if (adjustSizeRef.current !== condition) {
            adjustSizeRef.current = condition
            setAdjustSize(condition)
        }
    }, [columns.length, adjustSizeRef.current, width])

    return (
        <>
            <div className="risk-table">
                <div className="risk-table__title">{title}</div>
                {loading && <div className="table__loader" />}
                {!loading && (
                    <div className="risk-table__content" ref={contentRef}>
                        <table
                            className={"risk-table__table " + ((!adjustSize && " fixed-width") || "")}
                            cellSpacing={0}
                        >
                            <thead>
                                <tr>
                                    {columns &&
                                        columns.length > 0 &&
                                        columns.map((col) => {
                                            return (
                                                <th>
                                                    <div
                                                        className={`risk-table__table__header ${
                                                            (!col.available && "greyed-out") || ""
                                                        }`}
                                                    >
                                                        {(col.propName === "scenario" && " ") || (
                                                            <>
                                                                <h3 className="risk-table__table__header__title">
                                                                    {col.propName !== "climate_risk" && (
                                                                        <div className="risk-table__table__header__title__icon">
                                                                            {getIcon(col.riskKey)}
                                                                        </div>
                                                                    )}
                                                                    {(col.propName !== "climate_risk" &&
                                                                        PROPERTY_TITLES_EQUIVALENCE[col.riskKey]) ||
                                                                        "Climate Risk"}
                                                                </h3>
                                                                {col.alertName && (
                                                                    <p className="risk-table__table__header__subtitle">
                                                                        {col.alertName}
                                                                    </p>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </th>
                                            )
                                        })}
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    data.length > 0 &&
                                    data.map((row) => {
                                        return (
                                            <tr>
                                                {columns &&
                                                    columns.length > 0 &&
                                                    columns.map((col) => {
                                                        return (
                                                            <td>
                                                                <div
                                                                    className={`risk-table__table__cell ${
                                                                        (!col.available && "greyed-out") || ""
                                                                    }`}
                                                                    style={{
                                                                        color:
                                                                            (col.propName === "climate_risk" &&
                                                                                (row[col.propName] < 0.1
                                                                                    ? "#F37920"
                                                                                    : "#E00028")) ||
                                                                            undefined,
                                                                    }}
                                                                >
                                                                    {(col.propName !== "scenario" &&
                                                                        row[col.propName] &&
                                                                        (row[col.propName] * 100)
                                                                            .toFixed(2)
                                                                            .toString() + "%") ||
                                                                        row[col.propName] ||
                                                                        "0%"}
                                                                </div>
                                                            </td>
                                                        )
                                                    })}
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </>
    )
}

export default RiskTable
