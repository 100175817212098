import React, { useEffect, useMemo, useState } from "react"
import CalendarIcon from "../../../ui/Icons/CalendarIcon"
import CardItem from "./CardItem"

import { getIcon } from "../../Settings/AlertSettings/components/VarietyAlert"
import { PROPERTY_TITLES_EQUIVALENCE } from "../../../Util/CSVParser"

import SmallControls from "../../../ui/Controls/SmallControls"
import ControlItem from "../../../ui/Controls/ControlItem"
import ColorPicker from "../../../ui/Table/ColorPicker"
import ExclamationIcon from "../../../ui/Icons/ExclamationIcon"
import { alertBetweenTimePeriod, capitalizeFirstLetter } from "../../../helpers/plantingTool"
import DatePicker from "react-datepicker"

import "./ScenarioForm.css"
import { getCorrectUnitValue } from "../../../Util/Alerts"

function ScenarioForm(props) {
    const { scenario, editScenario, errors, advancedSettings, minDate, toggleAdvancedSettings, tab = "" } = props
    const [showColorPicker, toggleColorPicker] = useState(false)

    // set local state with converted thresholds
    const [scenarioFormPlaceholders, setScenarioFormPlaceholders] = useState({})
    useEffect(() => {
        const result = {}
        if (scenario.chart_data) {
            for (let riskKey in scenario.chart_data) {
                if (Object.keys(scenario.chart_data[riskKey]).length > 0) {
                    result[riskKey] = {}
                    for (let alertId in scenario.chart_data[riskKey]) {
                        if (Object.keys(scenario.chart_data[riskKey][alertId]).length > 0) {
                            result[riskKey][alertId] = { threshold: 0 }

                            // transform threshold to current units
                            result[riskKey][alertId].lag = scenario.chart_data[riskKey][alertId].lag
                            result[riskKey][alertId].weight = scenario.chart_data[riskKey][alertId].weight
                            result[riskKey][alertId].threshold = parseFloat(
                                getCorrectUnitValue(
                                    scenario.chart_data[riskKey][alertId].alert_data.default_units,
                                    "metric",
                                    scenario.chart_data[riskKey][alertId].alert_data.unit_system,
                                    scenario.chart_data[riskKey][alertId].alert_data.threshold
                                )
                            )
                        }
                    }
                }
            }
        }
        setScenarioFormPlaceholders(result)
    }, [scenario.uid])

    const alerts = useMemo(() => {
        const result = []
        if (scenario.chart_data) {
            for (let risk in scenario.chart_data) {
                for (let alert in scenario.chart_data[risk]) {
                    result.push({
                        ...scenario.chart_data[risk][alert],
                        _riskId: risk,
                        _alertId: alert,
                    })
                }
            }
        }
        return result
    }, [scenario.uid])

    // const totalRiskWeight = useMemo(() => {
    //     let result = 0
    //     alerts &&
    //         Object.keys(scenarioFormPlaceholders).length > 0 &&
    //         alerts.map((risk) => {
    //             result += scenarioFormPlaceholders[risk._riskId][risk._alertId].weight || 0
    //         })
    //     return result
    // }, [alerts, scenarioFormPlaceholders])

    return (
        <>
            <div className="scenarios__new-scenario">
                <CardItem
                    icon={
                        <>
                            <button
                                className="scenarios__new-scenario__color-picker"
                                onClick={() => toggleColorPicker(!showColorPicker)}
                            >
                                <div
                                    style={{
                                        width: 22,
                                        height: 22,
                                        backgroundColor: scenario.color || "#000000",
                                        borderRadius: 4,
                                    }}
                                ></div>
                                {showColorPicker && (
                                    <SmallControls>
                                        <ControlItem name="color_picker" title="Pick a color">
                                            <>
                                                <ColorPicker
                                                    value={scenario.color}
                                                    onColorSelect={(value) => {
                                                        editScenario("color", value)
                                                    }}
                                                />
                                                {/* <div style={{ textAlign: "right" }}>
                                                    <button className="label-form__save">Save</button>
                                                </div> */}
                                            </>
                                        </ControlItem>
                                    </SmallControls>
                                )}
                            </button>
                        </>
                    }
                    render={() => {
                        return (
                            <>
                                {(scenario.uid === "current" && scenario.name) || (
                                    <>
                                        <input
                                            type="text"
                                            onChange={(e) => editScenario("name", e.target.value)}
                                            value={scenario.name}
                                            placeholder="New scenario"
                                            className={errors.name ? "error" : ""}
                                        />
                                        <div className="scenario-form__input-errors">{errors.name}</div>
                                    </>
                                )}
                            </>
                        )
                    }}
                />
                <CardItem
                    icon={
                        <div className="scenario-form__icon">
                            <CalendarIcon />
                        </div>
                    }
                    render={() => {
                        return (
                            <>
                                <div className="scenario-form__planting">
                                    <div className="scenario-form__planting__day">
                                        {capitalizeFirstLetter(tab.split("_")[0])} Date
                                        <DatePicker
                                            showPopperArrow={false}
                                            closeOnScroll={true}
                                            selected={scenario.plantingDate}
                                            onChange={(date) => {
                                                date.setHours(0, 0, 0, 0)
                                                editScenario("plantingDate", date)
                                            }}
                                            minDate={minDate}
                                            dateFormat="MMM d, yyyy"
                                        />
                                    </div>
                                    <div className="scenario-form__planting__length">
                                        {capitalizeFirstLetter(tab.split("_")[0])} Days
                                        <input
                                            type="number"
                                            onChange={(e) => {
                                                editScenario("length", parseInt(e.target.value))
                                            }}
                                            value={scenario.length}
                                            min={14}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    }}
                />
                {scenario.uid === "current" && (
                    <CardItem
                        icon={
                            <div className="scenario-form__icon">
                                <ExclamationIcon fill="#adadad" />
                            </div>
                        }
                        render={() => (
                            <label style={{ color: "#666" }}>
                                You cannot update the values in the current scenario, please create a new one.
                            </label>
                        )}
                    />
                )}
                <div className="scenario-form__alerts">
                    {Object.keys(scenarioFormPlaceholders).length > 0 &&
                        alerts
                            .map((alert) => {
                                const endDate = new Date(scenario.plantingDate)
                                endDate.setDate(scenario.plantingDate.getDate() + scenario.length)

                                return {
                                    alert,
                                    available:
                                        alert.weightAvailable &&
                                        alertBetweenTimePeriod(alert, scenario.plantingDate, endDate),
                                }
                            })
                            .sort(({ available: a }, { available: b }) => (a && !b ? -1 : !a && b ? 1 : 0))
                            .map(({ alert: risk, available }) => {
                                const icon = getIcon(risk._riskId)
                                const riskStartDate = risk?.alert_data?.time && new Date(risk?.alert_data?.time[0])
                                const riskEndDate =
                                    risk?.alert_data?.time &&
                                    new Date(risk?.alert_data?.time[risk?.alert_data?.time.length - 1])

                                // add lag
                                if (
                                    scenario.chart_data[risk._riskId][risk._alertId].lag &&
                                    !Number.isNaN(scenario.chart_data[risk._riskId][risk._alertId].lag)
                                ) {
                                    riskStartDate.setDate(
                                        riskStartDate.getDate() - scenario.chart_data[risk._riskId][risk._alertId].lag
                                    )
                                    riskEndDate.setDate(
                                        riskEndDate.getDate() - scenario.chart_data[risk._riskId][risk._alertId].lag
                                    )
                                }

                                // const hasItemErrors =
                                //     errors["threshold-" + risk._riskId + risk._alertId] ||
                                //     errors["weight-" + risk._riskId + risk._alertId] ||
                                //     errors["lag-" + risk._riskId + risk._alertId]

                                return (
                                    <>
                                        <CardItem
                                            scrollable
                                            available={available}
                                            icon={<div className="scenario-item__risk__item__icon">{icon}</div>}
                                            render={() => {
                                                return (
                                                    <>
                                                        <div className="scenario-item__risk__name">
                                                            {PROPERTY_TITLES_EQUIVALENCE[risk._riskId]}
                                                        </div>
                                                        <div className="scenario-item__risk__name">
                                                            <div className="scenario-item__risk__alert-name__threshold-label">
                                                                {risk.alert_data.alert_name}
                                                            </div>
                                                        </div>
                                                        <div className="scenario-item__risk__alert-name">
                                                            {!Number.isNaN(
                                                                scenarioFormPlaceholders[risk._riskId][risk._alertId]
                                                                    .threshold
                                                            ) && (
                                                                <label className="scenario-item__risk__alert-name__threshold-label">
                                                                    (
                                                                    {risk.alert_data.conditional === "less_than"
                                                                        ? "< "
                                                                        : "> "}
                                                                    {
                                                                        scenarioFormPlaceholders[risk._riskId][
                                                                            risk._alertId
                                                                        ].threshold
                                                                    }{" "}
                                                                    {risk.alert_data.units}
                                                                    {/* {(
                                                                        (Number.isNaN(
                                                                            scenarioFormPlaceholders[risk._riskId][
                                                                                risk._alertId
                                                                            ].weight
                                                                        )
                                                                            ? 0
                                                                            : scenarioFormPlaceholders[risk._riskId][
                                                                                  risk._alertId
                                                                              ].weight / totalRiskWeight) * 100
                                                                    ).toFixed(2) + "%"} */}
                                                                    )
                                                                </label>
                                                            )}
                                                        </div>
                                                        <div className="scenario-item__risk__description">
                                                            {riskStartDate.toLocaleDateString(undefined, {
                                                                month: "short",
                                                                day: "numeric",
                                                            })}{" "}
                                                            -{" "}
                                                            {riskEndDate.toLocaleDateString(undefined, {
                                                                month: "short",
                                                                day: "numeric",
                                                            })}
                                                        </div>
                                                    </>
                                                )
                                            }}
                                        >
                                            <div className="scenario__risk__row">
                                                Threshold
                                                <div className="scenario__risk__container threshold">
                                                    {scenario.chart_data[risk._riskId][risk._alertId].alert_data
                                                        .conditional === "less_than"
                                                        ? "< "
                                                        : "> "}
                                                    <input
                                                        disabled={scenario.uid === "current"}
                                                        type="number"
                                                        className={
                                                            errors["threshold-" + risk._riskId + risk._alertId]
                                                                ? "error"
                                                                : ""
                                                        }
                                                        value={
                                                            scenarioFormPlaceholders[risk._riskId][risk._alertId]
                                                                .threshold
                                                        }
                                                        onChange={(e) => {
                                                            // updates local state
                                                            setScenarioFormPlaceholders({
                                                                ...scenarioFormPlaceholders,
                                                                [risk._riskId]: {
                                                                    ...scenarioFormPlaceholders[risk._riskId],
                                                                    [risk._alertId]: {
                                                                        ...scenarioFormPlaceholders[risk._riskId][
                                                                            risk._alertId
                                                                        ],
                                                                        threshold: parseFloat(e.target.value),
                                                                    },
                                                                },
                                                            })
                                                        }}
                                                        onBlur={(e) =>
                                                            // once the user is finished editing, save correct value
                                                            editScenario(
                                                                `chart_data/${risk._riskId}/${risk._alertId}/alert_data/threshold`,
                                                                parseFloat(
                                                                    getCorrectUnitValue(
                                                                        scenario.chart_data[risk._riskId][risk._alertId]
                                                                            .alert_data.default_units,
                                                                        scenario.chart_data[risk._riskId][risk._alertId]
                                                                            .alert_data.unit_system,
                                                                        "metric",
                                                                        parseFloat(e.target.value)
                                                                    )
                                                                )
                                                            )
                                                        }
                                                    />
                                                    {scenario.chart_data[risk._riskId][risk._alertId].alert_data.units}
                                                </div>
                                            </div>
                                            <div className="scenario-form__input-errors right">
                                                {errors["threshold-" + risk._riskId + risk._alertId]}
                                            </div>
                                            {/* {advancedSettings && ( */}

                                            <div className="scenario__risk__row">
                                                Importance
                                                <div className="scenario__risk__container">
                                                    <input
                                                        disabled={scenario.uid === "current" || advancedSettings}
                                                        type="number"
                                                        className={
                                                            errors["weight-" + risk._riskId + risk._alertId]
                                                                ? "error"
                                                                : ""
                                                        }
                                                        value={
                                                            scenarioFormPlaceholders[risk._riskId][risk._alertId].weight
                                                        }
                                                        onChange={(e) => {
                                                            // updates local state
                                                            setScenarioFormPlaceholders({
                                                                ...scenarioFormPlaceholders,
                                                                [risk._riskId]: {
                                                                    ...scenarioFormPlaceholders[risk._riskId],
                                                                    [risk._alertId]: {
                                                                        ...scenarioFormPlaceholders[risk._riskId][
                                                                            risk._alertId
                                                                        ],
                                                                        weight: parseFloat(e.target.value),
                                                                    },
                                                                },
                                                            })
                                                        }}
                                                        onBlur={(e) =>
                                                            // once the user is finished editing, save correct value
                                                            editScenario(
                                                                `chart_data/${risk._riskId}/${risk._alertId}/weight`,
                                                                parseFloat(e.target.value)
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="scenario-form__input-errors right">
                                                {errors["weight-" + risk._riskId + risk._alertId]}
                                            </div>
                                            <div className="scenario__risk__row">
                                                <div className="scenario__risk__container lag">
                                                    <select
                                                        disabled={scenario.uid === "current" || advancedSettings}
                                                        value={
                                                            scenario.chart_data[risk._riskId][risk._alertId].lag_label
                                                        }
                                                        onChange={(e) =>
                                                            editScenario(
                                                                `chart_data/${risk._riskId}/${risk._alertId}/lag_label`,
                                                                e.target.value
                                                            )
                                                        }
                                                    >
                                                        <option value="Days to Harvesting">Days to Harvesting</option>
                                                        <option value="Days from Planting">Days from Planting</option>
                                                    </select>
                                                </div>
                                                <div className="scenario__risk__container lag">
                                                    <input
                                                        disabled={scenario.uid === "current" || advancedSettings}
                                                        type="number"
                                                        className={
                                                            errors["lag-" + risk._riskId + risk._alertId] ? "error" : ""
                                                        }
                                                        value={
                                                            scenarioFormPlaceholders[risk._riskId][risk._alertId].lag
                                                        }
                                                        onChange={(e) => {
                                                            // updates local state
                                                            setScenarioFormPlaceholders({
                                                                ...scenarioFormPlaceholders,
                                                                [risk._riskId]: {
                                                                    ...scenarioFormPlaceholders[risk._riskId],
                                                                    [risk._alertId]: {
                                                                        ...scenarioFormPlaceholders[risk._riskId][
                                                                            risk._alertId
                                                                        ],
                                                                        lag: parseFloat(e.target.value),
                                                                    },
                                                                },
                                                            })
                                                        }}
                                                        onBlur={(e) =>
                                                            // once the user is finished editing, save correct value
                                                            editScenario(
                                                                `chart_data/${risk._riskId}/${risk._alertId}/lag`,
                                                                parseFloat(e.target.value)
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="scenario-form__input-errors right">
                                                {errors["lag-" + risk._riskId + risk._alertId]}
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    opacity: scenario.uid === "current" ? 0 : 1,
                                                    pointerEvents: scenario.uid === "current" ? "none" : "initial",
                                                }}
                                            >
                                                <button
                                                    disabled={scenario.uid === "current"}
                                                    className="scenario-form__advanced-settings"
                                                    onClick={() => toggleAdvancedSettings(!advancedSettings)}
                                                >
                                                    Show {advancedSettings ? "more" : "less"}
                                                </button>
                                            </div>
                                        </CardItem>
                                    </>
                                )
                            })}
                </div>
            </div>
        </>
    )
}

export default ScenarioForm
