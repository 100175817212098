import axios from "axios"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"

import configuration from "./config"

const API_URL = configuration.backend_server

export function makeRequest(method = "", path = "", data, config = {}) {
    // check if method is valid
    if (!(method && typeof method === "string" && ["get", "post", "put", "delete"].includes(method.toLowerCase())))
        throw new TypeError("You must provide a valid Http Method.")

    // parse body
    const _body = method.toLowerCase() !== "get" && (data || config.data) ? { data: data || config.data || {} } : {}
    // generate cancel token
    const cancelSource = axios.CancelToken.source()

    const request = new Promise((resolve, reject) => {
        // get firebase user token
        getUserToken()
            .then((userToken) => {
                // check if auth is needed
                let auth = typeof config.auth === "undefined" || config.auth
                if (auth && !userToken) throw new TypeError("User is not logged.")

                // make request
                return axios
                    .create({
                        baseURL: API_URL,
                        method: method || config.method,
                        timeout: config.timeout || 30000,
                        cancelToken: cancelSource.token,
                        headers: {
                            "Content-Type": "application/json; charset=UTF-8",
                            "Accept-Language": config.language ? config.language : "en",
                            lang: config.lang ? config.lang : "en",
                            "User-Token": userToken,
                            ...(config.headers || {}),
                        },
                    })
                    .request({ url: path, data: _body.data || {} })
            })
            .then((res) => {
                const _data = JSON.parse(JSON.stringify(res.data).replaceAll("Lelystadt", "Lelystad"))
                resolve(_data)
            })
            .catch((err) => {
                if (axios.isCancel(err)) return
                console.error(
                    `An error occurred processing the following request: ${method.toUpperCase()} ${path}`,
                    err
                )
                reject(err)
            })
    })

    request.cancel = cancelSource.cancel
    return request
}

export async function getUserToken() {
    try {
        return await firebase.auth().currentUser.getIdToken()
    } catch (e) {
        console.log(e)
        return null
    }
}

const http = {
    get(path, config) {
        // TODO remove this after DEMO
        const _path = path.replaceAll("Lelystad", "Lelystadt")
        return makeRequest("GET", _path, null, config)
    },
    post(path, data, config) {
        return makeRequest("POST", path, data, config)
    },
    put(path, data, config) {
        return makeRequest("PUT", path, data, config)
    },
    delete(path, config) {
        return makeRequest("DELETE", path, null, config)
    },
}

export default http
