import React from "react"

import SearchIcon from "../Icons/SearchIcon"
import TimesIcon from "../Icons/TimesIcon"

import "./SearchInput.css"

function SearchInput(props, ref) {
    const { onChange = () => null, onClear = () => null, value, ...rest } = props

    return (
        <>
            <div className="search-input" {...rest}>
                <div className="search-input__icon">
                    <SearchIcon />
                </div>
                <input
                    ref={ref}
                    type="text"
                    value={value}
                    className="search-input__input"
                    placeholder="Search"
                    onChange={(e) => {
                        e.persist()
                        onChange(e)
                    }}
                />
                {/* Erase button */}
                <button className="search-input__button" onClick={onClear}>
                    <TimesIcon />
                </button>
            </div>
        </>
    )
}

export default React.forwardRef(SearchInput)
