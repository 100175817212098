import React, { Children, useState, useReducer, useRef, useEffect, useCallback } from "react"

import "./Table.css"

import SearchIcon from "../Icons/SearchIcon"
import TimesIcon from "../Icons/TimesIcon"
import _ from "lodash"

import TableContent from "./TableContent"
import TableDraggableHeaders from "./TableDraggableHeaders"
import TablePagination from "./TablePagination"
//
import TableContext from "./TableContext"

// reducer
import TableReducer from "./TableReducer"

//
import { transformData, getRowsFromGroups, groupData } from "../../helpers/table"

import { SimpleModal } from "../Modal"
import ShareReportForm from "./ShareReportForm"
import TableLabelButton from "./TableLabelButton"
import LabelSelect from "./LabelSelect"

import { MapboxFieldHeatmapViewerComponent } from "../../components/Charts/MapboxMaps/MapboxFieldHeatmapViewer/MapboxFieldHeatmapViewer"
import { ToggleContainerRegionalViewMap } from "../../components"
import useTableShadow from "../../hooks/useTableShadow"
import SmallControls from "../Controls/SmallControls"
import ControlItem from "../Controls/ControlItem"
import AddLabelForm from "./AddLabelForm"
import Popover from "../Popover/Popover"

const REMEMBERED_GROUPING = "MainTableGrouping"

const getInitialGroupingState = () => {
    const rg = localStorage.getItem(REMEMBERED_GROUPING)
    return rg?.length > 1
        ? rg.split(",").map((gb) => {
              return {
                  propName: gb,
                  displayName: gb.charAt(0).toUpperCase() + gb.slice(1),
              }
          })
        : []
}
const initialState = {
    // groupBy: [{ propName: "region", displayName: "Region" }],
    groupBy: getInitialGroupingState(),
    sortBy: "",
    sortFunctionName: "",
    inputValue: "",
    rows: 10,
    currentPage: 1,
    openPaths: {},
    selected: [],
    selectedLabels: [],
    // uuids: [],
}

function Table(props) {
    //
    const {
        metaColumns = [],
        children,
        data = [],
        loading = false,
        onNewReport = () => null,
        onSelectionChanged = () => null,
        onRowAdd = () => null,
        onRowUpdate = () => null,
        onRowDelete = () => null,
        onToggleAddEditField = () => null,
        onDataSourceChange = () => null,
        reportTypes = [],
        labels,
        onDeleteLabel,
        onLabelFields,
        showAddButton,
        showDataSourceDropdown,
        fieldsPolygonData,
        onSelectedMapColumnVariable,
        regionalViewMapVisibleSetting,
        onUserSettingsChange,
        onRemoveLabelFromField,
        highlightByColumn,
        units,
        history,
        tableColumnSettings,
    } = props

    // state
    const [state, dispatch] = useReducer(TableReducer, initialState)
    const { groupBy, sortBy, sortFunctionName, inputValue, rows, currentPage, openPaths, selected, selectedLabels } =
        state

    useEffect(() => {
        onSelectionChanged(state.selected)
    }, [state.selected])

    const childrenArray = Children.toArray(children)

    const columns = childrenArray.map((child) => {
        // TODO detect if child is a component from TableColumn

        if (child && child.props) {
            let _groupColumns = []
            if (child.props.groupColumns && typeof child.props.groupColumns === "function") {
                _groupColumns = child.props.groupColumns(child.props)
            }

            return { ...child.props, groupFields: _groupColumns }
        }
        return null
    })

    const [report, setReport] = useState("-")

    const [showRegionalViewMap, setShowRegionalView] = useState(regionalViewMapVisibleSetting)

    // Table shadow
    const { shadow, horizontalScrollHandler, shadowWidth } = useTableShadow()

    useEffect(() => {
        setShowRegionalView(regionalViewMapVisibleSetting)
    }, [regionalViewMapVisibleSetting])

    const inputRef = useRef(null)

    function handleHeaderClick(propName) {
        dispatch({
            type: "SET_SORT_BY",
            payload: { sortBy: propName },
        })
    }

    function handleRowSelect(rowData) {
        dispatch({
            type: "ADD_SELECTED",
            payload: { items: rowData.__group ? getRowsFromGroups(rowData.data) : [rowData] },
        })
    }

    function handleRowDeselect(rowData) {
        if (rowData.__group) {
            dispatch({
                type: "REMOVE_SELECTED_BY_PROPNAME",
                payload: { propName: rowData.__propName, value: rowData[rowData.__propName] },
            })
        } else {
            dispatch({ type: "REMOVE_SELECTED", payload: { deselected: rowData } })
        }
    }

    function handleRowOpen(rowData, path) {
        // console.log("select")
        dispatch({ type: "ADD_OPEN_PATH", payload: { path, rowData } })
    }

    function handleRowDelete(rowData) {
        if (onRowDelete(rowData)) dispatch({ type: "REMOVE_SELECTED", payload: { deselected: rowData } })
    }

    function handleRowClose(_, path) {
        // console.log("deselect")
        dispatch({ type: "REMOVE_OPEN_PATH", payload: { path } })
    }

    // input handling
    const debounceInputChange = _.debounce((e) => {
        dispatch({ type: "INPUT_CHANGE", payload: { inputValue: e.target.value } })
    }, 500)

    const handleSearchBarInputChange = useCallback(
        (e) => {
            e.persist()
            debounceInputChange(e)
        },
        [debounceInputChange]
    )

    function handleSearchBarButtonClick(e) {
        dispatch({ type: "INPUT_CHANGE", payload: { inputValue: "" } })
        inputRef.current.value = ""
    }

    // draggable headers functionality
    function handleHeaderDrop(propData) {
        if (
            !groupBy.find(
                (_propData) =>
                    (propData && _propData && propData.propName === _propData.propName) ||
                    propData.displayName === _propData.displayName
            )
        ) {
            localStorage.setItem(
                REMEMBERED_GROUPING,
                groupBy.length > 0
                    ? groupBy
                          .map((gb) => {
                              return gb.propName
                          })
                          .join() +
                          "," +
                          propData.propName
                    : propData.propName
            )
            dispatch({ type: "ADD_GROUP_BY", payload: { ...propData } })
        }
    }

    function handleHeaderClose(propData) {
        const _groupBy = groupBy.filter(
            (_propData) => _propData.propName !== propData.propName && _propData.displayName !== propData.displayName
        )
        localStorage.setItem(
            REMEMBERED_GROUPING,
            _groupBy.length > 0
                ? _groupBy
                      .map((gb) => {
                          return gb.propName
                      })
                      .join()
                : ""
        )
        dispatch({ type: "REMOVE_GROUP_BY", payload: { ...propData } })
    }

    // pagination
    function handleRowNumberChange(_rows) {
        dispatch({ type: "ROW_NUMBER_CHANGE", payload: { rows: _rows, total: data.length } })
    }

    function handleCurrentPageChange(_page) {
        dispatch({ type: "CURRENT_PAGE_CHANGE", payload: { page: _page } })
    }

    let displayData = transformData(
        data,
        groupBy,
        sortBy,
        sortFunctionName,
        inputValue,
        rows,
        currentPage,
        columns,
        selectedLabels
    )

    // Define a state variable to check whether checkAll checkbox was checked once
    const [checkedOnce, setCheckedOnce] = useState(false)
    useEffect(() => {
        // Listen for data changes and if data found and checkbox is not checked at least once
        if (data.length > 0) {
            if (!checkedOnce) {
                // Set checked flag
                setCheckedOnce(true)

                // Get currently displayed data
                let currentDisplayedData = displayData

                // Dispatch check_all event (which checks `check all` checkbox)
                dispatch({
                    type: "ADD_SELECTED_BY_ROWS",
                    payload: { items: currentDisplayedData, data, rows, currentPage, groupBy },
                })
            }
        }
    }, [data])

    function handleCheckAllRows(e) {
        let currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)

        if (e.target.checked) {
            dispatch({
                type: "ADD_SELECTED_BY_ROWS",
                payload: { items: currentDisplayedData, data, rows, currentPage, groupBy },
            })
        } else {
            dispatch({
                type: "REMOVE_SELECTED_BY_ROWS",
                payload: { items: currentDisplayedData, rows, currentPage, groupBy },
            })
        }
    }

    function areReportsAllowed() {
        return Object.keys(reportTypes).some(function (object) {
            return reportTypes[object]
        })
    }

    function handleSelectReport(e) {
        if (e.target.value && e.target.value.length && e.target.value !== "-") setReport(e.target.value)
    }

    function handleReportCancel() {
        setReport("-")
    }

    function handleReportContinue(emailList) {
        onNewReport(report, selected, emailList)
        setReport("-")
    }

    function handleAddLabelFilter(label) {
        dispatch({ type: "ADD_LABEL_FILTER", payload: { label } })
    }

    function handleRemoveLabelFilter(label) {
        dispatch({ type: "REMOVE_LABEL_FILTER", payload: { label } })
    }

    // checkbox logic
    let checked = false
    let checkedStatus = "empty"

    let _currentDisplayedData = displayData.slice(rows * currentPage - rows, rows * currentPage)
    const groupedSelected = groupData(selected, groupBy)

    const _propName = (groupBy[0] && groupBy[0].propName) || "uuid"
    const hashed = {}

    for (let _currentItem of _currentDisplayedData) hashed[_currentItem[_propName]] = false
    for (let _selectItem of groupedSelected)
        if (hashed[_selectItem[_propName]] === false) hashed[_selectItem[_propName]] = true

    let c = Object.values(hashed).reduce((prev, curr) => (curr ? prev + 1 : prev), 0)

    checked = c > 0 && c <= _currentDisplayedData.length
    checkedStatus = c === 0 ? "empty" : c > 0 && c < _currentDisplayedData.length ? "half" : "full"

    return (
        <>
            <TableContext.Provider
                value={{
                    // TableItem
                    onRowAdd,
                    onRowDelete: handleRowDelete,
                    onRowUpdate,
                    onDataSourceChange,
                    onRowOpen: handleRowOpen,
                    onRowClose: handleRowClose,
                    onRowSelect: handleRowSelect,
                    onRowDeselect: handleRowDeselect,
                    onRemoveLabelFromField,
                    openPaths,
                    units,
                    showDataSourceDropdown,
                    highlightByColumn,
                    // TableControls
                    selected,
                    checked,
                    handleCheckAllRows,
                    checkedStatus,
                    handleSearchBarButtonClick,
                    handleSearchBarInputChange,
                }}
            >
                <div className="table">
                    <div className="table__container">
                        <div
                            style={{
                                width: "100%",
                                display: "block",
                                padding: "0px 0px",
                                overflow: "hidden",
                            }}
                            id="table-map"
                            className={`table__mapbox__container ${!showRegionalViewMap ? "hide" : ""}`}
                        >
                            <MapboxFieldHeatmapViewerComponent
                                data={fieldsPolygonData.data}
                                variables={fieldsPolygonData.variables}
                                units={fieldsPolygonData.units}
                                onSelectedVariableChange={(v) => onSelectedMapColumnVariable(v.value)}
                                width="100%"
                                height="50vh"
                                history={history}
                            ></MapboxFieldHeatmapViewerComponent>
                        </div>

                        <div className="table__main-header">
                            <div className="table__main-header__title">
                                <div className="table__main-header__title__controls-left">
                                    {showAddButton && (
                                        <button
                                            id="add-field-button__table"
                                            className="table__main-header__title__button"
                                            onClick={onToggleAddEditField}
                                        >
                                            New Field
                                        </button>
                                    )}
                                </div>
                                {/*TODO add logic to control reports that appear*/}
                                {(selected && selected.length && tableColumnSettings["labels"] && (
                                    <div className="table__main-header__title__controls-right">
                                        <div className="table__main-header__title__controls-right__label-button">
                                            {/* <Popover text="Label options" className="up"> */}
                                            <TableLabelButton>
                                                <SmallControls
                                                    className={_currentDisplayedData.length > 5 ? "down" : "up"}
                                                >
                                                    <ControlItem name="main" title="Label options" defaultList />
                                                    <ControlItem
                                                        name="existing_label"
                                                        title="Add existing label"
                                                        listItem
                                                    >
                                                        <LabelSelect
                                                            labels={labels}
                                                            // onNewLabel={onNewLabel}
                                                            onDeleteLabel={onDeleteLabel}
                                                            onSelectLabel={(label) => onLabelFields(label, selected)}
                                                        />
                                                    </ControlItem>
                                                    <ControlItem name="new_label" title="Add new label" listItem>
                                                        <AddLabelForm
                                                            onSelectLabel={(label) => onLabelFields(label, selected)}
                                                        />
                                                    </ControlItem>
                                                    <ControlItem
                                                        name="filter_by_label"
                                                        title="Filter by label"
                                                        listItem
                                                    >
                                                        <LabelSelect
                                                            labels={labels}
                                                            onAddLabelFilter={handleAddLabelFilter}
                                                            onRemoveLabelFilter={handleRemoveLabelFilter}
                                                            selectedLabels={selectedLabels}
                                                            hideDeleteButton
                                                        />
                                                    </ControlItem>
                                                </SmallControls>
                                            </TableLabelButton>
                                            {/* </Popover> */}
                                        </div>
                                        {areReportsAllowed() && (
                                            <select
                                                value={report}
                                                id="generate-report-select__table"
                                                className="table__main-header__title__select"
                                                onChange={handleSelectReport}
                                            >
                                                <option
                                                    id="generate-report-default__table"
                                                    value="-"
                                                    defaultChecked
                                                    disabled
                                                >
                                                    Choose report to generate
                                                </option>
                                                {Object.entries(reportTypes)
                                                    .filter((data) => data[1])
                                                    .map((data) => {
                                                        let enabled = data[1] ? "" : "disabled"
                                                        let reportName = data[0]
                                                        const nameCapitalized =
                                                            reportName.charAt(0).toUpperCase() + reportName.slice(1)
                                                        return (
                                                            <option
                                                                key={reportName}
                                                                value={reportName}
                                                                disabled={enabled}
                                                                id={`generate-report-${reportName}-option__table`}
                                                            >
                                                                {nameCapitalized}
                                                            </option>
                                                        )
                                                    })}
                                            </select>
                                        )}
                                    </div>
                                )) ||
                                    null}
                            </div>

                            <div id="search-bar-container__table" className="table__main-header__search-bar">
                                <div className="table__main-header__search-bar__icon">
                                    <SearchIcon />
                                </div>
                                <input
                                    ref={inputRef}
                                    type="text"
                                    id="search-bar-input__table"
                                    className="table__main-header__search-bar__input"
                                    placeholder="Search"
                                    onChange={(e) => {
                                        e.persist()
                                        handleSearchBarInputChange(e)
                                    }}
                                />
                                {/* Erase button */}
                                <button
                                    id="search-bar-cancel-button__table"
                                    className="table__main-header__search-bar__button"
                                    onClick={handleSearchBarButtonClick}
                                >
                                    <TimesIcon />
                                </button>
                            </div>
                            <div className="table__main-header__map-toggler">
                                <Popover text="Toggle map" className="up left">
                                    <ToggleContainerRegionalViewMap
                                        showRegionalViewMap={showRegionalViewMap}
                                        setShowRegionalViewMap={setShowRegionalView}
                                        onUserSettingsChange={onUserSettingsChange}
                                        isVisibleSetting={regionalViewMapVisibleSetting}
                                    />
                                </Popover>
                            </div>
                        </div>
                        {loading && <div className="table__loader" />}
                        <div className="table-container__draggable-headers">
                            <TableDraggableHeaders
                                groupBy={groupBy}
                                onDrop={handleHeaderDrop}
                                onHeaderClose={handleHeaderClose}
                            />
                        </div>
                        <div style={{ width: "100%", position: "relative", overflow: "hidden" }}>
                            {shadow && <div className="table__shadow" style={{ width: shadowWidth }}></div>}
                            <div className="table__scroll-container" onScroll={horizontalScrollHandler}>
                                <table className="table__content root">
                                    <TableContent
                                        displayData={displayData}
                                        fields={columns}
                                        metaFields={metaColumns}
                                        rows={rows}
                                        currentPage={currentPage}
                                        sortBy={sortBy}
                                        groupBy={groupBy}
                                        sortFunctionName={sortFunctionName}
                                        onHeaderClick={handleHeaderClick}
                                        selected={groupedSelected}
                                        root
                                    />
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="table__pagination">
                        <TablePagination
                            rowList={[5, 10, 20, "All"]}
                            rows={rows}
                            currentPage={currentPage}
                            dataLength={displayData.length}
                            onRowNumberChange={handleRowNumberChange}
                            onCurrentPageChange={handleCurrentPageChange}
                        />
                    </div>
                </div>
                {report && report !== "-" && (
                    <SimpleModal title="We’re getting your report ready!">
                        <ShareReportForm onCancel={handleReportCancel} onContinue={handleReportContinue} />
                    </SimpleModal>
                )}
            </TableContext.Provider>
        </>
    )
}

export default React.memo(Table)
