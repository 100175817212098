import React from "react"
import { alertBetweenTimePeriod } from "../../../helpers/plantingTool"
import Checkbox from "../../../ui/Checkbox/Checkbox"
import EditIcon from "../../../ui/Icons/EditIcon"
import { getCorrectUnitValue } from "../../../Util/Alerts"
import { PROPERTY_TITLES_EQUIVALENCE } from "../../../Util/CSVParser"
import { getIcon } from "../../Settings/AlertSettings/components/VarietyAlert"
import CardItem from "./CardItem"

import "./ScenarioItem.css"

function ScenarioItem(props) {
    const { scenario, onEdit = () => null, onCheckStatusChange } = props
    const { name, plantingDate, length, chart_data: chartData = {} } = scenario

    const alerts = []

    for (let risk in chartData) {
        for (let alert in chartData[risk]) {
            alerts.push({ ...chartData[risk][alert], _riskId: risk, _alertId: alert })
        }
    }

    const availableAlerts = alerts.filter((x) => {
        const endDate = new Date(plantingDate)
        endDate.setDate(plantingDate.getDate() + length)
        return alertBetweenTimePeriod(x, plantingDate, endDate) && x.weight > 0
    })

    return (
        <>
            <CardItem
                icon={
                    <Checkbox
                        color={scenario.color}
                        checked={scenario.checked}
                        status={(scenario.checked && "full") || "empty"}
                        onChange={(e) => onCheckStatusChange(scenario.uid, e.target.checked)}
                    />
                }
                className="scroll"
                render={() => {
                    return (
                        <>
                            <button className={"scenario-item__name"} onClick={onEdit}>
                                <div className="scenario-item__name__edit">
                                    <EditIcon />
                                </div>
                                <p className="scenario-item__name__text">{name}</p>
                            </button>
                            <div className="scenario-item__description">
                                {plantingDate
                                    .toLocaleDateString("default", {
                                        month: "short",
                                        day: "numeric",
                                        year: "numeric",
                                    })
                                    .toLowerCase()}{" "}
                                - {length} days
                            </div>
                        </>
                    )
                }}
            >
                <div className="scenario-item__risks">
                    {(availableAlerts &&
                        availableAlerts.length > 0 &&
                        availableAlerts.map((risk) => {
                            const icon = getIcon(risk._riskId)

                            return (
                                <div className="scenario-item__risks__item">
                                    <div className="scenario-item__risk__item__icon">{icon}</div>
                                    <div className="scenario-item__risk__content">
                                        <div className="scenario-item__risk__name">
                                            {PROPERTY_TITLES_EQUIVALENCE[risk._riskId]}
                                        </div>
                                        <div className="scenario-item__risk__alert-name">
                                            {risk.alert_data.alert_name}
                                            <label className="scenario-item__risk__alert-name__threshold-label">
                                                ({risk.alert_data.conditional === "less_than" ? "< " : "> "}
                                                {getCorrectUnitValue(
                                                    risk.alert_data.default_units,
                                                    "metric",
                                                    risk.alert_data.unit_system,
                                                    risk.alert_data.threshold
                                                )}{" "}
                                                {risk.alert_data.units})
                                            </label>
                                        </div>
                                        <div className="scenario-item__risk__description">
                                            {new Date(risk.time[0]).toLocaleDateString(undefined, {
                                                month: "short",
                                                day: "numeric",
                                            })}{" "}
                                            -{" "}
                                            {new Date(risk.time[risk.time.length - 1]).toLocaleDateString(undefined, {
                                                month: "short",
                                                day: "numeric",
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })) || (
                        <div className="scenario-item__risks__placeholder">
                            No available risks for current planting window.
                        </div>
                    )}
                </div>
            </CardItem>
        </>
    )
}

export default ScenarioItem
