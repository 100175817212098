import React from "react"

// Components
import MaterialPopover from "../../../ui/Popover/MaterialPopover"

// Utils
import { convertTemperatureValueOnly } from "../../../Util/UnitConversion"

import "./CropTableTomorrowTemp.css"

function CropTableTomorrowTemp(props) {
    const { rowData, units } = props
    let data = rowData?.weather_variables?.t2m?.data
    let minTemp = convertTemperatureValueOnly("metric", units, data?.t2m_min)
    let maxTemp = convertTemperatureValueOnly("metric", units, data?.t2m_max)

    // convertTemperatureValueOnly
    return (
        <>
            <MaterialPopover text="Tomorrow's Temperature" position="bottom" distance="medium">
                <div className="weather_variables__temp_container">
                    <div className="max_temp_value">{maxTemp}</div>
                    <div className="min_temp_value">{minTemp}</div>
                </div>
            </MaterialPopover>
        </>
    )
}

export default CropTableTomorrowTemp
