import React from "react"

function GrowingDegreeDaysIcon(props) {
    const {} = props
    return (
        <>
            <svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path fill="#666D74" d="M11.5 5h2v13h-2z" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.257 7.243 12.5 3l4.243 4.243H13.5v4.313h-2V7.243H8.257ZM4.5 9a5 5 0 0 0 5 5h1a5 5 0 0 0-5-5h-1Zm11 7a5 5 0 0 0 5-5h-1a5 5 0 0 0-5 5h1Zm6 2h-18v2h18v-2Z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default GrowingDegreeDaysIcon
