export default function PositionHelper(position, distance) {
    var positionObject = {
        anchorOrigin: {
            "vertical": 'bottom',
            "horizontal": 'center',
        },
        transformOrigin: {
            "vertical": 'top',
            "horizontal": 'center',
        }
    }

    let anchorVertical =  positionObject.anchorOrigin.vertical
    let anchorHorizontal =  positionObject.anchorOrigin.horizontal
    let transformVertical =  positionObject.transformOrigin.vertical
    let transformHorizontal =  positionObject.transformOrigin.horizontal
   
    switch(position) {
        case "top": 
            anchorVertical = "top"
            anchorHorizontal = "center"
            transformVertical = "bottom"
            transformHorizontal = "center"
            break
        case "top-left": 
            anchorVertical = "top"
            anchorHorizontal = "left"
            transformVertical = "bottom"
            transformHorizontal = "right"
            break
        case "bottom": 
            anchorVertical = "bottom"
            anchorHorizontal = "center"
            transformVertical = "top"
            transformHorizontal = "center"
            break
        case "right":
            anchorVertical = "center"
            anchorHorizontal = "right"
            transformVertical = "center"
            transformHorizontal = "left"
        default: 
            break
    }
    switch(distance) {
        case "near":
            transformVertical = "bottom"
            break
        case "medium":
            transformVertical = "center"
            break
        case "far":
            transformVertical = "top"
            break
        default:
            break
    }
    if(String(position) === "top") {
        switch(distance) {
            case "near":
                transformVertical = "bottom"
                break
            case "medium":
                transformVertical = "center"
                break
            case "far":
                transformVertical = "top"
                break
            default:
                break
        }
    } else if(String(position) === "top-left") {
        switch(distance) {
            case "near":
                transformVertical = "center"
                transformHorizontal = "center"
                break
            case "medium":
                transformVertical = "center"
                transformHorizontal = "left"
                break
            case "far":
                transformVertical = "bottom"
                transformHorizontal = "right"
                break
            default:
                break
        }
    } else if(String(position) === "bottom") {
        switch(distance) {
            case "near":
                transformVertical = "top"
                break
            case "medium":
                transformVertical = "center"
                break
            case "far":
                transformVertical = "bottom"
                break
            default:
                break
        }
    } 
    

    return [anchorVertical, anchorHorizontal, transformVertical, transformHorizontal]
}