import { useMemo } from "react"
import { getQuartiles } from "../Util/QuartilesUtil"

export default function useMonthlyData(data, selected, chartColors, variablesByDefault) {
    return useMemo(() => {
        let chartData = {}

        let selectedKeys = Object.keys(selected)

        if (data && Object.keys(data).length > 0) {
            const availableColors = selectedKeys.reduce((prev, key, index) => {
                selected[key] && prev.push(chartColors[index])
                return prev
            }, [])

            chartData = {
                type: "candlestick",
                // TODO modify with available colors
                color: (pointIndex, groupIndex, candleIndex) => {
                    return availableColors[groupIndex]
                },
                points: [],
            }

            const months = {}

            Object.keys(data)
                .filter((key) => selected[key])
                .forEach((key, index) => {
                    // month
                    Object.keys(data[key]).forEach((month, index) => {
                        if (!months[month]) months[month] = []

                        const values = data[key][month]

                        const { min, max, q1, q2, q3 } = getQuartiles(values)

                        months[month].push({
                            x: new Date(`Jan ${key}`),
                            y0: min,
                            y1: max,
                            yMax: q3,
                            yMid: q2,
                            yMin: q1,
                        })
                    })
                })

            Object.keys(months).forEach((key) => {
                chartData.points.push({
                    x: new Date(`${key} 16 2022`),
                    values: months[key],
                })
            })
        }

        return { chartData }
    }, [data, selected])
}
