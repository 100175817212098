import React from "react"

function ComulativeDroughtRiskIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M5 8a5 5 0 0 0 5-5H9a5 5 0 0 0-5 5h1ZM21 18H3v2h18v-2ZM19 13a5 5 0 0 1-5-5h1a5 5 0 0 1 5 5h-1ZM11 3h2v15h-2zM7 10H5v2H3v2h2v2h2v-2h2v-2H7v-2Z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default ComulativeDroughtRiskIcon
