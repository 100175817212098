import React, { useContext, useEffect } from "react"
// Modules

// Components
import AlertContainer from "./AlertContainer"

import PrecipitationIcon from "../../../../ui/Icons/AlertSettings/PrecipitationIcon"
import ComulativePrecipitationIcon from "../../../../ui/Icons/AlertSettings/ComulativePrecipitationIcon"
import ThermometerIcon from "../../../../ui/Icons/AlertSettings/ThermometerIcon"
import DrySoilIcon from "../../../../ui/Icons/AlertSettings/DrySoilIcon"
import SunIcon from "../../../../ui/Icons/AlertSettings/SunIcon"
import ColdIcon from "../../../../ui/Icons/AlertSettings/ColdIcon"
import HeatSoilIcon from "../../../../ui/Icons/AlertSettings/HeatSoilIcon"
import ColdSoilIcon from "../../../../ui/Icons/AlertSettings/ColdSoilIcon"
import UploadFileIcon from "../../../../ui/Icons/UploadFileIcon"
import DownloadFileIcon from "../../../../ui/Icons/DownloadFileIcon"
import IconButton from "../../../../ui/Button/IconButton"
import DroughtIcon from "../../../../ui/Icons/AlertSettings/DroughtIcon"
import ComulativeDroughtIcon from "../../../../ui/Icons/AlertSettings/ComulativeDroughtIcon"
import WetSoilIcon from "../../../../ui/Icons/AlertSettings/WetSoilIcon"
import LowHumidityIcon from "../../../../ui/Icons/AlertSettings/LowHumidityIcon"
import HighHumidityIcon from "../../../../ui/Icons/AlertSettings/HighHumidityIcon"
import GrowingDegreeDaysIcon from "../../../../ui/Icons/AlertSettings/GrowingDegreeDaysIcon"
import PestDegreeIcon from "../../../../ui/Icons/AlertSettings/PestDegreeDays"
import LowWindDayIcon from "../../../../ui/Icons/AlertSettings/LowWindDayIcon"
import HighWindDayIcon from "../../../../ui/Icons/AlertSettings/HighWindDayIcon"
import InsufficientHighTemperatureIcon from "../../../../ui/Icons/AlertSettings/InsufficientHighTemperatureIcon"
import InsufficientChillTemperatureIcon from "../../../../ui/Icons/AlertSettings/InsufficientChillTemperatureIcon"
import InsufficientSolarRadIcon from "../../../../ui/Icons/AlertSettings/InsufficientSolarRadIcon"
import ExcessiveSolarRadIcon from "../../../../ui/Icons/AlertSettings/ExcessiveSolarRadIcon"

// Views

// Context providers / Utils
import { RISKS } from "./RisksList"
import { STACKED_RISKS } from "./RisksList"
import { AuthContext } from "../../../../Auth/Auth"
import { SettingsContext } from "../../../../Util/SettingsContext"
import { toast } from "react-toastify"

// Hooks

// Material-UI *

// Styles

// import "./AlertSettings.css"

export const getIcon = (riskName) => {
    switch (riskName) {
        case "risk_heat_stress":
            return <SunIcon fill="#000" />
        case "risk_cold":
            return <ColdIcon fill="#000" />
        case "risk_insufficient_high_temp":
            return <InsufficientHighTemperatureIcon fill="#000" />
        case "risk_insufficient_low_temp":
            return <InsufficientChillTemperatureIcon fill="#000" />
        case "risk_flood":
            return <PrecipitationIcon fill="#000" />
        case "risk_cumulative_flood":
            return <ComulativePrecipitationIcon fill="#000" />
        case "risk_drought":
            return <DroughtIcon fill="#000" />
        case "risk_cumulative_drought":
            return <ComulativeDroughtIcon fill="#000" />
        case "risk_dry_soil":
            return <DrySoilIcon fill="#000" />
        case "risk_wet_soil":
            return <WetSoilIcon fill="#000" />
        case "risk_low_soil_temperature":
            return <ColdSoilIcon fill="#000" />
        case "risk_high_soil_temperature":
            return <HeatSoilIcon fill="#000" />
        case "risk_low_humidity":
            return <LowHumidityIcon fill="#000" />
        case "risk_high_humidity":
            return <HighHumidityIcon fill="#000" />
        case "risk_growing_degree_days":
            return <GrowingDegreeDaysIcon fill="#000" />
        case "risk_pest_degree_days":
            return <PestDegreeIcon fill="#000" />
        case "risk_low_wind_speed":
            return <LowWindDayIcon fill="#000" />
        case "risk_high_wind_speed":
            return <HighWindDayIcon fill="#000" />
        case "risk_insufficient_solar_radiation":
            return <InsufficientSolarRadIcon fill="#000" />
        case "risk_excessive_solar_radiation":
            return <ExcessiveSolarRadIcon fill="#000" />
        default:
            return <ThermometerIcon fill="#000" />
    }
}

function VarietyAlert(props) {
    const {
        varietyObj,
        path,
        onVarietyFileDownload = () => null,
        onVarietyFileUpload = () => null,
        isDownloadEnabled,
        isUploadEnabled,
        stackedAlertsView,
        onAlertStatusChange = () => null,
        reloadAlertSettings = () => null,
    } = props
    const {
        currentSettings: { units },
    } = useContext(SettingsContext)
    const { currentUser, featurePermissions } = useContext(AuthContext)
    const RISK_LIST = !stackedAlertsView ? RISKS : STACKED_RISKS

    function handleDownload() {
        onVarietyFileDownload(path.crop, path.variety)
    }

    function handleCSVUpload(e) {
        e.persist()
        const fileExt = e.target.value.split(".").pop()
        if (fileExt !== "csv") {
            toast.error("Error: Only .csv files are accepted")
            return
        }
        onVarietyFileUpload(path.crop, path.variety, e.target.files[0])
    }

    useEffect(() => {}, [])
    return (
        <div>
            <div className="variety-alert__controls">
                {isDownloadEnabled && !stackedAlertsView && (
                    <div className="variety-alert__controls__download">
                        <IconButton
                            id="download-button__alert-settings"
                            icon={DownloadFileIcon}
                            onClick={handleDownload}
                        >
                            Download Alerts
                        </IconButton>
                    </div>
                )}
                {isUploadEnabled && !stackedAlertsView && (
                    <div className="variety-alert__controls__upload">
                        <IconButton icon={UploadFileIcon} type="file" fileTypesAllowed=".csv" onClick={handleCSVUpload}>
                            Upload Alerts
                        </IconButton>
                    </div>
                )}
            </div>

            {Object.keys(RISK_LIST).map((mRisk) => {
                let risk = !stackedAlertsView ? RISKS[mRisk] : STACKED_RISKS[mRisk]
                let alertsObj = varietyObj[mRisk]
                return (
                    <AlertContainer
                        key={"risk-" + mRisk}
                        riskData={risk}
                        icon={getIcon(mRisk)}
                        alertsObj={alertsObj}
                        path={{ ...path, risk: mRisk }}
                        units={units}
                        currentUser={currentUser}
                        featurePermissions={featurePermissions}
                        onAlertStatusChange={onAlertStatusChange}
                        reloadAlertSettings={reloadAlertSettings}
                    />
                )
            })}
        </div>
    )
}

export default React.memo(VarietyAlert)
