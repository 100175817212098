import React from "react"

import DatePicker from "react-datepicker"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"

import "react-datepicker/dist/react-datepicker.css"
import "./PlantingToolChart.css"

import ScenarioSelector from "./ScenarioSelector"
import CalendarIcon from "../../../ui/Icons/CalendarIcon"
import { Popover } from "../../../ui/Popover"
import { useMediaQuery } from "@material-ui/core"
import ArrowBottom from "../../../ui/Icons/newIcons/ArrowBottom"
import LoaderWithMessages from "../../../components/LoaderWithMessages"
import InfoIcon from "../../../ui/Icons/InfoIcon"

const _MIN_WINDOW_DAYS = 14

const loadingMessages = [
    "Generating extended forecast...",
    "Analyzing probability of extreme weather events...",
    "Determining impact on key crop stages...",
    "Weighing risk-yield tradeoffs... ",
    "Calculating optimal time to plant/sow...",
    "Finalizing results...",
    "This could take up to 2 minutes...",
    "Finalizing results...",
]

function PlantingToolChart(props) {
    const {
        scenarios,
        setScenarios,
        mappedChartData,
        maxValue,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        availableAlerts,
        selectedRiskAlertKey,
        onRiskAlertSelected = () => null,
        loading,
        popoverMessage,
    } = props

    const endDateMin = new Date(startDate)
    endDateMin.setDate(startDate.getDate() + _MIN_WINDOW_DAYS)

    const _maxValue = Math.floor(maxValue * 100 + 10)
    const tabletMedia = useMediaQuery("(max-width: 750px)")

    return (
        <>
            <div className="planting-tool-chart__header">
                {/* <div className="planting-tool-chart__header__title">Climate Risk</div> */}
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="planting-tool-chart__header__container__info-icon">
                        <Popover text={popoverMessage}>
                            {/* <ExclamationIcon fill="#B3B6BA" /> */}
                            <div style={{ width: 20, height: 20 }}>
                                <InfoIcon fill="#B3B6BA" />
                            </div>
                        </Popover>
                    </div>
                    <div className="planting-tool-chart__header__container">
                        <div className="planting-tool-chart__header__title">
                            {availableAlerts[selectedRiskAlertKey].alertName}
                            <p className="planting-tool-chart__header__subtitle">
                                ({availableAlerts[selectedRiskAlertKey].riskName})
                            </p>
                        </div>

                        <div className="planting-tool-chart__header__icon">
                            <div className="h-5 w-5">
                                <ArrowBottom />
                            </div>
                        </div>
                        <select
                            value={selectedRiskAlertKey}
                            onChange={(e) => {
                                const { riskKey, alertId } = availableAlerts[e.target.value]
                                onRiskAlertSelected({ riskKey, alertId })
                            }}
                        >
                            {availableAlerts &&
                                Object.keys(availableAlerts).map((key) => {
                                    const { riskName, alertName } = availableAlerts[key]
                                    return <option value={key}>{alertName + " (" + riskName + ")"}</option>
                                })}
                        </select>
                    </div>
                </div>
                <div className="planting-tool-chart__range-date" style={{ width: 300 }}>
                    <div>
                        Show graph from:
                        <div className="planting-tool-chart__range-date__input-container">
                            <div className="planting-tool-chart__range-date__input-container__icon">
                                <Popover text="Select a start date to limit the graph." className="">
                                    <CalendarIcon />
                                </Popover>
                            </div>
                            <DatePicker
                                showPopperArrow={false}
                                closeOnScroll={true}
                                selected={startDate}
                                onChange={(date) => {
                                    date.setHours(0, 0, 0, 0)
                                    setStartDate(date)
                                }}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                dateFormat="MMM d"
                            />
                        </div>
                    </div>
                    <div>
                        to:
                        <div className="planting-tool-chart__range-date__input-container">
                            <div className="planting-tool-chart__range-date__input-container__icon">
                                <Popover text="Select an end date to limit the graph." className="left">
                                    <CalendarIcon />
                                </Popover>
                            </div>
                            <DatePicker
                                showPopperArrow={false}
                                closeOnScroll={true}
                                selected={endDate}
                                onChange={(date) => {
                                    date.setHours(0, 0, 0, 0)
                                    setEndDate(date)
                                }}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={endDateMin}
                                dateFormat="MMM d"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ScenarioSelector scenarios={scenarios} setScenarios={setScenarios} />
            {/* {loading && <div className="table__loader" />} */}
            {loading && (
                <div className="planting-tool-chart__loader-container">
                    <LoaderWithMessages messages={loadingMessages} />
                </div>
            )}
            {!loading && mappedChartData && mappedChartData.length > 0 && (
                <LineAreaChartComponent
                    title=""
                    curve={"curveLinear"}
                    marginLeft={65}
                    svgHeight={(tabletMedia && 300) || 500}
                    // svgWidth={300}
                    yTicksCount={5}
                    labelYFontSize={11}
                    yTickFormat={(d) => d + "%"}
                    gridView={false}
                    xLeftOffset={0.1}
                    xRightOffset={0.1}
                    xTicksCount={(tabletMedia && 1) || 5}
                    xDateMin={startDate}
                    xDateMax={endDate}
                    yValueMax={(_maxValue >= 100 && 100) || _maxValue}
                    // yValueMax={100}
                    labelY="Risk probability %"
                    labelYOffsetX={-18}
                    resizeEventListenerId="planting-tool-chart"
                    data={mappedChartData}
                    marginLeft={80}
                    tooltip={(EVENT, data, state) => {
                        return `
                        <div style="font-family: Montserrat;">
                            ${
                                data.key &&
                                `<strong>${data.key.toLocaleString(undefined, {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                })}</strong>`
                            }
                            ${data.points
                                .filter((d) => d && d.scenario && d.scenario.isVisible && !Number.isNaN(d.value))
                                .map((d) => {
                                    return `<p style="color:${d.scenario.color}"><strong>${d.scenario.name}:</strong> ${
                                        (d.value && d.value.toFixed(2).toString() + "%") || "0%"
                                    }</p>`
                                })
                                .join("")}
                        </div>
                    `
                    }}
                />
            )}
        </>
    )
}

export default PlantingToolChart
