import React from "react"

function ComulativePrecipitationRiskIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M0 12a5.998 5.998 0 0 1 4-5.655V8.53C2.795 9.22 2 10.522 2 12a4 4 0 0 0 2.34 3.64l-.49 1.962A6.008 6.008 0 0 1 0 12ZM12 2v2c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11A2.98 2.98 0 0 1 22 13c0 1.65-1.35 3-3 3h-.75l-.5 2H19c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96A7.49 7.49 0 0 0 12 2Z"
                    fill="#666D74"
                />
                <path
                    d="M15 21h-2l2-8h2l-2 8ZM9 21l2-8h2l-2 8H9ZM7 21H5l2-8h2l-2 8ZM10 6h2v2h-2v2H8V8H6V6h2V4h2v2Z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default ComulativePrecipitationRiskIcon
