import React from "react"

// Components
import { Popover } from "../../../ui/Popover"
import TrashIcon from "../../../ui/Icons/TrashIcon"
import EditIcon from "../../../ui/Icons/EditIcon"

function CropTableActions(props) {
    const {
        rowData,
        rowOptions: { toggleDelete },
        onToggleAddEditField,
        featurePermissions,
    } = props

    return (
        <>
            {featurePermissions?.field_tools?.edit && (
                <Popover text="Edit field" className="up left">
                    <button
                        className="crop-table__icon-button"
                        onClick={() => {
                            onToggleAddEditField(rowData)
                        }}
                    >
                        <div className="crop-table__edit-icon">
                            <EditIcon />
                        </div>
                    </button>
                </Popover>
            )}
            {featurePermissions?.field_tools?.delete && (
                <Popover text="Delete field" className="up left">
                    <button className="crop-table__icon-button" onClick={() => toggleDelete()}>
                        <TrashIcon className="crop-table__trash-icon" />
                    </button>
                </Popover>
            )}
        </>
    )
}

export default CropTableActions
