import { useContext, useEffect, useMemo, useState } from "react"
import ImageIcon from "../../ui/Icons/ImageIcon"
import FileIcon from "../../ui/Icons/FileIcon"
import LinkIcon from "../../ui/Icons/LinkIcon"
import DownloadIcon from "../../ui/Icons/newIcons/DownloadIcon"
import ChevronIcon from "../../ui/Icons/newIcons/ChevronArrow"
import { DataContext } from "../../Util/Data"
import { saveAs } from "file-saver"
import SideBarMobileToggler from "../../components/TopBar/SideBarMobileToggler"
import { getReportData } from "../../services/customAnalytics.service"
import DebounceSearchInput from "../../components/DebounceSearchInput/DebounceSearchInput"

const CustomReportsView = ({}) => {
    const [selectedReport, setSelectedReport] = useState(-1)
    const [activeURL, setActiveURL] = useState("")
    const [sideNavOpen, setSideNavOpen] = useState(false)
    const [searchInput, setSearchInput] = useState("")

    const {
        state: { customReports },
    } = useContext(DataContext)

    useEffect(() => {
        const selectFirstReport = async () => {
            setSelectedReport(customReports[0])
            setActiveURL(await getReportData(customReports[0].id))
        }
        if (customReports.length !== 0) {
            selectFirstReport()
        }
    }, [customReports])

    useEffect(() => {
        const getSelectedReportData = async () => {
            setActiveURL(await getReportData(selectedReport.id))
        }
        if (selectedReport !== -1) {
            getSelectedReportData()
        }
    }, [selectedReport])

    const exportReport = () => {
        saveAs(
            activeURL,
            selectedReport.name.replaceAll(" ", "_") + "." + (selectedReport.type === "pdf" ? "pdf" : "png")
        )
    }

    const reportsToDisplay = useMemo(() => {
        return customReports.filter((report) => {
            const name = report.name.toLowerCase()
            const url = report.url.toLowerCase()
            return name.includes(searchInput.toLowerCase()) || url.includes(searchInput.toLowerCase())
        })
    }, [searchInput, customReports])

    return (
        <>
            {/* VIEW CONTAINER  */}
            <div className="grow flex flex-row w-full h-full text-gray-90 roboto">
                {/* SIDENAV  */}
                <div
                    className={
                        "absolute inset-y-0 left-0 z-full md:z-50 md:relative shrink-0 grow-0 bg-white elevation-1 border-r border-gray-10 transition-all group" +
                        (sideNavOpen ? " w-[240px]" : " w-[0px] md:w-[68px]")
                    }
                >
                    {/* SIDENAV MOBILE BUTTON */}
                    <SideBarMobileToggler isOpen={sideNavOpen} toggleFunction={() => setSideNavOpen(!sideNavOpen)} />

                    {/* SIDENAV TOGGLE BUTTON */}
                    <div className="relative overflow-visible w-full">
                        <div
                            className={
                                " absolute -right-3 top-[24px]" +
                                (sideNavOpen ? " md:hidden group-hover:block" : " hidden md:block")
                            }
                            onClick={() => setSideNavOpen(!sideNavOpen)}
                        >
                            <div
                                className={
                                    "cursor-pointer rounded-full bg-white elevation-1 h-[24px] w-[24px] fill-gray-60 border border-gray-10 " +
                                    " flex flex-col items-center justify-center transition-all duration-75" +
                                    " hover:bg-accent hover:fill-white hover:border-accent"
                                }
                                onClick={() => setSideNavOpen(!sideNavOpen)}
                            >
                                <div className={"transform" + (sideNavOpen ? " rotate-90" : " -rotate-90")}>
                                    <ChevronIcon />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* SIDENAV OPTIONS */}
                    <div className="overflow-hidden overflow-y-auto h-full w-full pt-[12px]">
                        <div
                            className={
                                "px-[20px] my-2 w-full overflow-hidden transition-all" +
                                (sideNavOpen ? "" : " h-0 my-0")
                            }
                        >
                            <DebounceSearchInput onSearch={setSearchInput} ms={300} />
                        </div>
                        {customReports && (
                            <>
                                {customReports.length === 0 ? (
                                    <div className="flex flex-row items-center h-[48px] px-[22px] w-full">
                                        <div className="min-w-0 w-full flex flex-row items-center h-full border-y border-transparent border-b-gray-10">
                                            <h2 className="text-[16px] leading-none -mb-[1px] truncate">
                                                You don't have reports
                                            </h2>
                                        </div>
                                    </div>
                                ) : reportsToDisplay.length === 0 ? (
                                    <div className="flex flex-row items-center h-[48px] px-[22px] w-full">
                                        <div className="min-w-0 w-full flex flex-row items-center h-full border-y border-transparent border-b-gray-10">
                                            <h2 className="text-[16px] leading-none -mb-[1px] truncate">No match</h2>
                                        </div>
                                    </div>
                                ) : (
                                    reportsToDisplay.map((report) => {
                                        const index = report.id
                                        const isActive = index === selectedReport?.id
                                        let reportIcon = <FileIcon />
                                        if (report.type === "image") {
                                            reportIcon = <ImageIcon />
                                        } else if (report.type === "looker") {
                                            reportIcon = <LinkIcon />
                                        }
                                        // SIDENAV OPTION
                                        return (
                                            <div
                                                key={index}
                                                className={
                                                    "flex flex-row items-center h-[48px] pr-[14px] w-full cursor-pointer" +
                                                    " hover:bg-gray-5" +
                                                    (isActive ? " text-accent font-bold fill-accent" : " fill-gray-60")
                                                }
                                                onClick={() => {
                                                    setActiveURL("")
                                                    setSelectedReport(report)
                                                    setSideNavOpen(true)
                                                }}
                                            >
                                                {/* ICON SECTION */}
                                                <div
                                                    className={
                                                        "grow-0 shrink-0 transition-all duration-200 ease-in-out pr-[12px] pl-[20px]" +
                                                        (sideNavOpen ? "" : " md:px-[22px]")
                                                    }
                                                >
                                                    <span className="block w-[24px] h-[24px]">{reportIcon}</span>
                                                </div>
                                                {/* LABEL SECTION */}
                                                <div className="min-w-0 w-full flex flex-row items-center h-full border-y border-transparent border-b-gray-10">
                                                    <h2 className="text-[16px] leading-none -mb-[1px] truncate">
                                                        {report.name}
                                                    </h2>
                                                </div>
                                            </div>
                                        )
                                    })
                                )}
                            </>
                        )}
                    </div>
                </div>

                {/* REPORT */}
                <div className="bg-gray-5 grow p-6 md:py-6 md:px-8 overflow-hidden">
                    {selectedReport !== -1 && (
                        <div className="flex flex-col space-y-4 h-full min-w-[320px]">
                            {/* REPORT HEADER */}
                            <div className="flex-0 flex flex-row justify-between items-center">
                                <div className="grow">
                                    <div className="montserrat font-bold text-[16px]">{selectedReport.name}</div>
                                    <div className="text-[14px] text-gray-60 pt-2">
                                        Updated on: {new Date(selectedReport.modifiedDate).toISOString().split("T")[0]}
                                    </div>
                                </div>
                                {selectedReport.type !== "looker" && (
                                    <button
                                        className="bg-accent pl-2 pr-4 py-1 rounded-lg font-bold text-white text-[16px] flex flex-row items-center space-x-2 hover:opacity-90"
                                        onClick={exportReport}
                                    >
                                        <span className="fill-white w-6">
                                            <DownloadIcon />
                                        </span>
                                        <span>Export</span>
                                    </button>
                                )}
                            </div>
                            {/* REPORT VIEWER */}
                            <div className="grow flex flex-row justify-center">
                                <div className="relative w-[1400px] bg-gray-10 rounded-lg flex flex-col justify-center items-center overflow-hidden elevation-1 border border-gray-10 resize">
                                    <div className="animate-pulse-fast">Loading...</div>
                                    <div className="absolute inset-0 flex flex-col justify-center items-center bg-transparent">
                                        {(selectedReport.type === "pdf" || selectedReport.type === "looker") && (
                                            <iframe className="w-full h-full" src={activeURL}></iframe>
                                        )}
                                        {selectedReport.type === "image" && (
                                            <img className="object-contain w-full h-full" src={activeURL}></img>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default CustomReportsView
