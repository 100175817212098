import React, { useContext } from "react"

// Modules

// Components

// Views

// Context providers / Utils
import TableContext from "./TableContext"
import { UIAlertContext } from "../UIAlert/UIAlert"

// Hooks

// Material-UI *

// Styles
import "./TableItem.css"

function TableItem(props) {
    const { rowData = {}, fields = [], children, previousPath = "", path = "", checked, checkedStatus, index } = props

    const {
        onRowDelete,
        onRowOpen,
        onRowClose,
        onRowSelect,
        onRowDeselect,
        onRemoveLabelFromField,
        openPaths,
        highlightByColumn,
    } = useContext(TableContext)

    // Test
    const { confirmationModal } = useContext(UIAlertContext)

    // test, will change variable and function names later
    let currentPath = openPaths[path]

    function addCurrentPath(_value) {
        const rowValue = rowData.__group ? true : rowData
        if (_value) {
            onRowOpen(rowValue, path, previousPath)
        } else {
            onRowClose(rowValue, path, previousPath)
        }
    }

    async function handleRowDelete(rowData) {
        const value = await confirmationModal(
            "Are you sure you want to delete this field?",
            "This action can not be undone",
            ["Delete Field", "Cancel"]
        )

        if (!value) {
            return
        }

        onRowDelete(rowData)
    }

    // pass row config options for any available field
    const rowOptions = {
        toggleDelete: () => handleRowDelete(rowData),
        // toggle content functionallity
        toggleContent: () => addCurrentPath(!currentPath),
        removeLabelFromField: onRemoveLabelFromField,
        currentPath,
        // check functionallity
        handleCheckChange: (e) => (e.target.checked ? onRowSelect(rowData) : onRowDeselect(rowData)),
        checked,
        checkedStatus,
        index,
        canToggleContent: rowData.data ? true : false,
    }

    return (
        <>
            <tr className={`table-item  ${checked ? "checked" : ""}`}>
                {fields &&
                    fields.map((field, index) => {
                        // individual field
                        const { render, component: Component, headerStyle, style, className = "" } = field

                        return (
                            <td
                                key={`${rowData.uuid}_${field.propName}_${index}`}
                                id={
                                    field.propName
                                        ? rowData.name
                                            ? `${rowData.name}__field-${field.propName}__table`
                                            : `field-${field.propName}__table`
                                        : rowData.name
                                        ? `${rowData.name}__field-options__table`
                                        : "field-options__table"
                                }
                                className={`${className} ${(field.sticky && "stick") || ""}`}
                                style={{
                                    ...(style || headerStyle),
                                    ...(highlightByColumn &&
                                        field.propName === highlightByColumn && { fontWeight: "bold" }),
                                    justifyContent:
                                        style || headerStyle ? (style || headerStyle).justifyContent : "center",
                                }}
                            >
                                {(render && render(rowData[field.propName], rowData, rowOptions)) ||
                                    (Component && (
                                        <Component
                                            value={rowData[field.propName]}
                                            rowData={rowData}
                                            rowOptions={rowOptions}
                                            propName={field.propName}
                                        />
                                    )) ||
                                    rowData[field.propName]}
                            </td>
                        )
                    })}
            </tr>
            {/* Table Meta Item */}
            {currentPath && children && (
                <tr className="nested-table__container">
                    <td colSpan={fields.length} className="nested-table">
                        <table className="table__content">
                            <tbody>{children}</tbody>
                        </table>
                    </td>
                </tr>
            )}
        </>
    )
}

export default React.memo(TableItem)
