import React, { useContext } from "react"
import { Card, Typography, Box, Slider } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import { CircularProgress } from "@material-ui/core"

import Dropdown from "../Dropdown"

import RedDotsIcon from "../../../../assets/Chart/red-dots.svg"
import BlueDotsIcon from "../../../../assets/Chart/blue-dots.svg"
import RedObservedIcon from "../../../../assets/Chart/red-observed.svg"
import BlueObservedIcon from "../../../../assets/Chart/blue-observed.svg"
import GrayIcon from "../../../../assets/Chart/gray-observed.svg"
import RedLightenHistoricalIcon from "../../../../assets/Chart/red-lighten-historical.svg"
import RedDarkenHistoricalIcon from "../../../../assets/Chart/red-darken-historical.svg"
import BlueLightenHistoricalIcon from "../../../../assets/Chart/blue-lighten-historical.svg"
import BlueDarkenHistoricalIcon from "../../../../assets/Chart/blue-darken-historical.svg"
import BlueDarkenHistoricalWaterBudgetIcon from "../../../../assets/Chart/blue-darken-for-water-budget-historical.svg"
import RedLightenSeasonalIcon from "../../../../assets/Chart/red-lighten-seasonal.svg"
import RedDarkenSeasonalIcon from "../../../../assets/Chart/red-darken-seasonal.svg"
import BlueLightenSeasonalIcon from "../../../../assets/Chart/blue-lighten-seasonal.svg"
import BlueDarkenSeasonalIcon from "../../../../assets/Chart/blue-darken-seasonal.svg"
import GreenLightenHistoricalIcon from "../../../../assets/Chart/green-lighten-historical.svg"
import GreenDarkenHistoricalIcon from "../../../../assets/Chart/green-darken-historical.svg"
import BrownLightenHistoricalIcon from "../../../../assets/Chart/brown-lighten-historical.svg"
import BrownDarkenHistoricalIcon from "../../../../assets/Chart/brown-darken-historical.svg"
import { SettingsContext } from "../../../../Util/SettingsContext"
import CandleStickLegendContent from "./candlestick-legend.chartspec"
import DoubleCandleStickLegendContent from "./double-candlestick-legend.chartspec"
import AreaLegendSpec from "./area-legend.chartspec"
import GradientLegendSpec from "./gradient-bar-legend.chartspec.js"
import { getUnit } from "../../../../helpers/chartHelpers"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import { Popover } from "../../../../ui/Popover"
import SimplePopover from "../../../../ui/Popover/SimplePopover"
import AboutIcon from "../../../../ui/Icons/Navbar/AboutIcon"
import moment from "moment"

import "./ChartSpecs.css"

// Create custom theme for switch slider
const theme = createTheme({
    overrides: {
        MuiSwitch: {
            switchBase: {
                // Controls default (unchecked) color for the thumb
                color: "#ccc",
            },
            colorSecondary: {
                "&$checked": {
                    // Controls checked color for the thumb
                    color: "#fafafa",
                },
            },
            track: {
                // Controls default (unchecked) color for the track
                opacity: 0.2,
                backgroundColor: "gray",
                "$checked$checked + &": {
                    // Controls checked color for the track
                    opacity: 1,
                    backgroundColor: "#41A3A4",
                },
            },
        },
    },
})

const useStyles = makeStyles(() => ({
    headerBlock: {
        border: 0,
        borderRadius: 0,
        boxShadow: "none",
    },
    temperatureText: {
        fontFamily: "Roboto",
        fontSize: 12,
        marginLeft: 12,
    },
    infoContainer: {
        display: "flex",
        alignItems: "center",
    },
}))

const ChartSpecs = ({
    barType,
    GDDPlantingDate,
    onGDDPlantingDateChange,
    type,
    chartRef,
    data,
    onSliderChange,
    sliderMinValue,
    sliderMaxValue,
    sliderDefaultValue,
    historicalPending,
    actionsState,
    disabled,
    onHourlyCsvDataTrigger,
    cumulative,
    confidenceVisible,
    climatologyVisible,
    lastUpdated = {},
    selectedGranularity,
    colsArr = ["Date", "Value"],
    handleAreasVisibilityChange = (d) => d,
    handleBarTypeChange = (d) => d,
}) => {
    const { currentSettings } = useContext(SettingsContext)

    const { headerBlockContainer, headerBlock, titleText, temperatureText, infoContainer } = useStyles()

    let monthlyLegendMetadata = {
        temp: {
            max: { title: "Maximum", color: "#EB6425", fill: "rgb(255, 200, 130)" },
            min: { title: "Minimum", color: "#0089C6", fill: "rgb(145, 235, 255)" },
        },
        precipitation: {
            sum: { title: "Precip.", color: "rgb(29, 116, 179)", fill: "rgb(145, 212, 255)" },
        },
    }

    let colsHourly = ["Timeline", "History", "Forecast"]

    if (actionsState.currentTab === "precipitation") {
        colsArr = ["Timeline", "History", "Forecast", "Observed"]
    } else if (actionsState.extraHumidityChart) {
        colsArr = ["Timeline", "History", "Forecast", "Observed"]
    } else if (actionsState.solarRadiationChart) {
        colsArr = ["Timeline", "History", "Forecast", "Observed"]
    } else if (actionsState.growingDegreeDaysChart) {
        colsArr = ["Timeline", "History", "Forecast", "Observed"]
    } else if (actionsState.extraEvapotranspirationChart) {
        colsArr = ["Timeline", "History", "Forecast", "Observed"]
    } else if (actionsState.humidity) {
        colsArr = ["Timeline", "History", "Forecast", "Observed"]
    }

    function parseDate(timestamp) {
        var d = new Date(timestamp * 1000)
        var da = moment(d).format("YYYY, MMM DD, HH:mm")
        return da
    }

    return (
        <div className="chart-specs">
            <Dropdown
                // onHourlyCsvDataTrigger={onHourlyCsvDataTrigger}
                chartRef={chartRef}
                cols={{ csv: colsArr, hourly: colsHourly }}
                data={data}
                actionsState={actionsState}
                disabled={disabled}
            />

            {actionsState.currentTab === "gdd" ? (
                <div className="gdd-planting-date-wrapper" style={{ marginBottom: "20px" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="MM/dd/yy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Planting Date"
                            autoOk={true}
                            value={GDDPlantingDate}
                            onChange={onGDDPlantingDateChange}
                            KeyboardButtonProps={{
                                "aria-label": "change date",
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </div>
            ) : null}

            {actionsState.currentTab === "gdd" ? (
                <div className="chart-specs__slider-block">
                    <Slider
                        key={`slider-${sliderDefaultValue}`}
                        defaultValue={sliderDefaultValue}
                        aria-labelledby="discrete-slider-small-steps"
                        step={1}
                        marks
                        min={sliderMinValue}
                        max={sliderMaxValue}
                        valueLabelDisplay="on"
                        onChangeCommitted={onSliderChange}
                        disabled={disabled}
                    />
                    <p style={{ marginBottom: "30px" }}>
                        Temp. Threshold ({getUnit({ system: currentSettings.units }).tempUnit})
                    </p>
                </div>
            ) : null}

            {actionsState.extraPrecipitationChart ? (
                <div className="chart-specs__slider-block">
                    <h5>Evaporation Rate</h5>
                    <Slider
                        defaultValue={sliderDefaultValue}
                        aria-labelledby="discrete-slider-small-steps"
                        step={0.1}
                        marks
                        min={sliderMinValue ?? 0.3}
                        max={sliderMaxValue ?? 2}
                        valueLabelDisplay="auto"
                        onChange={onSliderChange}
                        disabled={disabled}
                    />
                </div>
            ) : null}

            <div
                className="bar-legend-stats"
                style={{
                    display:
                        ["temp", "precipitation"].includes(type) &&
                        ["monthly"].includes(actionsState.selectedGranularity) &&
                        !cumulative
                            ? "flex"
                            : "none",
                }}
            >
                <div>
                    {["temp"].includes(type) && ["monthly"].includes(selectedGranularity) ? (
                        <>
                            <div className="chart-specs__i-icon-container">
                                <h5>Temperature Forecast</h5>
                                {lastUpdated[selectedGranularity].ds_fc && (
                                    <SimplePopover
                                        className="down left"
                                        text={"Last updated: "}
                                        text2={parseDate(lastUpdated[selectedGranularity]?.ds_fc)}
                                    >
                                        <AboutIcon className="chart-specs__i-icon" fill={"#B3B6BA"} />
                                    </SimplePopover>
                                )}
                            </div>
                            <Box className={infoContainer}>
                                <img alt="" src={RedObservedIcon} />
                                <Typography className={temperatureText}>Maximum</Typography>
                            </Box>
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={BlueObservedIcon} />
                                <Typography className={temperatureText}>Minimum</Typography>
                            </Box>
                        </>
                    ) : null}

                    {["precipitation", "water-budget"].includes(type) ? (
                        <>
                            <div className="chart-specs__i-icon-container">
                                <h5>Precipitation Forecast</h5>
                                {lastUpdated[selectedGranularity].ds_fc && (
                                    <SimplePopover
                                        className="down left"
                                        text={"Last updated: "}
                                        text2={parseDate(lastUpdated[selectedGranularity]?.ds_fc)}
                                    >
                                        <AboutIcon className="chart-specs__i-icon" fill={"#B3B6BA"} />
                                    </SimplePopover>
                                )}
                            </div>
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={BlueObservedIcon} />
                                <Typography className={temperatureText}>
                                    {["precipitation", "water-budget"].includes(type)
                                        ? currentSettings.units === "metric"
                                            ? "mm"
                                            : "in"
                                        : "T"}
                                </Typography>
                            </Box>
                        </>
                    ) : null}
                </div>

                {/**************************** BARS - TEMPERATURE & Precipitation *********************/}

                <div
                    className={headerBlockContainer}
                    style={{
                        display:
                            ["temp"].includes(type) && ["monthly"].includes(actionsState.selectedGranularity)
                                ? "initial"
                                : "none",
                    }}
                >
                    <Card className={headerBlock}>
                        <div className="chart-specs__i-icon-container">
                            <h5>
                                &nbsp;&nbsp;&nbsp;
                                <div
                                    style={{
                                        borderRadius: 8,
                                        padding: "0px",
                                        display: "inline-block",
                                        backgroundColor: "none",
                                        width: "28px",
                                        height: "20px",
                                    }}
                                >
                                    <ThemeProvider theme={theme}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={barType != "candl"}
                                                    size="small"
                                                    onChange={(d) => {
                                                        handleBarTypeChange(!(barType == "candl"))
                                                    }}
                                                    name="areas"
                                                />
                                            }
                                            label=""
                                        />
                                    </ThemeProvider>
                                </div>
                                Directional Forecast
                            </h5>
                            {lastUpdated[selectedGranularity].ds_fc && (
                                <SimplePopover
                                    className="down left"
                                    text={"Last updated: "}
                                    text2={parseDate(lastUpdated[selectedGranularity]?.ds_fc)}
                                >
                                    <AboutIcon className="chart-specs__i-icon" fill={"#B3B6BA"} />
                                </SimplePopover>
                            )}
                        </div>
                        <div style={{ opacity: barType != "candl" ? 1 : 0.5 }}>{GradientLegendSpec({})}</div>
                    </Card>
                </div>

                {/**************************** BARS - PRECIPITATION *********************/}
                <div
                    className={headerBlockContainer}
                    style={{
                        display:
                            ["precipitation"].includes(type) && ["monthly"].includes(actionsState.selectedGranularity)
                                ? "initial"
                                : "none",
                    }}
                >
                    <Card className={headerBlock}>
                        <div className="chart-specs__i-icon-container">
                            <h5>
                                &nbsp;&nbsp;&nbsp;
                                <div
                                    style={{
                                        borderRadius: 8,
                                        padding: "0px",
                                        display: "inline-block",
                                        backgroundColor: "none",
                                        width: "28px",
                                        height: "20px",
                                    }}
                                >
                                    <ThemeProvider theme={theme}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={barType != "candl"}
                                                    size="small"
                                                    onChange={(d) => {
                                                        handleBarTypeChange(!(barType == "candl"))
                                                    }}
                                                    name="areas"
                                                />
                                            }
                                            label=""
                                        />
                                    </ThemeProvider>
                                </div>
                                Directional Forecast
                            </h5>
                            {lastUpdated[selectedGranularity].ds_fc && (
                                <SimplePopover
                                    className="down left"
                                    text={"Last updated: "}
                                    text2={parseDate(lastUpdated[selectedGranularity]?.ds_fc)}
                                >
                                    <AboutIcon className="chart-specs__i-icon" fill={"#B3B6BA"} />
                                </SimplePopover>
                            )}
                        </div>
                        <div style={{ opacity: barType != "candl" ? 1 : 0.5 }}>
                            {GradientLegendSpec({
                                colors: ["#0e4775", "#c9c9c9", "#c9c9c9", "#dedede", "#ffbb00", "#916b00"],
                                labels: ["More precipitation", "Normal", "Less precipitation"],
                            })}
                        </div>
                    </Card>
                </div>
            </div>

            <div
                className={headerBlockContainer}
                style={{
                    display:
                        ["temp", "precipitation"].includes(type) &&
                        ["monthly"].includes(actionsState.selectedGranularity)
                            ? "initial"
                            : "none",
                }}
            >
                <div className="bar-legend-stats__block">
                    &nbsp;&nbsp;&nbsp;
                    <div
                        style={{
                            borderRadius: 8,
                            padding: "0px",
                            display: "inline-block",
                            backgroundColor: "none",
                            width: "28px",
                            height: "20px",
                        }}
                    >
                        <ThemeProvider theme={theme}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={barType == "candl"}
                                        size="small"
                                        onChange={(d) => {
                                            handleBarTypeChange(!(barType == "candl"))
                                        }}
                                        name="areas"
                                    />
                                }
                                label=""
                            />
                        </ThemeProvider>
                    </div>
                    <div className="chart-specs__i-icon-container">
                        <h5
                            className="w-[130px]"
                            style={{
                                opacity: barType != "candl" ? 0.5 : 1,
                                margin: "0px",
                            }}
                        >
                            Historical Boxplots (last 20 years)
                        </h5>
                        {lastUpdated[selectedGranularity].ds_clim && (
                            <SimplePopover
                                className="down left"
                                text={"Last updated: "}
                                text2={parseDate(lastUpdated[selectedGranularity]?.ds_clim)}
                            >
                                <AboutIcon className="chart-specs__i-icon" fill={"#B3B6BA"} />
                            </SimplePopover>
                        )}
                    </div>
                    <div style={{ width: "100%", opacity: barType == "candl" ? 1 : 0.5 }}>
                        {DoubleCandleStickLegendContent(monthlyLegendMetadata[type])}
                    </div>
                </div>
            </div>

            <div
                className="chart-specs__specs-block-container"
                style={{
                    display:
                        !cumulative &&
                        ["temp", "precipitation"].includes(type) &&
                        ["monthly"].includes(actionsState.selectedGranularity)
                            ? "none"
                            : "flex",
                }}
            >
                {/* 'Observed' part of legend */}
                <div className="chart-specs__specs-block">
                    <div className="chart-specs__i-icon-container">
                        <h5>Observed</h5>
                        {lastUpdated[selectedGranularity].ds_hist && (
                            <SimplePopover
                                className="down left"
                                text={"Last updated: "}
                                text2={parseDate(lastUpdated[selectedGranularity]?.ds_hist)}
                            >
                                <AboutIcon className="chart-specs__i-icon" fill={"#B3B6BA"} />
                            </SimplePopover>
                        )}
                    </div>
                    <Card className={headerBlock}>
                        {["growing-degree-days"].includes(type) ? (
                            <>
                                <Box className={infoContainer}>
                                    <img alt="" src={RedObservedIcon} />
                                    <Typography className={temperatureText}>GDDs</Typography>
                                </Box>
                            </>
                        ) : null}
                        {["temp-soil"].includes(type) ? (
                            <>
                                <Box className={infoContainer}>
                                    <img alt="" src={RedObservedIcon} />
                                    <Typography className={temperatureText}>Temp</Typography>
                                </Box>
                            </>
                        ) : null}

                        {["temp"].includes(type) && ["hourly"].includes(selectedGranularity) ? (
                            <>
                                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                    <img alt="" src={BlueObservedIcon} />
                                    <Typography className={temperatureText}>Temp</Typography>
                                </Box>
                            </>
                        ) : null}

                        {["temp"].includes(type) && ["daily", "monthly"].includes(selectedGranularity) ? (
                            <>
                                <Box className={infoContainer}>
                                    <img alt="" src={RedObservedIcon} />
                                    <Typography className={temperatureText}>Tmax</Typography>
                                </Box>
                                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                    <img alt="" src={BlueObservedIcon} />
                                    <Typography className={temperatureText}>Tmin</Typography>
                                </Box>
                            </>
                        ) : null}
                        {["wind_speed"].includes(type) ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={RedObservedIcon} />
                                <Typography className={temperatureText}>
                                    {["wind_speed"].includes(type)
                                        ? currentSettings.units === "metric"
                                            ? "km / h"
                                            : "MPH"
                                        : "T"}
                                </Typography>
                            </Box>
                        ) : null}
                        {["relative-humidity"].includes(type) ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={BlueObservedIcon} />
                                <Typography className={temperatureText}>Relative Humidity</Typography>
                            </Box>
                        ) : null}
                        {["precipitation", "water-budget"].includes(type) ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={BlueObservedIcon} />
                                <Typography className={temperatureText}>
                                    {["precipitation", "water-budget"].includes(type)
                                        ? currentSettings.units === "metric"
                                            ? "mm"
                                            : "in"
                                        : "T"}
                                </Typography>
                            </Box>
                        ) : null}
                        {["soil_moisture"].includes(type) ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={RedObservedIcon} />
                                <Typography className={temperatureText}>Water per m^3 of soil</Typography>
                            </Box>
                        ) : null}
                        {["evapotranspiration"].includes(type) ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={RedObservedIcon} />
                                <Typography className={temperatureText}>
                                    {currentSettings.units === "metric" ? "mm" : "in"}
                                </Typography>
                            </Box>
                        ) : null}
                        {type === "solar-radiation" ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={RedObservedIcon} />
                                <Typography className={temperatureText}>Watt per m^2</Typography>
                            </Box>
                        ) : null}
                    </Card>
                </div>
                {/* 'Forecast' part of legend */}
                <div className="chart-specs__specs-block">
                    <div className="chart-specs__i-icon-container">
                        <h5>Forecast</h5>
                        {lastUpdated[selectedGranularity].ds_fc && (
                            <SimplePopover
                                className="down left"
                                text={"Last updated: "}
                                text2={parseDate(lastUpdated[selectedGranularity]?.ds_fc)}
                            >
                                <AboutIcon className="chart-specs__i-icon" fill={"#B3B6BA"} />
                            </SimplePopover>
                        )}
                    </div>
                    <Card className={headerBlock}>
                        {["growing-degree-days"].includes(type) ? (
                            <>
                                <Box className={infoContainer}>
                                    <img alt="" src={RedDotsIcon} />
                                    <Typography className={temperatureText}>GDDs</Typography>
                                </Box>
                            </>
                        ) : null}
                        {["temp-soil"].includes(type) ? (
                            <>
                                <Box className={infoContainer}>
                                    <img alt="" src={RedDotsIcon} />
                                    <Typography className={temperatureText}>Temp</Typography>
                                </Box>
                            </>
                        ) : null}

                        {["temp"].includes(type) && ["hourly"].includes(selectedGranularity) ? (
                            <>
                                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                    <img alt="" src={BlueDotsIcon} />
                                    <Typography className={temperatureText}>Temp</Typography>
                                </Box>
                            </>
                        ) : null}

                        {["temp"].includes(type) && ["daily", "monthly"].includes(selectedGranularity) ? (
                            <>
                                <Box className={infoContainer}>
                                    <img alt="" src={RedDotsIcon} />
                                    <Typography className={temperatureText}>Tmax</Typography>
                                </Box>
                                <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                    <img alt="" src={BlueDotsIcon} />
                                    <Typography className={temperatureText}>Tmin</Typography>
                                </Box>
                            </>
                        ) : null}
                        {["relative-humidity"].includes(type) ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={BlueDotsIcon} />
                                <Typography className={temperatureText}>Relative Humidity</Typography>
                            </Box>
                        ) : null}
                        {["precipitation", "water-budget"].includes(type) ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={BlueDotsIcon} />
                                <Typography className={temperatureText}>
                                    {["precipitation", "water-budget"].includes(type)
                                        ? currentSettings.units === "metric"
                                            ? "mm"
                                            : "in"
                                        : "T"}
                                </Typography>
                            </Box>
                        ) : null}
                        {["wind_speed"].includes(type) ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={RedDotsIcon} />
                                <Typography className={temperatureText}>
                                    {["wind_speed"].includes(type)
                                        ? currentSettings.units === "metric"
                                            ? "km / h"
                                            : "MPH"
                                        : "T"}
                                </Typography>
                            </Box>
                        ) : null}

                        {["soil_moisture"].includes(type) ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={RedDotsIcon} />
                                <Typography className={temperatureText}>Water per m^3 of soil</Typography>
                            </Box>
                        ) : null}

                        {["evapotranspiration"].includes(type) ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={RedDotsIcon} />
                                <Typography className={temperatureText}>
                                    {currentSettings.units === "metric" ? "mm" : "in"}
                                </Typography>
                            </Box>
                        ) : null}
                        {type === "solar-radiation" ? (
                            <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                <img alt="" src={RedDotsIcon} />
                                <Typography className={temperatureText}>Watt per m^2</Typography>
                            </Box>
                        ) : null}
                    </Card>
                </div>
                {/* 'Climatology' part of legend */}

                {(cumulative && ["precipitation"].includes(type)) || ["growing-degree-days"].includes(type) ? (
                    ""
                ) : (
                    <div
                        className="chart-specs__specs-block toggler"
                        style={{
                            display: ["hourly"].includes(selectedGranularity) ? "none" : "initial",
                        }}
                    >
                        <h5>
                            <div
                                style={{
                                    borderRadius: 8,
                                    padding: "0px",
                                    display: "inline-block",
                                    backgroundColor: "none",
                                    width: "28px",
                                    height: "20px",
                                }}
                            >
                                <ThemeProvider theme={theme}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={confidenceVisible}
                                                size="small"
                                                onChange={(d) => {
                                                    handleAreasVisibilityChange({
                                                        conf: !confidenceVisible,
                                                        clim: climatologyVisible,
                                                    })
                                                }}
                                                name="areas"
                                            />
                                        }
                                        label=""
                                    />
                                </ThemeProvider>
                            </div>
                            Confidence Bands
                        </h5>

                        <Card className={headerBlock} style={{ display: confidenceVisible ? "initial" : "none" }}>
                            {["growing-degree-days"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={RedLightenSeasonalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={RedDarkenSeasonalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}
                            {["temp-soil"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={RedLightenSeasonalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={RedDarkenSeasonalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}
                            {["temp"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={RedLightenSeasonalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={RedDarkenSeasonalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginTop: 14 }}>
                                        <img alt="" src={BlueLightenSeasonalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={BlueDarkenSeasonalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}
                            {["precipitation", "relative-humidity"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={BlueLightenSeasonalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={BlueDarkenSeasonalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}
                            {["evapotranspiration", "solar-radiation", "wind_speed", "soil_moisture"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={RedLightenSeasonalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={RedDarkenSeasonalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}
                        </Card>
                    </div>
                )}

                {(cumulative && ["precipitation"].includes(type)) || ["growing-degree-days"].includes(type) ? (
                    ""
                ) : (
                    <div
                        className="chart-specs__specs-block"
                        style={{
                            display: ["hourly"].includes(selectedGranularity) ? "none" : "initial",
                        }}
                    >
                        <div className="chart-specs__toggle-container">
                            <div
                                style={{
                                    borderRadius: 8,
                                    padding: "0px",
                                    display: "inline-block",
                                    backgroundColor: "none",
                                    width: "28px",
                                    height: "20px",
                                }}
                            >
                                <ThemeProvider theme={theme}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={climatologyVisible}
                                                size="small"
                                                onChange={(d) => {
                                                    handleAreasVisibilityChange({
                                                        conf: confidenceVisible,
                                                        clim: !climatologyVisible,
                                                    })
                                                }}
                                                name="areas"
                                            />
                                        }
                                        label=""
                                    />
                                </ThemeProvider>
                                <div></div>
                            </div>
                            {historicalPending ? (
                                <>
                                    <div className="chart-specs__toggle-container-text">
                                        {`Historical average ${
                                            actionsState.selectedGranularity === "daily"
                                                ? "(last 10 years)"
                                                : "(last 20 years)"
                                        }`}
                                    </div>
                                    <CircularProgress
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            marginRight: "7px",
                                            marginLeft: "5px",
                                        }}
                                    />
                                </>
                            ) : (
                                <div className="chart-specs__toggle-container-text">
                                    {`Historical average ${
                                        actionsState.selectedGranularity === "daily"
                                            ? "(last 10 years)"
                                            : "(last 20 years)"
                                    }`}
                                </div>
                            )}

                            {climatologyVisible && lastUpdated["daily"].ds_clim && (
                                <SimplePopover
                                    className="down left"
                                    text={"Last updated: "}
                                    text2={parseDate(lastUpdated["daily"]?.ds_clim)}
                                >
                                    <AboutIcon className="chart-specs__i-icon" fill={"#B3B6BA"} />
                                </SimplePopover>
                            )}
                        </div>
                        <Card className={headerBlock} style={{ display: climatologyVisible ? "initial" : "none" }}>
                            {["temp-soil"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={RedLightenHistoricalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={RedDarkenHistoricalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}
                            {["temp"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={RedLightenHistoricalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={RedDarkenHistoricalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginTop: 14 }}>
                                        <img alt="" src={BlueLightenHistoricalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={BlueDarkenHistoricalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}

                            {["water-budget"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={BlueLightenHistoricalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={BlueDarkenHistoricalWaterBudgetIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}

                            {["precipitation", "relative-humidity", "water-budget"].includes(type) ? (
                                cumulative ? (
                                    ""
                                ) : (
                                    <>
                                        <Box className={infoContainer}>
                                            <img alt="" src={BlueLightenHistoricalIcon} />
                                            <Typography className={temperatureText}>90%</Typography>
                                        </Box>
                                        <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                            <img alt="" src={BlueDarkenHistoricalIcon} />
                                            <Typography className={temperatureText}>50%</Typography>
                                        </Box>
                                    </>
                                )
                            ) : null}
                            {["evapotranspiration", "wind_speed"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={GreenLightenHistoricalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={GreenDarkenHistoricalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}
                            {["soil_moisture"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={BrownLightenHistoricalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={BrownDarkenHistoricalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}
                            {["solar-radiation"].includes(type) ? (
                                <>
                                    <Box className={infoContainer}>
                                        <img alt="" src={RedLightenHistoricalIcon} />
                                        <Typography className={temperatureText}>90%</Typography>
                                    </Box>
                                    <Box className={infoContainer} style={{ marginBottom: 0 }}>
                                        <img alt="" src={RedDarkenHistoricalIcon} />
                                        <Typography className={temperatureText}>50%</Typography>
                                    </Box>
                                </>
                            ) : null}
                        </Card>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ChartSpecs
