import React from "react"
import Checkbox from "../../../../ui/Checkbox/Checkbox"
// Modules
// Components
// Views
// Context providers / Utils
// Hooks
// Material-UI *
// Styles
import "../TableSettings.css"

const TableColumns = (props) => {
    const { columns, onCheckboxChange = () => null, isAllowedToEdit } = props
    const readableVariableName = {
        t2m: "Temperature",
        tp: "Precipitation",
        yield: "Yield",
        planting_date: "Planting Date",
        labels: "Labels",
    }

    return Object.entries(columns).map((column) => {
        if (column[0] === "yield") {
            return null
        }
        return (
            <div key={"col-" + column[0]} className="checkbox-container">
                <span className="checkbox">
                    <label className="checkbox-label">
                        {column[1] && (
                            <svg
                                width="90%"
                                height="90%"
                                viewBox="0 0 8 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.74982 4.73743L1.01232 2.99993L0.420654 3.58743L2.74982 5.9166L7.74982 0.916601L7.16232 0.329102L2.74982 4.73743Z"
                                    fill="rgb(0 0 0 / 60%)"
                                />
                            </svg>
                        )}
                    </label>
                    <div style={{ paddingRight: "10px" }}>
                        <Checkbox
                            onChange={(e) => {
                                if (isAllowedToEdit) onCheckboxChange(column[0])
                            }}
                            checked={column[1]}
                            disabled={!isAllowedToEdit}
                            status={column[1] ? "full" : "empty"}
                        />
                    </div>
                    {/* <input
                        type="checkbox"
                        onChange={(e) => {
                            if (isAllowedToEdit) onCheckboxChange(column[0])
                        }}
                        checked={column[1]}
    
                        disabled={!isAllowedToEdit}
                    /> */}
                </span>
                <div
                    className={`checkbox-text ${!isAllowedToEdit ? "checkbox-disabled" : ""}`}
                    onClick={(e) => {
                        if (isAllowedToEdit) onCheckboxChange(column[0])
                    }}
                >
                    {readableVariableName[column[0]]}
                </div>
            </div>
        )
    })
}

export default TableColumns
