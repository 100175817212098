import React from "react"

import { titleCase } from "../../../../Util/General"

const VarietyTab = (props) => {
    const { name } = props

    return (
        <button id={`${name}-variety-tab__alert-settings`} className="alert-settings__alerts__variety-button">
            <div className="alert-settings__alerts__variety-title">{titleCase(name)} Variety</div>
        </button>
    )
}

export default VarietyTab
