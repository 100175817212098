import React, { useContext, useEffect, useMemo, useState } from "react"
import _ from "lodash"

import StageTypeSelect from "./components/StageTypeSelect"
import ListItem from "./components/ListItem"
import AddEditItem from "./components/AddEditItem"
import ColumnHeader from "./components/ColumnHeader"
import AddNewItem from "./components/AddNewItem"
import CropConfirmationModal from "./components/CropConfirmationModal"
import ProtectorGlass from "./components/ProtectorGlass"
import {
    getAllCrops,
    getDefaultCrops,
    saveCrop,
    saveVariety,
    deleteCrop,
    saveStage,
    updateStage,
    updateCrop,
    updateVariety,
    deleteVariety,
    deleteStage,
} from "../../../services/cropVariety.service"
import { toast, ToastContainer } from "react-toastify"
import LoadingBar from "../../../components/LoadingBar"
import { DataContext } from "../../../Util/Data"
import { getTableData } from "../../../services/table.service"

const CropVarietySettings = (props) => {
    const [suggestions, setSuggestions] = useState({})
    const [crops, setCrops] = useState({})

    const [activeCrop, setActiveCrop] = useState("")
    const [activeVariety, setActiveVariety] = useState("")
    const [activeStage, setActiveStage] = useState("")

    const [editingCrop, setEditingCrop] = useState("")
    const [editingVariety, setEditingVariety] = useState("")
    const [editingStage, setEditingStage] = useState("")

    const [isAddingCrop, setIsAddingCrop] = useState(false)
    const [isAddingVariety, setIsAddingVariety] = useState(false)
    const [isAddingStage, setIsAddingStage] = useState(false)

    const [checkedCrops, setCheckedCrops] = useState({})
    const [checkedVarieties, setCheckedVarieties] = useState({})
    const [checkedStages, setCheckedStages] = useState({})

    const [tweakingType, setTweakingType] = useState(false)
    const [possibleType, setPosibleType] = useState("")

    const [loadingCrop, setLoadingCrop] = useState(false)
    const [loadingVariety, setLoadingVariety] = useState(false)
    const [loadingStage, setLoadingStage] = useState(false)

    const [confirmationModal, setConfirmationModal] = useState({
        active: false,
        title: "",
        implications: "",
        nextText: "",
        nextFunction: () => {},
    })

    const [searchInput, setSearchInput] = useState("")
    const [searchTarget, setSearchTarget] = useState("")

    const { dispatch } = useContext(DataContext)

    useEffect(() => {
        async function fetchData() {
            setLoadingCrop(true)
            await getAllCrops().then(async (response) => {
                setCrops(response)
            })
            await getDefaultCrops().then(async (response) => {
                setSuggestions(response)
            })
            setLoadingCrop(false)
        }
        fetchData()
    }, [])

    const debounceSearch = _.debounce((value) => {
        setSearchInput(value)
    }, 500)

    const cropsToShow = useMemo(() => {
        let toShow = {}
        const items = crops
        if (searchTarget === "Crops") {
            Object.keys(items).forEach((key) => {
                if (items[key].name.toLowerCase().includes(searchInput.toLowerCase())) {
                    toShow[key] = items[key]
                }
            })
        } else {
            toShow = { ...items }
        }
        return toShow
    }, [crops, searchInput, searchTarget])

    const varietiesToShow = useMemo(() => {
        let toShow = {}
        if (activeCrop !== "") {
            const items = crops[activeCrop].varieties
            if (searchTarget === "Varieties") {
                Object.keys(items).forEach((key) => {
                    if (items[key].name.toLowerCase().includes(searchInput.toLowerCase())) {
                        toShow[key] = items[key]
                    }
                })
            } else {
                toShow = { ...items }
            }
        }
        return toShow
    }, [crops, searchInput, searchTarget, activeCrop])

    const stagesToShow = useMemo(() => {
        let toShow = {}
        if (activeCrop !== "" && activeVariety !== "") {
            const items = crops[activeCrop].varieties[activeVariety].stages
            if (searchTarget === "Crop Stages") {
                Object.keys(items).forEach((key) => {
                    if (items[key].name.toLowerCase().includes(searchInput.toLowerCase())) {
                        toShow[key] = items[key]
                    }
                })
            } else {
                toShow = { ...items }
            }
        }
        return toShow
    }, [crops, searchInput, searchTarget, activeCrop, activeVariety])

    const updateStageType = async (type, baseTemp, isSuggested) => {
        const varietyInfo = {
            ...crops[activeCrop].varieties[activeVariety],
            type,
            baseTemp,
            isSuggested:
                crops[activeCrop].default_id !== undefined && crops[activeCrop].default_id !== null && isSuggested,
            default_id:
                crops[activeCrop].default_id !== undefined && crops[activeCrop].default_id !== null
                    ? Object.keys(suggestions[crops[activeCrop].default_id].varieties)[0]
                    : null,
        }
        setLoadingStage(true)
        // await delete
        await updateVariety(crops[activeCrop], varietyInfo).then(async (res) => {
            await getAllCrops().then(async (response) => {
                const varieties = response[activeCrop].varieties
                const index = Object.entries(varieties).find((obj) => obj[1].name === varietyInfo.name)
                setCrops(response)
                setActiveVariety(index[0])
                setPosibleType("")
                setTweakingType(false)
                setIsAddingStage(true)
                setLoadingStage(false)
            })
        })
    }

    const setUpdateStageType = (optionalBaseTemp, isSuggested) => {
        const baseTemp = optionalBaseTemp !== undefined && optionalBaseTemp !== 0 ? optionalBaseTemp : null
        const currentType = crops[activeCrop].varieties[activeVariety].type
        const stagesLength = Object.keys(crops[activeCrop].varieties[activeVariety].stages).length

        if (currentType === "" || stagesLength === 0) {
            updateStageType(possibleType, baseTemp, isSuggested)
            return
        }
        setConfirmationModal({
            active: true,
            title: `Are you sure you want to switch to ${possibleType.toUpperCase()}?`,
            implications: "This action will delete your current crop stages for this crop.",
            nextText: "Yes",
            nextFunction: async () => {
                await deleteSelectedStages(crops[activeCrop].varieties[activeVariety].stages, false)
                updateStageType(possibleType, baseTemp, isSuggested)
            },
            cancelFunction: () => {
                setPosibleType("")
                setTweakingType(false)
            },
        })
    }

    const saveStageInfo = async (stageInfo) => {
        setLoadingStage(true)
        if (activeStage !== "") {
            await updateStage(crops[activeCrop], crops[activeCrop].varieties[activeVariety], stageInfo)
                .then(async (res) => {
                    await getAllCrops().then(async (response) => {
                        setCrops(response)
                        setLoadingStage(false)
                    })
                })
                .catch((err) => {
                    toast.error("Error updating stage")
                    setLoadingStage(false)
                })
        } else {
            await saveStage(crops[activeCrop], crops[activeCrop].varieties[activeVariety], stageInfo)
                .then(async (res) => {
                    await getAllCrops().then(async (response) => {
                        setCrops(response)
                        setLoadingStage(false)
                    })
                })
                .catch((err) => {
                    toast.error("Error creating stage")
                    setLoadingStage(false)
                })
        }
    }

    const saveVarietyInfo = async (varietyInfo) => {
        setLoadingVariety(true)
        if (activeVariety !== "") {
            await updateVariety(crops[activeCrop], varietyInfo)
                .then(async (res) => {
                    await getAllCrops().then(async (response) => {
                        const varieties = response[activeCrop].varieties
                        const index = Object.entries(varieties).find((obj) => obj[1].name === varietyInfo.name)
                        setCrops(response)
                        setActiveVariety(index[0])
                        setIsAddingStage(true)
                    })
                })
                .catch((err) => {
                    toast.error("Error updating variety")
                    setLoadingVariety(false)
                })
        } else {
            await saveVariety(crops[activeCrop], varietyInfo)
                .then(async (res) => {
                    await getAllCrops().then(async (response) => {
                        const varieties = response[activeCrop].varieties
                        const index = Object.entries(varieties).find((obj) => obj[1].name === varietyInfo.name)
                        setCrops(response)
                        setActiveVariety(index[0])
                        setIsAddingStage(true)
                    })
                })
                .catch((err) => {
                    toast.error("Error creating variety")
                    setLoadingVariety(false)
                })
        }
        setLoadingVariety(false)
    }

    const saveCropInfo = async (cropInfo) => {
        setLoadingCrop(true)
        if (activeCrop !== "") {
            await updateCrop(cropInfo)
                .then(async (res) => {
                    await getAllCrops().then(async (response) => {
                        const index = Object.entries(response).find((obj) => obj[1].name === cropInfo.name)
                        setCrops({ ...response })
                        setActiveCrop(index[0])
                        setIsAddingVariety(true)
                    })
                })
                .catch((err) => {
                    toast.error("Error updating crop")
                    setLoadingCrop(false)
                })
        } else {
            await saveCrop(cropInfo)
                .then(async (res) => {
                    await getAllCrops().then(async (response) => {
                        const index = Object.entries(response).find((obj) => obj[1].name === cropInfo.name)
                        setCrops({ ...response })
                        setActiveCrop(index[0])
                        setIsAddingVariety(true)
                    })
                })
                .catch((err) => {
                    toast.error("Error creating crop")
                    setLoadingCrop(false)
                })
        }
        setLoadingCrop(false)
    }

    const toggleCheckedItem = (itemId, checkedItems, setCheckedItems, extraFunction) => {
        if (checkedItems.hasOwnProperty(itemId)) {
            delete checkedItems[itemId]
        } else {
            checkedItems[itemId] = true
        }
        setCheckedItems({ ...checkedItems })
        extraFunction()
    }

    const deleteSelectedCrops = async (crops, checkedItems) => {
        const itemsToDelete = Object.keys(checkedItems)
        setLoadingCrop(true)
        for await (const item of itemsToDelete) {
            await deleteCrop(crops[item])
                .then((response) => {})
                .catch((err) => {})
        }
        toast.success((itemsToDelete.length > 1 ? "Crops" : "Crop") + " deleted successfully.")
        await getAllCrops().then(async (response) => {
            setCrops({ ...response })
            setCheckedCrops({})
            setConfirmationModal({ active: false })
        })
        setLoadingCrop(false)
        dispatch({ type: "GET_FIELDS", payload: { fields: await getTableData() } })
    }

    const deleteSelectedVarieties = async (varieties, checkedItems) => {
        const itemsToDelete = Object.keys(checkedItems)
        setLoadingVariety(true)
        for await (const item of itemsToDelete) {
            await deleteVariety(crops[activeCrop], crops[activeCrop].varieties[item])
                .then((response) => {})
                .catch((err) => {})
        }
        toast.success((itemsToDelete.length > 1 ? "Varieties" : "Variety") + " deleted successfully.")
        await getAllCrops().then(async (response) => {
            setCrops({ ...response })
            setCheckedVarieties({})
            setConfirmationModal({ active: false })
        })
        setLoadingVariety(false)
        dispatch({ type: "GET_FIELDS", payload: { fields: await getTableData() } })
    }

    const deleteSelectedStages = async (checkedItems, showToast = false) => {
        const itemsToDelete = Object.keys(checkedItems)
        setLoadingStage(true)
        for await (const item of itemsToDelete) {
            await deleteStage(
                crops[activeCrop],
                crops[activeCrop].varieties[activeVariety],
                crops[activeCrop].varieties[activeVariety].stages[item]
            )
                .then((response) => {})
                .catch((err) => {})
        }
        if (showToast) {
            toast.success((itemsToDelete.length > 1 ? "Stages" : "Stage") + " deleted successfully.")
        }
        await getAllCrops().then(async (response) => {
            setCrops({ ...response })
            setCheckedStages({})
            setConfirmationModal({ active: false })
        })
        setLoadingStage(false)
    }

    return (
        <>
            {confirmationModal.active && (
                <CropConfirmationModal info={confirmationModal} setConfirmationModal={setConfirmationModal} />
            )}
            <div className="roboto min-h-full max-w-5xl mx-auto">
                <div className="flex flex-row justify-evenly md:divide-x-2 divide-gray-100 roboto h-[540px] md:h-[680px] bg-white overflow-hidden shadow-sm border-2 border-gray-100 rounded-xl mx-4 lg:mx-2">
                    <div className={(activeCrop === "" ? "flex " : "hidden md:flex ") + "flex-1 flex-col relative"}>
                        <ColumnHeader
                            title="Crops"
                            prevSelectedItemName="Crops"
                            items={cropsToShow}
                            checkedItems={checkedCrops}
                            setCheckedItems={setCheckedCrops}
                            deleteCheckedItems={() => {
                                setConfirmationModal({
                                    active: true,
                                    title: "Are you sure you want to delete?",
                                    implications:
                                        "All the selected crops will be deleted. All the related varieties and crop stages will be deleted as well.",
                                    nextText: "Delete",
                                    nextFunction: () => {
                                        deleteSelectedCrops(crops, checkedCrops)
                                    },
                                })
                            }}
                            debounceSearch={debounceSearch}
                            closeSearch={() => {
                                setSearchInput("")
                                setSearchTarget("")
                            }}
                            searchTarget={searchTarget}
                            openSearch={(title) => {
                                setSearchTarget(title)
                                setIsAddingCrop(false)
                                setIsAddingVariety(false)
                                setIsAddingStage(false)
                                setEditingCrop("")
                                setEditingVariety("")
                                setEditingStage("")
                            }}
                        />
                        <div className="absolute top-[50px] h-1 inset-x-0 z-full">
                            <LoadingBar loading={loadingCrop} />
                        </div>
                        <AddNewItem
                            doShow={true}
                            addItem={() => {
                                setIsAddingCrop(true)
                                setActiveCrop("")
                                setEditingCrop("")
                                setIsAddingVariety("")
                                setActiveVariety("")
                                setEditingVariety("")
                            }}
                            itemName="crop"
                            prevItemName=""
                            blockAddButton={isAddingCrop || editingCrop !== ""}
                        />
                        <div className="flex-grow overflow-y-auto">
                            {isAddingCrop && editingCrop === "" && (
                                <AddEditItem
                                    setIsActive={setIsAddingCrop}
                                    itemInfo={null}
                                    saveItemInfo={saveCropInfo}
                                    defaultEmptyValues={{
                                        name: "",
                                        varieties: {},
                                    }}
                                    validateFunction={(item) => {
                                        const taken = Object.keys(crops).map((key) => {
                                            return crops[key].name.toLowerCase()
                                        })
                                        return item.name !== "" && !taken.includes(item.name.toLowerCase())
                                    }}
                                    suggestions={suggestions}
                                />
                            )}
                            {Object.keys(cropsToShow)
                                .reverse()
                                .map((key) => {
                                    return (
                                        <ListItem
                                            itemId={key}
                                            itemInfo={crops[key]}
                                            activeItem={activeCrop}
                                            activateItem={() => {
                                                if (Object.keys(checkedCrops).length === 0) {
                                                    setActiveCrop(key.toString())
                                                    setIsAddingCrop(false)
                                                    setActiveVariety("")
                                                    setEditingVariety("")
                                                    setIsAddingVariety(false)
                                                    setActiveStage("")
                                                    setEditingStage("")
                                                    setIsAddingStage(false)
                                                    setTweakingType(false)
                                                    setCheckedVarieties({})
                                                    setCheckedStages({})
                                                }
                                            }}
                                            saveItemInfo={saveCropInfo}
                                            isAddingItem={isAddingCrop}
                                            editingItem={editingCrop}
                                            setEditingItem={setEditingCrop}
                                            checkedItems={checkedCrops}
                                            toggleCheckedItem={() => {
                                                toggleCheckedItem(key, checkedCrops, setCheckedCrops, () => {
                                                    setActiveCrop("")
                                                    setActiveVariety("")
                                                    setCheckedVarieties({})
                                                    setCheckedStages({})
                                                })
                                            }}
                                            childrenPropName="varieties"
                                            defaultEmptyValues={{
                                                name: "",
                                                varieties: {},
                                            }}
                                            validateFunction={(item) => {
                                                const taken = Object.keys(crops).map((subkey) => {
                                                    if (key !== subkey) {
                                                        return crops[subkey].name.toLowerCase()
                                                    }
                                                })
                                                return item.name !== "" && !taken.includes(item.name.toLowerCase())
                                            }}
                                            suggestions={suggestions}
                                        />
                                    )
                                })}
                        </div>
                    </div>
                    <div
                        className={
                            (activeCrop !== "" && activeVariety === "" ? "flex " : "hidden md:flex ") +
                            "flex-1 flex-col relative"
                        }
                    >
                        {(isAddingCrop || editingCrop !== "") && <ProtectorGlass />}
                        <ColumnHeader
                            title="Varieties"
                            prevSelectedItemName={activeCrop ? crops[activeCrop].name : "Varieties"}
                            items={varietiesToShow}
                            checkedItems={checkedVarieties}
                            setCheckedItems={setCheckedVarieties}
                            isActive={activeCrop !== ""}
                            deleteCheckedItems={() => {
                                setConfirmationModal({
                                    active: true,
                                    title: "Are you sure you want to delete?",
                                    implications:
                                        "All the selected varieties will be deleted. All the related crop stages will be deleted as well.",
                                    nextText: "Delete",
                                    nextFunction: () => {
                                        deleteSelectedVarieties(crops[activeCrop].varieties, checkedVarieties)
                                    },
                                })
                            }}
                            debounceSearch={debounceSearch}
                            closeSearch={() => {
                                setSearchInput("")
                                setSearchTarget("")
                            }}
                            searchTarget={searchTarget}
                            openSearch={(title) => {
                                setSearchTarget(title)
                                setIsAddingCrop(false)
                                setIsAddingVariety(false)
                                setIsAddingStage(false)
                                setEditingCrop("")
                                setEditingVariety("")
                                setEditingStage("")
                            }}
                            goBack={() => setActiveCrop("")}
                        />
                        <div className="absolute top-[50px] h-1 inset-x-0 z-full">
                            <LoadingBar loading={loadingVariety} />
                        </div>
                        <AddNewItem
                            doShow={activeCrop !== ""}
                            addItem={() => {
                                setIsAddingVariety(true)
                                setActiveVariety("")
                                setEditingVariety("")
                                setPosibleType("")
                            }}
                            itemName="variety"
                            prevItemName="crop"
                            blockAddButton={isAddingVariety || editingVariety !== ""}
                        />
                        <div className="flex-grow overflow-y-auto">
                            {isAddingVariety && editingVariety === "" && (
                                <AddEditItem
                                    setIsActive={setIsAddingVariety}
                                    itemInfo={null}
                                    saveItemInfo={saveVarietyInfo}
                                    defaultEmptyValues={{
                                        name: "",
                                        type: "",
                                        baseTemp: "",
                                        stages: {},
                                    }}
                                    validateFunction={(item) => {
                                        const taken = Object.keys(crops[activeCrop].varieties).map((key) => {
                                            return crops[activeCrop].varieties[key].name.toLowerCase()
                                        })
                                        return item.name !== "" && !taken.includes(item.name.toLowerCase())
                                    }}
                                />
                            )}
                            {activeCrop !== "" &&
                                Object.keys(varietiesToShow)
                                    .reverse()
                                    .map((key) => {
                                        return (
                                            <ListItem
                                                itemId={key}
                                                itemInfo={crops[activeCrop].varieties[key]}
                                                activeItem={activeVariety}
                                                activateItem={() => {
                                                    if (Object.keys(checkedVarieties).length === 0) {
                                                        setActiveVariety(key.toString())
                                                        setIsAddingVariety(false)
                                                        setActiveStage("")
                                                        setEditingStage("")
                                                        setIsAddingStage(false)
                                                        setTweakingType(false)
                                                        setPosibleType("")
                                                        setCheckedStages({})
                                                    }
                                                }}
                                                saveItemInfo={saveVarietyInfo}
                                                isAddingItem={isAddingVariety}
                                                editingItem={editingVariety}
                                                setEditingItem={setEditingVariety}
                                                checkedItems={checkedVarieties}
                                                toggleCheckedItem={() => {
                                                    toggleCheckedItem(
                                                        key,
                                                        checkedVarieties,
                                                        setCheckedVarieties,
                                                        () => {
                                                            setActiveVariety("")
                                                            setCheckedStages({})
                                                        }
                                                    )
                                                }}
                                                childrenPropName="stages"
                                                defaultEmptyValues={{
                                                    name: "",
                                                    type: "",
                                                    baseTemp: "",
                                                    stages: {},
                                                }}
                                                validateFunction={(item) => {
                                                    const taken = Object.keys(crops[activeCrop].varieties).map(
                                                        (subkey) => {
                                                            if (key !== subkey) {
                                                                return crops[activeCrop].varieties[
                                                                    subkey
                                                                ].name.toLowerCase()
                                                            }
                                                        }
                                                    )
                                                    return item.name !== "" && !taken.includes(item.name.toLowerCase())
                                                }}
                                            />
                                        )
                                    })}
                        </div>
                    </div>
                    <div
                        className={
                            (activeCrop !== "" && activeVariety !== "" ? "flex " : "hidden md:flex ") +
                            "flex-1 flex-col relative"
                        }
                    >
                        {(isAddingCrop || editingCrop !== "" || isAddingVariety || editingVariety !== "") && (
                            <ProtectorGlass />
                        )}
                        <ColumnHeader
                            title="Crop Stages"
                            prevSelectedItemName={
                                activeCrop && activeVariety
                                    ? crops[activeCrop].name + " / " + crops[activeCrop].varieties[activeVariety].name
                                    : "Crop Stages"
                            }
                            items={stagesToShow}
                            checkedItems={checkedStages}
                            setCheckedItems={setCheckedStages}
                            isActive={activeCrop !== "" && activeVariety !== ""}
                            type={
                                activeCrop !== "" && activeVariety !== ""
                                    ? crops[activeCrop].varieties[activeVariety].type
                                    : ""
                            }
                            tweakingType={tweakingType}
                            setTweakingType={setTweakingType}
                            setPosibleType={setPosibleType}
                            deleteCheckedItems={() => {
                                setConfirmationModal({
                                    active: true,
                                    title: "Are you sure you want to delete?",
                                    implications: "All the selected crop stages will be deleted.",
                                    nextText: "Delete",
                                    nextFunction: () => {
                                        deleteSelectedStages(checkedStages, true)
                                    },
                                })
                            }}
                            debounceSearch={debounceSearch}
                            closeSearch={() => {
                                setSearchInput("")
                                setSearchTarget("")
                            }}
                            searchTarget={searchTarget}
                            openSearch={(title) => {
                                setSearchTarget(title)
                                setIsAddingCrop(false)
                                setIsAddingVariety(false)
                                setIsAddingStage(false)
                                setEditingCrop("")
                                setEditingVariety("")
                                setEditingStage("")
                            }}
                            goBack={() => setActiveVariety("")}
                        />

                        <div className="absolute top-[50px] h-1 inset-x-0 z-full">
                            <LoadingBar loading={loadingStage} />
                        </div>
                        <AddNewItem
                            doShow={activeCrop !== "" && activeVariety !== ""}
                            doShowTwo={
                                activeCrop !== "" &&
                                activeVariety !== "" &&
                                crops[activeCrop].varieties[activeVariety].type !== "" &&
                                !tweakingType
                            }
                            addItem={() => {
                                setIsAddingStage(true)
                                setActiveStage("")
                            }}
                            itemName="crop stage"
                            prevItemName="variety"
                            blockAddButton={isAddingStage || editingStage !== ""}
                        />
                        <div className="flex-grow overflow-y-auto">
                            {activeCrop !== "" &&
                                activeVariety !== "" &&
                                (crops[activeCrop].varieties[activeVariety].type !== "" && !tweakingType ? (
                                    <>
                                        {Object.keys(stagesToShow).map((key, index) => {
                                            return (
                                                <ListItem
                                                    type={crops[activeCrop].varieties[activeVariety].type}
                                                    itemId={key}
                                                    index={index + 1}
                                                    itemInfo={crops[activeCrop].varieties[activeVariety].stages[key]}
                                                    activeItem={activeStage}
                                                    activateItem={() => {
                                                        if (Object.keys(checkedStages).length === 0) {
                                                            setActiveStage(key.toString())
                                                        }
                                                    }}
                                                    editingItem={editingStage}
                                                    setEditingItem={setEditingStage}
                                                    saveItemInfo={saveStageInfo}
                                                    isAddingItem={isAddingStage}
                                                    setIsAddingItem={setIsAddingStage}
                                                    checkedItems={checkedStages}
                                                    toggleCheckedItem={() => {
                                                        toggleCheckedItem(key, checkedStages, setCheckedStages, () => {
                                                            setActiveStage("")
                                                        })
                                                    }}
                                                    defaultEmptyValues={{
                                                        name: "",
                                                        days: "",
                                                    }}
                                                    validateFunction={(item) => {
                                                        const taken = Object.keys(
                                                            crops[activeCrop].varieties[activeVariety].stages
                                                        ).map((subkey) => {
                                                            if (key !== subkey) {
                                                                return crops[activeCrop].varieties[
                                                                    activeVariety
                                                                ].stages[subkey].name.toLowerCase()
                                                            }
                                                        })
                                                        return (
                                                            item.name !== "" &&
                                                            item.days !== "" &&
                                                            !taken.includes(item.name.toLowerCase())
                                                        )
                                                    }}
                                                />
                                            )
                                        })}
                                        {isAddingStage && editingStage === "" && (
                                            <AddEditItem
                                                type={crops[activeCrop].varieties[activeVariety].type}
                                                index={
                                                    Object.keys(crops[activeCrop].varieties[activeVariety].stages)
                                                        .length > 0
                                                        ? -1
                                                        : 0
                                                }
                                                setIsActive={setIsAddingStage}
                                                itemInfo={null}
                                                saveItemInfo={saveStageInfo}
                                                defaultEmptyValues={{
                                                    name: "",
                                                    days: "",
                                                }}
                                                validateFunction={(item) => {
                                                    const taken = Object.keys(
                                                        crops[activeCrop].varieties[activeVariety].stages
                                                    ).map((key) => {
                                                        return crops[activeCrop].varieties[activeVariety].stages[
                                                            key
                                                        ].name.toLowerCase()
                                                    })
                                                    return (
                                                        item.name !== "" &&
                                                        item.days !== "" &&
                                                        !taken.includes(item.name.toLowerCase())
                                                    )
                                                }}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <StageTypeSelect
                                        possibleType={possibleType}
                                        actualVariety={crops[activeCrop].varieties[activeVariety]}
                                        setPosibleType={setPosibleType}
                                        setUpdateStageType={setUpdateStageType}
                                        baseTempBase={crops[activeCrop].varieties[activeVariety].baseTemp}
                                        hasSuggestions={
                                            crops[activeCrop].default_id !== undefined &&
                                            crops[activeCrop].default_id !== null
                                                ? true
                                                : false
                                        }
                                    />
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default CropVarietySettings
