import React, {useEffect} from "react"

import CropTableLabelItem from "./CropTableLabelItem"

import { useMediaQuery } from "@material-ui/core"

import "./CropTableLabels.css"

function CropTableLabels(props) {
    const {
        value: labels = [],
        rowData = {},
        rowOptions: { removeLabelFromField },
    } = props

    const mediaQuery = useMediaQuery("(max-width: 1350px)")

    const items = []
    const lastItems = []
    if (mediaQuery) {
        for (let i = 0; i < labels.length; i++) (i < 1 ? items : lastItems).push(labels[i])
    } else {
        for (let i = 0; i < labels.length; i++) (i < 2 ? items : lastItems).push(labels[i])
    }
    

    function handleLabelDelete(label) {
        removeLabelFromField(label, rowData)
    }

    return (
        <>
            <div className="table-labels">
                {items.map((label) => {
                    return <CropTableLabelItem key={JSON.stringify(label)} label={label} onDelete={handleLabelDelete} />
                })}
                {(lastItems && lastItems.length && (
                    <div className="table-labels__last-items">
                        <div className="table-labels__last-items__value">+{lastItems.length}</div>
                        <div className="table-labels__last-items__container">
                            {lastItems.map((label) => {
                                return (
                                    <CropTableLabelItem
                                        key={JSON.stringify(label)}
                                        label={label}
                                        onDelete={handleLabelDelete}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )) ||
                    null}
            </div>
        </>
    )
}

export default CropTableLabels
