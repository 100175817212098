import * as React from "react"

function SlidesIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M0 2h8c0-.53.625-.625 1-1s.47-1 1-1 .625.625 1 1 1 .47 1 1h8v2h-1v11h-6l2 6h-2l-2-6H9l-2 6H5l2-6H1V4H0V2zm3 2v9h14V4H3zm7 7c-.09.09.14 0 0 0-.133 0-.906.094-1 0-.094-.094 0 .133 0 0V7c0-.133-.094-.906 0-1 .094-.094.867 0 1 0 .14 0-.09-.09 0 0l1 2c.32.32 1 .68 1 1 0 .32-.68-.32-1 0l-1 2z"
                fill={props.fill}
            />
        </svg>
    )
}

export default SlidesIcon
