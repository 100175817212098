import * as React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { token } from '../accessToken';
import './mapbox.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl';


mapboxgl.accessToken = token;





/**
 * We use class instead of pure functions because we need to
 * be able to handle chart update function.
 * 
 * 
 * Sample Invokation
 * ```javascript
 *   <MapboxPolygonViewerComponent 
 *     polygonData={{field_polygon: [[40, 43], [41, 41], [46, 41], [40, 43]]}} 
 *     width='100%' 
 *     height="120px">
 *  </MapboxPolygonViewerComponent>
 * ```
 * @typedef {MapboxPolygonViewerComponent} Props
 * @prop {PolygonData} polygonData - Necessary data to display polygon
 * @prop {string} width - Overall map width
 * @prop {string} height - Overall map height
 * @extends {Component<Props>}
 */
export class MapboxPolygonViewerComponent extends React.Component {
    /**
    * @private
    * @hideconstructor
    */
    constructor(props) {
        super(props);

        // Define initial state
        this.state = {
            viewport: {
                longitude: 42,
                latitude: 42,
                zoom: 0,
            },
            polygonData: {}
        };


        // Save mapbox instance reference
        this._map = React.createRef();
    }

    // Viewport update func - Mostly needed for dragging functionality
    _updateViewport = viewport => {
        this.setState({ viewport });
    };

    // Calculate right top end coordinates (lat,lon) of passed polygon
    maxRightTop = (polygonCoords) => {
        const maxX = Math.max(...polygonCoords.map(d => d[0]));
        const maxY = Math.max(...polygonCoords.map(d => d[1]));
        return [maxX, maxY];
    }

    // Calculate left bottom end coordinates (lat,lon) of passed polygon
    minLeftBottom = (polygonCoords) => {
        const minX = Math.min(...polygonCoords.map(d => d[0]));
        const minY = Math.min(...polygonCoords.map(d => d[1]));
        return [minX, minY];
    }

    // Handle component mounting event
    componentDidMount() {

        // Define map instance
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/mapbox/satellite-v9',
            center: [this.state.viewport.longitude, this.state.viewport.latitude],
            zoom: this.state.viewport.zoom
        });

        // Set centers
        map.on('move', () => {
            this.setState({
                lng: map.getCenter().lng.toFixed(4),
                lat: map.getCenter().lat.toFixed(4),
                zoom: map.getZoom().toFixed(2)
            });
        });

        this.map = map;
        map.on('load', d => {
            this.mapLoaded = true;
        })

        this.createDiagram();
    }

    // Handle component update event
    componentDidUpdate(prevProps, prevState) {
        // If passed polygon data is the same as in the previous case, don't do anything
        if (this.props.polygonData === prevProps.polygonData) return;
        if (!this.props.polygonData.field_polygon) return;

        // Add source of geojson layers
        function addSource({ map, props, maxRightTop, minLeftBottom, createDiagram }) {

            if (map.getLayer("polygon-layer")) {
                map.removeLayer("polygon-layer");
            }

            if (map.getSource("polygon-shape")) {
                map.removeSource("polygon-shape");
            }



            // Adding polygon shape layer
            map.addSource('polygon-shape', {
                'type': 'geojson',
                'data': {
                    type: "FeatureCollection",
                    features: [{
                        "type": "Feature",
                        "properties": {},
                        "geometry": {
                            "type": "Polygon",
                            "coordinates": [props.polygonData.field_polygon]
                        }
                    }]
                }
            });

            // Styling above layer
            map.addLayer({
                'id': 'polygon-layer',
                'type': 'fill',
                'source': 'polygon-shape',
                'layout': {},
                'paint': {
                    'fill-color': '#088',
                    'fill-opacity': 0.6
                }
            });

            // Fitting map to polygon bounds
            map.fitBounds([
                maxRightTop(props.polygonData.field_polygon),
                minLeftBottom(props.polygonData.field_polygon)
            ], { padding: 10 });

            // Otherwise update map
            createDiagram();
        }

        // If map was loaded
        if (this.mapLoaded) {
            // Add source
            addSource(this);
        } else {
            // If map is not yet loaded, wait for it and then add polygon source
            this.map.on('load', d => {
                this.mapLoaded = true;
                addSource(this)
            })
        }


    }

    // Render chart
    render() {
        return <div>
            <div style={{
                width: this.props.width || "200px",
                borderRadius: 5,
                height: this.props.height || "100px"
            }} ref={el => { this.mapContainer = el }} className="mapContainer" />
        </div>
    }

    // Reusable, create diagram function
    createDiagram() { }
}




/**
* Sample: 
* ```javascript
*{
*    field_polygon: [[40, 43], [41, 41], [46, 41], [40, 43]]
*}
* ```
* @typedef {object} PolygonData - Chart area data item
* @property {Array<Array<number,number>>} field_polygon - Coordinate (Latitude, Longitude) matrix for polygons
*/
