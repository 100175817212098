import React, { useState } from "react"
import SearchInput from "../Input/SearchInput"

import "./SidebarFields.css"

function NavbarFields(props) {
    const { linkComponent: LinkComponent, items = [], currentRegion } = props

    const [searchInputValue, setSearchInputValue] = useState("")

    function handleInputChange(e) {
        setSearchInputValue(e.target.value)
    }

    function handleInputClear() {
        setSearchInputValue("")
    }

    let _items = items
    if (searchInputValue)
        _items = _items.filter((item) => JSON.stringify(item).toLowerCase().includes(searchInputValue.toLowerCase()))

    return (
        <>
            <div className="sidebar__fields min-h-[300px]">
                <div className="sidebar__fields__search-bar">
                    <SearchInput onChange={handleInputChange} onClear={handleInputClear} value={searchInputValue} />
                </div>
                {Object.values(
                    _items
                        .sort((a) => {
                            // put fields w current region at beginin of array
                            if (a.region && a.region === currentRegion) return -1
                            else return 1
                        })
                        .reduce((prev, curr) => {
                            // group fields by region
                            if (prev[curr.region]) prev[curr.region].push(curr)
                            else prev[curr.region] = [curr]
                            return prev
                        }, {})
                ).map((items) => {
                    return (
                        <>
                            <div className="sidebar__field__item region">
                                {currentRegion && items[0].region === currentRegion
                                    ? "Current region"
                                    : `Region: ${items[0].region}`}
                            </div>
                            {items.map((item, index) => {
                                const { to, name, crop } = item
                                return (
                                    <LinkComponent key={"navbar-field-" + index} to={to}>
                                        <div className="sidebar__field__item">
                                            <h4 className="sidebar__field__item__name">{name}</h4>
                                            <p className="sidebar__field__item__crop">{crop}</p>
                                        </div>
                                    </LinkComponent>
                                )
                            })}
                        </>
                    )
                })}
            </div>
        </>
    )
}

export default NavbarFields
