import React, { createContext, useReducer } from "react"

export const DataContext = createContext({})

const initialState = {
    fields: [],
    customReportsRequested: false,
    customReports: [],
    polygons: {},
}

function DataReducer(state = {}, action) {
    const { type, payload } = action

    switch (type) {
        case "GET_FIELDS": {
            const { fields } = payload
            return { ...state, fields }
        }
        case "GET_POLYGONS": {
            const { polygons } = payload
            return { ...state, polygons }
        }
        case "SET_CUSTOM_REPORTS": {
            const { customReports } = payload
            return { ...state, customReports, customReportsRequested: true }
        }
        default: {
            return state
        }
    }
}

function DataProvider(props) {
    const { children } = props
    const [state, dispatch] = useReducer(DataReducer, initialState)

    // TODO: Request customReports

    return <DataContext.Provider value={{ state, dispatch }}>{children}</DataContext.Provider>
}

export default DataProvider
