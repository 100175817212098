import "react-datepicker/dist/react-datepicker.css"

import Select from "../../../ui/Select/Select"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"
import Section from "../../../ui/Section"
import useContainerSize from "../../../hooks/useContainerSize"
import MapPinIcon from "../../../ui/Icons/MapPinIcon"
import LoadingBar from "../../../components/LoadingBar"
import { capitalizeFirstCharacter, capitalizeFirstCharacterOfEachWord } from "../../../helpers/wordHelper"
import Menu from "../../../ui/Controls/Menu"
import MenuItem from "../../../ui/Controls/MenuItem"
import getVariableIcon from "../../../Util/getVariableIcon"
import getRiskIcon from "../../../Util/getRiskIcon"
import { getYFormat, getYLabel } from "../../../Util/CADChartLabel"

function DecadalChart(props) {
    const {
        loading,
        data,
        locations,
        crops,
        scenarios,
        variables,
        risks,
        selectedLocation,
        selectedCrop,
        selectedScenario,
        selectedVariable,
        selectedVariableObj,
        selectedRiskObj,
        setSelectedLocation = () => null,
        setSelectedCrop = () => null,
        setSelectedScenario = () => null,
        setSelectedVariable = () => null,
        setSelectedRisk = () => null,
        variablesByDefault,
    } = props

    const [containerRef, { height }] = useContainerSize(1000)

    let labelY = !variablesByDefault
        ? "Probability"
        : selectedVariableObj.label + " " + ((selectedVariableObj.units && `(${selectedVariableObj.units})`) || "")
    // let yValueMax = !variablesByDefault || selectedVariable.value === "rh" ? 100 : undefined
    let tickFormat = !variablesByDefault ? "%" : selectedVariableObj.units || ""
    const yTickFormat = (d) => {
        if (!variablesByDefault) {
            // show risks from 0 to 100
            return d < 0 || d > 100 ? "" : d
        } else {
            // return selectedVariableObj.units || ""
            return ["gdd", "rh", "ch"].includes(selectedVariable) && d < 0 ? "" : d
        }
    }

    function addYears(date, years) {
        const _date = new Date(date.getTime())
        _date.setFullYear(_date.getFullYear() + years)
        return _date
    }

    const chartXMin = data && data.points && data.points.length > 0 && addYears(data.points[0].x, -10)
    const chartXMax =
        data && data.points && data.points.length > 0 && addYears(data.points[data.points.length - 1].x, +10)

    return (
        <>
            <Section
                title="Decadal Climate Trend"
                description="This page displays the decadal average of your climate risk for your selected location."
                header={() => (
                    <>
                        {data && data.points && data.points.length > 0 && (
                            <>
                                <Select
                                    icon={MapPinIcon}
                                    value={selectedLocation || ""}
                                    onChange={(e) => setSelectedLocation(e.target.value)}
                                >
                                    {locations &&
                                        locations.map((location) => (
                                            <option value={location.name} key={`decadal_location_${location.name}`}>
                                                {location.name}
                                            </option>
                                        ))}
                                </Select>
                                {/* <Select
                                    icon={CropIcon}
                                    value={selectedCrop || ""}
                                    onChange={(e) => setSelectedCrop(e.target.value)}
                                >
                                    {crops &&
                                        crops.map((crop) => (
                                            <option value={crop} key={`decadal_crop_${crop}`}>
                                                {capitalizeFirstCharacter(crop)}
                                            </option>
                                        ))}
                                </Select> */}
                                <Menu
                                    className="left"
                                    icon={
                                        variablesByDefault
                                            ? () => getVariableIcon(selectedVariable)
                                            : () => getRiskIcon(selectedRiskObj.icon)
                                    }
                                    text={
                                        (variablesByDefault && selectedVariableObj.label) ||
                                        (!variablesByDefault &&
                                            `${selectedRiskObj.title} (${
                                                (selectedRiskObj.subtitle && selectedRiskObj.subtitle + ", ") || ""
                                            }${(selectedRiskObj.stage && selectedRiskObj.stage) || ""})`)
                                    }
                                >
                                    {/* Risks */}
                                    <MenuItem label="Risks">
                                        {risks &&
                                            risks.map((risk) => (
                                                <MenuItem
                                                    key={`decadal_risk_${risk.id}`}
                                                    label={`${risk.title} (${
                                                        (risk.subtitle && risk.subtitle + ", ") || ""
                                                    }${(risk.stage && risk.stage) || ""})`}
                                                    icon={() => getRiskIcon(risk.icon)}
                                                    onClick={() => setSelectedRisk(risk.id, false)}
                                                />
                                            ))}
                                    </MenuItem>
                                    <MenuItem label="Variables">
                                        {variables &&
                                            variables.map((v) => (
                                                <MenuItem
                                                    key={`decadal_var_${v.value}`}
                                                    label={v.label}
                                                    icon={() => getVariableIcon(v.value)}
                                                    onClick={() => setSelectedVariable(v.value, true)}
                                                />
                                            ))}
                                    </MenuItem>
                                </Menu>
                                <Select
                                    value={selectedScenario || ""}
                                    onChange={(e) => setSelectedScenario(e.target.value)}
                                >
                                    {scenarios.map((scenario) => (
                                        <option value={scenario.value} key={`decadal_scenario_${scenario.value}`}>
                                            {scenario.label}
                                        </option>
                                    ))}
                                </Select>
                            </>
                        )}
                    </>
                )}
            >
                {loading && <LoadingBar loading />}
                <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
                    <LineAreaChartComponent
                        labelY={labelY}
                        // yValueMax={yValueMax}
                        zeroBasis={false}
                        marginLeft={80}
                        marginRight={80}
                        xDateMin={chartXMin}
                        xDateMax={chartXMax}
                        xTicksCount={6}
                        labelYOffsetX={-20}
                        candlePadding={0}
                        candleTopBottomLines={true}
                        xTickFormat={(d) => {
                            let y = d.getFullYear()
                            return (y % 10 === 0 && y) || ""
                        }}
                        yTickFormat={yTickFormat}
                        yBottomOffset={0.3}
                        yTopOffset={0.3}
                        svgHeight={(height && height - 10) || 0}
                        svgWidth="100%"
                        shapeTip={(e, d, arr) => {
                            return `
                      <table>
                        <tr><th style="text-align:right">95th</th><td>${
                            d.y1 && d.y1.toFixed(2) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">75th</th><td>${
                            d.yMax && d.yMax.toFixed(2) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">50th</th><td>${
                            d.yMid && d.yMid.toFixed(2) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">25th</th><td>${
                            d.yMin && d.yMin.toFixed(2) + " " + tickFormat
                        }</td></tr>
                        <tr><th style="text-align:right">5th</th><td>${
                            d.y0 && d.y0.toFixed(2) + " " + tickFormat
                        }</td></tr>
                      </table>
                    `
                        }}
                        data={data}
                    ></LineAreaChartComponent>
                </div>
            </Section>
        </>
    )
}

export default DecadalChart
