import { addDays } from "./chartHelpers"

export const QUANTILES_ALERT = [
    0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95,
]
export const QUANTILES_REVERSE = QUANTILES_ALERT.reverse()

export function calculateProbabilities(days, forecast, conditional, threshold) {
    let probabilities = []

    for (let index = 0; index < days.length; index++) {
        let found_quantile = false
        if (conditional === "less_than") {
            for (let quantile of QUANTILES_REVERSE) {
                let x = quantile.toString().replace(".", ",")
                if (!found_quantile && forecast[x] && forecast[x][index] <= threshold) {
                    probabilities.push(parseFloat(quantile.toFixed(2)))
                    found_quantile = true
                }
            }
        } else {
            for (let quantile of QUANTILES_ALERT) {
                let x = quantile.toString().replace(".", ",")
                if (!found_quantile && forecast[x] && forecast[x][index] >= threshold) {
                    probabilities.push(parseFloat(1 - quantile.toFixed(2)))
                    found_quantile = true
                }
            }
        }
        if (!found_quantile) {
            probabilities.push(0)
        }
    }
    return probabilities
}

export function alertBetweenTimePeriod(alert, _startDate, _endDate) {
    if (!_startDate || !_endDate) throw new TypeError("Invalid arguments.")

    // alert planting window
    const alertStartDate = new Date(alert.time[0])
    const alertEndDate = new Date(alert.time[alert.time.length - 1])

    return (
        (alertStartDate.getTime() >= _startDate.getTime() && alertStartDate.getTime() < _endDate.getTime()) ||
        (alertEndDate.getTime() >= _startDate.getTime() && alertEndDate.getTime() < _endDate.getTime()) ||
        (_startDate.getTime() >= alertStartDate.getTime() && _startDate.getTime() < alertEndDate.getTime()) ||
        (_endDate.getTime() >= alertStartDate.getTime() && _endDate.getTime() < alertEndDate.getTime())
    )
}

export function getAlertProbabilitiesBetweenDates(alert, _startDate, _endDate) {
    // validate if alert's planting window exists in bewteen scenario's planting window and viceversa
    if (!alertBetweenTimePeriod(alert, _startDate, _endDate)) return null

    // alert planting window
    const alertStartDate = new Date(alert.time[0])

    let availableValues = []
    let alertTimeOffset = Math.floor((_startDate.getTime() - alertStartDate.getTime()) / (1000 * 3600 * 24))

    const currentDate = new Date(_startDate.getTime())
    while (currentDate < _endDate) {
        let currentValue = 0
        if (alertTimeOffset >= 0 && alert.values[alertTimeOffset] !== undefined)
            currentValue = alert.values[alertTimeOffset]

        availableValues.push({ time: currentDate.getTime(), value: currentValue })

        // ++
        alertTimeOffset++
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return availableValues
}

export function mapToFlatPlantingData(plantingData) {
    if (!plantingData) return []
    const result = Object.keys(plantingData)
        .map((key) => [plantingData[key], key])
        .map((arrKeyObj) => [Object.keys(arrKeyObj[0]), arrKeyObj[0], arrKeyObj[1]])
        .map((arr) => arr[0].map((k) => Object.assign({}, arr[1][k], { type: arr[2], subType: k })))
        .flat()
        .map((d) => Object.assign({ id: d.type + "_" + d.subType }, d))
        .map((d) => {
            // console.log(d)
            return Object.assign(d, {
                time: d.time || [],
                lag: d.lag || 0,
                values: d.values || [],
                description_long: d.description_long || "",
                description_short: d.description_short || "",
                title: d.title || "",
                // threshold: d?.alert_data?.threshold,
            })
        })
    return result
}

// Map planting data to chart data
export function mapToChartData(flatPlantingData, fromDate, toDate) {
    // eslint-disable-line react-hooks/exhaustive-deps

    // Define colors which will be used to color lines and areas
    const colors = ["#F3B52F", "#F4713D", "#663F59", "#6A6E93", "#4C88B2", "#01A6C4", "#04D8D7", "#73F3E4"]
        .concat([
            "#1f77b4",
            "#ff7f0e",
            "#2ca02c",
            "#d62728",
            "#9467bd",
            "#8c564b",
            "#e377c2",
            "#7f7f7f",
            "#bcbd22",
            "#17becf",
        ])
        .concat(["#D34A7C"])
        .reverse()

    // Predefine some colors for some risks
    const predefinedColors = {
        risk_drought: "#2E476B",
        risk_heat_stress: "#E55526",
        risk_high_soil_temperature: "#E55526",
        risk_cold: "#5984BB",
        risk_low_soil_temperature: "#5984BB",
        risk_wet_soil: "#288278",
    }
    let colorIncrement = 0

    // If a lag is defined, shift the planting data to reflect that lag
    const shiftedPlantingData = flatPlantingData.map((d) =>
        Object.assign({}, d, {
            time: d.time.map((t) => addDays(t, d.lag)),
        })
    )

    // Filter out invalid planting records
    const filteredPlantingData = shiftedPlantingData

    // Convert planting data to actual chart component supported data
    const result = filteredPlantingData
        .map((d) => {
            return Object.assign(d, {
                color: predefinedColors[d.type] || colors[colorIncrement++ % colors.length],
                points: wrapPoints(d),
                value: d.weight !== undefined ? d.weight * 100 : 100 / filteredPlantingData.length,
                name: d.title || d.type.split("_").map(capitalizeFirstLetter).join(" "),
                description: d.description_short,
                description_long: d.description_long,
                // threshold: d?.alert_data?.threshold,
            })
        })
        .map((d) => {
            d.visible = d.points.filter((d) => d.x >= fromDate && d.x <= toDate).some((d) => d.y)

            d.visible = d.visible && (d.weight !== undefined ? d.weight : true)
            return d
        })

    // Define points date key objects (For fast access, it'll improve performance)
    result.forEach((r) => {
        r.pointsObj = {}
        r.time.forEach((p, i) => {
            r.pointsObj[p] = r.points[i]
        })
    })
    return result
}
// Function to weigh up top chart from bottom charts
export function weighUp(riskFactorsData) {
    // Retrieve and sort ascending uniq dates from risk data
    const uniqTimes = Array.from(new Set(riskFactorsData.map((d) => d.time).flat())).sort((a, b) =>
        +new Date(a) > +new Date(b) ? 1 : -1
    )

    // Create new points for top chart
    const points = uniqTimes.map((d) => ({ x: new Date(d) }))

    // Map Attach point values to each point
    uniqTimes.forEach((t, i) => {
        // Filter out missing points from risks data
        const filteredValues = riskFactorsData.filter((d) => {
            return d.pointsObj[t]
        })

        // console.log(filteredValues)

        // Retrieve all weighted values from corresponding bottom chart points
        const values = filteredValues.map((d) => d.weight)
        // console.log(values)

        // Sum up weights
        const weightSum = values.reduce((a, b) => a + b, 0)
        // console.log(weightSum)

        // Calcualte weighted risks
        const weightedProbabilities = filteredValues.map((d) => (d.weight / weightSum) * d.pointsObj[t].y)

        // Sum up weighted values
        let probSum = weightedProbabilities.reduce((a, b) => a + b, 0)

        // if sum of weights is 0, then probability is 0 too
        if (weightSum === 0) {
            probSum = 0
        }

        // Calculate probabilities (point value for top chart)
        const prob = 100 - probSum

        // Round probability value
        points[i].y = Math.round(prob)

        // Attach corresponding risk values to top chart point (It'll be used in top charts tooltip)
        points[i].riskValues = filteredValues
            .filter((d) => d.weight)
            .map((r) => {
                return {
                    id: r.id,
                    color: r.color,
                    y: r.pointsObj[t].y,
                    name: r.name,
                }
            })
            .filter((d) => d.y)
            .sort((a, b) => (a.y < b.y ? 1 : -1)) // Sort it in way, bigger values are always on top

        // Add top charts risk value as well
        points[i].riskValues.unshift({
            color: "#2E81BB",
            y: prob,
            name: "Expected Yield",
        })
    })
    return points
}

// Reusable function to wrap up points for chart supported format
export function wrapPoints({ time, values }) {
    return time.map((d, i) => {
        return {
            x: new Date(d),
            y: values[i] * 100,
        }
    })
}

// Just a simple function to convert first letter to capital
export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item)
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target
    const source = sources.shift()

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} })
                mergeDeep(target[key], source[key])
            } else {
                Object.assign(target, { [key]: source[key] })
            }
        }
    }

    return mergeDeep(target, ...sources)
}

export function populateScenarios(result = {}, scenarios = {}) {
    scenarios &&
        Object.keys(scenarios).forEach((uid) => {
            const _scenario = scenarios[uid]
            result[uid] = { ...result.current, uid }

            _scenario &&
                Object.keys(_scenario)
                    .filter((key) => typeof _scenario[key] !== "object")
                    .forEach((key) => {
                        result[uid] = {
                            ...result[uid],
                            [key]: key === "plantingDate" ? new Date(_scenario[key]) : _scenario[key],
                        }
                    })

            _scenario.chart_data &&
                Object.keys(_scenario.chart_data).forEach((riskId) => {
                    // copy risk
                    result[uid].chart_data[riskId] = { ...result[uid].chart_data[riskId] }

                    _scenario.chart_data[riskId] &&
                        Object.keys(_scenario.chart_data[riskId]).forEach((alertId) => {
                            // copy alerts
                            if (
                                result[uid].chart_data[riskId][alertId] &&
                                _scenario.chart_data[riskId][alertId] &&
                                Object.keys(result[uid].chart_data[riskId][alertId]).length > 0 &&
                                Object.keys(_scenario.chart_data[riskId][alertId]).length > 0
                            ) {
                                result[uid] = {
                                    ...result[uid],
                                    chart_data: {
                                        ...result[uid].chart_data,
                                        [riskId]: {
                                            ...result[uid].chart_data[riskId],
                                            [alertId]: {
                                                ...result[uid].chart_data[riskId][alertId],
                                            },
                                        },
                                    },
                                }

                                Object.keys(_scenario.chart_data[riskId][alertId])
                                    .filter((key) => typeof _scenario.chart_data[riskId][alertId][key] !== "object")
                                    .forEach((key) => {
                                        result[uid].chart_data[riskId][alertId][key] =
                                            _scenario.chart_data[riskId][alertId][key]
                                    })
                            }

                            if (
                                result[uid].chart_data[riskId][alertId] &&
                                _scenario.chart_data[riskId][alertId] &&
                                result[uid].chart_data[riskId][alertId].alert_data &&
                                _scenario.chart_data[riskId][alertId].alert_data &&
                                Object.keys(result[uid].chart_data[riskId][alertId].alert_data).length > 0 &&
                                Object.keys(_scenario.chart_data[riskId][alertId].alert_data).length > 0
                            ) {
                                result[uid] = {
                                    ...result[uid],
                                    chart_data: {
                                        ...result[uid].chart_data,
                                        [riskId]: {
                                            ...result[uid].chart_data[riskId],
                                            [alertId]: {
                                                ...result[uid].chart_data[riskId][alertId],
                                                alert_data: {
                                                    ...result[uid].chart_data[riskId][alertId].alert_data,
                                                },
                                            },
                                        },
                                    },
                                }

                                Object.keys(_scenario.chart_data[riskId][alertId].alert_data).forEach((key) => {
                                    result[uid].chart_data[riskId][alertId].alert_data[key] =
                                        _scenario.chart_data[riskId][alertId].alert_data[key]
                                })
                            }
                        })
                })
        })

    return result
}
