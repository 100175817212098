import React, { useEffect, useRef, useState } from "react"

import SunIcon from "../../../ui/Icons/AlertSettings/SunIcon"
import CardItem from "../../Overview/components/CardItem"
import InfoIcon from "../../../ui/Icons/InfoIcon"

import ShieldIcon from "../../../ui/Icons/ShieldIcon"
import RevenueIcon from "../../../ui/Icons/RevenueIcon"
import { Popover } from "../../../ui/Popover"
import ChevronLeftIcon from "../../../ui/Icons/ChevronLeftIcon"
import ChevronRightIcon from "../../../ui/Icons/ChevronRightIcon"
import LoadingBar from "../../../components/LoadingBar"

function DepartureAside(props) {
    const { loading, threshold, futureq, pastq, setFutureq, setPastq } = props

    return (
        <>
            <div className="overview-risks">
                <div className="overview-risks-card">
                    <div className="overview-risks-card__header">Condition</div>
                    <div className="overview-risks-card__body">
                        {(!loading && (
                            <>
                                <CardItem
                                    disabled
                                    icon={
                                        <Popover text="Description ...">
                                            <div style={{ width: 20, height: 20 }}>
                                                <InfoIcon />
                                            </div>
                                        </Popover>
                                    }
                                    render={() => <p className="overview-risks-card__title">Question</p>}
                                >
                                    <p style={{ lineHeight: 2.5, fontFamily: "Roboto" }}>
                                        When will a{" "}
                                        <select
                                            value={pastq}
                                            onChange={(e) => {
                                                setPastq(parseFloat(e.target.value))
                                            }}
                                            defaultValue={0.1}
                                        >
                                            {new Array(10).fill(null).map((_, i) => {
                                                const q = i / 10
                                                return <option value={q}>{q * 10}</option>
                                            })}
                                        </select>{" "}
                                        in 10 years event becomes a{" "}
                                        <select
                                            value={futureq}
                                            onChange={(e) => {
                                                setFutureq(parseFloat(e.target.value))
                                            }}
                                            defaultValue={0.5}
                                        >
                                            {new Array(10).fill(null).map((_, i) => {
                                                const q = i / 10
                                                return <option value={q}>{q * 10}</option>
                                            })}
                                        </select>{" "}
                                        in 10 years event?
                                    </p>
                                </CardItem>
                                <CardItem
                                    disabled
                                    icon={
                                        <Popover text="Description ...">
                                            <div style={{ width: 20, height: 20 }}>
                                                <InfoIcon />
                                            </div>
                                        </Popover>
                                    }
                                    render={() => <p className="overview-risks-card__title">Answer</p>}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            fontFamily: "Roboto",
                                        }}
                                    >
                                        <p>Year</p>
                                        <p>{threshold || "No Year of Departure"}</p>
                                    </div>
                                </CardItem>
                            </>
                        )) || <LoadingBar loading />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default DepartureAside
