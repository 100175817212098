import React from "react"

import { Popover } from "../../../ui/Popover"
import TrashIcon from "../../../ui/Icons/TrashIcon"

import "./CropTableLabelItem.css"

function CropTableLabelItem(props) {
    const { label, onDelete = () => null } = props
    const { name, color } = label
    const child = (
            <div className="table-label-item">
                <div className="table-label-item__label" style={{ backgroundColor: color }}>
                    <div className="table-label-item__text">{name}</div>
                    <button className="table-item-labels__item__delete" onClick={() => onDelete(label)}>
                        <TrashIcon className="" fill="#fff" />
                    </button>
                </div>
            </div>
    )

    return (
        <>
            {name.length > 10 ? 
                        <Popover text={name} className={"up left"}>
                            {child}
                        </Popover> : child
            }
        </>
    )
}

export default CropTableLabelItem
