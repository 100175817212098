import React, { useContext, useEffect, useState } from "react"

// Components
import Table from "../../ui/Table/Table"
import TableColumn from "../../ui/Table/TableColumn"

import CropTableCheck from "./CropTableColumns/CropTableCheck"
import CropTableAlert from "./CropTableColumns/CropTableAlert"
import CropTableName from "./CropTableColumns/CropTableName"
import CropTableTomorrowTemp from "./CropTableColumns/CropTableTomorrowTemp"
import CropTableTomorrowPrecipt from "./CropTableColumns/CropTableTomorrowPrecipt"
import CropTableYieldVsPlan from "./CropTableColumns/CropTableYieldVsPlan"
import CropTableLabels from "./CropTableColumns/CropTableLabels"
import CropTableActions from "./CropTableColumns/CropTableActions"

import { processPolygonData } from "../../helpers/chartHelpers"
import { ToastContainer, toast } from "react-toastify"
import AddEditField from "../../ui/Table/AddEditField"

// Views
// Context providers / Utils
import {
    createNewReport,
    getTableData,
    getPolygonData,
    addTableRow,
    updateTableRow,
    deleteTableRow,
    uploadFile,
    getTableColumnSettings,
    formatDate,
} from "../../services/table.service"
import { addLabelToFields, getAllLabels, removeLabel, removeLabelFromField } from "../../services/label.service"
import networking from "../../Util/Networking"
import { SettingsContext } from "../../Util/SettingsContext"

// Hooks
import { useHistory } from "react-router-dom"
// Styles
import "./CropTable.css"

// Table fields
import {
    alertColumn,
    checkColumn,
    nameColumn,
    cropColumn,
    varietyColumn,
    regionColumn,
    labelsColumn,
    actionsColumn,
} from "./tableColumns"

// Table meta fields
import {
    plantingDateField,
    plantStageField,
    yieldForecastField,
    notesField,
    temporaryYieldsForHazeraFields,
} from "./tableMetaFields"
import Checkbox from "../../ui/Checkbox/Checkbox"

import { AuthContext } from "../../Auth/Auth"
import { DataContext } from "../../Util/Data"
import { Popover } from "../../ui/Popover"

function CropTable() {
    // field data
    const {
        state: { fields, polygons },
        dispatch,
    } = useContext(DataContext)

    const [loading, setLoading] = useState(false)
    const [selectedMapColumnVariable, setSelectedMapColumnVariable] = useState("temp")
    const [tableColumnSettings, setTableColumnSettings] = useState({})

    const { currentUser, permissions, featurePermissions } = useContext(AuthContext)
    const {
        currentSettings: { units, regionalViewMapVisible },
        handleUpdateDBUserSettings,
    } = useContext(SettingsContext)

    const [labels, setLabels] = useState([])
    const [selectedFields, setSelectedFields] = useState([])

    const [activeField, setActiveField] = useState(null)
    const [addEditFieldOpen, setAddEditFieldOpen] = useState(false)

    const history = useHistory()

    // get table data
    useEffect(() => {
        if (!fields || fields.length === 0) {
            setLoading(true)
            getTableData()
                .then((fields) => {
                    // save fields to upper state
                    dispatch({ type: "GET_FIELDS", payload: { fields } })
                    // uid array
                    const fieldPolygonsToRetrieve = fields.map((field) => field.uuid)
                    // get polygon data from all fields
                    // TODO: in the future we could get fields and polygon data in one request
                    return getPolygonData(fieldPolygonsToRetrieve)
                })
                .then((polygons) => {
                    // save polygons to upper state
                    dispatch({ type: "GET_POLYGONS", payload: { polygons } })
                })
                .catch(console.log)
                .finally(() => setLoading(false))
        }

        getAllLabels()
            .then((labelsData) => {
                setLabels(labelsData)
            })
            .catch(console.log)

        getTableColumnSettings().then((data) => {
            setTableColumnSettings(data)
        })
    }, [])

    const _checkColumn = {
        ...checkColumn,
        component: CropTableCheck,
        headerRender: (_, { checked, handleCheckAllRows, checkedStatus }) => (
            <div className="crop-table-check">
                <Checkbox checked={checked} onChange={handleCheckAllRows} status={checkedStatus} />
            </div>
        ),
    }

    const _alertColumn = {
        ...alertColumn,
        component: CropTableAlert,
    }

    const cropTableMetaFields = [plantingDateField, plantStageField, yieldForecastField, notesField]

    // this just saves the selected fields here in the screen
    function handleSelectionChange(selectedFields) {
        setSelectedFields(selectedFields)
    }

    async function handleRowAdd(rowData) {
        if (featurePermissions && featurePermissions.field_tools && featurePermissions.field_tools.add) {
            try {
                setLoading(true)

                // upload file to firebase
                if (rowData.shapefile instanceof File) rowData.shapefile = await uploadFile("Shape", rowData.shapefile)

                rowData["crop_id"] = +rowData["cropId"]
                delete rowData["cropId"]
                rowData["variety_id"] = +rowData["varietyId"]
                delete rowData["varietyId"]

                await addTableRow(rowData)
                const _data = await getTableData()
                // re - poblate table
                dispatch({ type: "GET_FIELDS", payload: { fields: _data } })
                setLoading(false)
                toast.success("Row added successfully!")
            } catch (e) {
                setLoading(false)
                toast.error(e.toString())
            }
        } else {
            toast.warning("Your account does not have permission to do this action.")
        }
    }

    async function handleRowUpdate(rowData, past) {
        if (featurePermissions && featurePermissions.field_tools && featurePermissions.field_tools.edit) {
            try {
                setLoading(true)

                // upload file to firebase
                if (rowData.shapefile instanceof File) rowData.shapefile = await uploadFile("Shape", rowData.shapefile)

                rowData["crop_id"] = +rowData["cropId"]
                delete rowData["cropId"]
                rowData["variety_id"] = +rowData["varietyId"]
                delete rowData["varietyId"]

                await updateTableRow(past.uuid, { ...past, ...rowData, labels: undefined })
                const _data = await getTableData()

                dispatch({ type: "GET_FIELDS", payload: { fields: _data } })
                setLoading(false)
                toast.success("Row updated successfully!")
            } catch (e) {
                console.log(e)
                setLoading(false)
                toast.error(e.toString())
            }
        } else {
            toast.warning("Your account does not have permission to do this action.")
        }
    }

    async function handleRowDelete(rowData) {
        if (featurePermissions && featurePermissions.field_tools && featurePermissions.field_tools.delete) {
            try {
                setLoading(true)
                await deleteTableRow(rowData.uuid)

                const _data = await getTableData()
                dispatch({ type: "GET_FIELDS", payload: { fields: _data } })

                setLoading(false)
                toast.success("Row deleted successfully!")
                return true
            } catch (e) {
                setLoading(false)
                toast.error(e.toString())
            }
        } else {
            toast.warning("Your account does not have permission to do this action.")
        }
        return false
    }

    function handleToggleAddEditField(field) {
        setActiveField(field && field.uuid ? field : null)
        setAddEditFieldOpen(!addEditFieldOpen)
    }

    async function handleNewReport(reportType, fields, emailList) {
        try {
            setLoading(true)
            await createNewReport(reportType, fields, emailList)
            setLoading(false)
            toast.success("Your report will be sent shortly!")
        } catch (e) {
            setLoading(false)
            toast.error(e.toString())
        }
    }

    async function handleDataSourceChange(fieldId, selectedObj) {
        if (featurePermissions?.datasources?.edit === false) {
            return
        }

        try {
            let userToken = await currentUser.getIdToken()
            return networking.put("/api/v1/fields/datasource/" + fieldId, selectedObj, {
                extraHeaders: { "User-Token": userToken },
            })
        } catch (err) {
            console.log("Error getting User token = ", err)
        }
    }

    async function handleDeleteLabel(label) {
        setLoading(true)

        const { name: labelName } = label
        const pastFields = [...fields]
        const pastLabels = [...labels]

        removeLabel(label)
            .catch(() => {
                setLabels(pastLabels)
                dispatch({ type: "GET_FIELDS", payload: { fields: pastFields } })
            })
            .finally(() => {
                setLoading(false)
            })

        setLabels(labels.filter((label) => label.name !== labelName))

        const _fields = fields.map((field) => {
            if (field.labels) {
                const _field = { ...field, labels: field.labels.filter((label) => label.name !== labelName) }
                return _field
            } else return field
        })

        dispatch({ type: "GET_FIELDS", payload: { fields: _fields } })
    }

    function handleLabelFields(label, selectedFields) {
        setLoading(true)

        const { name: labelName } = label
        const pastFields = [...fields]
        const pastLabels = [...labels]

        addLabelToFields(label, selectedFields)
            .catch(() => {
                setLabels(pastLabels)
                dispatch({ type: "GET_FIELDS", payload: { fields: pastFields } })
            })
            .finally(() => {
                setLoading(false)
            })

        // label exists?
        if (!labels.find((label) => label.name === labelName)) setLabels([...labels, label])

        // insert all fields by uuid into an object
        const hash = {}
        for (let selected of selectedFields) hash[selected.uuid] = selected

        const _fields = fields.map((field) => {
            if (hash[field.uuid]) {
                // delete hash[item.uuid]
                const _field = { ...field }
                if (_field.labels && !_field.labels.find((label) => label.name === labelName))
                    _field.labels = [..._field.labels, label]
                else if (!_field.labels) _field.labels = [label]

                return _field
            } else return field
        })

        dispatch({ type: "GET_FIELDS", payload: { fields: _fields } })
    }

    async function handleRemoveLabelFromField(label, field) {
        setLoading(true)

        const { name: labelName } = label
        const pastFields = [...fields]
        const pastLabels = [...labels]

        removeLabelFromField(label, field)
            .catch(() => {
                setLabels(pastLabels)
                dispatch({ type: "GET_FIELDS", payload: { fields: pastFields } })
            })
            .finally(() => {
                setLoading(false)
            })

        const _fields = fields.map((item) => {
            if (item.uuid === field.uuid) {
                return { ...item, labels: item?.labels?.filter((label) => label.name !== labelName) }
            } else return item
        })
        // setCropTableFields(_cropTableFields)
        dispatch({ type: "GET_FIELDS", payload: { fields: _fields } })
    }

    // Group fields
    const regionGroupColumns = [
        // Alert Field (render changes)
        {
            ..._alertColumn,
            render(_, rowData) {
                let count = 0
                for (let field of rowData.data)
                    if (field.triggered_alerts?.count) count += field.triggered_alerts?.count
                return <CropTableAlert value={{ count }} />
            },
        },
        _checkColumn,
        // Region Field (render changes)
        {
            ...regionColumn,
            displayName: "",
            toggler: true,
            draggable: false,
            style: { flex: 4 },
            headerStyle: { flex: 4 },
            render(value, rowData) {
                return (
                    <>
                        <strong>Region:</strong>
                        {value}
                    </>
                )
            },
        },
        // {
        //     propName: "report-button",
        //     sortable: false,
        //     displayName: "",
        //     style: { flex: 2, display: "flex", justifyContent: "center", alignItems: "center" },
        //     render(_, rowData) {
        //         return <SimpleButton>View Report</SimpleButton>
        //     },
        // },
    ]

    return (
        <>
            <div className="crop-table">
                <div className="crop-table__container">
                    <Table
                        title="Field List"
                        // data
                        data={fields}
                        metaColumns={cropTableMetaFields}
                        // Pass processed polygon data (Needed for the map display)
                        fieldsPolygonData={processPolygonData({ selectedFields, polygons }, units)}
                        // handlers
                        loading={loading}
                        onSelectedMapColumnVariable={setSelectedMapColumnVariable}
                        onSelectionChanged={handleSelectionChange} // Handling table row selection changes
                        onNewReport={handleNewReport}
                        onRowAdd={handleRowAdd}
                        onRowUpdate={handleRowUpdate}
                        onRowDelete={handleRowDelete}
                        onDataSourceChange={handleDataSourceChange}
                        onToggleAddEditField={handleToggleAddEditField}
                        // label functionallity
                        tableColumnSettings={tableColumnSettings}
                        labels={labels}
                        // highlight functionallity
                        highlightByColumn={selectedMapColumnVariable}
                        // onNewLabel={handleNewLabel}
                        onDeleteLabel={handleDeleteLabel}
                        onLabelFields={handleLabelFields}
                        onRemoveLabelFromField={handleRemoveLabelFromField}
                        // permissions
                        reportTypes={featurePermissions["report_generation"]}
                        showAddButton={featurePermissions?.field_tools?.add}
                        showDataSourceDropdown={featurePermissions?.datasource?.edit}
                        regionalViewMapVisibleSetting={regionalViewMapVisible}
                        onUserSettingsChange={handleUpdateDBUserSettings}
                        units={units}
                        // navigation
                        history={history}
                    >
                        {/* Alert Column */}
                        <TableColumn {..._alertColumn} />

                        {/* Check Column */}
                        <TableColumn {..._checkColumn} />

                        {/* Name Column */}
                        <TableColumn
                            {...nameColumn}
                            groupColumns={(self) => [_checkColumn, self]}
                            render={(value, rowData, rowOptions) => {
                                return (
                                    <CropTableName
                                        value={value}
                                        rowData={rowData}
                                        rowOptions={rowOptions}
                                        permissions={permissions}
                                    />
                                )
                            }}
                        />

                        {/* Crop Column */}
                        <TableColumn {...cropColumn} groupColumns={(self) => [_checkColumn, self]} />

                        {/* Variety Column */}
                        <TableColumn {...varietyColumn} groupColumns={(self) => [_checkColumn, self]} />

                        {/* Region Column */}
                        <TableColumn {...regionColumn} groupColumns={() => regionGroupColumns} />

                        {/* Weather Variables Planting Date */}
                        {tableColumnSettings["planting_date"] && (
                            <TableColumn
                                propName="plantingDateP"
                                displayName="Planting Date"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "13px",
                                }}
                                render={(_, rowData) => {
                                    return <div>{formatDate(rowData.plantingDateP)}</div>
                                }}
                                sortable
                            />
                        )}

                        {/* Weather Variables Tomorrow Temperature */}
                        {units && tableColumnSettings["t2m"] && (
                            <TableColumn
                                propName="weatherVar_tomorrowTemp"
                                displayName={
                                    <>
                                        <div>Tmrws. Temp.</div>
                                        <div>(°{units === "metric" ? "C" : "F"})</div>
                                    </>
                                }
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                }}
                                render={(value, rowData) => {
                                    return <CropTableTomorrowTemp value={value} rowData={rowData} units={units} />
                                }}
                            />
                        )}

                        {/* Weather Variables Tomorrow Precipitation */}
                        {units && tableColumnSettings["tp"] && (
                            <TableColumn
                                propName="weatherVar_tomorrowPrecip"
                                displayName={
                                    <>
                                        <div>Tmrws. Precip.</div>
                                        <div>({units === "metric" ? "mm" : "in"})</div>
                                    </>
                                }
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                render={(value, rowData) => {
                                    return <CropTableTomorrowPrecipt value={value} rowData={rowData} units={units} />
                                }}
                            />
                        )}

                        {/* Weather Variables Yield */}
                        {units && tableColumnSettings["yield"] && (
                            <TableColumn
                                propName="weatherVar_yield"
                                displayName={
                                    <>
                                        <div>Yield</div>
                                        <div>(Kg/Ha)</div>
                                    </>
                                }
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                render={(value, rowData) => {
                                    return <>{temporaryYieldsForHazeraFields[rowData.name]?.yield || "N/A"}</>
                                }}
                            />
                        )}

                        {/* Weather Variables Yield vs Plan */}
                        {units && tableColumnSettings["yield"] && (
                            <TableColumn
                                propName="weatherVar_yieldVsPlan"
                                displayName={
                                    <>
                                        <div>Yield</div>
                                        <div>(+/- vs. plan)</div>
                                    </>
                                }
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                component={CropTableYieldVsPlan}
                            />
                        )}

                        {/* Labels Column */}

                        {tableColumnSettings["labels"] && (
                            <TableColumn {...labelsColumn} style={{ width: 240 }} component={CropTableLabels} />
                        )}

                        {/* Actions */}
                        {(featurePermissions?.field_tools?.edit || featurePermissions?.field_tools?.delete) && (
                            <TableColumn
                                {...actionsColumn}
                                render={(value, rowData, rowOptions) => {
                                    return (
                                        <CropTableActions
                                            value={value}
                                            rowData={rowData}
                                            rowOptions={rowOptions}
                                            onToggleAddEditField={handleToggleAddEditField}
                                            featurePermissions={featurePermissions}
                                        />
                                    )
                                }}
                            />
                        )}
                    </Table>
                </div>
            </div>
            <AddEditField
                doShow={addEditFieldOpen}
                onCancel={() => setAddEditFieldOpen(false)}
                field={activeField}
                onSave={handleRowAdd}
                onUpdate={handleRowUpdate}
                canEditDatasource={featurePermissions?.datasource?.edit || false}
            />
            <ToastContainer />
        </>
    )
}

export default React.memo(CropTable)
