import * as React from "react"

function PlusIconAlt(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill={props.fill || "#1F9992"} />
        </svg>
    )
}

export default PlusIconAlt
