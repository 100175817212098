import { useMemo } from "react"
import { getQuartiles } from "../Util/QuartilesUtil"

function getStandardDeviation(array) {
    if (!array || array.length === 0) return { std: 0, mean: 0 }

    const n = array.length
    const mean = array.reduce((a, b) => a + b) / n
    const std = Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n - 1)
    return { std, mean }
}

export default function useAnalogsData(data, currentLocation) {
    return useMemo(() => {
        let chartData = {
            type: "FeatureCollection",
            features: [],
        }

        if (data && Object.keys(data).length > 0 && currentLocation)
            chartData.features = [
                {
                    type: "Feature",
                    properties: {
                        type: "pin",
                        color: "#F37920",
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            currentLocation.coords.lon > 180
                                ? currentLocation.coords.lon - 360
                                : currentLocation.coords.lon,
                            currentLocation.coords.lat,
                        ],
                    },
                },
            ]

        const top3Analogs = {
            first: { value: Number.MIN_VALUE },
            second: { value: Number.MIN_VALUE },
            third: { value: Number.MIN_VALUE },
        }

        // Not usable anymore
        // const { std, mean } = getStandardDeviation(Object.values(data))

        Object.keys(data).forEach((key) => {
            const strCoords = key.replaceAll("(", "").replaceAll(")", "").split(",")
            const lat = parseFloat(strCoords[0])
            const lng = parseFloat(strCoords[1])

            const radius = data[key]

            if (radius > top3Analogs.first.value) {
                delete top3Analogs.third
                top3Analogs.third = top3Analogs.second
                top3Analogs.second = top3Analogs.first
                top3Analogs.first = { value: radius, lat, lng }
            } else if (radius > top3Analogs.second.value) {
                delete top3Analogs.third
                top3Analogs.third = top3Analogs.second
                top3Analogs.second = { value: radius, lat, lng }
            } else if (radius > top3Analogs.third.value) {
                delete top3Analogs.third
                top3Analogs.third = { value: radius, lat, lng }
            }

            chartData.features.push({
                type: "Feature",
                properties: {
                    type: "rectangularHeatmap",
                    longitudeWidth: 1,
                    latitudeHeight: 1,
                    color: ["#00ceb4", "#0c7667"],
                    satelliteColors: ["#ff7f24", "#df6e20"],
                    opacity: radius,
                },
                geometry: {
                    type: "Point",
                    coordinates: [lng > 180 ? lng - 360 : lng, lat],
                },
            })
        })

        data &&
            Object.keys(top3Analogs).forEach((key) => {
                const { lat, lng } = top3Analogs[key]
                if (lat && lng) {
                    chartData.features.push({
                        type: "Feature",
                        properties: {
                            type: "pin",
                            color: "#1C9690",
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [lng, lat],
                        },
                    })
                }
            })

        return { chartData }
    }, [data])
}
