import React from "react"

function TemperatureIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M15 13V5a3 3 0 0 0-6 0v8a5 5 0 1 0 6 0Zm-3-9a1 1 0 0 1 1 1v4h-2V5a1 1 0 0 1 1-1Z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default TemperatureIcon
