import React from "react"
import "./DailyMonthlyOptions.css"

const DailyMonthlyOptions = (props) => {
    const {
        dailyAvailable = false,
        monthlyAvailable = false,
        hourlyAvailable = false,
        selectedGranularity = "daily",
        onChange = () => null,
        granularityAlerts,
    } = props

    return (
        <div className="dmoptions">
            {/* aapte - Commenting out this section till hourly is available {hourlyAvailable ? (
                <div
                    id="hourly-tab__weather-model"
                    className="dmoptions__button"
                    style={{
                        backgroundColor: selectedGranularity === "hourly" ? "#1C9690" : "",
                        border: "1px solid #1f9992",
                    }}
                    onClick={() => {
                        onChange("hourly")
                    }}
                >
                    <div
                        className="dmoptions__text"
                        style={{
                            color: selectedGranularity === "hourly" ? "#fff" : "#000",
                        }}
                    >
                        Hourly
                    </div>
                    {granularityAlerts.hourly && <div className="weather-tabs__alert-dot"></div>}
                </div>
            ) : (
                <div
                    id="hourly-tab__weather-model"
                    className="dmoptions__button--disabled"
                    style={{
                        boxShadow: "none",
                        cursor: "no-drop",
                    }}
                >
                    <div className="dmoptions__text">Hourly</div>
                </div>
            )} */}
            {dailyAvailable ? (
                <div
                    id="daily-tab__weather-model"
                    className="dmoptions__button"
                    style={{
                        backgroundColor: selectedGranularity === "daily" ? "#1C9690" : "",
                        border: "1px solid #1f9992",
                    }}
                    onClick={() => {
                        onChange("daily")
                    }}
                >
                    <div
                        className="dmoptions__text"
                        style={{
                            color: selectedGranularity === "daily" ? "#fff" : "#000",
                        }}
                    >
                        Daily
                    </div>
                    {granularityAlerts.daily && <div className="weather-tabs__alert-dot"></div>}
                </div>) : (
                    <div
                    id="monthly-tab__weather-model"
                    className="dmoptions__button--disabled"
                    style={{
                        boxShadow: "none",
                        cursor: "no-drop",
                    }}
                >
                    <div className="dmoptions__text">Daily</div>
                </div>
                )

            }
            {monthlyAvailable ? (
                <div
                    id="monthly-tab__weather-model"
                    className="dmoptions__button"
                    style={{
                        backgroundColor: selectedGranularity === "monthly" ? "#1C9690" : "",
                        border: "1px solid #1f9992",
                    }}
                    onClick={() => {
                        onChange("monthly")
                    }}
                >
                    <div
                        className="dmoptions__text"
                        style={{
                            color: selectedGranularity === "monthly" ? "#fff" : "#000",
                        }}
                    >
                        Monthly
                    </div>
                    {(granularityAlerts.monthly || granularityAlerts.daily) && <div className="weather-tabs__alert-dot"></div>}
                </div>
            ) : (
                <div
                    id="monthly-tab__weather-model"
                    className="dmoptions__button--disabled"
                    style={{
                        boxShadow: "none",
                        cursor: "no-drop",
                    }}
                >
                    <div className="dmoptions__text">Monthly</div>
                </div>
            )}
        </div>
    )
}

export default DailyMonthlyOptions
