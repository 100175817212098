import React, { useState, Children } from "react"
import useOutsideComponentClickHandler from "../../hooks/ClickOutsideHook"

import LabelIcon from "../Icons/LabelIcon"

import "./TableLabelButton.css"

function TableLabelButton(props) {
    const { children } = props

    const [open, toggle] = useState(false)
    const child = Children.only(children)

    const ref = useOutsideComponentClickHandler(() => toggle(false))

    function handleChildClose() {
        toggle(false)
    }

    return (
        <>
            <div className="table-label-button" ref={ref}>
                <button
                    id="label-button__table"
                    className={"table-label-button__button" + (open ? " active" : "")}
                    onClick={() => toggle(!open)}
                >
                    <LabelIcon fill={open ? "#4e4e4e" : "none"} />
                </button>
                {open && (
                    <div className="table-label-button__content">
                        {React.cloneElement(child, { __onClose__: handleChildClose })}
                    </div>
                )}
            </div>
        </>
    )
}

export default TableLabelButton
