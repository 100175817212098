import ChillHoursIcon from "../ui/Icons/VariablesIcons/ChillHoursIcon"
import GrowingDegreeDaysIcon from "../ui/Icons/VariablesIcons/GrowingDegreeDaysIcon"
import HumidityIcon from "../ui/Icons/VariablesIcons/HumidityIcon"
import PrecipitationIcon from "../ui/Icons/VariablesIcons/PrecipitationIcon"
import TemperatureIcon from "../ui/Icons/VariablesIcons/TemperatureIcon"

export default function getVariableIcon(variable) {
    switch (variable) {
        case "t2m": {
            return <TemperatureIcon />
        }
        case "tp": {
            return <PrecipitationIcon />
        }
        case "rh": {
            return <HumidityIcon />
        }
        case "gdd": {
            return <GrowingDegreeDaysIcon />
        }
        case "ch": {
            return <ChillHoursIcon />
        }
        default: {
            return null
        }
    }
}
