import { useMemo } from "react"

export default function useDepartureData(data, pastq, futureq) {
    return useMemo(() => {
        const { threshold = {}, x, y } = data

        let chartData = {
            type: "line",
            color: "teal",
            "stroke-width": 2,
            points: [],
        }

        x &&
            x.forEach((d, i) => {
                if (d && y[i]) {
                    chartData.points.push({
                        x: new Date(`Jan 1, ${d}`).getTime(),
                        y: y[i],
                    })
                }
            })

        return { chartData, threshold: threshold[`[${futureq},${pastq}]`] || null }
    }, [data, pastq, futureq])
}
