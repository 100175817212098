import React from "react"

function DrySoilRiskIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M16.085 11.326c.15-.903-.1-1.806-.67-2.515l-.07-.068a5.337 5.337 0 0 1-1.3-4.252l.06-.491h1.89l-.09.674c-.15.903.1 1.806.67 2.515l.07.08a5.264 5.264 0 0 1 1.3 4.24l-.06.491h-1.91l.11-.674ZM12.085 11.326c.15-.903-.1-1.806-.67-2.515l-.07-.068a5.337 5.337 0 0 1-1.3-4.252l.06-.491h1.89l-.09.674c-.15.903.1 1.806.67 2.515l.07.08a5.264 5.264 0 0 1 1.3 4.24l-.06.491h-1.91l.11-.674ZM8.085 11.326c.15-.903-.1-1.806-.67-2.515l-.07-.068a5.337 5.337 0 0 1-1.3-4.252L6.105 4h1.89l-.09.674c-.15.903.1 1.806.67 2.515l.07.08a5.264 5.264 0 0 1 1.3 4.24l-.06.491h-1.91l.11-.674ZM3 14h18v2H3zM3 19h18v2H3z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default DrySoilRiskIcon
