import React, { createContext, useContext, useEffect, useReducer, useState } from "react"

export const AdaptationContext = createContext({})

export const VARIABLES = [
    { label: "Temperature", value: "t2m", units: "°C" },
    { label: "Precipitation", value: "tp", units: "mm" },
    { label: "Relative Humidity", value: "rh", units: "%" },
    { label: "Growing Degree Days", value: "gdd", units: "" },
    { label: "Chill Hours", value: "ch", unit: "" },
]

export const DECADES = [
    // { label: "2010 - 2019", value: "2010" },
    { label: "2050 - 2059", value: "2050" },
    { label: "2040 - 2049", value: "2040" },
    { label: "2030 - 2039", value: "2030" },
    { label: "2020 - 2029", value: "2020" },
    { label: "2010 - 2019", value: "2010" },
]

export const SCENARIOS = [
    { label: "High emission scenario", value: "worst" },
    { label: "Middle of the road", value: "usual" },
    { label: "Low emission scenario", value: "best" },
]

const initialState = {
    // from server
    locations: [],
    crops: [],
    risks: [],
    // local
    variables: VARIABLES,
    decades: DECADES,
    scenarios: SCENARIOS,
    // user selections
    selectedLocation: null,
    selectedCrop: null,
    selectedRisk: null,
    //
    selectedVariable: VARIABLES[0].value,
    selectedDecade: DECADES[0].value,
    selectedScenario: SCENARIOS[0].value,
    // toggle variables and risks (for certain screens)
    // this is easier than erasing one of the latter each time
    // they toggle
    // 'true' for showing variables
    // 'false' for showing risks
    variablesByDefault: true,
}

function AdaptationReducer(state = {}, action) {
    const { type, payload } = action

    switch (type) {
        case "SET_INITIAL_STATE": {
            const { locations, crops, risks } = payload

            return {
                ...state,
                locations,
                crops,
                risks,
                selectedLocation: (locations && locations[0].name) || null,
                selectedCrop: (crops && crops[0]) || null,
                selectedRisk: (risks && risks[0].id) || null,
            }
            // get risks out of overview
            // put the variables too
        }
        case "SET_SELECTED_LOCATION": {
            const { location } = payload
            return { ...state, selectedLocation: location }
        }
        case "SET_SELECTED_CROP": {
            const { crop } = payload
            return { ...state, selectedCrop: crop }
        }
        case "SET_SELECTED_RISK": {
            const { risk, only = false } = payload
            return { ...state, selectedRisk: risk, variablesByDefault: only }
        }
        case "SET_SELECTED_VARIABLE": {
            const { variable, only = false } = payload
            return { ...state, selectedVariable: variable, variablesByDefault: only }
        }
        case "SET_SELECTED_DECADE": {
            const { decade } = payload
            return { ...state, selectedDecade: decade }
        }
        case "SET_SELECTED_SCENARIO": {
            const { scenario } = payload
            return { ...state, selectedScenario: scenario }
        }
        default: {
            return state
        }
    }
}

function ClimateProvider(props) {
    const { children } = props
    const [state, dispatch] = useReducer(AdaptationReducer, initialState)

    return <AdaptationContext.Provider value={{ state, dispatch }}>{children}</AdaptationContext.Provider>
}

export function useAdaptationValues() {
    const {
        state: { locations, crops, risks, ...rest },
        dispatch,
    } = useContext(AdaptationContext)

    function setSelectedLocation(location) {
        dispatch({ type: "SET_SELECTED_LOCATION", payload: { location } })
    }
    function setSelectedCrop(crop) {
        dispatch({ type: "SET_SELECTED_CROP", payload: { crop } })
    }
    function setSelectedRisk(risk, only) {
        dispatch({ type: "SET_SELECTED_RISK", payload: { risk, only } })
    }
    function setSelectedVariable(variable, only) {
        dispatch({ type: "SET_SELECTED_VARIABLE", payload: { variable, only } })
    }
    function setSelectedDecade(decade) {
        dispatch({ type: "SET_SELECTED_DECADE", payload: { decade } })
    }
    function setSelectedScenario(scenario) {
        dispatch({ type: "SET_SELECTED_SCENARIO", payload: { scenario } })
    }

    return {
        ready: locations && locations.length > 0 && crops && crops.length > 0 && risks && risks.length > 0,
        locations,
        crops,
        risks,
        ...rest,
        setSelectedLocation,
        setSelectedCrop,
        setSelectedRisk,
        setSelectedVariable,
        setSelectedDecade,
        setSelectedScenario,
    }
}

export default ClimateProvider
