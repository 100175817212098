import React, { useState, useEffect, useContext } from "react"
import { MapboxPolygonViewerComponent } from "../Charts/MapboxMaps/MapboxPolygonViewer/MapboxPolygonViewer"
import { AuthContext } from "../../Auth/Auth"
import networking from "../../Util/Networking"
import { useParams } from "react-router-dom"
import area from "@turf/area"
import { toast } from "react-toastify"

import "./FieldProperties.css"

export const FieldPropertiesContext = React.createContext(null)

function FieldProperties() {
    const [polygonData, setPolygonData] = useState({})
    const [fieldData, setFieldData] = useState({})
    let { id } = useParams()
    const { currentUser } = useContext(AuthContext)

    useEffect(() => {
        currentUser.getIdToken().then((userToken) => {
            // Load polygon data
            networking
                .get(`/api/v1/maps/ndvimap?uuid=${id}`, {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    setPolygonData(res.data)
                })
                .catch((err) => {
                    toast.warn("Could not load polygon data.")
                })

            // Load fields data
            networking
                .get(`/api/v1/fields/${id}`, {
                    extraHeaders: { "User-Token": userToken },
                })
                .then((res) => {
                    setFieldData(res.data.data)
                })
                .catch((err) => {
                    toast.error("Could not load field data")
                })
        })
    }, [currentUser, id])

    // Render field property lists and their values
    return (
        <FieldPropertiesContext.Provider value={(fieldData, polygonData)}>
            <div className="field-properties">
                <div className="field-properties__info">
                    <div className="field-properties__text">
                        <b>Crop:</b> {fieldData.crop}
                    </div>
                    <div className="field-properties__text">
                        <b>Variety:</b> {fieldData.variety}
                    </div>
                    <div className="field-properties__text">
                        <b>Region:</b> {fieldData.region}
                    </div>
                    <div style={{display:"none"}}>
                        <b>lat lon:</b> {polygonData.center?`${polygonData.center.lat},${polygonData.center.lon}`:""}
                    </div>
                </div>

                <div className="field-properties__mapbox full">
                    <MapboxPolygonViewerComponent polygonData={polygonData} width="100%"></MapboxPolygonViewerComponent>
                </div>
            </div>
        </FieldPropertiesContext.Provider>
    )
}

export default FieldProperties
