function ChevronIcon() {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="fill-inherit"
        >
            <path d="M16.59 9L12 13.58L7.41 9L6 10.41L12 16.41L18 10.41L16.59 9Z" />
        </svg>
    )
}

export default ChevronIcon
