import React from "react"

function ReportIcon(props) {
    return (
        <svg width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 16" {...props}>
            <path
                d="M3 11h6v1.5H3V11zm0-3h6v1.5H3V8zM7.5.5h-6C.675.5 0 1.175 0 2v12c0 .825.667 1.5 1.492 1.5H10.5c.825 0 1.5-.675 1.5-1.5V5L7.5.5zm3 13.5h-9V2h5.25v3.75h3.75V14z"
                fill={props.fill || "#666D74"}
            />
        </svg>
    )
}

export default ReportIcon
