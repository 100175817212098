import React from "react"
import PopoverTarget from "./PopoverTarget"
import "./Popover.css"

function Popover({ text, text2 = "", targetId, children, className = "" }) {
    return (
        <>
            <div className="popover__container">
                <PopoverTarget targetId={targetId}>{children}</PopoverTarget>
                <div className={`popover ${className}`}>
                    <div className="popover__label">
                        {text}
                        <br></br>
                        {text2}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Popover
