import React from "react"

// utils
import { temporaryYieldsForHazeraFields } from "../tableMetaFields"

function CropTableYieldVsPlan(props) {
    const { rowData } = props
    let className = ""
    let val = temporaryYieldsForHazeraFields[rowData.name]?.vsPlan || 0
    if (val > 0) {
        className = "green-pct"
    } else if (val < 0) {
        className = "red-pct"
    }

    return <div className={className}>{val + "%"}</div>
}

export default CropTableYieldVsPlan
