import axios from "axios"
import config from "../Util/config"
// firebase stuff
import { v4 as uuid } from "uuid"
import app from "../Util/Fire"

const hostDomain = config.backend_server

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export function formatDate(dateStr) {
    if (!dateStr) return "NA"
    const date = new Date(dateStr.replace(/-/g, "/"))
    const formatedDate = monthNames[date.getMonth()] + " " + date.getDate().toString()
    return formatedDate
}

export async function createNewReport(reportType, fields, emailList) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const response = await axios.post(
            hostDomain + "/api/v1/reports/queue_report",
            {
                report_type: reportType,
                fields: fields.map((field) => ({ uuid: field.uuid })),
                recipients: emailList.map((email) => ({ email })),
            },
            { headers: { "User-Token": userToken } }
        )

        return response
    } catch (e) {
        throw new Error(e.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function getTableData() {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const {
            data: { data },
        } = await axios.get(hostDomain + "/api/v1/fields/", { headers: { "User-Token": userToken } })

        return data.reverse()
    } catch (e) {
        throw new Error(e.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function getTableColumnSettings() {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const { data } = await axios.get(hostDomain + "/api/v1/tablesettings/columns", {
            headers: { "User-Token": userToken },
        })

        return data
    } catch (err) {
        console.log("Error getting user alerts ", err)
    }
}

// Polygon data loading function
export async function getPolygonData(polygonsToLoad) {
    try {
        // get user token
        const userToken = await app.auth().currentUser.getIdToken()

        // Generate URL
        let url = hostDomain + "/api/v1/maps/multiple_ndvimaps"

        // If polygons info passed, regenerate URL to load necessary data
        if (polygonsToLoad && polygonsToLoad.length) {
            url += "?field_ids=" + polygonsToLoad.join(",")
        }

        // Get and return data based on generated URL
        const {
            data: { data },
        } = await axios.get(url, { headers: { "User-Token": userToken } })
        return data
    } catch (e) {
        throw new Error(e.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function addTableRow(rowData = {}) {
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const response = await axios.post(
            hostDomain + "/api/v1/fields/",
            { ...rowData },
            { headers: { "User-Token": userToken } }
        )

        return response
    } catch (e) {
        throw new Error(e.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function updateTableRow(uuid, rowData) {
    //
    if (!uuid) throw new Error("Must provide the row uuid for the update to happen.")
    //
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const response = await axios.put(
            hostDomain + "/api/v1/fields/" + uuid,
            { ...rowData },
            { headers: { "User-Token": userToken } }
        )
        return response
    } catch (e) {
        throw new Error(e.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function deleteTableRow(uuid) {
    //
    if (!uuid) throw new Error("Must provide the row uuid for the update to happen.")
    //
    try {
        const userToken = await app.auth().currentUser.getIdToken()
        const response = await axios.delete(hostDomain + "/api/v1/fields/" + uuid, {
            headers: { "User-Token": userToken },
        })
        return response
    } catch (e) {
        throw new Error(e.response?.data?.message || "There was a problem, try again later.")
    }
}

export async function uploadFile(ref, file) {
    // get extension
    const { name } = file
    const extension = name.split(".").pop()

    const fileName = uuid() + "." + extension

    // storage ref
    const storageRef = app.storage().ref(ref)

    // fileRef
    const fileRef = storageRef.child(fileName)
    await fileRef.put(file)

    return fileName
}

export async function getFileUrl(ref, file) {
    return await app.storage().ref(ref).child(file).getDownloadURL()
}
