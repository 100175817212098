import { useMemo } from "react"
import { getQuartiles } from "../Util/QuartilesUtil"

export default function useHistoricalData(data) {
    return useMemo(() => {
        const candlesticks = {
            type: "candlestick",
            color: (pointIndex, groupIndex, candleIndex) => {
                return ["#218981", "#ED631A"][groupIndex]
            },
            points: [],
        }

        const line = {
            type: "line",
            color: "#8F0F9E",
            points: [],
        }

        data?.historical?.observation &&
            Object.keys(data.historical.observation).map((key) => {
                line.points.push({
                    x: new Date(key, 0, 1),
                    y: data.historical.observation[key],
                })
            })

        const years = {}

        data?.historical &&
            data?.historical["Post-processed"] &&
            Object.keys(data?.historical["Post-processed"]).map((key) => {
                if (!years[key]) years[key] = []
                const values = data?.historical["Post-processed"][key]
                const { min, max, q1, q2, q3 } = getQuartiles(values)

                years[key].push({
                    x: new Date(`Jan ${key}`),
                    y0: min,
                    y1: max,
                    yMax: q3,
                    yMid: q2,
                    yMin: q1,
                })
            })

        data?.historical &&
            data?.historical["Raw"] &&
            Object.keys(data?.historical["Raw"]).map((key) => {
                if (!years[key]) years[key] = []
                const values = data?.historical["Raw"][key]
                const { min, max, q1, q2, q3 } = getQuartiles(values)

                years[key].push({
                    x: new Date(`Jan ${key}`),
                    y0: min,
                    y1: max,
                    yMax: q3,
                    yMid: q2,
                    yMin: q1,
                })
            })

        Object.keys(years).map((key) => {
            candlesticks.points.push({
                x: new Date(key, 0, 1),
                values: years[key],
            })
        })

        const chartData = [candlesticks, line]
        let tableData = (data?.evaluation && Object.values(data.evaluation).reverse()) || []

        return { chartData, tableData }
    }, [data])
}
