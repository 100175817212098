import React from "react"
// Modules
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

// Components
import VarietyAlert from "./VarietyAlert"
import VarietyTab from "./VarietyTab"

// Views

// Hooks
// Material-UI *
// Styles

// TODO: Any difference in performance for children importing styles?
// import "./AlertSettings.css"

const CropAlert = (props) => {
    const {
        cropObj,
        path,
        onVarietyFileDownload,
        onVarietyFileUpload,
        isDownloadEnabled,
        isUploadEnabled,
        stackedAlertsView = false,
        onAlertStatusChange = () => null,
        reloadAlertSettings = () => null,
    } = props

    return (
        <Tabs>
            <TabList className="alert-settings__alerts__variety-container">
                {Object.keys(cropObj).map((mVariety) => {
                    if (mVariety !== "name") {
                        let name = cropObj[mVariety].name || mVariety
                        return (
                            <Tab key={"tab-" + mVariety}>
                                <VarietyTab name={name} />
                            </Tab>
                        )
                    }
                    return null
                })}
            </TabList>
            <div>
                {Object.keys(cropObj).map((mVariety) => {
                    if (mVariety !== "name") {
                        let varietyObj = cropObj[mVariety]
                        return (
                            <TabPanel key={"tabpanel-" + mVariety}>
                                <VarietyAlert
                                    varietyObj={varietyObj}
                                    path={{ ...path, variety: mVariety }}
                                    onVarietyFileDownload={onVarietyFileDownload}
                                    onVarietyFileUpload={onVarietyFileUpload}
                                    isDownloadEnabled={isDownloadEnabled}
                                    isUploadEnabled={isUploadEnabled}
                                    stackedAlertsView={stackedAlertsView}
                                    onAlertStatusChange={onAlertStatusChange}
                                    reloadAlertSettings={reloadAlertSettings}
                                />
                            </TabPanel>
                        )
                    }
                    return null
                })}
            </div>
        </Tabs>
    )
}

export default CropAlert
