import * as React from "react"

function DrySoilIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 29 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M24.205 10.19h-2.031c-.471 0-.853-.416-.853-.927 0-.512.382-.926.853-.926h2.559c.323 0 .618.198.762.512l3.412 7.41c.284.616-.129 1.34-.763 1.34H.85c-.634 0-1.046-.724-.763-1.34L3.5 8.85a.849.849 0 01.763-.512H6.82c.47 0 .853.414.853.926 0 .511-.382.926-.853.926H4.789L2.23 15.747h24.534l-2.559-5.558zm-8.55-.477c.242.438.111 1.007-.292 1.27-.404.264-.928.121-1.17-.317-1.016-1.837-1.016-3.748 0-5.585.69-1.25.69-2.428 0-3.678-.243-.44-.112-1.008.292-1.271.404-.263.928-.121 1.17.317 1.015 1.838 1.015 3.748 0 5.585-.69 1.25-.69 2.428 0 3.679zm-4.265 0c.243.438.112 1.007-.292 1.27-.404.264-.928.121-1.17-.317-1.015-1.837-1.015-3.748 0-5.585.69-1.25.69-2.428 0-3.678-.243-.44-.112-1.008.292-1.271.404-.263.928-.121 1.17.317 1.015 1.838 1.015 3.748 0 5.585-.69 1.25-.69 2.428 0 3.679zm8.53 0c.242.438.111 1.007-.293 1.27-.404.264-.928.121-1.17-.317-1.015-1.837-1.015-3.748 0-5.585.69-1.25.69-2.428 0-3.678-.242-.44-.111-1.008.293-1.271.404-.263.927-.121 1.17.317 1.015 1.838 1.015 3.748 0 5.585-.691 1.25-.691 2.428 0 3.679z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default DrySoilIcon
