import React from "react"

function ExcessiveSolarRadiationIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5ZM11 1v4h2V1h-2ZM11 23h2v-4h-2v4ZM19 11v2h4v-2h-4ZM5 11H1v2h4v-2ZM6.34 16.24l-2.47 2.47 1.41 1.41 2.47-2.47-1.41-1.41ZM18.72 3.87l-2.47 2.47 1.41 1.41 2.47-2.47-1.41-1.41ZM23 23v-6l-2.293 2.293-3.05-3.05-1.414 1.414 3.05 3.05L17 23h6ZM5 1H3v2H1v2h2v2h2V5h2V3H5V1Z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default ExcessiveSolarRadiationIcon
