import React, { useMemo, useState, useRef, useEffect, useContext } from "react"
import { Box, Card, CardContent, CircularProgress, Select, MenuItem, InputLabel, FormControl } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"
import { ToastContainer, toast } from "react-toastify"
import { convertTemperatureValueOnly } from "../../../../Util/UnitConversion"

import { AuthContext } from "../../../../Auth/Auth"
import { DataContext } from "../../../../Util/Data"
import { SettingsContext } from "../../../../Util/SettingsContext"
import networking from "../../../../Util/Networking"
import { isEmptyObject } from "../../../../Util/General"
import roundNumber from "../../../../helpers/roundNumber"
// import barDataInsightsMin from './seasonal_bar_sample';
// import climatologyQuantilesBarsData from './climatological_quantiles_data';
// import ForecastQuantilesbarDataInsightsMin from './forecast_quantiles_data';
import { interpolateRgbBasis, hcl } from "d3"

import {
    getHistoricalMinTemp,
    getHistoricalMaxTemp,
    getForecastMinTemp,
    getForecastMaxTemp,
    getClimMin,
    getClimMax,
    getForecastMinArr,
    getForecastMaxArr,
} from "./helper"
import {
    convertToBarObj,
    processUnitSystem,
    convertToLineFromBar,
    getUnit,
    trimmData,
    hourlyDataTrim,
    assembleAreaData,
    assembleLineData,
    getForecastConfidenceData,
    mergeHistoricalAndForecastData,
    addMonths,
    validateData,
    convertToShadedRangesFormat,
    addDays,
    convertDataToBarStructure,
    convertAPIDataToSeasonalBarStructure,
    convertToDoubleCandlestick,
} from "../../../../helpers/chartHelpers"
import ChartSpecs from "../ChartSpecs"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { makeStyles } from "@material-ui/styles"
import useLastUpdated from "../../../../hooks/useLastUpdated"
import { variableDeclaration } from "@babel/types"

const TempChart = ({ actionsState }) => {
    let chartRef = useRef(null)
    const { currentUser } = useContext(AuthContext)
    const { currentSettings } = useContext(SettingsContext)
    const { id } = useParams()

    const [fieldId, setFieldId] = useState()
    const [confidenceVisible, setConfidenceBarsVisibility] = useState(false)
    const [climatologyVisible, setClimatologyVisible] = useState(false)

    const [historicalPending, setHistoricalPending] = useState(false)

    // Media Queries for Tablet View
    const [tabletMedia, setTabletMedia] = useState(true)
    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)", { noSsr: true })
    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    // ******************************* BAR DATA PART ****************************
    // BarData - Forecast
    const [monthlyBarForecastData, setMonthlyBarForecastData] = useState({
        time: [],
    })

    // BarData - Climatology
    const [monthlyBarClimatologyData, setMonthlyBarClimatologyData] = useState({
        time: [],
    })

    // BarData - Forecast - Vigintiles
    const [monthlyBarForecastDataVigintiles, setMonthlyBarForecastDataVigintiles] = useState({ time: [] })

    // BarData - Climatology - Vigintiles
    const [monthlyBarClimatologyDataVigintiles, setMonthlyBarClimatologyDataVigintiles] = useState({ time: [] })

    // Bar Data - Insights
    const barDataInsightsMin = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "t2m_min",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarDataMin = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                property: "t2m_min",
            }),
        [monthlyBarForecastDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsDataMin = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarClimatologyDataVigintiles,
                property: "t2m_min",
            }),
        [monthlyBarClimatologyDataVigintiles]
    )

    // Bar Data - Insights
    const barDataInsightsMax = useMemo(
        () =>
            convertAPIDataToSeasonalBarStructure({
                climatology: monthlyBarClimatologyData,
                forecast: monthlyBarForecastData,
                property: "t2m_max",
            }),
        [monthlyBarForecastData, monthlyBarClimatologyData]
    )

    // BarData - Converted - Forecast
    const forecastQuantilesBarDataMax = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarForecastDataVigintiles,
                property: "t2m_max",
            }),
        [monthlyBarForecastDataVigintiles]
    )

    // BarData - Converted - Climatology
    const climatologyQuantilesBarsDataMax = useMemo(
        () =>
            convertDataToBarStructure({
                data: monthlyBarClimatologyDataVigintiles,
                property: "t2m_max",
            }),
        [monthlyBarClimatologyDataVigintiles]
    )

    // ******************************* END OF BAR DATA PART ****************************

    // Prepare initial data
    const weatherVariable = "temperature"
    const [data, setData] = useState({
        ds_hist: {
            time: [],
            t2m_min: [],
            t2m_max: [],
        },
        ds_fc: {
            time: [],
            t2m_min: [],
            t2m_max: [],
        },
        ds_clim: {
            time: [],
            t2m_min: [],
            t2m_max: [],
        },
        pending: true,
    })

    // Prepare initial data
    const [monthlyData, setMonthlyData] = useState({
        ds_hist: {
            time: [],
            t2m_min: [],
            t2m_max: [],
        },
        ds_fc: {
            time: [],
            t2m_min: [],
            t2m_max: [],
        },
        ds_clim: {
            time: [],
            t2m_min: [],
            t2m_max: [],
        },
        pending: true,
    })

    // Alerts Data
    const [alertsData, setAlertsData] = useState({
        t2m_max: {},
        t2m_min: {},
    })

    const [hourlyData, setHourlyData] = useState({
        ds_hist: {
            time: [],
            t2m: [],
        },
        ds_fc: {
            time: [],
            t2m: [],
        },
    })

    // Load data
    useEffect(() => {
        if (fieldId !== id || (fieldId === id && climatologyVisible && data.ds_clim.time.length === 0)) {
            // Set data status pending
            setData({
                ...data,
                pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
            })
            setMonthlyData({
                ...monthlyData,
                pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
            })

            // Load data
            currentUser.getIdToken().then((userToken) => {
                let localData = data

                if (climatologyVisible) {
                    setHistoricalPending(true)
                }
                // let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
                // if (fieldId !== id && climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
                let datasets = "history%2Cforecast%2Cclimatology"
                if (fieldId !== id && !climatologyVisible) {
                    localData = Object.assign({}, localData, {
                        ds_clim: {
                            time: [],
                            t2m_min: [],
                            t2m_max: [],
                        },
                        pending: true,
                    })
                    setData(localData)
                }

                networking
                    .get(`/api/v1/weather/${weatherVariable}/daily/${id}?datasets=${datasets}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (datasets.indexOf("climatology") !== -1) {
                            setHistoricalPending(false)
                        }
                        setData({
                            ...Object.assign({}, localData, res.data),
                            pending: false,
                        })
                    })
                    .catch(() => {
                        setData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        toast.error("Error occurred with server. Please, try later.")
                    })

                // Break network call if data already loaded
                if (fieldId === id && monthlyData.ds_fc.time.length) {
                    return
                }

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=terciles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setMonthlyBarForecastData(res.data.ds_fc)
                        setMonthlyBarClimatologyData(res.data.ds_clim)
                    })
                    .catch(() => {
                        toast.warn("Could not load monthly forecast data.")
                    })

                networking
                    .get(
                        `/api/v1/weather/${weatherVariable}/monthly/${id}?quantiles=vigintiles&datasets=forecast%2Cclimatology`,
                        {
                            extraHeaders: { "User-Token": userToken },
                        }
                    )
                    .then((res) => {
                        setMonthlyBarForecastDataVigintiles(res.data.ds_fc)
                        setMonthlyBarClimatologyDataVigintiles(res.data.ds_clim)
                    })
                    .catch(() => {
                        toast.warn("Could not load monthly forecast data.")
                    })

                // networking
                //     .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                //         extraHeaders: { "User-Token": userToken },
                //     })
                //     .then((res) => {
                //         setHourlyData({
                //             ...res.data,
                //             pending: false,
                //         })
                //     })
                //     .catch(() => {
                //         setHourlyData((prevData) => ({
                //             ...prevData,
                //             pending: false,
                //         }))
                //         toast.warn("Could not load hourly data.")
                //     })

                networking
                    .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (isEmptyObject(res.data)) {
                            toast.success(`There are no alerts for ${weatherVariable.replace("_", " ")}`)
                        }
                        setAlertsData(res.data)
                    })
                    .catch(() => {
                        //         toast.warn(
                        //             `Alerts not displayed on dashboard due to internet
                        // connectivity issues. All other functions working.`
                        //         )
                    })
            })
        }

        setFieldId(id)
    }, [actionsState.selectedGranularity, actionsState.currentTab, currentUser, id, climatologyVisible])

    const lastUpdated = useLastUpdated(data, monthlyData)

    // Prepare min  temp data
    const historicalMinTemp = useMemo(() => getHistoricalMinTemp(data["ds_hist"]), [data])
    const historicalMaxTemp = useMemo(() => getHistoricalMaxTemp(data["ds_hist"]), [data])
    const forecastMinArr = useMemo(() => getForecastMinArr(data["ds_fc"]), [data])
    const forecastMaxArr = useMemo(() => getForecastMaxArr(data["ds_fc"]), [data])

    // Prepare max temp data
    const forecastMinTemp = useMemo(
        () => getForecastMinTemp(data["ds_fc"], historicalMinTemp[historicalMinTemp.length - 1], forecastMinArr),
        [data, forecastMinArr, historicalMinTemp]
    )
    const forecastMaxTemp = useMemo(
        () => getForecastMaxTemp(data["ds_fc"], historicalMaxTemp[historicalMaxTemp.length - 1], forecastMaxArr),
        [data, forecastMaxArr, historicalMaxTemp]
    )
    const { climMinLighten, climMinDarken } = useMemo(() => getClimMin(data["ds_clim"]), [data])
    const { climMaxLighten, climMaxDarken } = useMemo(() => getClimMax(data["ds_clim"]), [data])

    // Look at the changes for historical and forecast data and display warning messages if invalid
    useMemo(() => {
        validateData({
            diffToAlert: 15,
            historic: climMinLighten,
            forecast: forecastMinTemp,
            message: "Tmin Forecast Anomaly Detected",
        })
        validateData({
            diffToAlert: 15,
            historic: climMaxLighten,
            forecast: forecastMaxTemp,
            accessorKey: "y",
            message: "TMax Forecast Anomaly Detected",
        })
    }, [forecastMinTemp, forecastMaxTemp, climMinLighten, climMaxLighten])

    // Prepare Confidence Data
    const forecastMinConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMinTemp[historicalMinTemp.length - 1],
            data["ds_fc"]["t2m_min"],
            "0.75",
            historicalMinTemp
        )
    }, [data, historicalMinTemp])
    const forecastMinConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMinTemp[historicalMinTemp.length - 1],
            data["ds_fc"]["t2m_min"],
            "0.95",
            historicalMinTemp
        )
    }, [data, historicalMinTemp])
    const forecastMaxConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMaxTemp[historicalMaxTemp.length - 1],
            data["ds_fc"]["t2m_max"],
            "0.75",
            historicalMaxTemp
        )
    }, [data, historicalMaxTemp])
    const forecastMaxConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMaxTemp[historicalMaxTemp.length - 1],
            data["ds_fc"]["t2m_max"],
            "0.95",
            historicalMaxTemp
        )
    }, [data, historicalMaxTemp])

    // Prepare historical data
    const histCsvData = data["ds_hist"].time.map((item, index) => {
        return [data["ds_hist"]["t2m_min"][index], data["ds_hist"]["t2m_max"][index]]
    })

    // Prepare forecast csv data
    const forcCsvData = data["ds_fc"].time.map((item, index) => {
        return [forecastMinArr[index], forecastMaxArr[index]]
    })

    // Prepare clim max and min data
    const climMaxArr = [].concat.apply([], Object.values(data["ds_clim"]["t2m_max"]))
    const climMinArr = [].concat.apply([], Object.values(data["ds_clim"]["t2m_min"]))

    // Assemble clim csv data
    const climCsvData = data["ds_clim"].time.map((item, index) => {
        return [item, climMinArr[index], climMaxArr[index]]
    })

    // Use styles
    const useStyles = makeStyles((theme) => ({
        root: {
            boxShadow: theme.palette.effectStyles.backGlowCards.boxShadow,
            borderRadius: "20px",
        },
    }))

    const [barType, setBarType] = useState("med")

    // Set chart title according to daily/monthly selection
    var chartTitle = "Min & Max Temperature"
    if (actionsState.selectedGranularity === "daily") {
        chartTitle = "Daily Surface Min & Max Temperature"
    } else if (actionsState.selectedGranularity === "monthly") {
        chartTitle = "Monthly Surface Min & Max Temperature"
    }
    const dailyMaxData = assembleLineData({
        isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
        historical: historicalMaxTemp,
        forecast: forecastMaxTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.t2m_max["0.5"][i],
            }
        }),
    })

    const dailyMinData = assembleLineData({
        selectedGranularity: actionsState.selectedGranularity,
        isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
        historical: historicalMinTemp,
        forecast: forecastMinTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.t2m_min["0.5"][i],
            }
        }),
    })

    const monthlyMaxData = convertToLineFromBar({
        obj: forecastQuantilesBarDataMax[0.5],
        props: {
            color: "#EB6425",
            barType: barType,
            visible: false,
            date: 9,
            unit: currentSettings.units,
            unitType: "temp",
        },
    })

    const monthlyMinData = convertToLineFromBar({
        obj: forecastQuantilesBarDataMin[0.5],
        props: {
            color: "#0089C6",
            barType: barType,
            visible: false,
            unit: currentSettings.units,
            unitType: "temp",
            date: 19,
        },
    })

    const csvDailyData = dailyMaxData.map((d, i) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })],
        [d.y],
        [dailyMinData[i].y],
    ])

    const csvMonthlyData = monthlyMaxData.points.map((d, i) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", year: "numeric" })],
        [d.y],
        [monthlyMinData.points[i].y],
    ])

    return (
        <>
            <div className="weather-chart">
                <div className="weather-chart__chart-container">
                    <div
                        style={{ display: data.pending ? "flex" : "none" }}
                        className="weather-chart__preload-container"
                    >
                        <CircularProgress />
                    </div>
                    <LineAreaChartComponent
                        svgHeight={650}
                        // Title text
                        title={chartTitle}
                        // Set title hover text
                        titleHover={
                            {
                                daily: `This graph shows the daily observed and forecasted maximum and minimum ${weatherVariable} that would be measured 2 meters above the surface (land or water surface).`,
                                monthly: `This graph shows the monthly observed and forecasted maximum and minimum ${weatherVariable} that would be measured 2 meters above the surface (land or water surface).`,
                                hourly: `This graph shows the hourly observed and forecasted maximum and minimum ${weatherVariable} that would be measured 2 meters above the surface (land or water surface).`,
                            }[actionsState.selectedGranularity]
                        }
                        // Y label text
                        labelY={`Temperature in ${getUnit({ system: currentSettings.units }).tempUnit}`}
                        // Add top margin
                        marginTop={tabletMedia ? 75 : 65}
                        // Title label offset
                        titleLabelOffsetY={0}
                        // Pass unique resize event key
                        resizeEventListenerId={`${weatherVariable}-chart`}
                        // Add chart data id to filter out some update requests
                        chartDataId={
                            {
                                daily: `month`,
                                monthly: `day`,
                                hourly: `hour`,
                            }[actionsState.selectedGranularity] +
                            "_temp-chart_" +
                            climMaxLighten.length
                        }
                        // Center Ticks
                        centerTicks={
                            {
                                daily: false,
                                monthly: true,
                                hourly: false,
                            }[actionsState.selectedGranularity]
                        }
                        // Make chart to have dynamic y basis
                        zeroBasis={false}
                        // Convert received data to shaded ranges format
                        shadedRanges={convertToShadedRangesFormat(alertsData, Object.keys(alertsData))}
                        // Bottom margin will be 0.3 times of data diff
                        yBottomOffset={0.3}
                        // Top margin will be 0.3 times of data diff
                        yTopOffset={0.3}
                        // Hides y axis numbers
                        hideYAxisNumbers={false}
                        // Provide custom date max axis extent for monthly view charts
                        xDateMax={
                            {
                                daily: tabletMedia ? addDays(new Date(), 14) : null,
                                monthly: addMonths(
                                    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                                    monthlyBarForecastDataVigintiles.time.length
                                ),
                                hourly: null,
                            }[actionsState.selectedGranularity]
                        }
                        // Provide custom date min axis extent for monthly view charts
                        xDateMin={
                            {
                                daily: tabletMedia ? addDays(new Date(), -2) : null,
                                monthly: addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 0),
                                hourly: null,
                            }[actionsState.selectedGranularity]
                        }
                        // How x ticks will be formatted in chart
                        xTickFormat={
                            {
                                daily: (d, i, arr) => {
                                    if (i < arr.length - 1 && i !== 0) return d.getDate()
                                    if (i === 0)
                                        return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                    return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                                },
                                monthly: (d, i, arr) => {
                                    // Remove last, overflowing tick item
                                    if (i === arr.length - 1) return ""
                                    return d.toLocaleString(undefined, { month: "short" })
                                },
                                hourly: (d, i, arr) => {
                                    if (i < arr.length - 1 && i !== 0) return d.getHours() + "h"
                                    if (i === 0)
                                        return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                                    return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                },
                            }[actionsState.selectedGranularity]
                        }
                        // Give chart tips count tip
                        xTicksCount={
                            {
                                daily: tabletMedia ? 10 : 30,
                                monthly: 6,
                                hourly: 16,
                            }[actionsState.selectedGranularity]
                        }
                        // Hide chart if data is pending
                        hide={data.pending}
                        // Tooltip content on line points mouse over
                        tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                            let prec = 1
                            let hour = undefined
                            let day = "numeric"
                            let month = "short"
                            let h = ""
                            if (actionsState.selectedGranularity === "hourly") {
                                prec = 10
                                hour = "numeric"
                                day = undefined
                                month = undefined
                                h = "h"
                            }

                            if (barType == "candl") {
                                day = undefined
                            }
                            return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                 <tr><td style="font-weight:bold;font-size:20px" rowspan="${
                     values.length
                 }"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(
                                undefined,
                                { day, month, hour }
                            )}${h}</div></td> 
                     <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                         points[0].dashed ? "dashed" : "solid"
                     } ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${roundNumber(values[0], 1)} ${
                                getUnit({ system: currentSettings.units }).tempUnit
                            }
                    </td></tr>
                 ${values
                     .filter((d, i) => i > 0)
                     .map((value, i) => {
                         return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                             points[i + 1].dashed ? "dashed" : "solid"
                         } ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${roundNumber(value, 1)} ${
                             getUnit({ system: currentSettings.units }).tempUnit
                         }
                    </td></tr>`
                     })
                     .join("")}
             </table>`
                        }}
                        shapeTip={
                            ["monthly"].includes(actionsState.selectedGranularity)
                                ? (EVENT, boundObj, state, point) => {
                                      let resultObj = point ?? boundObj
                                      let month = resultObj.key
                                          .toLocaleString("default", { month: "short", year: "numeric" })
                                          .split("")
                                          .join("")
                                      let insights = barDataInsightsMin
                                      if ((resultObj.line || boundObj).isMax) {
                                          insights = barDataInsightsMax
                                      }
                                      let warmer = Math.round(insights.normalMax[month])
                                      let clim67 = Math.round(insights.clim67[month])
                                      let normal = Math.round(insights.normal[month])
                                      let clim33 = Math.round(insights.clim33[month])
                                      let colder = Math.round(insights.normalMin[month])

                                      let unit = "C"
                                      if (currentSettings.units === "imperial") {
                                          clim33 = +convertTemperatureValueOnly("metric", "imperial", clim33)
                                          clim67 = +convertTemperatureValueOnly("metric", "imperial", clim67)
                                          unit = "F"
                                      }

                                      return `<div style="max-width:250px;">
                            
                            There is a ${warmer}% chance that temperatures will be warmer than usual (above ${clim67} ${unit}).
                            
                            </br>  </br> There is a ${normal}% chance that temperatures will be within the normal range (${clim33} to ${clim67} ${unit}). 
                            
                            </br>  </br>There is a ${colder}% chance that temperatures will be colder than normal (below ${clim33} ${unit}).</div>`
                                  }
                                : null
                        }
                        // Chart data content
                        data={[
                            //======================= PLOT MEDIAN AS LINE ===================
                            ["monthly"].includes(actionsState.selectedGranularity)
                                ? barType != "med"
                                    ? null
                                    : convertToLineFromBar({
                                          obj: forecastQuantilesBarDataMin[0.5],

                                          props: {
                                              color: "#0089C6",
                                              barType: barType,
                                              visible: true,
                                              isMax: false,
                                              unit: currentSettings.units,
                                              getArrow: (d) => {
                                                  if (!barDataInsightsMin)
                                                      return {
                                                          color: "",
                                                          symbol: `
                                            <style>
                                            .point-loader {
                                              border: 3px solid #f3f3f3;
                                              border-radius: 50%;
                                              border-top: 3px solid #3498db;
                                              width: 10px;
                                              height: 10px;
                                              -webkit-animation: spin 2s linear infinite; /* Safari */
                                              animation: spin 2s linear infinite;
                                            }
                                            
                                            /* Safari */
                                            @-webkit-keyframes spin {
                                              0% { -webkit-transform: rotate(0deg); }
                                              100% { -webkit-transform: rotate(360deg); }
                                            }
                                            
                                            @keyframes spin {
                                              0% { transform: rotate(0deg); }
                                              100% { transform: rotate(360deg); }
                                            }
                                            </style>
                                            
                                            <div class="point-loader"></div>
                                            `,
                                                      }
                                                  const month = d.key
                                                      .toLocaleString("default", { month: "short", year: "numeric" })
                                                      .split("")
                                                      .join("")
                                                  const normalMax = barDataInsightsMin.normalMax[month]
                                                  const normalMin = barDataInsightsMin.normalMin[month]
                                                  const normal = barDataInsightsMin.normal[month]
                                                  const maxValue = Math.max(...[normalMax, normalMin, normal])
                                                  if (maxValue == normalMin) {
                                                      return {
                                                          symbol: "↓",
                                                          color: "blue",
                                                      }
                                                  }
                                                  if (maxValue == normalMax) {
                                                      return {
                                                          symbol: "↑",
                                                          color: "red",
                                                      }
                                                  }
                                                  if (maxValue == normal) {
                                                      return {
                                                          symbol: "=",
                                                          color: "gray",
                                                      }
                                                  }
                                              },
                                          },
                                      })
                                : null,

                            ["monthly"].includes(actionsState.selectedGranularity)
                                ? barType != "med"
                                    ? null
                                    : convertToLineFromBar({
                                          obj: forecastQuantilesBarDataMax[0.5],
                                          props: {
                                              color: "#EB6425",
                                              barType: barType,
                                              visible: true,
                                              isMax: true,
                                              unit: currentSettings.units,
                                              getArrow: (d) => {
                                                  if (!barDataInsightsMax)
                                                      return {
                                                          color: "",
                                                          symbol: `
                                            <style>
                                            .point-loader {
                                              border: 3px solid #f3f3f3;
                                              border-radius: 50%;
                                              border-top: 3px solid #3498db;
                                              width: 10px;
                                              height: 10px;
                                              -webkit-animation: spin 2s linear infinite; /* Safari */
                                              animation: spin 2s linear infinite;
                                            }
                                            
                                            /* Safari */
                                            @-webkit-keyframes spin {
                                              0% { -webkit-transform: rotate(0deg); }
                                              100% { -webkit-transform: rotate(360deg); }
                                            }
                                            
                                            @keyframes spin {
                                              0% { transform: rotate(0deg); }
                                              100% { transform: rotate(360deg); }
                                            }
                                            </style>
                                            
                                            <div class="point-loader"></div>
                                            `,
                                                      }
                                                  const month = d.key
                                                      .toLocaleString("default", { month: "short", year: "numeric" })
                                                      .split("")
                                                      .join("")
                                                  const normalMax = barDataInsightsMax.normalMax[month]
                                                  const normalMin = barDataInsightsMax.normalMin[month]
                                                  const normal = barDataInsightsMax.normal[month]
                                                  const maxValue = Math.max(...[normalMax, normalMin, normal])
                                                  if (maxValue == normalMin) {
                                                      return {
                                                          symbol: "↓",
                                                          color: "blue",
                                                      }
                                                  }
                                                  if (maxValue == normalMax) {
                                                      return {
                                                          symbol: "↑",
                                                          color: "red",
                                                      }
                                                  }
                                                  if (maxValue == normal) {
                                                      return {
                                                          symbol: "=",
                                                          color: "gray",
                                                      }
                                                  }
                                              },
                                          },
                                      })
                                : null,

                            //=================== DOUBLE  CANDLESTICK VERSION  =========================

                            ["monthly"].includes(actionsState.selectedGranularity) && barType == "candl"
                                ? monthlyMaxData
                                : null,

                            ["monthly"].includes(actionsState.selectedGranularity) && barType == "candl"
                                ? monthlyMinData
                                : null,

                            ["monthly"].includes(actionsState.selectedGranularity) && barType == "candl"
                                ? convertToDoubleCandlestick({
                                      obj: [
                                          {
                                              isMax: true,
                                              values: [forecastQuantilesBarDataMax, climatologyQuantilesBarsDataMax],
                                          },
                                          {
                                              isMax: false,
                                              values: [forecastQuantilesBarDataMin, climatologyQuantilesBarsDataMin],
                                          },
                                      ],
                                      unit: currentSettings.units,
                                      unitType: "temp",
                                      colors: ["#EB6425", "#0089C6"],
                                  })
                                : null,

                            // ===================== End Of Horizontal and vertical bars versions  ====================

                            ["daily"].includes(actionsState.selectedGranularity) && climatologyVisible
                                ? {
                                      type: "area",
                                      points: {
                                          daily: trimmData(climMaxLighten),
                                          monthly: climMaxLighten,
                                          hourly: hourlyDataTrim(climMaxLighten),
                                      }[actionsState.selectedGranularity].map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                      color: "#F8D6C5",
                                      opacity: 0.6,
                                  }
                                : null,
                            ["daily"].includes(actionsState.selectedGranularity) && climatologyVisible
                                ? {
                                      type: "area",
                                      points: {
                                          daily: trimmData(climMaxDarken),
                                          monthly: climMaxDarken,
                                          hourly: hourlyDataTrim(climMaxDarken),
                                      }[actionsState.selectedGranularity].map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                      color: "#FDBE9D",
                                      opacity: 0.6,
                                  }
                                : null,
                            ["daily"].includes(actionsState.selectedGranularity) && climatologyVisible
                                ? {
                                      type: "area",
                                      points: {
                                          daily: trimmData(climMinLighten),
                                          monthly: climMinLighten,
                                          hourly: hourlyDataTrim(climMinLighten),
                                      }[actionsState.selectedGranularity].map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                      color: "#DBEBF5",
                                      opacity: 0.6,
                                  }
                                : null,
                            ["daily"].includes(actionsState.selectedGranularity) && climatologyVisible
                                ? {
                                      type: "area",
                                      points: {
                                          daily: trimmData(climMinDarken),
                                          monthly: climMinDarken,
                                          hourly: hourlyDataTrim(climMinDarken),
                                      }[actionsState.selectedGranularity].map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                      color: "#C6E2F1",
                                      opacity: 0.6,
                                  }
                                : null,
                            // Confidence Bands
                            ["daily"].includes(actionsState.selectedGranularity) && confidenceVisible
                                ? {
                                      type: "area",
                                      color: ["monthly"].includes(actionsState.selectedGranularity)
                                          ? "#9BC5E3"
                                          : "#0089C6",
                                      opacity: ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.4,
                                      points: assembleAreaData({
                                          areaData: forecastMinConfidence95,
                                          isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                          //climatology: duplicateMonthlyHistoricalDataForFutureSevenMonths(climMinLighten),
                                          seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                              return {
                                                  x: +new Date(d),
                                                  y: monthlyData.ds_fc.t2m_min["0.95"][i],
                                                  y0: monthlyData.ds_fc.t2m_min["0.05"][i],
                                              }
                                          }),
                                      }).map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                  }
                                : null,
                            ["daily"].includes(actionsState.selectedGranularity) && confidenceVisible
                                ? {
                                      type: "area",
                                      color: ["monthly"].includes(actionsState.selectedGranularity)
                                          ? "#5AA0D0"
                                          : "#0089C6",
                                      points: assembleAreaData({
                                          areaData: forecastMinConfidence75,
                                          isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                          // climatology: climMinDarken,
                                          seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                              return {
                                                  x: +new Date(d),
                                                  y: monthlyData.ds_fc.t2m_min["0.75"][i],
                                                  y0: monthlyData.ds_fc.t2m_min["0.25"][i],
                                              }
                                          }),
                                      }).map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                      opacity: ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.4,
                                  }
                                : null,
                            ["daily"].includes(actionsState.selectedGranularity) && confidenceVisible
                                ? {
                                      type: "area",
                                      points: assembleAreaData({
                                          areaData: forecastMaxConfidence95,
                                          isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                          //   climatology: duplicateMonthlyHistoricalDataForFutureSevenMonths(climMaxLighten),
                                          seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                              return {
                                                  x: +new Date(d),
                                                  y: monthlyData.ds_fc.t2m_max["0.95"][i],
                                                  y0: monthlyData.ds_fc.t2m_max["0.05"][i],
                                              }
                                          }),
                                      }).map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                      color: ["monthly"].includes(actionsState.selectedGranularity)
                                          ? "#FFBB97"
                                          : "#FF7100",
                                      opacity: ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.4,
                                  }
                                : null,
                            ["daily"].includes(actionsState.selectedGranularity) && confidenceVisible
                                ? {
                                      type: "area",
                                      points: assembleAreaData({
                                          areaData: forecastMaxConfidence75,
                                          isMonthly: ["monthly"].includes(actionsState.selectedGranularity),
                                          //climatology: climMaxDarken,
                                          seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                              return {
                                                  x: +new Date(d),
                                                  y: monthlyData.ds_fc.t2m_max["0.75"][i],
                                                  y0: monthlyData.ds_fc.t2m_max["0.25"][i],
                                              }
                                          }),
                                      }).map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                      color: ["monthly"].includes(actionsState.selectedGranularity)
                                          ? "#FB9769"
                                          : "#FF7100",
                                      opacity: ["monthly"].includes(actionsState.selectedGranularity) ? 1 : 0.4,
                                  }
                                : null,
                            ["daily"].includes(actionsState.selectedGranularity)
                                ? {
                                      type: "line",
                                      id: "line-max",
                                      alwaysOnTop: true,
                                      points: dailyMaxData.map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                      color: "#FF7100",
                                      "stroke-width": 2,
                                  }
                                : null,
                            ["daily"].includes(actionsState.selectedGranularity)
                                ? {
                                      type: "line",
                                      id: "line-min",
                                      alwaysOnTop: true,
                                      points: dailyMinData.map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                      color: "#0089C6",
                                      "stroke-width": 2,
                                  }
                                : null,
                            ["hourly"].includes(actionsState.selectedGranularity)
                                ? {
                                      type: "line",
                                      id: "line-mid",
                                      alwaysOnTop: true,
                                      points: assembleLineData({
                                          selectedGranularity: actionsState.selectedGranularity,
                                          hourlyData: hourlyData,
                                          propVar: "t2m",
                                      }).map((d) =>
                                          processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                                      ),
                                      color: "#0089C6",
                                      "stroke-width": 2,
                                  }
                                : null,
                        ]}
                    ></LineAreaChartComponent>
                </div>
                <div className="weather-chart__specs-container">
                    <ChartSpecs
                        type="temp"
                        barType={barType}
                        historicalPending={historicalPending}
                        chartRef={chartRef}
                        climatologyVisible={climatologyVisible}
                        confidenceVisible={confidenceVisible}
                        lastUpdated={lastUpdated}
                        selectedGranularity={actionsState.selectedGranularity}
                        handleAreasVisibilityChange={({ conf, clim }) => {
                            setConfidenceBarsVisibility(conf)
                            setClimatologyVisible(clim)
                        }}
                        handleBarTypeChange={(candlChecked) => {
                            if (candlChecked) setBarType("candl")
                            else setBarType("med")
                        }}
                        colsArr={["Date", "Temp. Max (°C)", "Temp. Min (°C)"]}
                        data={{
                            csv: ["daily"].includes(actionsState.selectedGranularity) ? csvDailyData : csvMonthlyData,
                            // hourlyCsv: mergeHistoricalAndForecastData({
                            //     forecast: hourlyData.ds_fc,
                            //     historical: hourlyData.ds_hist,
                            // }),
                        }}
                        // onHourlyCsvDataTrigger={() => {
                        //     return new Promise((resolve, reject) => {
                        //         if (hourlyData.ds_fc.time.length > 0) {
                        //             setTimeout((d) => {
                        //                 const mergedData = mergeHistoricalAndForecastData({
                        //                     forecast: hourlyData.ds_fc,
                        //                     historical: hourlyData.ds_hist,
                        //                 })
                        //                 resolve(mergedData)
                        //             }, 1000)
                        //         } else {
                        //             currentUser.getIdToken().then((userToken) => {
                        //                 networking
                        //                     .get(
                        //                         `/api/v1/weather/${weatherVariable}/hourly/${id}?datasets=history%2Cforecast%2Cclimatology`,
                        //                         {
                        //                             extraHeaders: { "User-Token": userToken },
                        //                         }
                        //                     )
                        //                     .then((res) => {
                        //                         setHourlyData({
                        //                             ...res.data,
                        //                         })
                        //                         resolve(res.data)
                        //                     })
                        //                     .catch(() => {
                        //                         reject()
                        //                     })
                        //             })
                        //         }
                        //     })
                        // }}
                        actionsState={actionsState}
                    />
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default TempChart
