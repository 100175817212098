import React from "react"

// Alert
export const alertColumn = {
    propName: "triggered_alerts",
    displayName: "",
    style: { flex: "initial", justifyContent: "flex-end", width: "40px" },
    sortable: false,
}

export const checkColumn = {
    propName: "check",
    headerClassName: "check",
    className: "check",
    displayName: "",
    style: { flex: "initial", justifyContent: "center", width: "80px" },
    headerStyle: { flex: "initial", width: "80px" },
    toggler: true,
}

// Name
export const nameColumn = {
    propName: "name",
    displayName: "Field's Name",
    type: "string",
    style: { width: "160px" },
    toggler: true,
    draggable: true,
    sortable: true,
    form: true,
    required: true,
    sticky: true,
}

// Crop
export const cropColumn = {
    propName: "crop",
    displayName: "Crop",
    type: "string",
    draggable: true,
    sortable: true,
    form: true,
    required: true,
}

export const varietyColumn = {
    propName: "variety",
    displayName: "Variety",
    type: "string",
    draggable: true,
    sortable: true,
    form: true,
    required: true,
}

// Region
export const regionColumn = {
    propName: "region",
    displayName: "Region",
    type: "string",
    draggable: true,
    sortable: true,
    form: true,
    required: true,
}

export const avgPlantDateColumn = {
    propName: "avgPlantDate",
    displayName: "Average Plant Date",
    style: { flex: 2 },
    sortable: true,
    transform(value, rowData) {
        let sum = 0
        for (let field of rowData.data) {
            sum += new Date(field.plantingDateP).getTime()
        }
        const avgPlantDate = new Date(sum / rowData.data.length)
        return `${avgPlantDate.getDate()}/${avgPlantDate.getMonth() + 1}/${avgPlantDate.getFullYear()}`
    },
}

export const medianStageColumn = {
    propName: "medianStage",
    displayName: "Median Stage",
    style: { flex: 2 },
    sortable: true,
    transform(value, rowData) {
        return "Canopy Closure"
    },
}

export const avgYieldColumn = {
    propName: "avgYield",
    displayName: "Average Yield",
    style: { flex: 2 },
    sortable: true,
    transform(value, rowData) {
        return "200 tn/ha"
    },
}

export const labelsColumn = {
    className: "labels",
    headerClassName: "labels",
    propName: "labels",
    displayName: "Labels",
}

// Weather File
export const weatherFileColumn = {
    propName: "weatherfile",
    displayName: "Weather File",
    headerStyle: { display: "flex", justifyContent: "center", alignItems: "center" },
    style: { display: "flex", justifyContent: "center", alignItems: "center" },
    render: (value, obj) => {
        return <div className="weather-file">{/* <ErrorOutlined fontSize="inherit" /> */}</div>
    },
}

// Shape File
export const shapeFileColumn = {
    className: "shapefile",
    headerClassName: "shapefile",
    propName: "shapefile",
    displayName: "Shape File",
    type: "file",
    form: true,
    required: true,
    style: { display: "flex", justifyContent: "center", alignItems: "center" },
}

export const actionsColumn = {
    className: "actions",
    headerClassName: "actions",
    editField: true,
    deleteField: true,
    displayName: "Actions",
    style: { flex: "initial", display: "flex", justifyContent: "center", alignItems: "center" },
}

export const deleteColumn = {
    deleteField: true,
    displayName: "",
    headerStyle: { display: "flex", justifyContent: "center", alignItems: "center" },
    style: { display: "flex", justifyContent: "space-around", alignItems: "center" },
}
