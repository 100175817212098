import React, { useState } from "react"
import { Link } from "react-router-dom"
import ReportIcon from "../../../ui/Icons/ReportIcon"
import { Popover } from "@material-ui/core"

function ReportItem({ report, linkComponent: LinkComponent }) {
    const { coordinates, region, crops, lastUpdate, alerts, reportId } = report
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <>
            <Link to={"/climate/" + reportId + "/overview"}>
                <div className="long-term__report-item">
                    <div className="long-term__report-image">
                        <ReportIcon />
                    </div>

                    <div className="long-term__report-content">
                        <div className="long-term__report-info">
                            <div className="long-term__report-info-title">{region} Report</div>
                            <div className="long-term__report-info-subtitle">{crops.join(", ")}</div>
                            <div className="long-term__report-info-text">Updated: {lastUpdate}</div>
                        </div>

                        {alerts > 0 && (
                            <>
                                <div
                                    className="long-term__report-alerts"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                >
                                    {alerts}
                                </div>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: "none",
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <div className="long-term__report-alert-popover">{alerts} alerts</div>
                                </Popover>
                            </>
                        )}
                    </div>
                </div>
            </Link>
        </>
    )
}

export default ReportItem
