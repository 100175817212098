import { useContext, useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { UserProfile } from "../"
import { AuthContext } from "../../Auth/Auth"
import { getMyReports } from "../../services/customAnalytics.service"
import { DataContext } from "../../Util/Data"

const TopBarOption = ({ option }) => {
    return (
        <NavLink
            className={(isActive) =>
                "flex-1 w-[84px] border-y-4 border-transparent flex flex-row items-center justify-center hover:font-bold transition-all" +
                (isActive ? " border-b-accent text-accent font-bold cursor-default" : " text-gray-90 cursor-pointer")
            }
            to={option.to}
        >
            <div>{option.label}</div>
        </NavLink>
    )
}

const TopBar = ({ navOptions = [], profileOptions = [] }) => {
    const NAVBAR_OPTIONS = [
        {
            label: "Weather",
            to: "/weather",
            permission: "weather",
        },
        {
            label: "Climate",
            to: "/climate",
            permission: "climate",
        },
        {
            label: "Custom",
            to: "/custom",
            permission: "custom",
        },
    ]

    const [options, setOptions] = useState(NAVBAR_OPTIONS)

    const {
        state: { customReports, customReportsRequested },
        dispatch,
    } = useContext(DataContext)

    const { permissions, currentUser } = useContext(AuthContext)

    useEffect(() => {
        const setCustomReports = async () => {
            dispatch({
                type: "SET_CUSTOM_REPORTS",
                payload: {
                    customReports: await getMyReports(),
                },
            })
        }

        if (navOptions.length !== 0) {
            setOptions(navOptions)
        }
        if (!customReportsRequested) {
            setCustomReports()
        } else if (customReports.length === 0) {
            setOptions(options.filter((option) => option.label !== "Custom"))
        }
    }, [customReports])

    return (
        <div className="grow-0 shrink-0 bg-white elevation-1 md:pr-3 z-full">
            {/* DESKTOP VIEW */}
            <div className="hidden md:flex flex-row items-center justify-between h-[56px]">
                <div className="h-full flex flex-row items-center justify-start">
                    <span className="w-[240px] grow-0 shrink-0 h-full pl-16 md:pl-5 flex flex-col justify-center items-start">
                        <img className="w-auto h-[26px]" src="/ClimateAi_Logo.png" />
                    </span>
                    <div className="h-full flex flex-row items-stretch space-x-4 uppercase text-[14px]">
                        {options.map((option, index) => {
                            if (permissions[option.permission]) {
                                return <TopBarOption option={option} key={index} />
                            }
                            return null
                        })}
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center space-x-4">
                    {currentUser.email.includes("demo") && (
                        <div className="text-[14px] text-gray-30 font-bold">Demo account</div>
                    )}
                    <UserProfile options={profileOptions} />
                </div>
            </div>

            {/* MOBILE VIEW */}
            <div className="flex md:hidden flex-row items-center justify-between h-[56px] pr-2">
                <div className="flex-1"></div>
                <span className="grow h-full flex flex-col justify-center items-center overflow-visible">
                    <img className="w-auto h-[26px]" src="/ClimateAi_Logo.png" />
                </span>
                <div className="flex-1 flex flex-row justify-end items-center">
                    <UserProfile options={profileOptions} />
                </div>
            </div>
            <div className="flex md:hidden flex-row justify-start space-x-4 uppercase h-[50px] text-[14px]">
                {options.map((option, index) => {
                    if (permissions[option.permission]) {
                        return <TopBarOption option={option} key={index} />
                    }
                    return null
                })}
            </div>
        </div>
    )
}

export default TopBar
