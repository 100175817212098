import { Switch, Redirect } from "react-router-dom"

// Views
import Overview from "../views/Overview/Overview"
import Decadal from "../views/Decadal/Decadal"
import Monthly from "../views/Monthly/Monthly"
import Departure from "../views/Departure/Departure"
import Analogs from "../views/Analogs/Analogs"
import Portfolio from "../views/Portfolio/Portfolio"
import Historical from "../views/Historical/Historical"
import ReportBuilder from "../views/LongTerm/components/ReportBuilder"

// Components
import { RouteWithLayout, TopBar } from "../components"

// Layouts
import { ClimateLayout } from "../layouts"

import PrivateRoute from "../Util/PrivateRoute"
import { LongTerm, ProfileSettings } from "../views"
import { AuthContext } from "../Auth/Auth"
import { useContext } from "react"

function ClimateRouter(props) {
    const PROFILE_OPTIONS = [
        {
            id: "profile-settings__user-profile",
            label: "Profile settings",
            to: "/climate/profile-settings",
        },
    ]

    const { currentUser } = useContext(AuthContext)
    if (!currentUser) return <Redirect to="/" />

    return (
        <div className="flex flex-col h-full">
            <TopBar profileOptions={PROFILE_OPTIONS} />
            <div className="grow overflow-auto">
                <Switch>
                    <PrivateRoute exact path="/climate/reports" component={LongTerm} />
                    <RouteWithLayout
                        requiresPermission={false}
                        component={ProfileSettings}
                        exact
                        layout={ClimateLayout}
                        path="/climate/profile-settings"
                        title="Profile Settings"
                        settings
                    />
                    <RouteWithLayout
                        component={Overview}
                        exact
                        layout={ClimateLayout}
                        path="/climate/:reportId/overview"
                        tool_name="overview"
                        header="Climate Risk Overview"
                    />
                    <RouteWithLayout
                        component={Decadal}
                        exact
                        layout={ClimateLayout}
                        path="/climate/:reportId/decadal"
                        tool_name="location-risk"
                        header="Climate Risk by Decade"
                    />
                    <RouteWithLayout
                        component={Monthly}
                        exact
                        layout={ClimateLayout}
                        path="/climate/:reportId/monthly"
                        tool_name="monthly-location-risk"
                        header="Climate Risk by Month"
                    />
                    <RouteWithLayout
                        component={Departure}
                        exact
                        layout={ClimateLayout}
                        path="/climate/:reportId/departure"
                        tool_name="year-of-departure"
                        header="Year of Departure"
                    />
                    <RouteWithLayout
                        component={Analogs}
                        exact
                        layout={ClimateLayout}
                        path="/climate/:reportId/analogs"
                        tool_name="climate-analogs"
                        header="Climate Analogs"
                    />
                    <RouteWithLayout
                        component={Portfolio}
                        exact
                        layout={ClimateLayout}
                        path="/climate/:reportId/portfolio"
                        tool_name="portfolio-risk-analysis"
                        header="Portfolio Analysis"
                    />
                    <RouteWithLayout
                        component={Historical}
                        exact
                        layout={ClimateLayout}
                        path="/climate/:reportId/historical"
                        tool_name="historical-validation"
                        header="Historical Validation"
                    />
                    {/* <RouteWithLayout
                        component={ReportBuilder}
                        exact
                        layout={ClimateLayout}
                        path="/climate/:reportId/builder"
                        tool_name="report-builder"
                        header="Report Builder"
                    /> */}
                    <Redirect to="/climate/reports" />
                </Switch>
            </div>
        </div>
    )
}

export default ClimateRouter
