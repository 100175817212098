import React from "react"
import PlantingToolLabelIcon from "../../../ui/Icons/PlantingToolLabelIcon"

import "./ScenarioSelector.css"

function ScenarioSelector(props) {
    const { scenarios = {}, setScenarios = () => null } = props

    function handleScenariosSelectToggle(uid) {
        // toggle scenario here
        const status = scenarios[uid].isVisible
        setScenarios({ ...scenarios, [uid]: { ...scenarios[uid], isVisible: !status } })
    }

    return (
        <>
            <div className="scenario-selector">
                {Object.keys(scenarios)
                    .filter((uid) => scenarios[uid].checked)
                    .map((uid) => {
                        const scenario = scenarios[uid]
                        return (
                            <button
                                className={"scenario-selector__button" + (!scenario.isVisible ? " non-visible" : "")}
                                onClick={() => handleScenariosSelectToggle(uid)}
                            >
                                <div style={{ width: 22, height: 22 }}>
                                    <PlantingToolLabelIcon stroke={scenario.color} />
                                </div>{" "}
                                {scenario.name}
                            </button>
                        )
                    })}
            </div>
        </>
    )
}

export default ScenarioSelector
