import { useEffect, useRef, useState } from "react"
import debounce from "lodash/debounce"

export default function useTableShadow() {
    const [shadow, setShadow] = useState(false)
    const [shadowWidth, setShadowWidth] = useState(false)
    const headerRef = useRef(null)
    let shadowOffset = useRef(0)

    useEffect(() => {
        setValues()

        // wait 2 seconds for the user to stop resizing the screen and
        // then re-calculate the table shadow values
        const debounceResize = debounce(setValues, 2000)

        window.addEventListener("resize", debounceResize)
        return () => window.removeEventListener("resize", debounceResize)
    }, [])

    function setValues() {
        const header = document.querySelector("th.stick")
        const container = document.querySelector(".table__scroll-container")

        if (header && container) {
            const { left: headerLeft } = header.getBoundingClientRect()
            const { left: containerLeft } = container.getBoundingClientRect()

            shadowOffset.current = headerLeft - containerLeft
            setShadowWidth(160)
        }
    }

    function horizontalScrollHandler(e) {
        // make sure you only execute this once each bounce
        if (e.target.scrollLeft > shadowOffset.current && !shadow) {
            setShadow(true)
        } else if (e.target.scrollLeft <= shadowOffset.current && shadow) {
            setShadow(false)
        }
    }

    return { shadow, horizontalScrollHandler, headerRef, shadowWidth }
}
