import _ from "lodash"
import SearchIcon from "../../ui/Icons/SearchIcon"

const DebounceSearchInput = ({ onSearch = () => {}, ms = 500 }) => {
    const debounceSearch = _.debounce(onSearch, ms)

    return (
        <div className="flex flex-row items-center border border-gray-10 rounded-lg h-[36px] w-full px-2">
            <span className="h-[24px] w-[24px] grow-0">
                <SearchIcon />
            </span>
            <div className="grow h-full">
                <input
                    type="text"
                    placeholder="Search"
                    className="h-full w-full focus:outline-none pl-2 placeholder:text-gray-30"
                    onKeyUp={(e) => debounceSearch(e.target.value)}
                />
            </div>
        </div>
    )
}

export default DebounceSearchInput
