import React, { useMemo, useState, useRef, useEffect, useContext } from "react"
import { CircularProgress } from "@material-ui/core"
import { useParams } from "react-router-dom"
import { LineAreaChartComponent } from "../../../../components/Charts/LineAreaChart/LineAreaChart.component"
import { toast, ToastContainer } from "react-toastify"

import { AuthContext } from "../../../../Auth/Auth"
import networking from "../../../../Util/Networking"
import { SettingsContext } from "../../../../Util/SettingsContext"
import roundNumber from "../../../../helpers/roundNumber"

import {
    getHistoricalMaxTemp,
    getForecastMinTemp,
    getForecastMaxTemp,
    getClimMin,
    getClimMax,
    getForecastMinArr,
    getForecastMaxArr,
    trimmData,
} from "./helper"
import {
    getUnit,
    processUnitSystem,
    mergeHistoricalAndForecastData,
    getForecastConfidenceData,
    addMonths,
    validateData,
    convertToShadedRangesFormat,
    addDays,
    assembleAreaData,
    assembleLineData,
    getSevenMonthsMarginClimData,
} from "../../../../helpers/chartHelpers"

import ChartSpecs from "../ChartSpecs"
import { isEmptyObject } from "../../../../Util/General"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useLastUpdated from "../../../../hooks/useLastUpdated"

const SollTempChart = ({ actionsState }) => {
    const chartRef = useRef(null)
    const { currentUser } = useContext(AuthContext)
    const { currentSettings } = useContext(SettingsContext)
    const { id } = useParams()
    const [fieldId, setFieldId] = useState()

    const [confidenceVisible, setConfidenceBarsVisibility] = useState(false)
    const [climatologyVisible, setClimatologyVisible] = useState(false)

    const [historicalPending, setHistoricalPending] = useState(false)

    // Media Queries for Tablet View
    const [tabletMedia, setTabletMedia] = useState(true)
    const mediaBreakpoint = useMediaQuery("(max-width: 1100px)", { noSsr: true })
    useEffect(() => {
        setTabletMedia(mediaBreakpoint)
    }, [mediaBreakpoint])

    // Prepare initial data
    const weatherVariable = "soil_temperature"
    const [data, setData] = useState({
        ds_hist: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        ds_fc: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        ds_clim: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        pending: true,
    })

    // const [lastUpdated, setLastUpdated] = useState({

    // })
    // const lastUpdatedRef = useRef(null)

    const [hourlyData, setHourlyData] = useState({
        ds_hist: {
            time: [],
            t2m: [],
        },
        ds_fc: {
            time: [],
            t2m: [],
        },
    })

    // Prepare initial data
    const [monthlyData, setMonthlyData] = useState({
        ds_hist: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        ds_fc: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        ds_clim: {
            time: [],
            stl1_min: [],
            stl1_mean: [],
        },
        pending: true,
    })

    // Alerts Data
    const [alertsData, setAlertsData] = useState({
        stl1_mean: {},
    })

    // Load data
    useEffect(() => {
        console.log("fires 1")
        if (fieldId !== id || (fieldId === id && climatologyVisible && data.ds_clim.time.length === 0)) {
            console.log("fires 2")
            // Set data status pending
            setData({
                ...data,
                pending: fieldId === id && climatologyVisible && data.ds_clim.time.length === 0 ? false : true,
            })

            let localData = data

            if (climatologyVisible) {
                setHistoricalPending(true)
            }
            // let datasets = climatologyVisible ? "climatology" : "history%2Cforecast"
            // if (climatologyVisible) datasets = "history%2Cforecast%2Cclimatology"
            let datasets = "history%2Cforecast%2Cclimatology"
            if (!climatologyVisible) {
                if (fieldId !== id) {
                    localData.pending = true
                }
                localData = Object.assign({}, localData, {
                    ds_clim: {
                        time: [],
                        stl1_min: [],
                        stl1_mean: [],
                    },
                })
                setData(localData)
            }

            currentUser.getIdToken().then((userToken) => {
                networking
                    .get(`/api/v1/weather/${weatherVariable}/daily/${id}?datasets=${datasets}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (datasets.indexOf("climatology") !== -1) {
                            setHistoricalPending(false)
                        }
                        setData({
                            ...Object.assign({}, localData, res.data),
                            pending: false,
                        })
                    })
                    .catch((e) => {
                        setData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        toast.error("Error occurred with server. Please, try later.")
                    })

                // Break network call if data already loaded
                if (fieldId === id && localData.ds_fc.time.length) {
                    return
                }

                networking
                    .get(`/api/v1/alertsettings/${weatherVariable}/${id}`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        if (isEmptyObject(res.data)) {
                            toast.success(`There are no alerts for ${weatherVariable.replace("_", " ")}`)
                        }
                        setAlertsData(res.data)
                    })
                    .catch(() => {
                        //         toast.warn(
                        //             `Alerts not displayed on dashboard due to internet
                        // connectivity issues. All other functions working.`
                        //         )
                    })

                networking
                    .get(`/api/v1/weather/${weatherVariable}/monthly/${id}?datasets=history%2Cforecast`, {
                        extraHeaders: { "User-Token": userToken },
                    })
                    .then((res) => {
                        console.log("data fires monthly")
                        setMonthlyData({
                            ...res.data,
                            pending: false,
                        })
                    })
                    .catch(() => {
                        console.log("catch monthly")
                        setMonthlyData((prevData) => ({
                            ...prevData,
                            pending: false,
                        }))
                        toast.warn("Could not load monthly data.")
                    })
            })
            setFieldId(id)
        }
    }, [
        actionsState.selectedGranularity,
        actionsState.isMonthly,
        actionsState.currentTab,
        currentUser,
        id,
        climatologyVisible,
    ])

    const lastUpdated = useLastUpdated(data, monthlyData)

    // Prepare min historical  data
    // const historicalMinTemp = useMemo(() => getHistoricalMinTemp(data["ds_hist"]), [data])
    const historicalMaxTemp = useMemo(() => getHistoricalMaxTemp(data["ds_hist"]), [data])
    const forecastMinArr = useMemo(() => getForecastMinArr(data["ds_fc"]), [data])
    const forecastMaxArr = useMemo(() => getForecastMaxArr(data["ds_fc"]), [data])

    // Prepare max historical data
    const forecastMinTemp = useMemo(() => getForecastMinTemp(data["ds_fc"], forecastMinArr), [data, forecastMinArr])
    const forecastMaxTemp = useMemo(() => getForecastMaxTemp(data["ds_fc"], forecastMaxArr), [data, forecastMaxArr])
    const { climMinLighten, climMinDarken } = useMemo(() => getClimMin(data["ds_clim"]), [data])
    const { climMaxLighten, climMaxDarken } = useMemo(() => getClimMax(data["ds_clim"]), [data])

    // Look at the changes for historical and forecast data and display warning messages if invalid
    useMemo(() => {
        validateData({
            diffToAlert: 12,
            historic: climMinLighten,
            forecast: forecastMinTemp,
            accessorKey: "y0",
            message: "Tmin Forecast Anomaly Detected",
        })
        validateData({
            diffToAlert: 12,
            historic: climMaxLighten,
            forecast: forecastMaxTemp,
            accessorKey: "y",
            message: "TMax Forecast Anomaly Detected",
        })
    }, [forecastMinTemp, forecastMaxTemp, climMinLighten, climMaxLighten])

    // Prepare Confidence Data
    // const forecastMinConfidence75 = useMemo(() => {
    //     return getForecastConfidenceData(
    //         data["ds_fc"],
    //         historicalMinTemp[historicalMinTemp.length - 1],
    //         data["ds_fc"]["stl1_min"],
    //         "0.75"
    //     )
    // }, [data, historicalMinTemp])
    // const forecastMinConfidence95 = useMemo(() => {
    //     return getForecastConfidenceData(
    //         data["ds_fc"],
    //         historicalMinTemp[historicalMinTemp.length - 1],
    //         data["ds_fc"]["stl1_min"],
    //         "0.95"
    //     )
    // }, [data, historicalMinTemp])
    const forecastMaxConfidence75 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMaxTemp[historicalMaxTemp.length - 1],
            data["ds_fc"]["stl1_mean"],
            "0.75"
        )
    }, [data, historicalMaxTemp])
    const forecastMaxConfidence95 = useMemo(() => {
        return getForecastConfidenceData(
            data["ds_fc"],
            historicalMaxTemp[historicalMaxTemp.length - 1],
            data["ds_fc"]["stl1_mean"],
            "0.95"
        )
    }, [data, historicalMaxTemp])

    // Prepare historical data
    const histCsvData = data["ds_hist"].time.map((item, index) => {
        return [data["ds_hist"]["stl1_min"][index], data["ds_hist"]["stl1_mean"][index]]
    })

    // Prepare forecast csv data
    const forcCsvData = data["ds_fc"].time.map((item, index) => {
        return [forecastMinArr[index], forecastMaxArr[index]]
    })

    // Prepare clim max and min data
    const climMaxArr = [].concat.apply([], Object.values(data["ds_clim"]["stl1_mean"]))
    const climMinArr = [].concat.apply([], Object.values(data["ds_clim"]["stl1_min"]))

    // Assemble clim csv data
    const climCsvData = data["ds_clim"].time.map((item, index) => {
        return [item, climMinArr[index], climMaxArr[index]]
    })

    // Combine CSV data, which will be used for export
    const combinedCsvData = (clim, forecast, historical) => {
        const csvArr = []
        let j = 0
        for (let i = 0; i < clim.length; i++) {
            if (historical[i]) {
                csvArr.push([...clim[i], [""], [""], ...historical[i]])
            } else if (clim[i] && forecast[j]) {
                csvArr.push([...clim[i], ...forecast[j], [""], [""]])
                j += 1
            } else if (clim[i]) {
                csvArr.push([...clim[i], [""], [""]])
            }
        }
        return csvArr
    }

    const lineData = assembleLineData({
        isMonthly: actionsState.isMonthly,
        historical: historicalMaxTemp,
        forecast: forecastMaxTemp,
        seasonal: monthlyData.ds_fc.time.map((d, i) => {
            return {
                x: new Date(d),
                y: monthlyData.ds_fc.stl1_mean["0.5"][i],
            }
        }),
    })

    const csvData = lineData.map((d) => [
        [new Date(d.x).toLocaleDateString(undefined, { month: "short", day: "numeric", year: "numeric" })],
        [d.y],
    ])

    return (
        <div className="weather-chart">
            <div className="weather-chart__chart-container">
                <div style={{ display: data.pending ? "flex" : "none" }} className="weather-chart__preload-container">
                    <CircularProgress />
                </div>
                <LineAreaChartComponent
                    // Pass height externally
                    svgHeight={650}
                    // Title text
                    title="Soil Temperature (0-7cm)"
                    // Set title hover text

                    titleHover={
                        actionsState.isMonthly
                            ? "This graph shows the monthly average observed and forecasted soil temperature in the first 7 centimeters of depth."
                            : "This graph shows the daily average observed and forecasted soil temperature in the first 7 centimeters of depth."
                    }
                    // Y label text
                    labelY={`Soil Temperature in ${getUnit({ system: currentSettings.units }).tempUnit}`}
                    // Pass unique resize event key
                    resizeEventListenerId="soil-temperature-chart"
                    // Add chart data id to filter out some update requests
                    chartDataId={
                        (actionsState.isMonthly ? "month" : "day") + "_soil-temp-chart_" + climMaxLighten.length
                    }
                    // Center Ticks
                    centerTicks={actionsState.isMonthly ? true : false}
                    // Convert received data to shaded ranges format
                    shadedRanges={convertToShadedRangesFormat(
                        alertsData,
                        Object.keys(alertsData),
                        `<svg style="position:relative;top:3px" width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.76 4.83999L4.96 3.04999L3.55 4.45999L5.34 6.24999L6.76 4.83999ZM4 10.5H1V12.5H4V10.5ZM13 0.549988H11V3.49999H13V0.549988ZM20.45 4.45999L19.04 3.04999L17.25 4.83999L18.66 6.24999L20.45 4.45999ZM17.24 18.16L19.03 19.96L20.44 18.55L18.64 16.76L17.24 18.16ZM20 10.5V12.5H23V10.5H20ZM12 5.49999C8.69 5.49999 6 8.18999 6 11.5C6 14.81 8.69 17.5 12 17.5C15.31 17.5 18 14.81 18 11.5C18 8.18999 15.31 5.49999 12 5.49999ZM11 22.45H13V19.5H11V22.45ZM3.55 18.54L4.96 19.95L6.75 18.15L5.34 16.74L3.55 18.54Z" fill="#4B545F"/> </svg>`
                    )}
                    // Make chart to have dynamic y basis
                    zeroBasis={false}
                    marginTop={tabletMedia ? 65 : 65}
                    // Bottom margin will be 0.3 times of data diff
                    yBottomOffset={0.3}
                    // Top margin will be 0.3 times of data diff
                    yTopOffset={0.3}
                    // Provide custom date max axis extent for monthly view charts
                    xDateMax={
                        actionsState.isMonthly
                            ? addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), 2), 7)
                            : tabletMedia
                            ? addDays(new Date(), 14)
                            : addDays(new Date(), 6)
                    }
                    // Provide custom date min axis extent for monthly view charts
                    xDateMin={
                        actionsState.isMonthly
                            ? addMonths(new Date(new Date().getFullYear(), new Date().getMonth(), 28), -7)
                            : tabletMedia
                            ? addDays(new Date(), -2)
                            : null
                    }
                    // How x ticks will be formatted in chart
                    xTickFormat={
                        actionsState.isMonthly
                            ? (d, i, arr) => {
                                  // Remove last, overflowing tick item
                                  if (i === arr.length - 1) return ""
                                  return d.toLocaleString(undefined, { month: "short" })
                              }
                            : (d, i, arr) => {
                                  if (i < arr.length - 1 && i !== 0) return d.getDate()
                                  if (i === 0)
                                      return d.toLocaleString(undefined, { month: "short" }) + " " + d.getDate()
                                  return d.getDate() + " " + d.toLocaleString(undefined, { month: "short" })
                              }
                    }
                    // Give chart tips count tip
                    xTicksCount={actionsState.isMonthly ? 12 : tabletMedia ? 14 : 20}
                    // Hide chart if data is pending
                    hide={data.pending}
                    // Tooltip content on line points mouse over
                    tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                        return `<table  cellspacing="0" cellpadding="0" style="color:#7B8399;margin:0px;border:none;outline:none;border-collapse:collapse;border-bottom:none">
                 <tr><td style="font-weight:bold;font-size:20px" rowspan="${
                     values.length
                 }"><div style="padding-right: 12px; border-right: 1px solid #f3e6e6; text-align:center;margin-right:14px;width:40px;line-height:1.1">${key.toLocaleString(
                            undefined,
                            {
                                day: "numeric",
                                month: "short",
                            }
                        )}</div></td>
                    <td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                        points[0].dashed ? "dashed" : "solid"
                    } ${colors[0]};margin-top:-10px;border-radius:5px;"></div>${roundNumber(values[0], 1)}
                    ${getUnit({ system: currentSettings.units }).tempUnit}
                    </td></tr>
                 ${values
                     .filter((d, i) => i > 0)
                     .map((value, i) => {
                         return ` <tr><td><div style="position:relative;top:-3px;margin-right:8px;display:inline-block;width:50px;height:0px;border: 1px ${
                             points[i + 1].dashed ? "dashed" : "solid"
                         } ${colors[i + 1]};margin-top:-10px;border-radius:5px;"></div>${roundNumber(value, 1)} ${
                             getUnit({ system: currentSettings.units }).tempUnit
                         }
                    </td></tr>`
                     })
                     .join("")}
             </table>`
                    }}
                    // Chart data content
                    data={[
                        climatologyVisible
                            ? {
                                  type: "area",
                                  points: (!actionsState.isMonthly
                                      ? trimmData(getSevenMonthsMarginClimData(climMaxLighten))
                                      : getSevenMonthsMarginClimData(climMaxLighten)
                                  ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: "#F8D6C5",
                                  opacity: 0.6,
                              }
                            : null,
                        climatologyVisible
                            ? {
                                  type: "area",
                                  points: (!actionsState.isMonthly
                                      ? trimmData(getSevenMonthsMarginClimData(climMaxDarken))
                                      : getSevenMonthsMarginClimData(climMaxDarken)
                                  ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: "#FDBE9D",
                                  opacity: 0.6,
                              }
                            : null,
                        // climatologyVisible ?{
                        //     type: "area",
                        //     points: (!actionsState.isMonthly ? trimmData(climMinLighten) : climMinLighten).map((d) =>
                        //         processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                        //     ),
                        //     color: "#DBEBF5",
                        //     opacity: 0.6,
                        // }:null,
                        // climatologyVisible ?{
                        //     type: "area",
                        //     points: (!actionsState.isMonthly ? trimmData(climMinDarken) : climMinDarken).map((d) =>
                        //         processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                        //     ),
                        //     color: "#C6E2F1",
                        //     opacity: 0.6,
                        // }:null,
                        // Confidence Bands
                        // confidenceVisible?{
                        //     type: "area",
                        //     points: (!actionsState.isMonthly
                        //         ? trimmData(forecastMinConfidence95)
                        //         : forecastMinConfidence95
                        //     ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                        //     color: "#0089C6",
                        //     opacity: 0.4,
                        // }:null,
                        // confidenceVisible?{
                        //     type: "area",
                        //     points: (!actionsState.isMonthly
                        //         ? trimmData(forecastMinConfidence75)
                        //         : forecastMinConfidence75
                        //     ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                        //     color: "#0089C6",
                        //     opacity: 0.4,
                        // }:null,
                        confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastMaxConfidence95,
                                      isMonthly: actionsState.isMonthly,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y1: monthlyData.ds_fc.stl1_mean["0.95"][i],
                                              y0: monthlyData.ds_fc.stl1_mean["0.05"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: actionsState.isMonthly ? "#FFBB97" : "#d05e0a",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,
                        confidenceVisible
                            ? {
                                  type: "area",
                                  points: assembleAreaData({
                                      areaData: forecastMaxConfidence75,
                                      isMonthly: actionsState.isMonthly,
                                      seasonal: monthlyData.ds_fc.time.map((d, i) => {
                                          return {
                                              x: +new Date(d),
                                              y1: monthlyData.ds_fc.stl1_mean["0.75"][i],
                                              y0: monthlyData.ds_fc.stl1_mean["0.25"][i],
                                          }
                                      }),
                                  }).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                                  color: actionsState.isMonthly ? "#FB9769" : "#db630a",
                                  opacity: actionsState.isMonthly ? 1 : 0.4,
                              }
                            : null,
                        {
                            type: "line",
                            id: "line-max",
                            alwaysOnTop: true,
                            points: lineData.map((d) =>
                                processUnitSystem(d, { system: currentSettings.units, type: "temp" })
                            ),
                            color: "#FF7100",
                            "stroke-width": 2,
                        },
                        // {
                        //     type: "line",
                        //     id:"line-min",
                        //     alwaysOnTop:true,
                        //     points: (!actionsState.isMonthly
                        //         ? trimmData(historicalMinTemp).concat(
                        //               trimmData(forecastMinTemp).map((d) => Object.assign(d, { dashed: true }))
                        //           )
                        //         : historicalMinTemp.concat(
                        //               forecastMinTemp.map((d) => Object.assign(d, { dashed: true }))
                        //           )
                        //     ).map((d) => processUnitSystem(d, { system: currentSettings.units, type: "temp" })),
                        //     color: "#0089C6",
                        //     "stroke-width": 2,
                        // },
                    ]}
                ></LineAreaChartComponent>
            </div>
            <div className="weather-chart__specs-container">
                <ChartSpecs
                    type="temp-soil"
                    historicalPending={historicalPending}
                    chartRef={chartRef}
                    climatologyVisible={climatologyVisible}
                    lastUpdated={lastUpdated}
                    selectedGranularity={actionsState.selectedGranularity}
                    confidenceVisible={confidenceVisible}
                    handleAreasVisibilityChange={({ conf, clim }) => {
                        setConfidenceBarsVisibility(conf)
                        setClimatologyVisible(clim)
                    }}
                    colsArr={["Date", "Value (°C)"]}
                    data={{
                        csv: csvData,
                        // hourlyCsv: mergeHistoricalAndForecastData({
                        //     forecast: hourlyData.ds_fc,
                        //     historical: hourlyData.ds_hist,
                        //     prop: "stl1",
                        // }),
                    }}
                    // onHourlyCsvDataTrigger={() => {
                    //     return new Promise((resolve, reject) => {
                    //         currentUser.getIdToken().then((userToken) => {
                    //             networking
                    //                 .get(`/api/v1/weather/${weatherVariable}/hourly/${id}`, {
                    //                     extraHeaders: { "User-Token": userToken },
                    //                 })
                    //                 .then((res) => {
                    //                     setHourlyData({
                    //                         ...res.data,
                    //                     })
                    //                     resolve(res.data)
                    //                 })
                    //                 .catch(() => {
                    //                     reject()
                    //                 })
                    //         })
                    //     })
                    // }}
                    actionsState={actionsState}
                />
            </div>

            <ToastContainer />
        </div>
    )
}

export default SollTempChart
