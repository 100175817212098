import { useState, useContext } from "react"
// Modules
import { useHistory } from "react-router-dom"

// Components
import ArrowRight from "../../ui/Icons/ArrowRight"
import ExitApp from "../../ui/Icons/ExitApp"
import Dropdown from "../../ui/Controls/Dropdown"
import DropdownItem from "../../ui/Controls/DropdownItem"

// Views
// Context providers / Utils
import { AuthContext } from "../../Auth/Auth"
import app from "../../Util/Fire"

// Hooks
import useOutsideComponentClickHandler from "../../hooks/ClickOutsideHook"

// Styles
import "./UserProfile.css"

const UserProfile = ({ options = [] }) => {
    const DEFAULT_OPTIONS = [
        // {
        //     id: "profile-settings__user-profile",
        //     label: "Profile settings",
        //     to: "/profile-settings",
        // },
        // {
        //     id: "alert-settings__user-profile",
        //     label: "Alerts settings",
        //     to: "/alerts-settings-individual",
        // },
        // {
        //     id: "table-settings__user-profile",
        //     label: "Table settings",
        //     to: "/table-settings",
        // },
        // {
        //     id: "crop-settings__user-profile",
        //     label: "Crop & Variety settings",
        //     to: "/crop-settings",
        // },
    ]

    if (options.length === 0) {
        options = DEFAULT_OPTIONS
    }

    const [open, toggle] = useState(false)
    const profileRef = useOutsideComponentClickHandler(() => toggle(false))
    const history = useHistory()

    const {
        currentUser: { displayName, email },
    } = useContext(AuthContext)

    function handleLogoutButton(e) {
        if (e) e.preventDefault()
        app.auth().signOut()
        history.push("/")
    }

    return (
        <div className="user-profile" ref={profileRef}>
            <div className="user-profile__visible" onClick={() => toggle(!open)}>
                <div className={"user-profile__icon orange"}>
                    <div>{displayName ? displayName[0] : email[0]}</div>
                </div>
                <ArrowRight
                    id="arrow-dropdown__user-profile"
                    className={open ? "user-profile__deploy-icon--rotate" : "user-profile__deploy-icon"}
                />
            </div>
            {open && (
                <Dropdown title={displayName} className="down left" __onClose__={() => toggle(false)}>
                    {options.map((option, index) => {
                        return (
                            <DropdownItem
                                title={option.label}
                                id={option.id}
                                key={index}
                                onClick={() => history.push(option.to)}
                            />
                        )
                    })}
                    <DropdownItem
                        title="Contact us"
                        id="contact-us__user-profile"
                        onClick={() => window.open("https://climate.ai", "_blank")}
                    />
                    <DropdownItem
                        title={
                            <div className="user-profile__dropdown__logout">
                                Logout
                                <ExitApp
                                    fill="#505050"
                                    width="9px"
                                    height="9px"
                                    className="user-profile__logout-icon"
                                />
                            </div>
                        }
                        id="logout__user-profile"
                        onClick={(e) => {
                            handleLogoutButton(e)
                        }}
                    />
                </Dropdown>
            )}
        </div>
    )
}

export default UserProfile
