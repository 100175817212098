import * as React from "react"

function PrecipitationIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15 5c3.961 0 6.207 2.622 6.534 5.787h.1a4.61 4.61 0 014.616 4.605 4.61 4.61 0 01-4.616 4.604h-.728l-1.979 3.295a.938.938 0 01-1.68-.822l.057-.115 1.437-2.359h-2.67l-1.979 3.296a.938.938 0 01-1.68-.822l.057-.115 1.437-2.359h-2.671l-1.977 3.296a.938.938 0 01-1.68-.822l.056-.115 1.436-2.359h-.704a4.608 4.608 0 01-4.616-4.603 4.609 4.609 0 014.615-4.602h.1C8.795 7.6 11.04 5 15 5z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default PrecipitationIcon
