import React from "react"
import { Popover } from "../../../ui/Popover"
import { LineAreaChart } from "./LineAreaChart.d3"
import "./LineAreaChart.css"

/**
 * We use class instead of pure functions because we need to
 * be able to handle chart update function
 *
 * Sample Invocation
 * ```javascript
 * <LineAreaChartComponent
 *     title='Chart Title'
 *     marginLeft={65}
 *     onChartMouseLeave={d => console.log('chart mouse leave happened')}
 *     svgHeight={200}
 *     yTicksCount={5}
 *     yValueMax={100}
 *     labelYFontSize={11}
 *     yTickFormat={(d) => d + '%'}
 *     resizeEventListenerId='planting-tool-main-chart'
 *     gridView={false}
 *     curve={"curveMonotoneX"}
 *     tooltip={null}
 *     xLeftOffset={0.1}
 *     xRightOffset={0.1}
 *     shadedRanges={
 *         [
 *             {
 *                 type: 'area',
 *                 points: [
 *                     { x: new Date('1 Jan 2021'), y0: 20, y: 100 },
 *                     { x: new Date('31 Dec 2021'), y0: 20, y: 100 }
 *                 ]
 *             },
 *             {
 *                 type: 'line',
 *                 points: [
 *                     { x: new Date('1 Jan 2021'), y: 80 },
 *                     { x: new Date('31 Dec 2021'), y: 80 }
 *                 ]
 *             }
 *         ]
 *     }
 *     xTicksCount={5}
 *     labelY='Percentage probability of risk'
 *     data={[
 *         {
 *             type: 'area',
 *             color: 'red',
 *             points: [
 *                 { x: new Date('1 Jan 2021'), y0: 20, y: 100 },
 *                 { x: new Date('31 Dec 2021'), y0: 20, y: 100 }
 *             ]
 *         },
 *         {
 *             type: 'line',
 *             color: 'blue',
 *             points: [
 *                 { x: new Date('1 Jan 2021'), y: 80 },
 *                 { x: new Date('31 Dec 2021'), y: 80 }
 *             ]
 *         }
 *     ]}
 * >
 *
 * </LineAreaChartComponent>
 * ```
 * @typedef {LineAreaChartComponent} Props
 * @prop {boolean} disableUpdate - **Component Prop** - Parameter to specify not to update component when react tries to update it
 * @prop {boolean} hide - **Component Prop** - Parameter to specify whether chart should be hidden or not
 * @prop {string} resizeEventListenerId - Sets resize event listener id. It's preferred to pass it externally, in order to not  register too many event handlers for the same event when redrawing happens (Which happens a lot)
 * @prop {string} title - Chart top text title
 * @prop {string} labelY - Text value of y axis Label
 * @prop {date} xDateMax - Right bound of x axis. If skipped, it will be determined from data (Taking right offset proportion into account as well)
 * @prop {date} xDateMin - Left bound of x axis. If skipped, it will be determined from data (Taking left offset proportion into account as well)
 * @prop {ChartData} data - Array of line and area data items
 * @prop {number} xTicksCount - Preferred x axis ticks count
 * @prop {function} xTickFormat - Function, which formats  `x` tick value, passed as an argument
 * @prop {function} yTickFormat - Function, which formats  `y` tick value, passed as an argument
 * @prop {boolean} centerTicks - Flag, which will center axis texts between tick lines
 * @prop {number} tipOffsetY - Pixel value which will move static tip vertically
 * @prop {number} yBottomOffset - How much y axis will offset from the bottom side. if 0.5 is passed, data will be shown in the first half of y axis (Unless zero basis is set)
 * @prop {number} yTopOffset - How much y axis will offset from the top side. if 0.5 is passed, data will be shown in the second half of y axis
 * @prop {number} titleLabelFontSize - How many pixels title label font size will be
 * @prop {number} titleLabelOffsetX - How many pixels will label be moved horizontally
 * @prop {number} titleLabelOffsetY - How many pixels will label be moved vertically
 * @prop {number} labelYOffsetX - How many px  y axis label will move, horizontally
 * @prop {boolean} zeroBasis - Flag, which will set y axis min value to be zero based or  min value from data based
 * @prop {boolean} gridView - Allow or disable gridview (Evently distributed axis grids)
 * @prop {date} staticTipXPosition - Position, at which static tip will be displayed
 * @prop {date} customStaticTipXPosition - Position, at which custom static tip will be displayed
 * @prop {string} customStaticTipXText - Text inside the custom static tip
 * @prop {function} onChartMouseLeave - Function, to handle mouse leave event
 * @prop {function} onMainTipFire - Function, to handle tip firing event
 * @prop {number} labelYFontSize - Pixel font size of y axis label
 * @prop {number} xRightOffset - How much x axis will offset from the right side. if 0.5 is passed, data will be shown in the first half of axis
 * @prop {number} xLeftOffset - How much x axis will offset from the left side. if 0.5 is passed, data will be shown in the second half of axis
 * @prop {number} yValueMax - Upper bound of y axis , if skipped it will be based on the data
 * @prop {number} marginLeft - Set margin left value for chart content in pixels
 * @prop {number} marginTop - Set margin top value for chart content in pixels
 * @prop {number} yTicksCount - Preferred y axis ticks count
 * @prop {number} svgHeight - Chart height number value in pixels
 * @prop {string} titleHover - Chart title tip hover text
 * @prop {string} curve - Chart line and area curvature (Supported curves are curveMonotoneX and curveLinear)
 * @prop {ShadedRangesData} shadedRanges - Shaded ranges data
 * @prop {function} shapeTip - Function, which generates bar tip content.
 * @prop {function} staticTipHtml - Function, which generates tip content. It receives three arguments:
 * **EVENT** - current event .
 *
 * **{key, values, colors}**
 *
 * **key** - is hovered date  .
 *
 * **values** - are corresponding line points .
 *
 * **colors** - are corresponding point colors .
 *
 * **state** - is current chart state.
 *
 *
 * @prop {function} tooltip - Function, which generates tip content. It receives three arguments:
 * **EVENT** - current event .
 *
 * **{key, values, colors}**
 *
 * **key** - is hovered date  .
 *
 * **values** - are corresponding line points .
 *
 * **colors** - are corresponding point colors .
 *
 * **state** - is current chart state.
 *
 *
 * @extends {Component<Props>}
 */
export class LineAreaChartComponent extends React.Component {
    // Handle component mounting event
    componentDidMount() {
        this.createDiagram = this.createDiagram.bind(this)
        this.createDiagram()
    }

    // Handle component update event
    componentDidUpdate(prevProps, prevState) {
        if (this.props.disableUpdate) {
            return
        }
        // if (prevProps.chartDataId && (prevProps.chartDataId === this.props.chartDataId)) {
        //     return;
        // }
        this.createDiagram()
    }

    // Render chart
    render() {
        return (
            <div
                style={{ display: this.props.hide ? "none" : "inline-block" }}
                className="d3-line-area-chart-container d3-chart-container"
            >
                <div
                    className="icon-title"
                    style={{
                        marginBottom: -10,
                        zIndex: 10,
                        minHeight: 30,
                        marginBottom: -30 - (this.props.titleLabelOffsetY || 0),
                        display: "flex",
                        fontSize: (this.props.titleLabelFontSize || 16) + "px",
                        marginLeft: 75 + (this.props.titleLabelOffsetX || 0),
                        position: "relative",
                    }}
                >
                    <span style={{ display: this.props.titleHover ? "initial" : "none" }}>
                        <Popover text={this.props.titleHover}>
                            <svg
                                className="MuiSvgIcon-root jss144"
                                focusable="false"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                ariaHidden="true"
                                tabIndex="-1"
                                title="InfoOutlined"
                            >
                                {" "}
                                <circle fill="white" pointerEvents="all" r="12" cx="12" cy="12"></circle>{" "}
                                <path
                                    pointerEvents="none"
                                    fill="#87ceeb"
                                    d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
                                ></path>
                            </svg>
                        </Popover>
                    </span>

                    <div
                        style={{
                            display: "inline-block",
                            marginTop: 3,
                            fontWeight: "bold",
                            marginLeft: 8,
                            color: "#66708A",
                        }}
                    >
                        {this.props.title || ""}
                    </div>
                </div>
                <div ref={(node) => (this.node = node)} />
            </div>
        )
    }

    // Reusable, create diagram function
    createDiagram() {
        // Retrieve node dom element
        const node = this.node

        // If there is no data, exit from component
        if (!this.props.data) {
            return
        }

        // Retrieve props
        const props = this.props

        // Create chart reference

        if (!this.chart) {
            /* eslint-disable */
            this.chart = new LineAreaChart()
        }

        // Pass chart some parameters and (re)render it
        this.chart
            .container(node)
            .data(props.data)
            .title(props.title)
            .labelY(props.labelY || "")
            .xDateMax(props.xDateMax || null)
            .xDateMin(props.xDateMin || null)
            .resizeEventListenerId(props.resizeEventListenerId || "listener-id")
            .xTicksCount(props.xTicksCount)
            .xTickFormat(props.xTickFormat)
            .yTickFormat(props.yTickFormat)
            .centerTicks(props.centerTicks || false)
            .tipOffsetY(props.tipOffsetY)
            .yBottomOffset(props.yBottomOffset != null ? props.yBottomOffset : 0.9)
            .yTopOffset(props.yTopOffset != null ? props.yTopOffset : 0.1)
            .shadedRanges(props.shadedRanges)

        if (props.hideYAxisNumbers !== undefined) {
            this.chart.hideYAxisNumbers(props.hideYAxisNumbers)
        }

        if (props.titleHover !== undefined) {
            this.chart.titleHover(props.titleHover)
        }

        if (props.titleLabelFontSize !== undefined) {
            this.chart.titleLabelFontSize(props.titleLabelFontSize)
        }
        if (props.titleLabelOffsetX !== undefined) {
            this.chart.titleLabelOffsetX(props.titleLabelOffsetX)
        }
        if (props.titleLabelOffsetY !== undefined) {
            this.chart.titleLabelOffsetY(props.titleLabelOffsetY)
        }

        if (props.labelYOffsetX !== undefined) {
            this.chart.labelYOffsetX(props.labelYOffsetX)
        }

        if (props.zeroBasis !== undefined) {
            this.chart.zeroBasis(props.zeroBasis)
        }

        if (props.gridView !== undefined) {
            this.chart.gridView(props.gridView)
        }

        if (props.staticTipXPosition !== undefined) {
            this.chart.staticTipXPosition(props.staticTipXPosition)
        }

        if (props.customStaticTipXPosition !== undefined) {
            this.chart.customStaticTipXPosition(props.customStaticTipXPosition)
        }

        if (props.customStaticTipXText !== undefined) {
            this.chart.customStaticTipXText(props.customStaticTipXText)
        }

        if (props.onChartMouseLeave) {
            this.chart.onChartMouseLeave(props.onChartMouseLeave)
        }

        if (props.onMainTipFire) {
            this.chart.onMainTipFire(props.onMainTipFire)
        }

        if (props.labelYFontSize !== undefined) {
            this.chart.labelYFontSize(props.labelYFontSize)
        }

        if (props.xRightOffset !== undefined) {
            this.chart.xRightOffset(props.xRightOffset)
        }

        if (props.curve !== undefined) {
            this.chart.curve(props.curve)
        }

        if (props.xLeftOffset !== undefined) {
            this.chart.xLeftOffset(props.xLeftOffset)
        }

        if (props.yValueMax !== undefined) {
            this.chart.yValueMax(props.yValueMax)
        }

        if (props.marginLeft) {
            this.chart.marginLeft(props.marginLeft)
        }

        if (props.marginTop) {
            this.chart.marginTop(props.marginTop)
        }

        if (props.yTicksCount) {
            this.chart.yTicksCount(props.yTicksCount)
        }

        if (props.svgHeight) {
            this.chart.svgHeight(props.svgHeight)
        }

        if (props.tooltip !== undefined) {
            this.chart.tooltip(props.tooltip)
        }

        if (props.staticTipHtml !== undefined) {
            this.chart.staticTipHtml(props.staticTipHtml)
        }

        if (props.shapeTip !== undefined) {
            this.chart.shapeTip(props.shapeTip)
        }

        if (props.candlestickGroupMargin !== undefined) {
            this.chart.candlestickGroupMargin(props.candlestickGroupMargin)
        }

        if (props.maxCandlestickWidth !== undefined) {
            this.chart.maxCandlestickWidth(props.maxCandlestickWidth)
        }

        if (props.candlePadding !== undefined) {
            this.chart.candlePadding(props.candlePadding)
        }

        if (props.candleTopBottomLines !== undefined) {
            this.chart.candleTopBottomLines(props.candleTopBottomLines)
        }

        if (props.curve !== undefined) {
            this.chart.curve(props.curve)
        }

        this.chart.render()
    }
}
