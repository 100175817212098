import * as React from "react"

const OptionsIcon = (props) => (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)">
            <path
                d="M5.125 16.375h-2.5A.625.625 0 0 0 2 17v2.5a.625.625 0 0 0 .625.625h2.5a.625.625 0 0 0 .625-.625V17a.625.625 0 0 0-.625-.625Zm0-12.5h-2.5A.625.625 0 0 0 2 4.5V7a.625.625 0 0 0 .625.625h2.5A.625.625 0 0 0 5.75 7V4.5a.625.625 0 0 0-.625-.625Zm0 6.25h-2.5A.625.625 0 0 0 2 10.75v2.5a.625.625 0 0 0 .625.625h2.5a.625.625 0 0 0 .625-.625v-2.5a.625.625 0 0 0-.625-.625ZM21.375 17h-12.5a.625.625 0 0 0-.625.625v1.25a.625.625 0 0 0 .625.625h12.5a.624.624 0 0 0 .625-.625v-1.25a.624.624 0 0 0-.625-.625Zm0-12.5h-12.5a.625.625 0 0 0-.625.625v1.25A.625.625 0 0 0 8.875 7h12.5A.625.625 0 0 0 22 6.375v-1.25a.625.625 0 0 0-.625-.625Zm0 6.25h-12.5a.625.625 0 0 0-.625.625v1.25a.625.625 0 0 0 .625.625h12.5a.624.624 0 0 0 .625-.625v-1.25a.624.624 0 0 0-.625-.625Z"
                fill={"#666D74" || props.fill}
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default OptionsIcon
