import React from "react"

import "./ColorPicker.css"

const colors = [
    ["#1C9690", "#F3833F", "#2187C1", "#8E3FF3", "#464B86"],
    ["#55DDE0", "#E43F6F", "#FF99C8", "#C9E4CA", "#FFCF00"],
]

function ColorPicker(props) {
    const { value = "", onColorSelect = () => null } = props

    return (
        <>
            <div className="color-picker">
                {colors.map((row) => {
                    return (
                        <div className="color-picker__row">
                            {row.map((color) => (
                                <button
                                    key={`color-picker-item__${color}`}
                                    className="color-picker__item"
                                    style={{ backgroundColor: color }}
                                    onClick={() => onColorSelect(color)}
                                >
                                    {color === value && (
                                        <svg
                                            width="100%"
                                            height="100%"
                                            viewBox="0 0 8 6"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M2.74982 4.73743L1.01232 2.99993L0.420654 3.58743L2.74982 5.9166L7.74982 0.916601L7.16232 0.329102L2.74982 4.73743Z"
                                                fill="white"
                                            />
                                        </svg>
                                    )}
                                </button>
                            ))}
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default ColorPicker
