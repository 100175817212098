import * as React from "react"

function PestDegreeIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M5.64 5.195h7.72a.149.149 0 00.148-.148c0-.742-.163-1.423-.48-2.006a3.42 3.42 0 00-1.374-1.373c-.582-.317-1.263-.48-2.006-.48h-.296c-.743 0-1.424.163-2.006.48a3.42 3.42 0 00-1.373 1.373c-.318.583-.48 1.264-.48 2.006 0 .082.066.148.148.148z"
                fill={props.fill || "#fff"}
            />
            <path
                d="M17.441 9.5h-2.746V7.645a2.578 2.578 0 002.58-2.58.149.149 0 00-.149-.148h-1.113a.149.149 0 00-.149.148c0 .646-.523 1.17-1.169 1.17H4.305a1.169 1.169 0 01-1.17-1.17.149.149 0 00-.148-.148H1.874a.149.149 0 00-.148.148 2.578 2.578 0 002.579 2.58V9.5H1.559a.149.149 0 00-.149.148v1.04c0 .081.067.148.149.148h2.746v1.781c0 .12.003.241.013.358a3.565 3.565 0 00-2.166 3.279c0 .081.067.148.149.148H3.34a.149.149 0 00.148-.148c0-.82.444-1.538 1.106-1.924a5.221 5.221 0 002.286 2.774 5.177 5.177 0 001.768.64.149.149 0 00.149-.149V8.98c0-.081.067-.148.148-.148h1.114c.081 0 .148.067.148.148v8.614c0 .081.067.148.148.148a5.18 5.18 0 001.769-.64 5.221 5.221 0 002.286-2.774 2.234 2.234 0 011.102 1.926c0 .081.066.148.148.148h1.04a.149.149 0 00.148-.148 3.565 3.565 0 00-2.166-3.279c.008-.118.013-.237.013-.358v-1.781h2.746a.149.149 0 00.149-.149V9.649a.149.149 0 00-.149-.148z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default PestDegreeIcon
