import * as React from "react"

function DroughtIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M15.27 17.412V5.51a5.583 5.583 0 00-1.55-3.838A5.42 5.42 0 009.98.002a5.411 5.411 0 00-3.835 1.436 5.572 5.572 0 00-1.778 3.735 23.019 23.019 0 00-2.05 2.103C.777 9.079 0 10.656 0 11.96c0 1.306.778 2.883 2.315 4.686a22.283 22.283 0 002.235 2.261.633.633 0 00.827 0c.793-.699 1.537-1.455 2.224-2.261 1.537-1.803 2.316-3.38 2.316-4.686S9.137 9.08 7.6 7.276a22.724 22.724 0 00-1.96-2.02 4.266 4.266 0 011.366-2.848 4.143 4.143 0 012.929-1.094 4.15 4.15 0 012.858 1.27A4.274 4.274 0 0113.99 5.51v5.512l-3.017 2.996a.654.654 0 00-.01.918.635.635 0 00.904.01l2.126-2.11V25a.654.654 0 00.197.439.635.635 0 00.883 0A.654.654 0 0015.27 25v-5.703l1.612 1.72a.64.64 0 00.703.175.637.637 0 00.362-.349.657.657 0 00-.137-.72l-2.54-2.711zM6.635 8.125c1.31 1.538 2.003 2.865 2.003 3.836 0 1.803-2.364 4.368-3.677 5.584a21.538 21.538 0 01-1.678-1.747c-1.31-1.539-2.004-2.865-2.004-3.837 0-1.537 1.72-3.628 3.04-4.965v3.587a.653.653 0 00.199.439.635.635 0 00.882 0 .653.653 0 00.198-.439V6.996c.318.325.682.707 1.037 1.129z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default DroughtIcon
