import React, { useState, useEffect, useContext } from "react"
import useDecadalData from "../../hooks/useDecadalData"
import ReportDownloadButton from "../LongTerm/components/ReportDownloadButton"

import DecadalChart from "./components/DecadalChart"

import { useParams } from "react-router-dom"
import { useAdaptationValues } from "../../layouts/Climate/ClimateProvider"
import http from "../../Util/http"
import { capitalizeFirstCharacter } from "../../helpers/wordHelper"

function Decadal(props) {
    const { reportId } = useParams()

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})

    const {
        ready,
        locations,
        crops,
        scenarios,
        variables,
        risks,
        selectedLocation,
        selectedCrop,
        selectedScenario,
        selectedVariable,
        selectedRisk,
        setSelectedLocation,
        setSelectedCrop,
        setSelectedScenario,
        setSelectedVariable,
        setSelectedRisk,
        variablesByDefault,
    } = useAdaptationValues()

    // Fetch info
    useEffect(() => {
        if (!ready) return

        if (!loading) setLoading(true)

        let query = `?scenario=${selectedScenario}&location=${selectedLocation}`

        if (variablesByDefault && selectedVariable) query = query + `&var=${selectedVariable}`
        else if (!variablesByDefault && selectedRisk) query = query + `&risk=${selectedRisk}`

        const request = http.get(`/api/v1/adapt/${reportId}/decadal${query}`, { timeout: 180000 })
        request
            .then((response) => {
                console.log(response.data)
                setData(response.data)
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false))

        return () => request.cancel()
    }, [ready, selectedLocation, selectedCrop, selectedVariable, selectedRisk, selectedScenario, variablesByDefault])

    const { chartData } = useDecadalData(data)

    const selectedRiskObj = risks.find(({ id }) => id === selectedRisk.toString())
    const selectedVariableObj = variables.find(({ value }) => value === selectedVariable)
    const selectedScenarioObj = scenarios.find((s) => s.value === selectedScenario)

    return (
        <>
            <ReportDownloadButton
                text={`Decadal Risk / ${selectedLocation} / ${
                    selectedCrop && capitalizeFirstCharacter(selectedCrop)
                } / ${
                    variablesByDefault
                        ? selectedVariableObj.label
                        : `${selectedRiskObj.title} (${selectedRiskObj.subtitle}, ${selectedRiskObj.stage})`
                } / ${selectedScenarioObj.label}`}
            />
            <div className="overview">
                <div className="overview__main">
                    <DecadalChart
                        data={chartData}
                        loading={loading}
                        locations={locations}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        crops={crops}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        variables={variables}
                        selectedVariable={selectedVariable}
                        setSelectedVariable={setSelectedVariable}
                        selectedVariableObj={selectedVariableObj}
                        risks={risks}
                        selectedRisk={selectedRisk}
                        setSelectedRisk={setSelectedRisk}
                        selectedRiskObj={selectedRiskObj}
                        scenarios={scenarios}
                        selectedScenario={selectedScenario}
                        setSelectedScenario={setSelectedScenario}
                        variablesByDefault={variablesByDefault}
                    />
                </div>
            </div>
        </>
    )
}

export default Decadal
