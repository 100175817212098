import React from "react"

import ArrowRight from "../../../../ui/Icons/ArrowRight"

import { titleCase } from "../../../../Util/General"

// TODO: Any difference in performance for children importing styles?
// import "./AlertSettings.css"

const CropTab = (props) => {
    const { name } = props
    return (
        <div id={`${name}-crop-tab__alert-settings`} className="alert-settings__alerts__crop-menu">
            <div className="alert-settings__alerts__crop-menu-title">{titleCase(name)} Crop</div>

            <ArrowRight className="alert-settings__alert__crop-menu-icon" />
        </div>
    )
}

export default CropTab
