import React, { useState, useEffect, useContext } from "react"

import { AuthContext } from "../../Auth/Auth"
import { useParams } from "react-router-dom"
import Scenarios from "./components/Scenarios"
import http from "../../Util/http"

import "./PlantingTool.css"
import { useMemo } from "react"
import RiskTable from "./components/RiskTable"
import usePlantingToolData from "../../hooks/usePlantingToolData"
import PlantingToolChart from "./components/PlantingToolChart"
import { FieldProperties } from "../../components"
import { calculateProbabilities, populateScenarios } from "../../helpers/plantingTool"

const _MIN_WINDOW_DAYS = 14

function PlantingTool(props) {
    const { id } = useParams()
    const tab = props.config?.tabName ?? "planting_tool"

    // scenarios logic
    const [scenarios, setScenarios] = useState({})
    const [loading, setLoading] = useState(false)
    const { currentUser } = useContext(AuthContext)

    const [{ selectedRisk, selectedAlert }, setSelectedRiskAlert] = useState({
        selectedRisk: "climate_risk",
        selectedAlert: "default",
    })

    // planting dates logic

    const now = new Date()
    now.setHours(0, 0, 0, 0)

    const _from = new Date(now.getTime())
    _from.setDate(now.getDate())
    const _to = new Date(now.getTime())
    _to.setDate(now.getDate() + _MIN_WINDOW_DAYS)

    const [startDate, setStartDate] = useState(_from)
    const [endDate, setEndDate] = useState(_to)

    useEffect(() => {
        setScenarios({})
        setLoading(true)
        Promise.allSettled([
            http.get(`/api/v1/alertsettings/${id}?tool_name=${tab}`, {
                timeout: 180000,
            }),
            http.get(`/api/v1/scenario/${id}?tool_name=${tab}`),
            // http.get(`/api/v1/decision_tools/settings/${id}?tool_name=${tab}`),
        ])
            .then(
                ([
                    { status: statusData, value: data },
                    { status: statusScenarios, value: scenarios },
                    // { status: statusDates, value: datesData },
                ]) => {
                    if (statusData === "fulfilled" && statusScenarios === "fulfilled") {
                        const result = {
                            current: {
                                uid: "current",
                                name: "Current scenario",
                                plantingDate: new Date(now.getTime()),
                                length: _MIN_WINDOW_DAYS,
                                color: "#1C9690",
                                chart_data: data.chart_data,
                                checked: true,
                                isVisible: true,
                            },
                        }

                        // Iterate alerts, set forecast values and time

                        result?.current?.chart_data &&
                            Object.keys(result.current.chart_data).forEach((riskId) => {
                                Object.keys(result.current.chart_data[riskId]).forEach((alertId) => {
                                    result.current.chart_data[riskId][alertId].time =
                                        result.current.chart_data[riskId][alertId].alert_data.time
                                    result.current.chart_data[riskId][alertId].values = calculateProbabilities(
                                        result.current.chart_data[riskId][alertId].alert_data.time,
                                        result.current.chart_data[riskId][alertId].alert_data.forecast_values,
                                        result.current.chart_data[riskId][alertId].alert_data.conditional,
                                        result.current.chart_data[riskId][alertId].alert_data.threshold
                                    )
                                })
                            })

                        const _from = localStorage.getItem(`@${tab}/${currentUser.uid}/${id}/startDate`)
                        const _to = localStorage.getItem(`@${tab}/${currentUser.uid}/${id}/endDate`)
                        if (_from) setStartDate(new Date(_from))
                        if (_to) setEndDate(new Date(_to))

                        setScenarios(populateScenarios(result, scenarios))
                    } else {
                        setScenarios({})
                    }
                }
            )
            .finally(() => setLoading(false))
    }, [id, tab])

    function handleEditScenario(uid, scenario, updatedPaths) {
        // move end date to match scenario
        const scenarioEndDate = new Date(scenario.plantingDate.getTime())
        scenarioEndDate.setDate(scenarioEndDate.getDate() + scenario.length + 2)

        if (scenarioEndDate > endDate) setEndDate(scenarioEndDate)

        const _scenarios = { ...scenarios }
        // change values for the ones in the threshold
        _scenarios[uid] = Object.assign({ uid }, scenario)
        setScenarios(_scenarios)

        http.post(`/api/v1/scenario/${id}?tool_name=${tab}`, { scenario_id: uid, paths: updatedPaths })
            .then((data) => {
                console.log(data)
            })
            .catch(console.log)
    }

    function handleDeleteScenario(uid) {
        const _scenarios = { ...scenarios }
        delete _scenarios[uid]
        setScenarios(_scenarios)

        http.delete(`/api/v1/scenario/${id}?scenario_id=${uid}&tool_name=${tab}`).then((data) => {
            console.log(data)
        })
    }

    function handleScenarioCheckStatusChange(uid, checked) {
        const _scenarios = { ...scenarios, [uid]: { ...scenarios[uid], checked } }
        setScenarios(_scenarios)
    }

    function handleRiskAlertSelected({ riskKey, alertId }) {
        setSelectedRiskAlert({ selectedRisk: riskKey, selectedAlert: alertId })
    }

    const {
        _scenarios,
        _tableColumns: riskTableColumns,
        _tableData: riskTableData,
        _chartData,
        _availableAlerts,
    } = usePlantingToolData(scenarios, startDate, endDate, tab)

    const { mappedChartData, maxValue } = useMemo(() => {
        let data = []
        if (loading) return {}

        // const selectedRisk = "climate_risk"
        // const selectedAlert = "default"

        const lines = []
        const highlights = []
        const notVisible = []
        let maxValue = 0

        for (let uid in _scenarios) {
            if (!_chartData[uid][selectedRisk] || !_chartData[uid][selectedRisk][selectedAlert]) continue

            const scenario = _scenarios[uid]
            const points = _chartData[uid][selectedRisk][selectedAlert].points

            if (_chartData[uid][selectedRisk][selectedAlert].maxValue > maxValue) {
                maxValue = _chartData[uid][selectedRisk][selectedAlert].maxValue
            }

            if (!scenario.checked) continue

            // scenario's planting window
            // planting date time window
            const _startDate = new Date(_scenarios[uid].plantingDate.getTime())
            const _endDate = new Date(_scenarios[uid].plantingDate.getTime())
            _endDate.setDate(_scenarios[uid].plantingDate.getDate() + _scenarios[uid].length)

            const line = {
                id: uid,
                type: "line",
                color: scenario.isVisible ? scenario.color : "#ddd",
                points: points.map((d) => ({
                    x: d.time,
                    y: d.value * 100,
                    scenario: { name: scenario.name, color: scenario.color, isVisible: scenario.isVisible },
                })),
                "stroke-width": 2,
            }

            const highlight = {
                id: `${uid}-highlight`,
                hidePoints: true,
                showTips: _chartData[uid][selectedRisk][selectedAlert].maxValue > 0,
                type: "line",
                color: scenario.isVisible ? scenario.color : "#ddd",
                points: points
                    .filter((d) => d.time > _startDate.getTime() && d.time < _endDate.getTime())
                    .map((d) => ({ x: d.time, y: d.value * 100 })),
                "stroke-width": 4,
            }

            if (scenario.isVisible) {
                line.alwaysOnTop = true
                highlight.alwaysOnTop = true
                lines.push(line)
                highlights.push(highlight)
            } else {
                line.hidePoints = true
                highlight.hidePoints = true
                notVisible.push(line)
                notVisible.push(highlight)
            }
        }

        data = [...notVisible, ...lines, ...highlights]

        return { mappedChartData: data, maxValue }
    }, [_scenarios, _chartData, loading, selectedRisk, selectedAlert])

    // sets the tooltip text
    var popoverMessage =
        "The Planting Tool allows you to compare different planting scenarios in one graph. You will see what planting scenario has a higher climate risk probability (%), so that you can make an informed decision on the optimum time to plant."

    switch (tab) {
        case "planting_tool":
            popoverMessage =
                "The Planting Tool allows you to compare different planting scenarios in one graph. You will see what planting scenario has a higher climate risk probability (%), so that you can make an informed decision on the optimum time to plant."
            break
        case "harvesting_tool":
            popoverMessage =
                "The Harvesting Tool allows you to compare different harvesting scenarios in one graph. You will see what harvesting scenario has a higher climate risk probability (%), so that you can make an informed decision on the optimum time to harvest."
            break
        case "spraying_tool":
            popoverMessage =
                "The Spraying Tool allows you to compare different spraying scenarios in one graph. You will see what spraying scenario has a higher climate risk probability (%), so that you can make an informed decision on the optimum time to spray."
            break
        case "nitrogen_application_tool":
            popoverMessage =
                "The Nitrogen Application Tool allows you to compare different nitrogen application scenarios in one graph. You will see what nitrogen application scenario has a higher climate risk probability (%), so that you can make an informed decision on the optimum time to apply."
            break
        default:
            popoverMessage =
                "The Planting Tool allows you to compare different planting scenarios in one graph. You will see what planting scenario has a higher climate risk probability (%), so that you can make an informed decision on the optimum time to plant."
            break
    }

    return (
        <>
            <div className="planting-tool-v2">
                <div className="planting-tool-v2__main">
                    <div className="planting-tool-v2__main__graph">
                        <PlantingToolChart
                            scenarios={_scenarios}
                            setScenarios={setScenarios}
                            mappedChartData={mappedChartData}
                            maxValue={maxValue}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={(date) => {
                                setSelectedRiskAlert({ selectedRisk: "climate_risk", selectedAlert: "default" })
                                const x = new Date(date)
                                x.setDate(date.getDate() + _MIN_WINDOW_DAYS)

                                if (x > endDate) {
                                    setEndDate(new Date(x.getTime()))
                                    localStorage.setItem(`@${tab}/${currentUser.uid}/${id}/endDate`, x.toISOString())
                                }

                                setStartDate(date)
                                localStorage.setItem(`@${tab}/${currentUser.uid}/${id}/startDate`, date.toISOString())
                            }}
                            setEndDate={(date) => {
                                setSelectedRiskAlert({ selectedRisk: "climate_risk", selectedAlert: "default" })
                                setEndDate(date)
                                localStorage.setItem(`@${tab}/${currentUser.uid}/${id}/endDate`, date.toISOString())
                            }}
                            availableAlerts={_availableAlerts}
                            onRiskAlertSelected={handleRiskAlertSelected}
                            selectedRiskAlertKey={selectedRisk + selectedAlert}
                            loading={loading}
                            popoverMessage={popoverMessage}
                        />
                    </div>
                    <div className="planting-tool-v2__main__table">
                        <RiskTable loading={loading} columns={riskTableColumns} data={riskTableData} />
                    </div>
                </div>
                <div className="planting-tool-v2__aside">
                    <div className="plating-tool__field-properties" style={{ pointerEvents: "none" }}>
                        <FieldProperties />
                    </div>
                    <Scenarios
                        loading={loading}
                        mainScenario={_scenarios.current}
                        scenarios={_scenarios}
                        onNewScenario={handleEditScenario}
                        onEditScenario={handleEditScenario}
                        onDeleteScenario={handleDeleteScenario}
                        onCheckStatusChange={handleScenarioCheckStatusChange}
                        startDate={startDate}
                        now={now}
                        tab={tab}
                    />
                </div>
            </div>
        </>
    )
}

export default PlantingTool
