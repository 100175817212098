import React from "react"

function PestDegreeDaysIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path d="M8 7a4 4 0 1 1 8 0H8Z" fill="#666D74" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.828 8.172 17 11v2h4v1.5h-4v1.503l2.828 2.829-1.06 1.06-2.1-2.099a5.006 5.006 0 0 1-3.918 3.151V11h-1.5v9.944a5.006 5.006 0 0 1-3.919-3.15l-2.099 2.098-1.06-1.06L7 16.003V14.5H3V13h4v-2L4.172 8.172l1.06-1.061L7.122 9h9.757l1.889-1.89 1.06 1.062Z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default PestDegreeDaysIcon
