import * as React from "react"

function LowWindDayIcon(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.5 1A2.5 2.5 0 007 3.5a.5.5 0 11-1 0A3.5 3.5 0 119.5 7h-9a.5.5 0 010-1h9a2.5 2.5 0 100-5zm-7 1a1 1 0 00-1 1 .5.5 0 11-1 0 2 2 0 112 2h-2a.5.5 0 010-1h2a1 1 0 000-2zM0 8.5A.5.5 0 01.5 8h7.042a3 3 0 11-3 3 .5.5 0 011 0 2 2 0 102-2H.5a.5.5 0 01-.5-.5z"
                fill={props.fill || "#fff"}
            />
        </svg>
    )
}

export default LowWindDayIcon
