import React from "react"

function HeatStressRiskIcon(props) {
    const {} = props
    return (
        <>
            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <path
                    d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5Zm-1-6v4h2V1h-2Zm0 18v4h2v-4h-2Zm12-8h-4v2h4v-2ZM5 11H1v2h4v-2Zm11.24 6.66 2.47 2.47 1.41-1.41-2.47-2.47-1.41 1.41ZM3.87 5.28l2.47 2.47 1.41-1.41-2.47-2.47-1.41 1.41Zm2.47 10.96-2.47 2.47 1.41 1.41 2.47-2.47-1.41-1.41ZM18.72 3.87l-2.47 2.47 1.41 1.41 2.47-2.47-1.41-1.41Z"
                    fill="#666D74"
                />
            </svg>
        </>
    )
}

export default HeatStressRiskIcon
