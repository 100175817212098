import React from "react"

import "react-datepicker/dist/react-datepicker.css"

import Select from "../../../ui/Select/Select"
import { LineAreaChartComponent } from "../../../components/Charts/LineAreaChart/LineAreaChart.component"
import Section from "../../../ui/Section"
import useContainerSize from "../../../hooks/useContainerSize"
import LoadingBar from "../../../components/LoadingBar"
import ChartLabels from "../../../components/LongTerm/ChartLabels/ChartLabels"
import ChartLabelItem from "../../../components/LongTerm/ChartLabels/ChartLabelItem"
import MapPinIcon from "../../../ui/Icons/MapPinIcon"

export function LineIcon({ fill }) {
    return (
        <div
            style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{ width: "100%", height: 2, backgroundColor: fill || "#000" }} />
        </div>
    )
}

function DepartureChart(props) {
    const {
        loading,
        data,
        threshold,
        locations,
        crops,
        scenarios,
        variables,
        risks,
        selectedLocation,
        selectedCrop,
        selectedScenario,
        selectedVariable,
        selectedRisk,
        setSelectedLocation,
        setSelectedCrop,
        setSelectedScenario,
        setSelectedVariable,
        setSelectedRisk,
    } = props

    const [containerRef, { height }] = useContainerSize(1000)

    const selectedVariableObj = variables.find(({ value }) => value === selectedVariable)

    let labelY =
        selectedVariableObj.label + " " + ((selectedVariableObj.units && `(${selectedVariableObj.units})`) || "")
    let tickFormat = selectedVariableObj.units || ""

    return (
        <>
            <Section
                title="Climate Tipping Point"
                description="This page displays the approximated year when variances in weather due to climate change indicates a major change in a location's climate - i.e. when the average temperature sits outside the range of the warmest and coolest year."
                header={() =>
                    data &&
                    data.points &&
                    data.points.length > 0 && (
                        <>
                            <Select
                                icon={MapPinIcon}
                                value={selectedLocation || ""}
                                onChange={(e) => setSelectedLocation(e.target.value)}
                            >
                                {locations &&
                                    locations.map((location) => (
                                        <option value={location.name} key={`departure_location_${location.name}`}>
                                            {location.name}
                                        </option>
                                    ))}
                            </Select>
                            <Select
                                value={selectedVariable || ""}
                                onChange={(e) => setSelectedVariable(e.target.value)}
                            >
                                {variables &&
                                    variables
                                        // .filter((v) => v.value === "t2m")
                                        .map((v) => (
                                            <option value={v.value} key={`departure_var_${v.value}`}>
                                                {v.label}
                                            </option>
                                        ))}
                            </Select>
                            <Select
                                value={selectedScenario || ""}
                                onChange={(e) => setSelectedScenario(e.target.value)}
                            >
                                {scenarios.map((scenario) => (
                                    <option value={scenario.value} key={`departure_scenario_${scenario.value}`}>
                                        {scenario.label}
                                    </option>
                                ))}
                            </Select>
                        </>
                    )
                }
            >
                {loading && <LoadingBar loading />}
                <ChartLabels>
                    <ChartLabelItem renderIcon={() => <LineIcon fill="#1C9690" />}>
                        <label>Projection</label>
                    </ChartLabelItem>
                    <ChartLabelItem renderIcon={() => <LineIcon fill="#F37920" />}>
                        <label>Year of departure</label>
                    </ChartLabelItem>
                </ChartLabels>
                <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
                    <LineAreaChartComponent
                        labelY={labelY}
                        yBottomOffset={0.2}
                        yTopOffset={0.2}
                        zeroBasis={false}
                        xRightOffset={0.1}
                        svgHeight={(height && height - 10) || 0}
                        data={data}
                        customStaticTipXPosition={new Date(`Jan 1, ${threshold}`)}
                        customStaticTipXText={threshold}
                        tooltip={(EVENT, { key, values, colors, lines, points }, state) => {
                            return new Date(key).getFullYear()
                        }}
                    ></LineAreaChartComponent>
                </div>
            </Section>
        </>
    )
}

export default DepartureChart
