import { useMemo } from "react"
import { getQuartiles } from "../Util/QuartilesUtil"

export default function useDecadalData(data) {
    return useMemo(() => {
        let chartData = {}

        if (data && Object.keys(data).length > 0) {
            chartData = {
                type: "candlestick",
                color: () => "#218980",
                points: [],
            }

            for (let i = 0; i < Object.keys(data).length; i++) {
                let key = Object.keys(data)[i]

                if (!["2010", "2020", "2030", "2040", "2050", "2060"].includes(key)) continue

                let values = data[key]

                const { min, max, q1, q2, q3 } = getQuartiles(values)

                chartData.points.push({
                    x: new Date(`Jan 1, ${key}`),
                    y0: min,
                    y1: max,
                    yMax: q3,
                    yMid: q2,
                    yMin: q1,
                })
            }
        }

        return { chartData }
    }, [data])
}
